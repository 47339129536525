import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadCountriesApi, loadShippingFeesApi } from '../services/api';

import { getClientToken } from '../selectors';

export const LOAD_COUNTRIES = 'countries/loadCountries';
export const LOAD_SHIPPING_FEES = 'countries/loadShippingFees';

const initialState = {
  countries: {},
  shippingFees: [],
  loading: {},
  errors: {},
};

export const loadCountries = createAsyncThunk(LOAD_COUNTRIES, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const countries = await loadCountriesApi(clientToken);
  return { countries };
});

export const loadShippingFees = createAsyncThunk(
  LOAD_SHIPPING_FEES,
  async (params, { getState }) => {
    const clientToken = getClientToken(getState());
    const shippingFees = await loadShippingFeesApi(clientToken);
    return { shippingFees };
  },
);

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadCountries
      .addCase(loadCountries.pending, (state, action) => {
        state.loading[LOAD_COUNTRIES] = true;
        state.errors[LOAD_COUNTRIES] = null;
      })
      .addCase(loadCountries.fulfilled, (state, action) => {
        state.countries = action.payload.countries.reduce((acc, cur) => {
          acc[cur.objectId] = cur;
          return acc;
        }, {});
        state.loading[LOAD_COUNTRIES] = false;
        state.errors[LOAD_COUNTRIES] = null;
      })
      .addCase(loadCountries.rejected, (state, action) => {
        state.loading[LOAD_COUNTRIES] = false;
        state.errors[LOAD_COUNTRIES] = action.error;
      })
      // loadShippingFees
      .addCase(loadShippingFees.pending, (state, action) => {
        state.loading[LOAD_SHIPPING_FEES] = true;
        state.errors[LOAD_SHIPPING_FEES] = null;
      })
      .addCase(loadShippingFees.fulfilled, (state, action) => {
        state.shippingFees = action.payload?.shippingFees || [];
        state.loading[LOAD_SHIPPING_FEES] = false;
        state.errors[LOAD_SHIPPING_FEES] = null;
      })
      .addCase(loadShippingFees.rejected, (state, action) => {
        state.loading[LOAD_SHIPPING_FEES] = false;
        state.errors[LOAD_SHIPPING_FEES] = action.error;
      });
  },
});

export default countriesSlice.reducer;
