import React from 'react';

import { useSelector } from 'react-redux';

import { LOAD_ARTICLES_FOR_RESOURCES } from 'bubble-reducers/src/reducers/articles';

import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';

export default function AuthorArticlesSection({ authorObjectId }) {
  const articlesObjectIds = useSelector(
    (state) => state.articles.articlesByResourceId[authorObjectId],
  );
  const isLoading = useSelector((state) => state.articles.loading[LOAD_ARTICLES_FOR_RESOURCES]);

  return (
    <>
      {isLoading && (
        <div className="col-12 d-flex justify-content-center pb-5">
          <span className="spinner-border spinner-lg" />
        </div>
      )}
      <div className="row">
        {articlesObjectIds?.map((objectId, index) => (
          <div key={'relatedarticle_' + objectId} className="col-12 mt-3">
            <ArticleHorizontalCardWhite big articleObjectId={objectId} />
          </div>
        ))}
        {!isLoading && !articlesObjectIds?.length && (
          <div className="col-12">
            Nous n'avons pas encore d'articles pour cet auteur{' '}
            <span role="img" aria-label="pray">
              🙏
            </span>
          </div>
        )}
      </div>
    </>
  );
}
