import React from 'react';

import placeholderJpegUrl from '@/assets/placeholders/placeholder-item.jpg';

import './ImageCover.css';

export default function ImageCover({ height, width, imageUrl, alt, classNames, hoverable }) {
  return (
    <img
      style={{
        height: `${height ? height : '120px'}`,
        width: `${width ? width : '100%'}`,
        minWidth: `${width ? width : '100%'}`,
        maxWidth: `${width ? width : '100%'}`,
      }}
      className={['image-cover']
        .concat(classNames)
        .concat(hoverable ? 'hoverable transition' : null)
        .filter((r) => r)
        .join(' ')}
      src={imageUrl || placeholderJpegUrl}
      alt={alt || ''}
    />
  );
}
