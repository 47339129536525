import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../components/Icon/Icon';
import ArticleHorizontalCard from '../ArticleHorizontalCard/ArticleHorizontalCard';
import SearchAlbumCard from './components/SearchAlbumCard';
import SearchAuthorCard from './components/SearchAuthorCard';
import SearchProductCard from './components/SearchProductCard';
import SearchSerieCard from './components/SearchSerieCard';
import SearchStoreCard from './components/SearchStoreCard';

const SearchResults = (props) => {
  const hits = (props.hits || []).filter((index) => index.data && index.data.length > 0);
  const totalIndexes = hits.length;
  const searchBarPositions = props.searchBarRef.current.getBoundingClientRect() || {};
  let showAddAlbumLink = false;

  const articlesHits = hits.find((searchIndex) => searchIndex.type === 'article') || { data: [] };
  const potentialStoreIndex = hits.find((idx) => idx.type === 'store');
  const potentialAlbumIndex = hits.find((idx) => idx.type === 'album');
  if (potentialAlbumIndex && potentialAlbumIndex.data?.length < 4) {
    showAddAlbumLink = true;
  }

  const getTitleForType = (type) => {
    const map = {
      product: 'Goodies',
      serie: 'Séries',
      album: 'Albums populaires',
      author: 'Auteurs / dessinateurs',
      article: 'Articles',
      store: 'Librairies',
    };

    return map[type] || '';
  };

  const renderCard = (type, hit) => {
    switch (type) {
      case 'serie':
        return <SearchSerieCard key={hit.objectId} className="col-12 mb-3" serie={hit} />;
      case 'album':
        return <SearchAlbumCard key={hit.objectId} className="col-12 mb-3" album={hit} />;
      case 'author':
        return <SearchAuthorCard key={hit.objectId} className="col-12 mb-3" author={hit} />;
      case 'article':
        return (
          <div key={hit.objectId} className="col-sm-4 mb-3 d-flex flex-fill">
            <ArticleHorizontalCard article={hit} />
          </div>
        );
      case 'store':
        return (
          <div key={hit.objectId} className="col-12 mb-3">
            <SearchStoreCard store={hit} />
          </div>
        );
      case 'product':
        return (
          <div className="col-12 mb-3" key={'search_' + hit.objectId}>
            <SearchProductCard product={hit} />
          </div>
        );
      default:
        return (
          <div key={hit.objectId} className="col-6">
            {hit.name}
          </div>
        );
    }
  };

  hits.splice(2, 0, {
    type: 'product',
    data: [
      {
        objectId: 'w9pdlcpOnl',
        sku: 'bb-gift-card-80',
        permalink: 'bb-gift-card-80',
        type: 'gift-card',
        title: 'Cheque Cadeau 80€',
        description: "Cheque Cadeau Bubble d'une valeur de 80€",
        images: {
          front: {
            large: 'https://static-assets.bubblebd.com/app/gift-cards/cheque_cadeau_80.jpg',
            medium: null,
            mediumFramedGradient: null,
            smallFramed:
              'https://static-assets.bubblebd.com/app/gift-cards/cheque_cadeau_small.jpg',
          },
        },
        price: '80',
        giftCardDurationInMonths: '12',
      },
    ],
  });

  const renderCol = (searchIndex, index) => (
    <React.Fragment key={`search_${searchIndex.type}`}>
      <div className="col-lg-3 text-black">
        <div className="fw-bold mb-3 d-flex flex-fill">
          <div className="flex-fill text-black">{getTitleForType(searchIndex.type)}</div>
          <Link
            className="bb-d-text-size fw-normal"
            to={`/search?q=${props.text}&i=${searchIndex.type}s`}
          >
            Voir tout
          </Link>
        </div>
        <div className="row">
          {searchIndex.data.slice(0, 4).map((hit) => {
            return renderCard(searchIndex.type, hit);
          })}
          {searchIndex.type === 'album' && showAddAlbumLink && (
            <div className="col">
              <Link to="/ajouter-un-album">
                <Icon name="plus" /> Vous n'avez pas trouvé votre album ?
              </Link>
            </div>
          )}
        </div>
      </div>
      {totalIndexes > 1 && index + 1 < totalIndexes}
    </React.Fragment>
  );

  let count = 0; // used to display 'store' category if less than 3 indexes

  return (
    <div
      className="shadow p-3 px-4 search-result-zone"
      style={{ top: searchBarPositions.height + searchBarPositions.y - 4 }}
    >
      <div className="row">
        {hits.map((searchIndex, index) => {
          if (searchIndex.type === 'article' || searchIndex.type === 'store') return null;
          ++count;
          return renderCol(searchIndex, index);
        })}
        {potentialStoreIndex && count <= 3 && renderCol(potentialStoreIndex, count + 1)}
      </div>
      {!!articlesHits.data.length && (
        <>
          <div className="fw-bold pt-3 mb-3">Sur le blog Bubble</div>
          <div className="row">
            {articlesHits.data.map((article) => renderCard('article', article))}
          </div>
        </>
      )}
    </div>
  );
};

SearchResults.propTypes = {
  hits: PropTypes.array,
  text: PropTypes.string,
};

export default SearchResults;
