import React, { useCallback, useMemo, useState } from 'react';

import { compressNumber } from 'bubble-utils/src/number-utils';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import AttachedPromotionCard from '@/components/AttachedPromotionCard/AttachedPromotionCard';
import Cover from '@/components/Cover/Cover';
import EditorChoiceBadge from '@/components/EditorChoiceBadge/EditorChoiceBadge';
import { Icon } from '@/components/Icon/Icon';
import RevisionModals from '@/components/Modals/RevisionModals';
import SerieFollowBadge from '@/components/SerieFollowBadge/SerieFollowBadge';
import SerieTerminatedBadge from '@/components/SerieTerminatedBadge/SerieTerminatedBadge';
import StarLine from '@/components/StarLine/StarLine';
import TagSection from '@/components/TagSection/TagSection';

import SerieHeaderSummary from './SerieHeaderSummary';

export default function SerieHeader({ serie = {} }) {
  const isUserlogged = useIsUserlogged();

  const [enableRevisionModals, setEnableRevisionModals] = useState(false);

  const onClickRevision = useCallback(() => {
    if (isUserlogged()) {
      setEnableRevisionModals(true);
    }
  }, [isUserlogged, serie.objectId]);

  const images = serie.images || {};
  const front = images.front || {};

  const followCount = useMemo(
    () => compressNumber(serie.numberOfFollowers),
    [serie.numberOfFollowers],
  );

  return (
    <div className="row px-sm-3 pb-md-3">
      {enableRevisionModals && (
        <RevisionModals
          sourcePage="serie"
          serieObjectId={serie.objectId}
          callback={(value) => setEnableRevisionModals(value)}
        />
      )}

      <div className="d-none d-md-block col-md-3 col-lg-3 col-xl-3">
        <Cover rounded shadow fullWidth imageUrl={front.medium} />
        <button onClick={onClickRevision} className="py-3 btn btn-link m-0 p-0 w-100">
          Signaler une erreur sur la série
        </button>
      </div>

      <div className="col-md-9 col-lg-5 col-xl-6 py-md-5">
        <div className="d-flex align-items-center mb-2">
          <SerieTerminatedBadge isTerminated={serie.isTerminated} />
          {!!serie.editorChoice && (
            <div className="ps-2">
              <EditorChoiceBadge article={serie.editorChoice} />
            </div>
          )}
        </div>
        <h1 className="fw-bold bb-xl-text-size">{serie.title}</h1>
        <h2 className="bb-medium-large-text-size fw-normal">
          {[
            serie.numberOfAlbums
              ? `${serie.numberOfAlbums} tome${serie.numberOfAlbums > 1 ? 's' : ''}`
              : null,
            serie.type ? `${serie.type.charAt(0).toUpperCase() + serie.type.slice(1)}` : null,
          ]
            .filter(Boolean)
            .join(' - ')}
        </h2>
        <div className="d-flex flex-column flex-md-row align-items-md-center mt-md-n1">
          <div className="d-inline-block">
            <StarLine size="medium" note={serie.note} classes="up-x-pix" />
            <span className="fw-bold pe-2 ps-2 bb-medium-small-text-size">
              {serie.note ? serie.note.toFixed(1) : ''}
            </span>
          </div>
        </div>
        <TagSection tags={serie.tags} />
        <div className="d-flex d-md-none pb-4 pt-2 justify-content-center">
          <Cover rounded shadow fullWidth imageUrl={front.medium} />
        </div>
        <SerieHeaderSummary descriptionShort={serie.descriptionShort} />
        <button
          onClick={onClickRevision}
          className="d-block d-md-none pt-3 btn btn-link m-0 p-0 w-100"
        >
          Signaler une erreur sur la série
        </button>
      </div>

      <div className="col-md-12 col-lg-4 col-xl-3 text-center pt-5 mb-md-5">
        <div className="rounded bg-white bb-shadow-hover">
          <div className="d-flex align-items-center justify-content-between p-3">
            <div className="d-flex align-items-center text-success bb-medium-large-text-size">
              <Icon className="me-2 mt-n1" name="user-check" />
              <span>
                {followCount}
                {' abonné'}
                {serie.numberOfFollowers > 1 && 's'}
              </span>
            </div>
            <SerieFollowBadge serieObjectId={serie.objectId} />
          </div>
        </div>
        <AttachedPromotionCard serieObjectId={serie.objectId} />
      </div>
    </div>
  );
}
