import PropTypes from 'prop-types';
import React from 'react';

import './WithClickHandler.css';

const WithClickHandler = (props) => {
  const handleClick = (e) => {
    if (props.onClick) {
      if ('consumeEvent' in props) {
        e && e.stopPropagation();
        e && e.preventDefault();
      }
      if (!props.disabled && props.onClick) {
        props.onClick({ ...e, target: { ...e.target, id: props.elemId || e.target.id || null } });
      }
    }
  };
  const handleMouseDown = (e) => {
    if (props.onMouseDown) {
      if ('consumeEvent' in props) {
        e && e.stopPropagation();
        e && e.preventDefault();
      }

      if (!props.disabled && props.onMouseDown) {
        props.onMouseDown(e);
      }
    }
  };

  const handleMouseEnter = () => (props.onMouseEnter ? props.onMouseEnter() : null);
  const handleMouseLeave = () => (props.onMouseLeave ? props.onMouseLeave() : null);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`${props.className || ''} ${
        props.disabled ? (props.noDisabledStyle ? '' : 'disabled-bb') : 'link-bb'
      }`}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      style={{ cursor: props.disabled ? 'default' : 'pointer' }}
      data-bs-target={props.dataBsTarget}
      data-bs-toggle={props.dataBsToggle}
    >
      {props.children}
    </div>
  );
};

WithClickHandler.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  consumeEvent: PropTypes.bool,
};

export default WithClickHandler;
