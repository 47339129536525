import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { storeComment } from 'bubble-reducers/src/reducers/comments';

import './WriteComment.css';

const WriteComment = (props) => {
  const dispatch = useDispatch();
  const articleObjectId = props.articleObjectId;

  const user = useSelector((state) => state.user.user);

  const [content, setContent] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [invalid, setInvalid] = useState(true);

  const validate = () => {
    const emptyUserName = !newUsername.length;
    const emptyContent = !content.length;
    let invalid = emptyContent;
    if (!user?.username) {
      invalid = invalid || emptyUserName;
    }
    setInvalid(invalid);
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setNewUsername({ username: newUsername });
    validate();
  };

  const handleChange = (e) => {
    const content = e.target.value;
    setContent(content);
    validate();
  };

  const submitComment = (e) => {
    e.preventDefault();
    const userObjectId = user.objectId;
    if (newUsername) {
      dispatch(
        storeComment({
          articleObjectId,
          content,
          userObjectId,
          user: { username: newUsername },
        }),
      );
    } else {
      dispatch(storeComment({ articleObjectId, comment: content }));
    }
    setContent('');
    setNewUsername('');
    setInvalid(true);
  };

  const isLoggedIn = Boolean(user) && Boolean(user.objectId);
  const username = user?.username ? user.username : 'Anonyme';
  return (
    <div className="row mb-4">
      <form className="col-12">
        {!user?.username && (
          <div className="form-group">
            <input
              onChange={handleUsernameChange}
              className="form-control"
              type="text"
              placeholder="nom d'utilisateur"
            />
          </div>
        )}
        <div className="form-group">
          <textarea
            className="CommentWrite-textarea form-control"
            onChange={handleChange}
            value={content}
          />
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-md-10">
            {isLoggedIn ? (
              <span className="writeComment-greet-logged">
                Vous êtes connecté en tant que <span className="fw-bold">{username}</span>
              </span>
            ) : (
              <span className="writeComment-greet-guest">
                Vous devez vous <Link to="/login">connecter</Link> pour poster un commentaire
              </span>
            )}
          </div>
          <div className="col-md-2 col-4 offset-8 offset-md-0 pt-4 pt-md-0">
            <button
              type="submit"
              disabled={invalid || !isLoggedIn}
              onClick={submitComment}
              className={`btn ${isLoggedIn ? 'btn-warning' : 'btn-secondary'} fw-bold m-0 p-2`}
            >
              Poster
            </button>
          </div>
        </div>
        {props.noComments && (
          <button
            className={`btn ${isLoggedIn ? 'btn-warning' : 'btn-secondary'} fw-bold my-4 py-3`}
            disabled={invalid || !isLoggedIn}
            onClick={submitComment}
          >
            Poster le 1er commentaire
          </button>
        )}
      </form>
    </div>
  );
};

export default WriteComment;
