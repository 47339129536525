import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getPropertiesForCart } from 'bubble-utils/src/event-utils';

import { loadCartApi, updateCartApi } from '../services/api';

import { logEvent } from '../reducers/events';
import { getClientId, getClientToken } from '../selectors';

import { EVENT } from 'bubble-constants';

const LOAD_CART = 'cart/loadCart';
const UPDATE_CART = 'cart/updateCart';
const ADD_TO_CART = 'cart/addToCart';
const REMOVE_FROM_CART = 'cart/removeFromCart';
const ADD_SKUS_TO_CART = 'cart/addSkusToCart';
const REMOVE_SKUS_FROM_CART = 'cart/removeSkusFromCart';

const initialState = {
  cart: { items: [] },
  loading: {},
  errors: {},
};

export const loadCart = createAsyncThunk(LOAD_CART, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const clientId = getClientId(getState());
  const cart = await loadCartApi(clientToken, clientId);

  return { cart };
});

export const updateCart = createAsyncThunk(UPDATE_CART, async (params, { getState }) => {
  const options = params?.options || null;

  const clientToken = getClientToken(getState());
  const clientId = getClientId(getState());

  const cart = await updateCartApi(clientToken, clientId, options);

  return { cart };
});

export const addToCart = createAsyncThunk(ADD_TO_CART, async (params, { getState, dispatch }) => {
  const printsParam = params?.prints || null;
  const isBatch = params?.isBatch || null;

  const prints = Array.isArray(printsParam) ? printsParam : [printsParam];
  const items = prints.map((print) => ({
    printObjectId: print.objectId,
    sku: print.ean || print.sku,
    quantity: print.quantity !== null && print.quantity !== undefined ? print.quantity : 1,
  }));

  const clientToken = getClientToken(getState());
  const clientId = getClientId(getState());
  const cart = await updateCartApi(clientToken, clientId, { items });

  const addedItems = (cart.items || []).filter((item) =>
    prints.find((print) => print.objectId === item.itemObjectId),
  );

  dispatch(itemsAddedToCart({ addedItems }));
  if (addedItems.length) {
    dispatch(
      logEvent(
        EVENT.ADD_TO_CART,
        getPropertiesForCart(addedItems, isBatch),
        null,
        EVENT.ITEMS_IN_CART,
        addedItems.reduce((acc, curr) => acc + Number(curr.quantity || 1), 0),
      ),
    );
  }
  return { cart };
});

export const removeFromCart = createAsyncThunk(
  REMOVE_FROM_CART,
  async (params, { getState, dispatch }) => {
    const printsParam = params?.prints || null;
    const isBatch = params?.isBatch || null;

    const prints = Array.isArray(printsParam) ? printsParam : [printsParam];
    const items = prints.map((print) => ({
      printObjectId: print.objectId,
      sku: print.ean || print.sku,
      quantity: 0,
    }));

    const clientToken = getClientToken(getState());
    const clientId = getClientId(getState());
    const cart = await updateCartApi(clientToken, clientId, { items });

    dispatch(
      logEvent(
        EVENT.REMOVE_FROM_CART,
        getPropertiesForCart(prints, isBatch),
        null,
        EVENT.ITEMS_IN_CART,
        prints.reduce((acc, curr) => acc + Number(curr.quantity || 1), 0) * -1,
      ),
    );
    return { cart };
  },
);

export const addSkusToCart = createAsyncThunk(
  ADD_SKUS_TO_CART,
  async (params, { getState, dispatch }) => {
    const skusParam = params?.skus || null;

    const skus = Array.isArray(skusParam) ? skusParam : [skusParam];
    const items = skus.map((sku) => ({
      sku: sku.sku,
      quantity: sku.quantity !== null && sku.quantity !== undefined ? sku.quantity : 1,
    }));

    const clientToken = getClientToken(getState());
    const clientId = getClientId(getState());
    const cart = await updateCartApi(clientToken, clientId, { items });

    const addedItems = (cart.items || []).filter((item) =>
      skus.find((sku) => sku.sku === item.sku),
    );
    dispatch(itemsAddedToCart({ addedItems }));
    return { cart };
  },
);

export const removeSkusFromCart = createAsyncThunk(
  REMOVE_SKUS_FROM_CART,
  async (params, { getState }) => {
    const skusParam = params?.skus || null;

    const skus = Array.isArray(skusParam) ? skusParam : [skusParam];
    const items = skus.map((sku) => ({ sku: sku.sku, quantity: 0 }));

    const clientToken = getClientToken(getState());
    const clientId = getClientId(getState());

    const cart = await updateCartApi(clientToken, clientId, { items });
    return { cart };
  },
);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetCartReducer: {
      reducer: (state, action) => initialState,
    },
    itemsAddedToCart: {
      reducer: (state, action) => {},
    },
  },
  extraReducers: (builder) => {
    builder
      // loadCart
      .addCase(loadCart.pending, (state, action) => {
        state.loading[LOAD_CART] = true;
        state.errors[LOAD_CART] = null;
      })
      .addCase(loadCart.fulfilled, (state, action) => {
        state.cart = action.payload.cart;
        state.loading[LOAD_CART] = false;
        state.errors[LOAD_CART] = null;
      })
      .addCase(loadCart.rejected, (state, action) => {
        console.log('action', action.error);
        state.loading[LOAD_CART] = false;
        state.errors[LOAD_CART] = action.error;
      })
      // updateCart
      .addCase(updateCart.pending, (state, action) => {
        state.loading[UPDATE_CART] = true;
        state.errors[UPDATE_CART] = null;
      })
      .addCase(updateCart.fulfilled, (state, action) => {
        state.cart = action.payload.cart;
        state.loading[UPDATE_CART] = false;
        state.errors[UPDATE_CART] = null;
      })
      .addCase(updateCart.rejected, (state, action) => {
        state.loading[UPDATE_CART] = false;
        state.errors[UPDATE_CART] = action.error;
      })
      // addToCart
      .addCase(addToCart.pending, (state, action) => {
        state.loading[UPDATE_CART] = true;
        state.errors[UPDATE_CART] = null;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cart = action.payload.cart;
        state.loading[UPDATE_CART] = false;
        state.errors[UPDATE_CART] = null;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.loading[UPDATE_CART] = false;
        state.errors[UPDATE_CART] = action.error;
      })
      // removeFromCart
      .addCase(removeFromCart.pending, (state, action) => {
        state.loading[REMOVE_FROM_CART] = true;
        state.errors[REMOVE_FROM_CART] = null;
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.cart = action.payload.cart;
        state.loading[REMOVE_FROM_CART] = false;
        state.errors[REMOVE_FROM_CART] = null;
      })
      .addCase(removeFromCart.rejected, (state, action) => {
        state.loading[REMOVE_FROM_CART] = false;
        state.errors[REMOVE_FROM_CART] = action.error;
      })
      // addSkusToCart
      .addCase(addSkusToCart.pending, (state, action) => {
        state.loading[ADD_SKUS_TO_CART] = true;
        state.errors[ADD_SKUS_TO_CART] = null;
      })
      .addCase(addSkusToCart.fulfilled, (state, action) => {
        state.cart = action.payload.cart;
        state.loading[ADD_SKUS_TO_CART] = false;
        state.errors[ADD_SKUS_TO_CART] = null;
      })
      .addCase(addSkusToCart.rejected, (state, action) => {
        state.loading[ADD_SKUS_TO_CART] = false;
        state.errors[ADD_SKUS_TO_CART] = action.error;
      })
      // removeSkusFromCart
      .addCase(removeSkusFromCart.pending, (state, action) => {
        state.loading[REMOVE_SKUS_FROM_CART] = true;
        state.errors[REMOVE_SKUS_FROM_CART] = null;
      })
      .addCase(removeSkusFromCart.fulfilled, (state, action) => {
        state.cart = action.payload.cart;
        state.loading[REMOVE_SKUS_FROM_CART] = false;
        state.errors[REMOVE_SKUS_FROM_CART] = null;
      })
      .addCase(removeSkusFromCart.rejected, (state, action) => {
        state.loading[REMOVE_SKUS_FROM_CART] = false;
        state.errors[REMOVE_SKUS_FROM_CART] = action.error;
      });
  },
});

export default cartSlice.reducer;

export const { itemsAddedToCart, resetCartReducer } = cartSlice.actions;
