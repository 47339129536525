import React from 'react';

import { Link } from 'react-router-dom';

import ArticleSectionBadge from '@/components/ArticleSectionBadge/ArticleSectionBadge';

import './ArticleHorizontalCard.css';

export default function ArticleHorizontalCard(props) {
  const article = props.article;

  if (!article?.path) return null;

  return (
    <Link
      to={article?.path}
      className={`${
        props.className || ''
      } d-flex flex-fill rounded-medium bg-cover text-white no-decoration`}
      style={{
        backgroundImage: `url(${article?.imageSmallUrl || article?.imageUrl})`,
      }}
    >
      <div className="d-flex flex-fill flex-column p-3 px-md-4 rounded-medium article-overlay ">
        <ArticleSectionBadge
          tag={{
            id: article?.section?.id || article?.category?.id,
            title: article?.section?.title || article?.category?.title || article?.categoryTitle,
          }}
        />
        <div className="fw-bold bb-medium-large-text-size mt-2 article-title">
          {article?.title || ''}
        </div>
      </div>
    </Link>
  );
}
