import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { loadAgendas } from 'bubble-reducers/src/reducers/agendas';
import { updateFilterItemValue } from 'bubble-reducers/src/reducers/filters';
import { getFiltersFromId } from 'bubble-reducers/src/selectors';

import { getSectionsForAgenda } from '@/services/library-utils';

import BubbleCheckbox from '@/components/BubbleCheckbox/BubbleCheckbox';
import { Icon } from '@/components/Icon/Icon';

const AgendaFilter = (props) => {
  const dispatch = useDispatch();

  const filters = useSelector((state) => getFiltersFromId(state, 'agenda'));

  const filterList = (filter) => {
    dispatch(
      updateFilterItemValue({
        filterId: 'agenda',
        filterItem: 'categories',
        filterAction: filters?.categories?.[filter] ? 'del' : 'add',
        value: filter,
      }),
    );
  };

  const loadWithDate = (date) => {
    dispatch(loadAgendas({ startDate: date }));
  };

  const loadPast = () => {
    let date = new Date();
    date.setDate(date.getDate() - 31);
    loadWithDate(date);
  };

  const loadPresent = () => {
    loadWithDate(new Date());
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    if (value === 'past') return loadPast();
    if (value === 'present') return loadPresent();
  };

  const categoryFilters = Object.keys(filters?.categories || {});
  let filteredList = props.list;
  if (categoryFilters.length) {
    filteredList = props.list.filter((album) =>
      categoryFilters.some((category) => (album.serie.category || '').toLowerCase() === category),
    );
  }
  const sections = getSectionsForAgenda(filteredList);

  return (
    <>
      <div className="row px-3 pb-5">
        <div className="offset-lg-2 col-lg-8 bg-white rounded bb-shadow-hover p-3">
          <div className="d-sm-flex align-items-center bb-medium-text-size">
            <div className="me-5 py-2">
              <BubbleCheckbox
                label="Bande dessinées"
                checked={filters?.categories?.bd}
                onChange={() => filterList('bd')}
                id="checkBd"
              />
            </div>
            <div className="me-5 py-2">
              <BubbleCheckbox
                label="Comics"
                checked={filters?.categories?.comics}
                onChange={() => filterList('comics')}
                id="checkComics"
              />
            </div>
            <div className="me-5 py-2 ">
              <BubbleCheckbox
                label="Mangas"
                checked={filters?.categories?.mangas}
                onChange={() => filterList('mangas')}
                id="checkMangas"
              />
            </div>
            {/* DATES */}
            <div className="input-group agenda-time-chooser-prepend">
              <div className="input-group-prepend">
                <div className="mt-n1 input-group-text transparent-container">
                  <Icon className="text-secondary" name="calendar-alt" />
                </div>
              </div>
              <select
                className="form-select agenda-time-chooser text-secondary"
                onChange={handleDateChange}
                defaultValue="present"
              >
                <option value="past">Le mois dernier</option>
                <option value="present">Les sorties à venir</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {props.children(sections)}
    </>
  );
};

export default AgendaFilter;
