import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadAlbums } from 'bubble-reducers/src/reducers/albums';
import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { loadBanners } from 'bubble-reducers/src/reducers/banners';
import { getHighlightedArticles, getTopRatedSeries } from 'bubble-reducers/src/selectors';

import { getSeoForHome } from '@/services/seo-utils';

import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import HighlightBanners from '@/components/HighlightBanner/HighlightBanners';
import SerieCard from '@/components/SerieCard/SerieCard';

import AlbumCard from '../../components/AlbumCard/AlbumCard';
import ArticleCard from '../../components/ArticleCard/ArticleCard';
import TitledListContainer from '../../components/TitledListContainer/TitledListContainer';
import BubbleBooksellerPartner from './components/BubbleBooksellerPartner/BubbleBooksellerPartner';
import BubbleConcept from './components/BubbleConcept/BubbleConcept';
import BubbleHomeInfinityAd from './components/BubbleHomeInfinityAd/BubbleHomeInfinityAd';
import BubbleHomeMobileAppAd from './components/BubbleHomeMobileAppAd/BubbleHomeMobileAppAd';
import HighlightSection from './components/HighlightSection';
import MainCategories from './components/MainCategories/MainCategories';

import './Home.scss';

const Home = () => {
  const dispatch = useDispatch();

  const highlightedArticles = useSelector((state) => getHighlightedArticles(state));
  const topRatedSeries = useSelector((state) => getTopRatedSeries(state, 8));
  const mapOfAlbumIdsLastPublished = useSelector(
    (state) => state.albums.mapOfAlbumIdsLastPublished,
  );

  useEffect(() => {
    dispatch(loadArticles({ options: { highlighted: 1 } }));
    dispatch(loadAlbums({ options: { last_published: 1, complete: 1 } }));
    dispatch(loadBanners());
  }, []);

  const lastPublishedAlbums = (mapOfAlbumIdsLastPublished['all'] || []).slice(0, 10);

  return (
    <div className="bb-background-light-grey">
      <div className="container">
        <BubbleHelmet seo={getSeoForHome()} />
        <div className="row">
          <HighlightBanners />
        </div>

        <div className="row px-3 px-lg-0">
          <TitledListContainer
            noWrap
            className={'mb-0 w-100'}
            title={
              <div className="h1 text-center fw-bold pt-5 pb-3">
                Coup d'oeil sur les nouveautés et prochaines sorties BD, Comics et Mangas
              </div>
            }
            list={lastPublishedAlbums.slice(0, 5).map((albumObjectId) => (
              <AlbumCard
                className="col-7 col-md-4 col-xl col-lg-3 pb-3"
                key={`agenda${albumObjectId}`}
                forcePublicationDate={true}
                albumObjectId={albumObjectId}
              />
            ))}
          />
          <TitledListContainer
            noWrap
            className={'mt-0 w-100'}
            list={lastPublishedAlbums.slice(5, 10).map((albumObjectId) => (
              <AlbumCard
                className="col-7 col-md-4 col-xl col-lg-3 pb-3"
                key={`agenda${albumObjectId}`}
                forcePublicationDate={true}
                albumObjectId={albumObjectId}
              />
            ))}
          />
        </div>

        <div className="row px-3 px-lg-0">
          <div className="h1 w-100 text-center fw-bold pt-5 pb-3">
            BD, Mangas, Comics et Romans Graphiques - il y en a pour tous les goûts !
          </div>
          <MainCategories />
        </div>

        <div className="row agenda-background">
          <div className="col-12 text-center py-6 text-white">
            <div className="h3 fw-bold">
              Flippé(e) de manquer la sortie de
              <br />
              votre série favorite ?
            </div>
            <div className="h3 pt-2">Consultez notre agenda des nouveautés !</div>
            <Link to="/agenda" className="btn btn-outline-white mt-4 px-5 bb-medium-text-size">
              Voir les nouveautés
            </Link>
          </div>
        </div>

        <div className="row px-3 px-lg-0">
          <HighlightSection />
        </div>

        <div className="pt-5">
          <BubbleConcept />
        </div>

        <div className="row px-3 px-lg-0">
          <TitledListContainer
            title={
              <div className="h1 text-center fw-bold pt-5 pb-3">
                Découvrez les albums les mieux notés par les membres Bubble
              </div>
            }
            list={topRatedSeries.slice(0, 4).map((serie) => (
              <div key={`top_${serie.objectId}`} className="col-12 pb-3 col-lg-4 col-xl-3">
                <SerieCard serieObjectId={serie.objectId} />
              </div>
            ))}
          />
        </div>

        <BubbleBooksellerPartner />

        <div className="row px-3 px-lg-0">
          <TitledListContainer
            noWrap
            title={
              <div className="h1 text-center fw-bold pt-5 pb-3">
                Suivez toute l'actualité BD, Comics et Mangas sur nos médias
              </div>
            }
            list={highlightedArticles.slice(0, 3).map((article) => (
              <ArticleCard
                className="col-9 col-md-6 col-lg-4 pt-3"
                key={`article-${article?.objectId}`}
                article={article}
              />
            ))}
          />
        </div>

        <BubbleHomeInfinityAd />

        <BubbleHomeMobileAppAd />
      </div>
    </div>
  );
};

export default Home;
