import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addLike, deleteLike } from 'bubble-reducers/src/reducers/likes';
import { getUserOrGuest, makeIsReviewLiked } from 'bubble-reducers/src/selectors';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

const LikeZone = (props) => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const user = useSelector(getUserOrGuest);
  const likes = props.review?.likes || props.comment?.likes || 0;
  const isAuthor =
    props.review?.isAuthor ||
    props.review?.userObjectId === user.objectId ||
    props.comment?.isAuthor;
  const isReviewLikedSelector = useMemo(() => makeIsReviewLiked(), []);

  const isLiked =
    useSelector((state) => isReviewLikedSelector(state, props.review?.objectId)) ||
    props.isCommentLiked;

  const handleLikeClick = useCallback(() => {
    const like = {
      userObjectId: user?.objectId,
    };
    if (props.serieObjectId) like.serieObjectId = props.serieObjectId;
    if (props.albumObjectId) like.albumObjectId = props.albumObjectId;
    if (props.reviewObjectId) like.reviewObjectId = props.reviewObjectId;
    if (props.commentObjectId) like.commentObjectId = props.commentObjectId;

    if (isUserlogged()) {
      isLiked ? dispatch(deleteLike({ like })) : dispatch(addLike({ like }));
    }
  }, [
    isUserlogged,
    dispatch,
    user,
    props.serieObjectId,
    props.albumObjectId,
    props.commentObjectId,
    props.reviewObjectId,
    isLiked,
  ]);

  return (
    <div className="d-flex flex-fill flex-column justify-content-end">
      <div className="h-separator my-2" />
      <div>
        <span role="img" aria-label="pouce">
          👍
        </span>
        {!isAuthor && (
          <button onClick={handleLikeClick} className="btn btn-link m-0 p-0 ms-1">
            {isLiked ? `Je n'aime plus cet avis (${likes})` : `J'aime cet avis (${likes})`}
          </button>
        )}
        {isAuthor && <span className="text-secondary ms-1">({likes})</span>}
      </div>
    </div>
  );
};

export default LikeZone;
