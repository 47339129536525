import React, { useCallback } from 'react';

import { Link } from 'react-router-dom';

import LinkUtils from '@/services/link-utils';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';
import KeySellingPointsBanner from '@/components/KeySellingPointsBanner/KeySellingPointsBanner';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import iconAppStore from '@/assets/footer/appstore.png';
import iconAppStoreWebp from '@/assets/footer/appstore.webp';
import iconPlayStore from '@/assets/footer/googleplay.png';
import iconPlayStoreWebp from '@/assets/footer/googleplay.webp';
import iconSocialFacebook from '@/assets/icon-social-facebook-light-gray.svg';
import iconSocialInstragam from '@/assets/icon-social-instagram-light-gray.svg';
import iconSocialTwitter from '@/assets/icon-social-twitter-light-gray.svg';

import { LINK } from 'bubble-constants';

import './Footer.scss';

const Footer = () => {
  const onClickShowIntercom = useCallback(
    () => (window.Intercom ? window.Intercom('show') : null),
    [],
  );

  return (
    <footer className="container">
      <div className="row">
        <KeySellingPointsBanner />
        <div className="px-3 pt-3 avis-verifies-footer-area">
          <iframe
            title="avisverifies1"
            id="AV_widget_iframe"
            frameBorder="0"
            width="100%"
            height="100%"
            src="//cl.avis-verifies.com/fr/cache/d/c/b/dcb8e0e7-782e-d8e4-51ba-a9b06969e774/widget4/dcb8e0e7-782e-d8e4-51ba-a9b06969e774horizontal_index.html"
          />
        </div>

        <div className="bg-blacker">
          <div className="row g-0 py-4 px-3 px-lg-5 bb-footer footer-text text-center text-lg-start">
            <div className="col-lg-2 mb-3">
              <div className="fw-bold footer-title">A propos</div>
              <div className="mt-1">
                <Link to="/qui-sommes-nous" className="no-decoration hover-decoration">
                  Qui sommes-nous ?
                </Link>
              </div>
              <div className="mt-1">
                <a href="mailto:contact@bubblebd.com" className="no-decoration hover-decoration">
                  Presse
                </a>
              </div>
              <div className="mt-1">
                <WithClickHandler onClick={onClickShowIntercom}>
                  <div className="no-decoration hover-decoration">Contact</div>
                </WithClickHandler>
              </div>

              <div className="mt-3">
                <a target="_blank" rel="noopener noreferrer" href={LINK.FACEBOOK_BUBBLE_URL}>
                  <img
                    className="social-icon"
                    src={iconSocialFacebook}
                    alt="Facebook social icon"
                  />
                </a>
                <a
                  className="ms-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={LINK.TWITTER_BUBBLE_URL}
                >
                  <img className="social-icon" src={iconSocialTwitter} alt="Twitter social icon" />
                </a>
                <a
                  className="ms-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={LINK.INSTAGRAM_BUBBLE_URL}
                >
                  <img
                    className="social-icon"
                    src={iconSocialInstragam}
                    alt="Instagram social icon"
                  />
                </a>
              </div>
            </div>

            <div className="col-lg-2 mb-3">
              <div className="fw-bold footer-title">Explorez Bubble</div>
              <Link className="d-block mt-1 no-decoration hover-decoration" to={'/9emeart'}>
                Trouvez de l'inspiration
              </Link>
              <Link
                className="d-block mt-1 no-decoration hover-decoration"
                to={'/list?category=bd'}
              >
                Bande dessinée
              </Link>
              <Link
                className="d-block mt-1 no-decoration hover-decoration"
                to={'/list?category=comics'}
              >
                Comics
              </Link>
              <Link
                className="d-block mt-1 no-decoration hover-decoration"
                to={'/list?category=mangas'}
              >
                Mangas
              </Link>
              <Link
                className="d-block mt-1 no-decoration hover-decoration"
                to={'/list?category=jeunesse'}
              >
                Jeunesse
              </Link>
              <Link className="d-block mt-1 no-decoration hover-decoration" to={'/agenda'}>
                Nouveautés
              </Link>
              <Link className="d-block mt-1 no-decoration hover-decoration" to={'/tops'}>
                Tops & sélections
              </Link>
              <Link to="/series/nouveautes" className="d-block mt-1 no-decoration hover-decoration">
                Toutes les séries
              </Link>
              <Link to="/auteurs/top" className="d-block mt-1 no-decoration hover-decoration">
                Tous les auteurs
              </Link>
            </div>

            <div className="col-lg-2 mb-3">
              <div className="fw-bold footer-title">Gérez votre collection</div>
              <Link to="/mobile-app" className="d-block mt-1 no-decoration hover-decoration">
                L'application Bubble
              </Link>
              <Link to="/infinity" className="d-block mt-1 no-decoration hover-decoration">
                Essayez Bubble Infinity
              </Link>
              <Link
                to="/programme-de-fidelite"
                className="d-block mt-1 no-decoration hover-decoration"
              >
                Notre programme de fidélité
              </Link>
              <Link to="/ajouter-un-album" className="d-block mt-1 no-decoration hover-decoration">
                Ajouter un album
              </Link>

              <div className="mt-4 fw-bold footer-title">Librairies</div>
              <Link
                to="/devenir-partenaire-libraire"
                className="d-block mt-1 no-decoration hover-decoration"
              >
                Libraire ? Devenez partenaire
              </Link>
              <Link
                to="/cartes-des-librairies-partenaires"
                className="d-block mt-1 no-decoration hover-decoration"
              >
                Les librairies partenaires
              </Link>
            </div>

            <div className="col-lg-2 mb-3">
              <div className="fw-bold footer-title">Aide & support</div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="d-block mt-1 no-decoration hover-decoration"
                href={LINK.FAQ_URL}
              >
                Foire aux questions
              </a>

              <Link
                className="d-block mt-1 no-decoration hover-decoration"
                to="/aide-ecommerce/frais-de-port"
              >
                Livraison et frais de port
              </Link>

              <Link
                className="d-block mt-1 no-decoration hover-decoration"
                to="/aide-ecommerce/suivi-commande"
              >
                Suivre ma commande
              </Link>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="d-block mt-1 no-decoration hover-decoration"
                href={LINK.FAQ_BUY_ONLINE_URL}
              >
                Acheter en ligne
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="d-block mt-1 no-decoration hover-decoration"
                href={LINK.FAQ_BUY_RESERVATION_URL}
              >
                Réserver et retirer en librairie
              </a>
            </div>

            <div className="col-lg-4 mb-3">
              <div className="fw-bold footer-title">Rejoignez la communauté Bubble !</div>
              <div>
                Bubble, c'est + de 250 000 utilisateurs passionnés de Bande Dessinée, Comics et
                Mangas. Rejoignez-nous en téléchargeant gratuitement notre application et commencez
                à lister votre collection{' '}
                <span role="img" aria-label="nerd">
                  🤓
                </span>
              </div>
              <div>
                <a href={LinkUtils.getLinkDownloadFromFooterIos()}>
                  <WebpWrapper
                    alt="App store icon"
                    className="app-store-button me-3 mt-3"
                    webp={iconAppStoreWebp}
                    default={iconAppStore}
                  />
                </a>
                <a href={LinkUtils.getLinkDownloadFromFooterAndroid()}>
                  <WebpWrapper
                    alt="Play store icon"
                    className="app-store-button me-3 mt-3"
                    webp={iconPlayStoreWebp}
                    default={iconPlayStore}
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="row g-0 py-4 px-3 px-lg-5 footer-text">
            <div className="col">
              <div className="float-start">{`© ${new Date().getFullYear()} Bubble - Tous droits réservés`}</div>
              <div className="float-end">
                <Link className="me-4 no-decoration hover-decoration" to={'/mentions-legales'}>
                  Mentions légales
                </Link>
                <Link
                  className="me-4 no-decoration hover-decoration"
                  to={'/conditions-generales-de-vente-ecommerce'}
                >
                  CGV ecommerce
                </Link>
                <Link
                  className="me-4 no-decoration hover-decoration"
                  to={'/conditions-generales-de-vente-et-d-utilisation-marketplace'}
                >
                  CGVU marketplace
                </Link>
                <Link
                  className="me-4 no-decoration hover-decoration"
                  to={"/charte-de-publication-d'avis"}
                >
                  Publication d'avis
                </Link>
                <Link
                  className="me-4 no-decoration hover-decoration"
                  to={'/protection-de-vos-donnees-personnelles'}
                >
                  Protection de vos données personelles
                </Link>
                <Link className="me-4 no-decoration hover-decoration" to={'/cookies'}>
                  Cookies
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
