import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';

import { loadAlbums } from 'bubble-reducers/src/reducers/albums';
import { loadArticlesForResource } from 'bubble-reducers/src/reducers/articles';
import { loadAuthor, loadRelatedAuthors } from 'bubble-reducers/src/reducers/authors';

import { getSeoForAuthor } from '@/services/seo-utils';

import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import InfinityCard from '@/components/InfinityCard/InfinityCard';

import AuthorHeader from './components/AuthorHeader/AuthorHeader';
import AuthorNavBar from './components/AuthorNavBar';
import AuthorAlbumsSection from './scenes/AuthorAlbumsSection';
import AuthorAllSection from './scenes/AuthorAllSection';
import AuthorAllSectionBottom from './scenes/AuthorAllSectionBottom';
import AuthorArticlesSection from './scenes/AuthorArticlesSection';
import AuthorRelatedAuthorsSection from './scenes/AuthorRelatedAuthorsSection';
import AuthorSeriesSection from './scenes/AuthorSeriesSection';

const Author = () => {
  const dispatch = useDispatch();

  const { authorObjectId } = useParams();

  const author = useSelector((state) => state.authors.authors[authorObjectId]) || null;

  useEffect(() => {
    if (authorObjectId) {
      dispatch(loadAuthor({ authorObjectId }));
      dispatch(loadRelatedAuthors({ authorObjectId }));
      dispatch(loadAlbums({ options: { author: authorObjectId } }));
      dispatch(loadArticlesForResource({ resourceObjectId: authorObjectId }));
    }
  }, [authorObjectId]);

  return (
    <>
      <BubbleHelmet seo={getSeoForAuthor(author)} />
      <AuthorHeader authorObjectId={authorObjectId} />
      <div className="bb-background-light-grey" id="ct1">
        <div className="container">
          <div className="row px-sm-3">
            <div className="col-xl-2 d-none d-xl-block">
              <InfinityCard />
            </div>
            <div className="col-xl-10">
              <AuthorNavBar authorObjectId={authorObjectId} />
              <Routes>
                <Route
                  path="/albums"
                  element={<AuthorAlbumsSection authorObjectId={authorObjectId} />}
                />
                <Route
                  path="/series"
                  element={<AuthorSeriesSection authorObjectId={authorObjectId} />}
                />
                <Route
                  path="/articles"
                  element={<AuthorArticlesSection authorObjectId={authorObjectId} />}
                />
                <Route
                  path="/authors"
                  element={<AuthorRelatedAuthorsSection authorObjectId={authorObjectId} />}
                />
                <Route path="/" element={<AuthorAllSection authorObjectId={authorObjectId} />} />
              </Routes>
            </div>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<AuthorAllSectionBottom authorObjectId={authorObjectId} />} />
        </Routes>
        <div className="py-4" />
      </div>
    </>
  );
};

export default Author;
