import { useEffect, useRef, useState } from 'react';

export const useImageLoaded = () => {
  const [imageLoaded, setimageLoaded] = useState(false);
  const ref = useRef();

  const onImageLoaded = () => {
    setimageLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onImageLoaded();
    }
  });

  return [ref, imageLoaded, onImageLoaded];
};
