import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  loadAuthorApi,
  loadAuthorTagsApi,
  loadAuthorsAtAlphanumericIndexApi,
  loadRelatedAuthorsApi,
} from '../services/api';
import menuAuthors from '../services/menu-authors.json';

import { getClientToken } from '../selectors';

export const LOAD_AUTHOR = 'authors/loadAuthor';
export const LOAD_AUTHOR_TAGS = 'authors/loadAuthorTags';
export const LOAD_RELATED_AUTHORS = 'authors/loadRelatedAuthors';
const LOAD_AUTHORS_ALPHANUMERIC_INDEX = 'authors/loadAuthorsAtAlphanumericIndex';

const initialState = {
  authors: menuAuthors,
  menuAuthorsObjectIds: {
    bd: [
      '79uE6tav93',
      'E3us165g2U',
      'AGW8AOfuaP',
      'i0NHUrcqGi',
      'Tr23NgmAMt',
      'cSkvfqdiUV',
      'uB3AMrxu9L',
      'Vjj9Io8APS',
      'PJhjZ3Mb4C',
      'Mxh28n62Q3',
      '9A3ECKZQjX',
      'tJYFZyfhwU',
      'tfeI0cwG2Y',
      'WBxFwdDKhf',
      'YrmQvTb5Y2',
    ],
    comics: [
      'xaNPdlnqHC',
      'CoZy96h4H6',
      'RK81jtkR9X',
      '3wSaSWOZXh',
      '79P6Keo2PK',
      '1Uvt74IE4M',
      'vLK2RCbvm8',
      'dUNJWl2Msu',
      'bbcZVAygPT',
      'ftVxxGshYq',
      'OzGbmrtDgZ',
      'U3LPrEc0JS',
      'HbjiLG9IRM',
      'UzLwanTyN1',
      'j6rcZ69NYq',
    ],
    mangas: [
      'RQ5tK5OEQA',
      'rmCqAg0XuF',
      'Ggq8twUs57',
      'CrQIiyaPyI',
      'weBPi1v2kT',
      'I5yUIAmh8n',
      'OHr9xughQf',
      'HE8pBBtKpB',
      'xMDFveqoMW',
      'BsSnl5T6Qp',
      '3Zq3DrXUYl',
      'Y2IWO0Wbk3',
      'wG9iX2nUkT',
      'nVR4oWJjYO',
      'A6wbVIVtwg',
    ],
    jeunesse: ['0l7NNoc3km', 'dNFI4g5XCY', 'BzyGofOFbM', 'rl9EjsdOhb'],
  },
  relatedAuthorsMap: {},
  relatedAuthorsRelatedSeriesObjectIdsMap: {},
  authorSeriesObjectIdsMap: {},
  authorSeries: {}, // trash series not conforming to "series" reducer
  authorTagsMap: {},
  authorsAlphanumericIndexMap: {},
  loading: {},
  errors: {},
};

export const loadAuthor = createAsyncThunk(LOAD_AUTHOR, async (params, { getState, dispatch }) => {
  const authorObjectId = params?.authorObjectId || null;

  const clientToken = getClientToken(getState());
  const author = await loadAuthorApi(clientToken, authorObjectId);
  dispatch(storeAuthors({ authors: [author] }));
});

export const loadAuthorTags = createAsyncThunk(LOAD_AUTHOR_TAGS, async (params, { getState }) => {
  const authorObjectId = params?.authorObjectId || null;

  const clientToken = getClientToken(getState());
  const tags = await loadAuthorTagsApi(clientToken, authorObjectId);
  return { authorObjectId, tags };
});

export const loadRelatedAuthors = createAsyncThunk(
  LOAD_RELATED_AUTHORS,
  async (params, { getState, dispatch }) => {
    const authorObjectId = params?.authorObjectId || null;

    const clientToken = getClientToken(getState());
    const authors = await loadRelatedAuthorsApi(clientToken, authorObjectId);
    dispatch(storeAuthors({ authors }));

    return { authorObjectId, authors };
  },
);

export const loadAuthorsAtAlphanumericIndex = createAsyncThunk(
  LOAD_AUTHORS_ALPHANUMERIC_INDEX,
  async (params, { getState }) => {
    const index = params?.index || null;

    const clientToken = getClientToken(getState());
    const authors = await loadAuthorsAtAlphanumericIndexApi(clientToken, index);
    return { index, authors };
  },
);

const authorsSlice = createSlice({
  name: 'authors',
  initialState,
  reducers: {
    storeAuthors: {
      reducer: (state, action) => {
        let authorSeries = state.authorSeries;
        const authorSeriesObjectIdsMap = { ...state.authorSeriesObjectIdsMap };
        let authors = state.authors;
        if (action.payload.authors.length) {
          authors = action.payload.authors.reduce(
            (map, author) => {
              authorSeriesObjectIdsMap[author.objectId] = new Set(
                authorSeriesObjectIdsMap[author.objectId],
              );
              authorSeries = (author.series || []).reduce(
                (serieMap, serie) => {
                  serieMap[serie.objectId] = serie;
                  authorSeriesObjectIdsMap[author.objectId].add(serie.objectId);
                  return serieMap;
                },
                { ...state.authorSeries },
              );
              authorSeriesObjectIdsMap[author.objectId] = Array.from(
                authorSeriesObjectIdsMap[author.objectId],
              );

              author.series = (author.series || []).map((serie) => serie.objectId);
              map[author.objectId] = author;
              return map;
            },
            { ...state.authors },
          );
        }
        state.authorSeries = authorSeries;
        state.authorSeriesObjectIdsMap = authorSeriesObjectIdsMap;
        state.authors = authors;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadAuthor
      .addCase(loadAuthor.pending, (state, action) => {
        state.loading[LOAD_AUTHOR] = true;
        state.errors[LOAD_AUTHOR] = null;
      })
      .addCase(loadAuthor.fulfilled, (state, action) => {
        state.loading[LOAD_AUTHOR] = false;
        state.errors[LOAD_AUTHOR] = null;
      })
      .addCase(loadAuthor.rejected, (state, action) => {
        console.log('loadAuthor.rejected', action.error);
        state.loading[LOAD_AUTHOR] = false;
        state.errors[LOAD_AUTHOR] = action.error;
      })
      // loadAuthorTags
      .addCase(loadAuthorTags.pending, (state, action) => {
        state.loading[LOAD_AUTHOR_TAGS] = true;
        state.errors[LOAD_AUTHOR_TAGS] = null;
      })
      .addCase(loadAuthorTags.fulfilled, (state, action) => {
        state.authorTagsMap = {
          ...state.authorTagsMap,
          [action.payload.authorObjectId]: action.payload.tags,
        };
        state.loading[LOAD_AUTHOR_TAGS] = false;
        state.errors[LOAD_AUTHOR_TAGS] = null;
      })
      .addCase(loadAuthorTags.rejected, (state, action) => {
        console.log('loadAuthorTags.rejected', action.error);
        state.loading[LOAD_AUTHOR_TAGS] = false;
        state.errors[LOAD_AUTHOR_TAGS] = action.error;
      })
      // loadRelatedAuthors
      .addCase(loadRelatedAuthors.pending, (state, action) => {
        state.loading[LOAD_RELATED_AUTHORS] = true;
        state.errors[LOAD_RELATED_AUTHORS] = null;
      })
      .addCase(loadRelatedAuthors.fulfilled, (state, action) => {
        const authorObjectId = action.payload.authorObjectId;
        const authors = action.payload.authors;
        const relatedAuthorsRelatedSeriesObjectIdsMap =
          state.relatedAuthorsRelatedSeriesObjectIdsMap;
        relatedAuthorsRelatedSeriesObjectIdsMap[authorObjectId] =
          relatedAuthorsRelatedSeriesObjectIdsMap[authorObjectId] || {};
        const relatedAuthorsMap = {
          ...state.relatedAuthorsMap,
          [authorObjectId]: authors.map((author) => {
            relatedAuthorsRelatedSeriesObjectIdsMap[authorObjectId][author.objectId] =
              author.series;
            return author.objectId;
          }),
        };
        state.relatedAuthorsMap = relatedAuthorsMap;
        state.relatedAuthorsRelatedSeriesObjectIdsMap = relatedAuthorsRelatedSeriesObjectIdsMap;

        state.loading[LOAD_RELATED_AUTHORS] = false;
        state.errors[LOAD_RELATED_AUTHORS] = null;
      })
      .addCase(loadRelatedAuthors.rejected, (state, action) => {
        console.log('loadRelatedAuthors.rejected', action.error);
        state.loading[LOAD_RELATED_AUTHORS] = false;
        state.errors[LOAD_RELATED_AUTHORS] = action.error;
      })
      // loadAuthorsAtAlphanumericIndex
      .addCase(loadAuthorsAtAlphanumericIndex.pending, (state, action) => {
        state.loading[LOAD_AUTHORS_ALPHANUMERIC_INDEX] = true;
        state.errors[LOAD_AUTHORS_ALPHANUMERIC_INDEX] = null;
      })
      .addCase(loadAuthorsAtAlphanumericIndex.fulfilled, (state, action) => {
        state.authorsAlphanumericIndexMap = {
          ...state.authorsAlphanumericIndexMap,
          [action.payload.index]: action.payload.authors,
        };
        state.loading[LOAD_AUTHORS_ALPHANUMERIC_INDEX] = false;
        state.errors[LOAD_AUTHORS_ALPHANUMERIC_INDEX] = null;
      })
      .addCase(loadAuthorsAtAlphanumericIndex.rejected, (state, action) => {
        console.log('loadAuthorsAtAlphanumericIndex.rejected', action.error);
        state.loading[LOAD_AUTHORS_ALPHANUMERIC_INDEX] = false;
        state.errors[LOAD_AUTHORS_ALPHANUMERIC_INDEX] = action.error;
      });
  },
});

export default authorsSlice.reducer;

export const { storeAuthors } = authorsSlice.actions;
