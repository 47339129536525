import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import Cover from '@/components/Cover/Cover';
import SerieTerminatedBadge from '@/components/SerieTerminatedBadge/SerieTerminatedBadge';
import StarLine from '@/components/StarLine/StarLine';

/**
 * Renders a serie card
 */
const SearchSerieCard = (props) => {
  const serie = props.serie || {};

  return (
    <Link
      className={`${props.className || ''} no-decoration`}
      to={`/${serie.permalink}/serie/${serie.objectId}`}
    >
      <div className="d-flex align-items-center bb-background-light-grey rounded">
        <div className="me-2">
          <Cover
            shadow
            rounded
            height={120}
            imageUrl={serie.images ? serie.images.front.smallFramed : serie.imageUrl}
          />
        </div>
        <div className="overflow-hidden">
          <div className="d-flex mb-2">
            <SerieTerminatedBadge isTerminated={serie.isTerminated} objectKey={serie.objectId} />
          </div>
          <div className="text-truncate">
            <span className="bb-medium-text-size bb-text-gray-dark ">{serie.title}</span>
            <span className="text-secondary">{serie.type ? ' - ' + serie.type : ''}</span>
          </div>
          <StarLine note={serie.note} />
        </div>
      </div>
    </Link>
  );
};

SearchSerieCard.propTypes = {
  serie: PropTypes.shape({
    objectId: PropTypes.string,
    title: PropTypes.string,
    isTerminated: PropTypes.bool,
    note: PropTypes.number,
    imageUrl: PropTypes.string,
  }),
};

export default SearchSerieCard;
