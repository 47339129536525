import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { createSerieRevision } from 'bubble-reducers/src/reducers/catalogs';
import { loadCategories } from 'bubble-reducers/src/reducers/categories';

import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './Modals.css';

const SerieRevisionModal = (props) => {
  const dispatch = useDispatch();
  const { serieObjectId, allowGoBack } = props;

  const [categoryFocused, setCategoryFocused] = useState(false);
  const [editedFields, setEditedFields] = useState({});

  const serie = useSelector((state) => state.series.series[serieObjectId]);
  const categories = useSelector((state) => state.categories.categories);
  const userObjectId = useSelector((state) => state.user.user.objectId);

  useEffect(() => {
    dispatch(loadCategories());
  }, []);

  const dismiss = () => {
    setEditedFields({});
    setCategoryFocused(false);
    props.callback();
  };

  const handleGoBack = () => {
    dismiss();
    props.handleGoBack();
  };

  const displayField = (field) => {
    if (field in editedFields) return editedFields[field];
    return serie[field] ? serie[field].toString() : '';
  };
  const displayCategory = () => {
    if ('category' in editedFields) return editedFields.category || '';
    return serie?.category || '';
  };

  const isRevisionEmpty = (newValue, oldValue) => {
    const convertedOldValue =
      oldValue && typeof oldValue !== 'string' ? oldValue.toString() : oldValue;
    if (newValue === convertedOldValue) {
      return true;
      //This is to prevent revisions like null => null or null => ''
    } else if (
      (convertedOldValue === null || convertedOldValue === '' || convertedOldValue === undefined) &&
      (newValue === null || newValue === '' || newValue === undefined)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const sendRevision = () => {
    const revisions = [];
    Object.keys(editedFields).forEach((field) => {
      if (!isRevisionEmpty(editedFields[field], serie[field])) {
        revisions.push({
          userObjectId,
          oldValue: serie[field],
          newValue: editedFields[field],
          fieldRevised: field,
        });
      }
    });
    if (revisions.length) {
      dispatch(createSerieRevision({ serieObjectId, revisions }));
      dismiss();
      props.openThankYourModal();
    } else {
      alert("Aucun champ n'a l'air d'avoir été modifié, êtes vous bien sûr de vos modifications ?");
    }
  };

  const renderSummarySection = () => {
    return (
      <div className="col-12 px-0">
        <div className="form-group mt-3">
          <label className="text-secondary font-style-italic" htmlFor="exampleFormControlTextarea1">
            Présentation de la série:
          </label>
          <textarea
            value={displayField('summary')}
            onChange={(e) => setEditedFields({ ...editedFields, summary: e.target.value })}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="5"
          />
        </div>
      </div>
    );
  };

  const renderCategorySection = () => {
    return (
      <div className="col">
        <div className="row d-flex align-items-center">
          <div className="col">
            <LabeledTextInput
              white
              className={'bg-white'}
              noRightLabel
              title="Catégorie"
              readOnly
              value={displayCategory()}
              onFocus={() => setCategoryFocused(true)}
              onBlur={() =>
                setTimeout(() => {
                  setCategoryFocused(false);
                }, 200)
              }
            />
            <div className={`dropdown ${categoryFocused ? 'show' : ''}`}>
              <div className={`mt-n3 dropdown-menu ${categoryFocused ? 'show' : ''}`}>
                {categories.map((category) => (
                  <WithClickHandler
                    key={'search_' + category.objectId}
                    onMouseDown={() =>
                      setEditedFields({ ...editedFields, category: category.nameFrench })
                    }
                  >
                    <div className="ms-3 me-5">{category.nameFrench}</div>
                  </WithClickHandler>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStatusSection = () => {
    const isTerminated = () => {
      if ('isTerminated' in editedFields) return editedFields.isTerminated;
      return serie.isTerminated;
    };
    return (
      <div className="col text-secondary">
        <span className="ms-lg-2">Status de la série:</span>
        <div className="d-flex flex-lg-row flex-column justify-content-around text-center pt-1">
          <WithClickHandler
            onClick={() => setEditedFields({ ...editedFields, isTerminated: false })}
          >
            <div
              className={`d-flex flex-lg-row flex-column p-2 px-5  mb-lg-0 mb-2 ${
                !isTerminated()
                  ? 'bg-success text-white text-center border border-success'
                  : 'bg-white text-secondary text-center border border-grey'
              }`}
            >
              <span className="text-center">En cours</span>
            </div>
          </WithClickHandler>
          <WithClickHandler
            onClick={() => setEditedFields({ ...editedFields, isTerminated: true })}
          >
            <div
              className={`d-flex flex-lg-row flex-column p-2 px-5  mt-lg-0 mt-2 ${
                isTerminated()
                  ? 'bg-success text-white border border-success'
                  : 'bg-white text-secondary border border-grey'
              }`}
            >
              Terminée
            </div>
          </WithClickHandler>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      {(serie && categories?.length && (
        <>
          <div className="modal-header header-no-separator">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <Icon name="chevron-left" />
              </WithClickHandler>
            )}
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={dismiss}
            ></button>
          </div>
          <div className="modal-body mx-sm-3">
            <div className="row">
              <div className="col">
                <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
                  <span>Signalement</span>
                  <span className="fw-bold">{serie.title || ''}</span>
                </div>
                <div className="text-secondary d-flex justify-content-center">
                  Informations sur la série
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="row">
                <div className="col-12 mt-3">
                  <LabeledTextInput
                    white
                    noRightLabel
                    title="Titre"
                    placeholder="Titre"
                    value={displayField('title')}
                    onChange={(e) => setEditedFields({ ...editedFields, title: e.target.value })}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between">
                {renderCategorySection()}
                {renderStatusSection()}
              </div>
            </div>
            {renderSummarySection()}
          </div>
          <div className="h-separator mt-2" />
          <div className="d-flex flex-row justify-content-between p-4 align-items-center">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <div className="text-start bb-medium-text-size">
                  <u>Retour</u>
                </div>
              </WithClickHandler>
            )}
            <div className="text-end">
              <button onClick={sendRevision} className="btn btn-success btn-lg text-white">
                Soumettre
              </button>
            </div>
          </div>
        </>
      )) ||
        null}
    </Modal>
  );
};

export default SerieRevisionModal;
