import React from 'react';

const Card = (props) => {
  const card = props.card || {};
  return (
    <div
      className={`text-white ${
        'card' in props ? 'bg-primary rounded p-3 h-100 bb-shadow-hover' : ''
      }`}
    >
      <div className="fw-bold">{card.brand}</div>
      <div>****-****-****-{card.last4}</div>
      <div>
        Expire le {card.expMonth}/{card.expYear}
      </div>
      <div>
        {/* <span>Boutons</span> */}
        {props.button ? props.button : null}
      </div>
    </div>
  );
};

export default Card;
