import React from 'react';

const Star = (props) => {
  const size = props.size === 'medium' ? '22px' : props.size === 'big' ? '42px' : '16px';
  const percentFill = props.percentFill || '0%';
  return (
    <svg className={props.className} height={size} width={size} viewBox="0 0 24 24">
      <path
        fill={`url(#fill-to-${percentFill})`}
        d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
      />
    </svg>
  );
};

export default Star;
