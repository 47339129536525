import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addToCart } from 'bubble-reducers/src/reducers/cart';

import { isBinaryFlagEnabled } from 'bubble-utils/src/code-utils';

import {
  useAddPrintsToLibrary,
  useRemoveFromCart,
  useRemovePrintsFromLibrary,
} from '@/services/hooks/useLibraryUtils';
import { getAssetImage } from '@/services/image-utils';

import AddedToCartModal from '@/components/Modals/AddedToCartModal';

import SerieModeSwitcher from '../SerieModeSwitcher/SerieModeSwitcher';

import { GENERAL } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

const SerieBatchActions = (props) => {
  const dispatch = useDispatch();

  const addPrintsToLibrary = useAddPrintsToLibrary();
  const removePrintsFromLibrary = useRemovePrintsFromLibrary();
  const removeFromCart = useRemoveFromCart();

  const serie = props.serie || {};

  const user = useSelector((state) => state.user.user);
  const ownedAlbumsIdsMap = useSelector((state) => state.profiles.ownedAlbumsIdsMap);
  const myAlbums = useSelector((state) => state.profiles.myAlbums);
  const albums = useSelector((state) => state.albums.albums);
  const prints = useSelector((state) => state.prints.prints);
  const cart = useSelector((state) => state.cart.cart);

  const missingAlbumsIds = (serie.albums || []).reduce((acc, cur) => {
    if (!ownedAlbumsIdsMap[cur]) acc.push(cur);
    return acc;
  }, []);
  const ownedSeriePrints = (serie.albums || []).reduce((acc, cur) => {
    if (ownedAlbumsIdsMap[cur]) {
      Object.entries(myAlbums[cur]?.userPrints || {}).forEach(([printObjectId, flag]) => {
        if (isBinaryFlagEnabled(flag, GENERAL.USER_PRINT.OWNED)) {
          acc.push({ album: { objectId: cur }, objectId: printObjectId });
        }
      });
    }
    return acc;
  }, []);
  const serieMissingPrints = (serie.albums || []).reduce((acc, cur) => {
    if (!ownedAlbumsIdsMap[cur])
      acc.push({ album: { objectId: cur }, objectId: albums?.[cur]?.defaultPrintObjectId });
    return acc;
  }, []);
  const missingAvailablePrints = missingAlbumsIds
    .map((albumId) => prints[(albums[albumId] || {}).defaultSellingPrintObjectId] || {})
    .filter((print) => {
      const availabilities = bubbleUtils.album.getAvailabilities(print);
      return availabilities.atLeastOneChannel;
    });
  const allMissingsInCart =
    missingAvailablePrints.length &&
    missingAvailablePrints.every(
      (print) => !!cart.items.find((item) => item.itemObjectId === print.objectId),
    );

  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);

  const missingAlbumsPrice = missingAvailablePrints.reduce((acc, cur) => {
    acc += Number(cur.sellingInfo.price);
    return acc;
  }, 0);

  const onClickCart = () => {
    if (allMissingsInCart) {
      removeFromCart({ prints: missingAvailablePrints, isBatch: true });
    } else {
      dispatch(addToCart({ prints: missingAvailablePrints, isBatch: true }));
      setShowAddedToCartModal(true);
    }
  };

  const onClickCollection = () => {
    missingAlbumsIds.length === 0
      ? removePrintsFromLibrary(user, ownedSeriePrints)
      : addPrintsToLibrary(user, serieMissingPrints);
  };

  return (
    <div className="row mt-3 mb-4">
      {showAddedToCartModal && (
        <AddedToCartModal
          show={showAddedToCartModal}
          itemIds={missingAvailablePrints.map((print) => print.objectId)}
          callback={() => setShowAddedToCartModal(false)}
        />
      )}
      <div className="col-12 d-flex flex-fill align-items-center">
        <div className="d-none d-md-block">
          {serie.numberOfAlbums
            ? `${serie.numberOfAlbums} tome${serie.numberOfAlbums > 1 ? 's :' : ':'}`
            : ''}
        </div>
        <div className="d-flex flex-column flex-md-row mt-n1">
          <span>
            <button
              onClick={onClickCollection}
              className="btn btn-link no-decoration d-flex align-items-center"
            >
              <img
                className="mt-n2"
                alt="tout ajouter collection"
                src={
                  missingAlbumsIds.length === 0
                    ? getAssetImage('icon_add_collection_green.svg')
                    : getAssetImage('icon_add_collection.svg')
                }
              />
              <div
                className={`ms-1 mt-md-1 ${missingAlbumsIds.length === 0 ? 'text-success' : ''}`}
              >
                {missingAlbumsIds.length === 0 ? 'Tout retirer de' : 'Tout ajouter à'} ma collection
              </div>
            </button>
          </span>
          <span>
            <button
              disabled={missingAvailablePrints.length === 0}
              onClick={onClickCart}
              className="ms-md-2 btn btn-link no-decoration d-flex align-items-center"
            >
              <img
                className="mt-n2"
                alt="tout ajouter panier"
                src={
                  allMissingsInCart
                    ? getAssetImage('icon_remove_cart_red.svg')
                    : getAssetImage('icon_add_cart_grey.svg')
                }
              />
              <div className={`ms-1 mt-md-1 ${allMissingsInCart ? 'text-danger' : ''}`}>
                {allMissingsInCart
                  ? 'Retirer les albums de mon panier'
                  : 'Ajouter les manquants au panier'}
              </div>
              <div className={`ms-1 mt-md-1 ${allMissingsInCart ? 'text-danger' : ''}`}>
                ({bubbleUtils.currency.formatCurrency(missingAlbumsPrice) || '-'})
              </div>
            </button>
          </span>
        </div>

        <div className="flex-fill" />
        <span className="d-none d-md-block">
          <SerieModeSwitcher mode={props.serieMode} callback={props.setSerieMode} />
        </span>
      </div>
    </div>
  );
};

SerieBatchActions.propTypes = {
  serie: PropTypes.object,
  serieMode: PropTypes.any,
  setSerieMode: PropTypes.func,
};

export default SerieBatchActions;
