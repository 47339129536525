import React from 'react';

import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { getAlbumsForAuthorId } from 'bubble-reducers/src/selectors';

import '../Author.scss';

const PAGE = {
  ALL: 0,
  SERIES: 1,
  ALBUMS: 2,
};

export default function AuthorNavBar({ authorObjectId }) {
  const location = useLocation();
  const { permalink } = useParams();
  const authorSeriesObjectIdsMapCount =
    useSelector((state) => state.authors.authorSeriesObjectIdsMap[authorObjectId]?.length) || null;
  const lastPublishedAlbumsCount =
    useSelector((state) => getAlbumsForAuthorId(state, authorObjectId)?.length) || null;
  const splittedPathName = location.pathname.split('/');
  const currentPage = splittedPathName.includes('series')
    ? PAGE.SERIES
    : splittedPathName.includes('albums')
      ? PAGE.ALBUMS
      : PAGE.ALL;

  const rootPath = '/' + [permalink, 'author', authorObjectId].filter(Boolean).join('/');

  return (
    <>
      <nav className="pt-3 d-flex align-items-center fw-bold h5 text-secondary ms-md-n2">
        <ul className="pe-4 ps-0 fw-normal author-navbar-container">
          <Link className="no-decoration" to={`${rootPath}`}>
            <span className={`px-2${!!(currentPage === PAGE.ALL) ? ' text-black fw-bold' : ''}`}>
              Tout
            </span>
          </Link>
          <div
            className={`author-navbar-highlight-bar rounded mt-1 px-2${
              !!(currentPage === PAGE.ALL) ? ' bg-purple' : ''
            }`}
          ></div>
        </ul>
        <ul className="pe-4 ps-0 fw-normal author-navbar-container">
          <Link className="no-decoration" to={`${rootPath}/series`}>
            <span className={`px-2${!!(currentPage === PAGE.SERIES) ? ' text-black fw-bold' : ''}`}>
              Séries
              {!!authorSeriesObjectIdsMapCount ? ` (${authorSeriesObjectIdsMapCount})` : ''}
            </span>
          </Link>
          <div
            className={`author-navbar-highlight-bar rounded mt-1 px-2${
              !!(currentPage === PAGE.SERIES) ? ' bg-purple' : ''
            }`}
          ></div>
        </ul>
        <ul className="pe-4 ps-0 fw-normal author-navbar-container">
          <Link className="no-decoration" to={`${rootPath}/albums`}>
            <span className={`px-2${!!(currentPage === PAGE.ALBUMS) ? ' text-black fw-bold' : ''}`}>
              Albums
              {lastPublishedAlbumsCount
                ? lastPublishedAlbumsCount === 200
                  ? ' (200+)'
                  : ` (${lastPublishedAlbumsCount})`
                : ''}
            </span>
          </Link>
          <div
            className={`author-navbar-highlight-bar rounded mt-1 px-2${
              !!(currentPage === PAGE.ALBUMS) ? ' bg-purple' : ''
            }`}
          ></div>
        </ul>
      </nav>
      <hr className="mt-n2" />
    </>
  );
}
