import React from 'react';

import Comment from './Comment/Comment';
import FlaggedComment from './FlaggedComment';

const CommentItemizer = (props) => {
  const { isAdmin, comments } = props;
  if (!comments) {
    return null;
  }

  return Object.values(comments).map((comment) => {
    if (comment) {
      const { flagcount } = comment;
      if (flagcount) {
        return <FlaggedComment key={comment.objectId} commentObjectId={comment.objectId} />;
      } else {
        return <Comment isAdmin={isAdmin} key={comment.objectId} comment={comment} />;
      }
    } else {
      return null;
    }
  });
};

export default CommentItemizer;
