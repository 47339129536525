export const throttle = (fn, wait) => {
  var time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};

export const pad = (number) => (number < 10 ? '0' + number.toString() : number.toString());

export const isBinaryFlagEnabled = (number, flag) => number & flag;

export const removeBitFromBinaryFlag = (number, bitToUnset) => number & ~bitToUnset;

export const addBitToBinaryFlag = (number, bitToSet) => number | bitToSet;
