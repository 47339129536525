import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CartRecommendations from '@/components/CartRecommendations/CartRecommendations';
import ItemCard from '@/components/ItemCard/ItemCard';

import './Modals.css';

const AddedToCartModal = (props) => {
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.user.user);

  const dismiss = () => {
    props.callback();
  };

  const goToCart = () => {
    dismiss();
    navigate('/cart');
  };

  const itemIds = props?.itemIds
    ? Array.isArray(props.itemIds)
      ? props.itemIds
      : [props.itemIds]
    : [];

  const items =
    itemIds
      .map((itemId) =>
        (cart || { items: [] }).items.find(
          (cartItem) => cartItem.itemObjectId === itemId || cartItem.sku === itemId,
        ),
      )
      .filter((row) => row && row !== 'undefined') || [];

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        <div>
          <div className="text-center fw-bold bb-l-text-size text-success mb-3">
            {itemIds.length > 1
              ? 'Ces articles ont bien été ajoutés'
              : 'Cet article a bien été ajouté'}{' '}
            à votre panier
          </div>
        </div>
        <div>
          {items.map((item) => (
            <ItemCard
              key={`cart_modal_${item.itemObjectId}`}
              item={item}
              noClose
              isQuantitySelectable={true}
              user={user}
            />
          ))}
        </div>
        <div className="mt-4 d-flex justify-content-center mb-4">
          <button className="btn btn-lg btn-secondary text-white me-2" onClick={dismiss}>
            Continuer mes achats
          </button>
          <button className="btn btn-lg btn-success text-white ms-2" onClick={goToCart}>
            Voir mon panier
          </button>
        </div>
        <div className="mb-n5">
          <CartRecommendations />
        </div>
      </div>
    </Modal>
  );
};

AddedToCartModal.propTypes = {
  itemIds: PropTypes.any,
};

export default AddedToCartModal;
