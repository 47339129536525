import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';

import { loadTopApi, loadTopsApi } from '../services/api';

import { getClientToken } from '../selectors';
import { storeAlbums } from './albums';
import { storePrints } from './prints';
import { storeSeries } from './series';

export const LOAD_TOP = 'tops/loadTop';
const LOAD_TOPS = 'tops/loadTops';

const initialState = {
  topNames: [],
  tops: {},
  loadedTops: {},
  loading: {},
  errors: {},
};

export const loadTop = createAsyncThunk(LOAD_TOP, async (params, { getState, dispatch }) => {
  const topName = params?.topName || null;

  const clientToken = getClientToken(getState());
  const top = await loadTopApi(clientToken, topName);

  const type = top[0]?.dataType;
  if (type === 'albums') {
    const { albums, prints } = extractAlbumAndPrints(top.map((element) => ({ ...element.album })));
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));
  } else if (type === 'series') {
    dispatch(storeSeries({ series: top.map((element) => ({ ...element.serie })) }));
  }

  return { top, topName };
});

export const loadTops = createAsyncThunk(LOAD_TOPS, async (params, { getState }) => {
  const withData = params?.withData || null;

  const clientToken = getClientToken(getState());
  const tops = await loadTopsApi(clientToken, withData);
  return { tops, withData };
});

const topsSlice = createSlice({
  name: 'tops',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadTop
      .addCase(loadTop.pending, (state, action) => {
        state.loading[LOAD_TOP] = true;
        state.errors[LOAD_TOP] = null;
      })
      .addCase(loadTop.fulfilled, (state, action) => {
        state.loadedTops[action.payload.topName] = action.payload.top;

        state.loading[LOAD_TOP] = false;
        state.errors[LOAD_TOP] = null;
      })
      .addCase(loadTop.rejected, (state, action) => {
        state.loading[LOAD_TOP] = false;
        state.errors[LOAD_TOP] = action.error;
      })
      // loadTops
      .addCase(loadTops.pending, (state, action) => {
        state.loading[LOAD_TOPS] = true;
        state.errors[LOAD_TOPS] = null;
      })
      .addCase(loadTops.fulfilled, (state, action) => {
        let loadedTops = { ...state.loadedTops };
        const tops = action.payload.tops;
        const topNames = tops.map((top) => top.name);
        let newTops = {};
        topNames.forEach((name) => {
          const associatedTop = tops.find((top) => top.name === name);
          if (action.payload.withData) {
            loadedTops[name] = (associatedTop.albums || associatedTop.series).slice();
            delete associatedTop.series;
            delete associatedTop.albums;
          }
          newTops[name] = associatedTop;
        });

        state.topNames = topNames;
        state.tops = newTops;
        state.loadedTops = loadedTops;

        state.loading[LOAD_TOPS] = false;
        state.errors[LOAD_TOPS] = null;
      })
      .addCase(loadTops.rejected, (state, action) => {
        state.loading[LOAD_TOPS] = false;
        state.errors[LOAD_TOPS] = action.error;
      });
  },
});

export default topsSlice.reducer;
