import React, { useRef } from 'react';

import { CSSTransition } from 'react-transition-group';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import phonePngBd from '@/assets/landings/phone_bd.jpg';
import phoneWebpBd from '@/assets/landings/phone_bd.webp';
import phonePngComics from '@/assets/landings/phone_comics.png';
import phoneWebpComics from '@/assets/landings/phone_comics.webp';
import phonePngMangas from '@/assets/landings/phone_mangas.jpg';
import phoneWebpMangas from '@/assets/landings/phone_mangas.webp';
import phoneTransparentPng from '@/assets/landings/phone_mangas_transparency.png';
import phoneTransparentWebp from '@/assets/landings/phone_mangas_transparency.webp';
import question1 from '@/assets/landings/question1.jpg';
import question2 from '@/assets/landings/question2.jpg';
import question3 from '@/assets/landings/question3.jpg';
import question4 from '@/assets/landings/question4.jpg';

import { GENERAL } from 'bubble-constants';

import './PhoneAndQuestions.scss';

const PhoneAndQuestions = ({ showFade, category = GENERAL.BD, usePng }) => {
  const defaultImg = usePng
    ? phoneTransparentPng
    : category === GENERAL.BD
      ? phonePngBd
      : category === GENERAL.COMICS
        ? phonePngComics
        : phonePngMangas;

  const webp = usePng
    ? phoneTransparentWebp
    : category === GENERAL.BD
      ? phoneWebpBd
      : category === GENERAL.COMICS
        ? phoneWebpComics
        : phoneWebpMangas;

  const nodeRef1 = useRef(null);
  const nodeRef2 = useRef(null);
  const nodeRef3 = useRef(null);
  const nodeRef4 = useRef(null);

  return (
    <div className="d-flex align-items-center justify-content-center justify-content-md-start">
      <div className="d-none d-lg-block">
        <CSSTransition
          nodeRef={nodeRef1}
          unmountOnExit
          timeout={1000}
          appear={false}
          in={showFade}
          classNames="sms-fade"
        >
          <div ref={nodeRef1} className="d-flex justify-content-end">
            <img alt="question" className="question" src={question1} />
          </div>
        </CSSTransition>
        <CSSTransition
          nodeRef={nodeRef2}
          unmountOnExit
          timeout={900}
          appear={false}
          in={showFade}
          classNames="sms-fade3"
        >
          <div ref={nodeRef2} className="d-flex justify-content-end py-3 pe-md-5">
            <img alt="question" className="question" src={question2} />
          </div>
        </CSSTransition>
        <CSSTransition
          nodeRef={nodeRef3}
          unmountOnExit
          timeout={600}
          appear={false}
          in={showFade}
          classNames="sms-fade2"
        >
          <div ref={nodeRef3} className="d-flex justify-content-end">
            <img alt="question" className="question" src={question3} />
          </div>
        </CSSTransition>
        <CSSTransition
          nodeRef={nodeRef4}
          unmountOnExit
          timeout={1000}
          appear={false}
          in={showFade}
          classNames="sms-fade"
        >
          <div ref={nodeRef4} className="d-flex justify-content-end py-3 pe-md-3">
            <img alt="question" className="question" src={question4} />
          </div>
        </CSSTransition>
      </div>
      <div className="d-flex flex-fill justify-content-center justify-content-md-end ps-3 ps-md-0">
        <WebpWrapper
          className="big-phone overflow-hidden"
          default={defaultImg}
          webp={webp}
          alt={`ecran ${category}`}
        />
      </div>
    </div>
  );
};

export default PhoneAndQuestions;
