import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import Cover from '@/components/Cover/Cover';
import SerieFollowBadge from '@/components/SerieFollowBadge/SerieFollowBadge';
import StarLine from '@/components/StarLine/StarLine';

import './SerieVerticalCard.css';

/**
 * Renders a serie card
 */
const SerieVerticalCard = (props) => {
  const serie = props.serie || {};

  return (
    <Link
      className={`no-decoration ${
        props.className || ''
      } d-flex align-items-center flex-column py-2 position-relative`}
      to={`/${serie.permalink}/serie/${serie.objectId}`}
    >
      <div className="position-relative">
        <Cover
          alt={`Couverture du premier album de la série ${serie.title}`}
          height={160}
          shadow
          hoverable
          rounded
          imageUrl={serie.images ? serie.images.front.smallFramed : serie.imageUrl}
        />
        {'coverAddon' in props && props.coverAddon}
      </div>
      <div className="bb-text-gray-dark fw-bold mb-n1 pt-1">
        {serie.title.substring(0, 18)}
        {serie.title.length > 18 ? '...' : ''}
      </div>
      <div className="py-1">
        <StarLine note={serie.note} />
      </div>
      {!('noSubscribe' in props) && (
        <SerieFollowBadge noflex serieObjectId={serie.objectId} smallPadding />
      )}
    </Link>
  );
};

SerieVerticalCard.propTypes = {
  serie: PropTypes.shape({
    objectId: PropTypes.string,
    title: PropTypes.string,
    isTerminated: PropTypes.bool,
    note: PropTypes.number,
    imageUrl: PropTypes.string,
    showDate: PropTypes.any,
    noSubscribe: PropTypes.any,
  }),
};

export default SerieVerticalCard;
