import React from 'react';

import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import RevisionMenuCell from './components/RevisionMenuCell';

import './Modals.css';

const SerieRevisionMenuModal = (props) => {
  const { serieObjectId, allowGoBack } = props;

  const serie = useSelector((state) => state.series.series[serieObjectId]);

  const dismiss = () => {
    props.callback();
  };

  const handleSerieRevision = () => {
    dismiss();
    props.openSerieRevisionModal();
  };

  const handleDuplicateAlbum = () => {
    dismiss();
    props.openDuplicateAlbumRevisionModal();
  };

  const handleGoBack = () => {
    dismiss();
    props.handleGoBack();
  };

  const handleOtherError = () => {
    window &&
      window.Intercom &&
      window.Intercom('showNewMessage', `Série: ${serie.title || ''} ${serie.type || ''}\nErreur:`);
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      {(serie && (
        <>
          <div className="modal-header header-no-separator">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <Icon name="chevron-left" className="flex-fill" />
              </WithClickHandler>
            )}
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={dismiss}
            ></button>
          </div>
          <div className="modal-body mx-3">
            <div className="row pb-2">
              <div className="col py-1">
                <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
                  <span>Signalement</span>
                  <span className="fw-bold">{serie.title || ''}</span>
                </div>
                <div className="text-secondary text-center">
                  Quelles modifications souhaitez-vous suggérer pour cet album ?
                </div>
              </div>
            </div>
            <RevisionMenuCell
              onClick={handleSerieRevision}
              mainText="Informations sur la série"
              subText="Nom, catégorie, résumé..."
              imageSource="icon_album_memo_grey.svg"
            />
            <RevisionMenuCell
              onClick={handleDuplicateAlbum}
              mainText="Signaler un album en double"
              subText="Selectionnez-le dans la liste"
              imageSource="icon_double_album.svg"
            />
            <div className="h-separator mt-2" />
            <div className="d-flex flex-row justify-content-between p-4">
              <WithClickHandler onClick={handleOtherError}>
                <div className="text-start">
                  <u>Signaler une autre erreur</u>
                </div>
              </WithClickHandler>
              <WithClickHandler onClick={dismiss}>
                <div className="text-end">
                  <u>Annuler</u>
                </div>
              </WithClickHandler>
            </div>
          </div>
        </>
      )) ||
        null}
    </Modal>
  );
};

export default SerieRevisionMenuModal;
