import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { LOAD_XMAS_SELECTION, loadXmasSelection } from 'bubble-reducers/src/reducers/albums';
import { getAlbumsForChristmasId } from 'bubble-reducers/src/selectors';

import { getSeoForXmas2024 } from '@/services/seo-utils';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';

import sectionsMap from '../sections_map.json';

export default function Christmas2024Section() {
  const dispatch = useDispatch();
  const { sectionSlug, subSectionSlug } = useParams();
  const section = Object.values(sectionsMap).find((s) => !!s.slug && s.slug === sectionSlug) || {};
  const subSections = section.subs?.map((s) => sectionsMap[s]) || [];
  const currentSubSection =
    Object.values(sectionsMap).find((s) => !!s.slug && s.slug === subSectionSlug) || null;
  const params = currentSubSection?.params || section.params;
  const albums = useSelector((state) =>
    getAlbumsForChristmasId(state, currentSubSection?.id || section.id),
  );
  const isLoading = useSelector((state) => state.albums.loading[LOAD_XMAS_SELECTION]);

  useEffect(() => {
    dispatch(loadXmasSelection({ options: params }));
  }, [sectionSlug, subSectionSlug]);

  return (
    <>
      <BubbleHelmet seo={getSeoForXmas2024(section, currentSubSection)} />
      <h1 className="h3 fw-bold">
        {currentSubSection?.allTitle ||
          currentSubSection?.title ||
          section.allTitle ||
          section.title}
      </h1>

      {(currentSubSection?.descrption ||
        section.description ||
        currentSubSection?.articleLink ||
        section.articleLink) && (
        <p className="pb-3 pt-2">
          {currentSubSection?.descrption || section.description}
          {(currentSubSection?.articleLink || section.articleLink) && (
            <>
              <br />
              Découvrez aussi{' '}
              <Link
                className="text-primary"
                to={currentSubSection?.articleLink || section.articleLink}
              >
                {currentSubSection?.articleName || section.articleName}
              </Link>
            </>
          )}
        </p>
      )}
      <div className="d-flex align-items-center">
        <Link
          className="me-md-5 me-3 no-decoration text-primary"
          to={section.link || `/noel/${section.slug}`}
        >
          {section.allTitle}
        </Link>
        {subSections.map((subSection) => (
          <Link
            key={subSection.slug}
            className="me-md-5 me-3 no-decoration text-primary"
            to={subSection.link || `/noel/${section.slug}/${subSection.slug}`}
          >
            {subSection.title}
          </Link>
        ))}
      </div>

      <div className="row pt-4">
        {!!albums?.length ? (
          albums.map((album) => (
            <AlbumCard
              key={album.objectId}
              albumObjectId={album.objectId}
              className="col-6 col-md-2 pb-4"
            />
          ))
        ) : isLoading ? (
          <div className="col-12 d-flex align-items-center justify-content-center py-5">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </>
  );
}
