import React from 'react';

import Toast from './components/Toast';

const Toaster = () => {
  const addToast = (title, body, small, id = null) => {};
  const removeToast = (id) => {};

  const __addToast = (title, body, small, id) => {
    if (!id) {
      id = Math.ceil(Math.random() * 1000).toString();
    }
    let toasts = this.state.toasts.slice();
    toasts.push({
      id: id,
      toast: () => <Toast key={`key${id}`} toastId={id} title={title} body={body} small={small} />,
    });

    this.setState({ toasts }, () => {
      // $(`#${id}`).toast('show');
      // $(`#${id}`).on('hidden.bs.toast', () => this.__removeToast(id));
    });
  };

  const __removeToast = (id) => {
    let toasts = this.state.toasts.slice();
    const toDelete = this.state.toasts.find((toast) => {
      return Number(toast.id) === Number(id);
    });
    const index = this.state.toasts.indexOf(toDelete);
    toasts.splice(index, 1);
    this.setState({ toasts });
  };

  return null;
  // return (
  //   <div
  //     style={{
  //       position: 'fixed',
  //       bottom: '2rem',
  //       left: '1rem',
  //       zIndex: 4000,
  //     }}
  //   >
  //     {this.state.toasts.map((toast) => toast.toast())}
  //   </div>
  // );
};

export default Toaster;
