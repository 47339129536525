import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import { compressDates } from 'bubble-utils/src/author-utils';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

import '../SearchResults.css';

const SearchAuthorCard = (props) => {
  const author = props.author || {};
  const noLink = 'noLink' in props;

  const content = () => (
    <div className="d-flex align-items-center">
      <img
        className="rounded small-square-size me-2"
        alt={author.displayName || 'auteur'}
        src={author.imageUrl || author.images?.front?.medium || placeholderProfileJpg}
      />
      <div>
        <div>{author.displayName}</div>
        <div className="text-secondary font-italic">{compressDates(author)}</div>
      </div>
    </div>
  );

  return noLink ? (
    <div className={`${props.className || ''} d-flex align-items-center`}>{content()}</div>
  ) : (
    <Link className={props.className || ''} to={`/${author.permalink}/author/${author.objectId}`}>
      {content()}
    </Link>
  );
};

SearchAuthorCard.propTypes = {
  author: PropTypes.shape({
    objectId: PropTypes.string,
    permalink: PropTypes.string,
    displayName: PropTypes.string,
  }),
};

export default SearchAuthorCard;
