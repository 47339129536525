import React from 'react';

import { useSelector } from 'react-redux';

import { LOAD_AUTHOR } from 'bubble-reducers/src/reducers/authors';
import { getAlbumIdsForAuthorId } from 'bubble-reducers/src/selectors';

import AlbumCard from '@/components/AlbumCard/AlbumCard';

export default function AuthorAlbumsSection({ authorObjectId }) {
  const authorAlbumsObjectIds = useSelector((state) =>
    getAlbumIdsForAuthorId(state, authorObjectId),
  );
  const isLoading = useSelector((state) => state.authors.loading[LOAD_AUTHOR]);

  return (
    <>
      {isLoading && (
        <div className="col-12 d-flex justify-content-center pb-5">
          <span className="spinner-border spinner-lg" />
        </div>
      )}
      {/* <div style={{ position: 'absolute', top: 25, right: 8 }}>Trier par ... v</div> TODO:Filters */}
      <div className="row row-cols-12 row-cols-md-4 row-cols-lg-5">
        {authorAlbumsObjectIds?.map((albumObjectId) => (
          <div key={`author_album_${albumObjectId}`} className="col pb-3">
            <AlbumCard albumObjectId={albumObjectId} />
          </div>
        ))}
      </div>
    </>
  );
}
