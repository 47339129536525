import { PROJECT } from '@/project-constants';

import { getFormattedTitle } from 'bubble-utils/src/album-utils';
import { isString } from 'bubble-utils/src/string-utils';

import { getAssetImage } from '@/services/image-utils';

import imagePaper from '@/assets/opengraph/9eme_art.jpg';
import imageAgenda from '@/assets/opengraph/agenda.jpg';
import imageMobileApp from '@/assets/opengraph/application_mobile_bubble.jpg';
import imageInfinity from '@/assets/opengraph/bubble_infinity.jpg';
import imageGiftCard from '@/assets/opengraph/cheque_cadeau.jpg';
import imageHome from '@/assets/opengraph/home.jpg';
// images are declared as assets because once the urls are stored in facebook we can't update
// them anymore. So that way there is a dynamic part to the url when the image is updated
import imageIcon1024 from '@/assets/opengraph/icon-1024.png';
import imageMyProfile from '@/assets/opengraph/ma_collection.jpg';
import imageMyWishlist from '@/assets/opengraph/ma_wishlist.jpg';
import imageLandingBd from '@/assets/opengraph/opengraph_landing_bd.jpg';
import imageLandingComics from '@/assets/opengraph/opengraph_landing_comics.jpg';
import imageLandingMangas from '@/assets/opengraph/opengraph_landing_mangas.jpg';
import imagePromotions from '@/assets/opengraph/opengraph_promo.jpg';
import imageStoresMap from '@/assets/opengraph/stores_map.jpg';
import imageTops from '@/assets/opengraph/tops.jpg';
import imagePlaceholderPeople from '@/assets/placeholders/placeholder-profile.jpg';

import { GENERAL, LINK, TEXT } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

const minimumNumberOfNotesToIndex = 0;

// Twitter
// https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup
// twitter:image: Images for this Card support an aspect ratio of 2:1 with minimum dimensions of
// 300x157 or maximum of 4096x4096 pixels. Images must be less than 5MB in size.

// Facebook OpenGraph
// https://ogp.me/

const getStructuredDataForWebsite = () => {
  return {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: TEXT.WEBSITE_TITLE,
    url: LINK.WEBSITE,
  };
};

const getStructuredDataForOrganization = () => {
  return {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: TEXT.WEBSITE_TITLE,
    legalName: TEXT.WEBSITE_LEGAL_NAME,
    description: TEXT.WEBSITE_DESCRIPTION,
    url: LINK.WEBSITE,
    logo: {
      '@type': 'ImageObject',
      width: 1024,
      height: 1024,
      url: imageIcon1024,
    },
    sameAs: [
      LINK.TWITTER_BUBBLE_URL,
      LINK.LINKEDIN_BUBBLE_URL,
      LINK.FACEBOOK_BUBBLE_URL,
      LINK.INSTAGRAM_BUBBLE_URL,
      LINK.WIKIPEDIA_BUBBLE_URL,
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Station F, 5 Parvis Alan Turing',
      addressLocality: 'PARIS',
      postalCode: '75013',
      addressCountry: 'France',
    },
  };
};

// do not pass `url` parameter here otherwise it can be used on many different pages as
// canonical, instead pass a url` specific `url` for every page
export const getDefaultSeo = () => {
  const html = {
    title: '', // empty as it will be suffixed by `TEXT.WEBSITE_TITLE`
    description: TEXT.WEBSITE_DESCRIPTION,
    keywords: TEXT.WEBSITE_KEYWORDS,
  };
  const openGraph = {
    'og:title': TEXT.WEBSITE_TITLE,
    'og:type': 'website',
    'og:image': imageHome,
    'og:description': TEXT.WEBSITE_DESCRIPTION,
    'og:image:width': 1350,
    'og:image:height': 675,
  };

  const structuredData = [getStructuredDataForWebsite()].concat(getStructuredDataForOrganization());
  return { html, openGraph, structuredData };
};

export const getSeoForListing = (category, genre) => {
  const html = {
    title: [PROJECT.CATEGORY_NAMES_SHORT[category.name], genre.name].filter((row) => row).join(' '),
    description: genre.text || category.text,
  };
  const openGraph = {
    'og:title': [PROJECT.CATEGORY_NAMES_SHORT[category.name], genre.name]
      .filter((row) => row)
      .join(' '),
    // 'og:image': 'TODO'
    // 'og:url': 'TODO'
    'og:description': genre.text || category.text,
  };

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'CollectionPage',
    keywords: ['bubble'].concat([category.name, genre.name].filter((row) => row)),
  };
  return { html, openGraph, structuredData };
};

export const getSeoForWishlist = (user = {}, url) => {
  const title = [user.username, 'Wishlist'].filter((row) => row).join(' - ');
  const description = 'Découvrez ma wishlist BD, Comics, Mangas';
  url = url ? `${LINK.WEBSITE}${url}` : '';

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imageMyWishlist,
    'og:url': url,
    'og:description': description,
  };

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'CollectionPage',
    keywords: ['bubble'].concat(['wishlist', 'liste', 'envies'].filter((row) => row)),
  };
  return { html, openGraph, structuredData };
};

export const getSeoForHome = () => {
  const html = {
    canonical: LINK.WEBSITE,
  };
  const openGraph = {
    'og:url': LINK.WEBSITE,
  };
  return { html, openGraph };
};

export const getSeoForAboutUs = () => {
  const title = 'Tout sur Bubble et son équipe';
  const description = "Découvrez l'équipe derrière Bubble";
  const url = `${LINK.WEBSITE}/qui-sommes-nous`;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:url': url,
    'og:description': description,
  };

  return { html, openGraph };
};

export const getSeoForXmas2024 = (category, subcategory) => {
  const mainCategory = subcategory || category || {};
  category = category || {};
  subcategory = subcategory || {};
  const title = `Idées cadeaux Noël 2024${!!mainCategory.title ? ` - ${mainCategory.title}` : ''}`;
  const url = [LINK.WEBSITE, 'noel', category.slug || null, subcategory.slug || null]
    .filter((v) => v)
    .join('/');

  const defaultDescription =
    "Ce n'est pas pour rien qu'il y a au moins une BD en dessous de chaque sapin à Noël ! La bande dessinée est une valeure sûre. On vous propose une sélection d'albums qu'il ne fallait pas rater et qui feront toujours plaisir.";

  const html = {
    title: title,
    description: mainCategory.description || defaultDescription,
    canonical: url,
  };
  const openGraph = {
    'og:title': mainCategory.title,
    'og:url': url,
    'og:description': mainCategory.description || defaultDescription,
  };

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'CollectionPage',
    keywords: ['bubble', 'noël', 'cadeaux', '2024', 'bd', 'livre', mainCategory.title || null]
      .concat(mainCategory?.params?.tags || null)
      .filter((v) => v),
  };
  return { html, openGraph, structuredData };
};

export const getSeoForMobileAppLanding = (review) => {
  const description = `Découvrez l'application Bubble pour iOS et Android - ${
    review.comment || ''
  }`;
  const url = `${LINK.WEBSITE}/mobile-app`;

  const html = {
    title: "L'application Bubble",
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': "L'application Bubble sur iOS et Android",
    'og:image': imageMobileApp,
    'og:url': url,
    'og:description': description,
  };

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'MobileApplication',
    aggregateRating: {
      ...createAggregateRatings(4.4, 2354 + 737),
      itemReviewed: "L'application Bubble",
    },
    featureList: 'Collection management, wishlist, tops, articles, shop',
    operatingSystem: 'iOS, Android',
    applicationCategory: 'Comic books, Collection management',
    screenshot: imageMobileApp,
  };
  return { html, openGraph, structuredData };
};

export const getSeoForInfinityLanding = () => {
  const description =
    'Bubble Infinity - Tout simplement le meilleur outil pour organiser et gérer vos albums';
  const url = `${LINK.WEBSITE}/infinity`;

  const html = {
    title: 'Bubble Infinity',
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': 'Bubble Infinity',
    'og:image': imageInfinity,
    'og:url': url,
    'og:description': description,
  };

  const structuredData = {};
  return { html, openGraph, structuredData };
};

export const getSeoForPromotions = () => {
  const description =
    'Voir les figurines, ex-libris et goodies offerts en ce moment pour vos achats en ligne de BD, Comics et Manga.';
  const url = `${LINK.WEBSITE}/promotions`;

  const html = {
    title: 'Toutes les promotions BD, Comics et Manga',
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': 'Toutes les promotions BD, Comics et Manga',
    'og:image': imagePromotions,
    'og:url': url,
    'og:description': description,
  };

  const structuredData = {};
  return { html, openGraph, structuredData };
};

export const getSeoForArticle = (article) => {
  const url = `${LINK.WEBSITE}${article.path}`;
  const tags = ['bubble'].concat(article.tags);

  const html = {
    title: article.title,
    description: article.excerpt || article.excerptGenerated,
    canonical: url,
  };
  const openGraph = {
    'og:type': 'article',
    'og:title': article.title,
    'og:url': url,
    'og:image': article.imageUrl,
    'og:image:width': article.imageWidth || 1350,
    'og:image:height': article.imageHeight || 675,
    'og:description': article.excerpt || article.excerptGenerated,
    'article:published_time': article.publicationDate,
    'article:modified_time': article.lastModificationDate,
    'article:section': article.section?.title,
    'article:tag': tags,
    'article:author': article.author,
  };
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'Article',
    url: url,
    name: article.title,
    publisher: {
      '@type': 'Organization',
      name: 'Bubble',
      logo: {
        '@type': 'ImageObject',
        width: 376,
        height: 64,
        url: getAssetImage('logo_rectangle.png'),
      },
    },
    mainEntityOfPage: url,
    headline: article.title,
    alternativeHeadline: article.excerpt || article.excerptGenerated,
    articleSection: article.section?.title,
    description: article.excerpt || article.excerptGenerated,
    image: article.imageUrl,
    datePublished: article.publicationDate,
    dateModified: article.lastModificationDate,
    keywords: tags,
    wordCount: (article.contentWithLinks || '').split(' ').length,
    author: {
      '@type': 'Person',
      name: article.author,
    },
  };
  return { html, openGraph, structuredData };
};

export const getSeoForAgenda = (albums) => {
  const title =
    'Agenda des sorties BD, Comics et Mangas - Calendrier des nouveautés et albums à paraître';
  const description = `Toutes les sorties des BD, Comics et Mangas récentes et à paraître`;
  const url = `${LINK.WEBSITE}/agenda`;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imageAgenda,
    'og:url': url,
    'og:description': description,
  };
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    url: url,
    name: title,
    image: imageAgenda,
    description: description,
    numberOfItems: (albums || []).length,
    itemListOrder: 'Unordered',
    itemListElement: albums.map((album, index) => {
      return {
        '@type': 'ListItem',
        name: [album.serie?.title, album.title, album?.tome ? 'Tome ' + album.tome : '']
          .filter((row) => row)
          .join(' - '),
        url: `${LINK.WEBSITE}/${album.permalink}/${`album/${album.objectId}`}`,
        position: index,
      };
    }),
  };
  return { html, openGraph, structuredData };
};

export const getSeoForDefaultNArt = () => {
  const title = "Lisez, Bubble s'occupe du reste";
  const description = "Tout savoir sur l'actualité BD, Comics et Mangas chaque semaine";

  const html = {
    title: title,
    description: description,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imagePaper,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForNArtDefault = () => {
  const url = `${LINK.WEBSITE}/9emeart`;

  const html = {
    canonical: url,
  };
  const openGraph = {
    'og:url': url,
  };
  return { html, openGraph };
};

export const getSeoForPaperKnowAll = () => {
  const description = 'Tout savoir sur les BD, Comics et Mangas';
  const url = `${LINK.WEBSITE}/paper/tout-savoir-sur`;

  const html = {
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:url': url,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForWhatsNew = () => {
  const description = 'Quoi de neuf dans le monde du 9eme art ?';
  const url = `${LINK.WEBSITE}/paper/quoi-de-9`;

  const html = {
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:url': url,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForPaperEyeOnReleases = () => {
  const description = 'Tout savoir sur les sorties BD, Comics et Mangas';
  const url = `${LINK.WEBSITE}/paper/notre-oeil-sur-les-sorties`;

  const html = {
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:url': url,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForPaperBackstage = () => {
  const description = 'Tout savoir sur les coulisses';
  const url = `${LINK.WEBSITE}/paper/dans-les-coulisses`;

  const html = {
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:url': url,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForPaperCantMiss = () => {
  const description = 'Tout savoir sur les incontournables BD, Comics et Mangas';
  const url = `${LINK.WEBSITE}/paper/les-incontournables`;

  const html = {
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:url': url,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForTopList = () => {
  const title = 'Découvrez les tops de la communauté Bubble';
  const description = `Suivez les tops de Bubble pour connaître les tendances actuelles sur les BD, Comics et Mangas`;
  const url = `${LINK.WEBSITE}/tops`;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imageTops,
    'og:url': url,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForTop = (top, items) => {
  const description = top.description;
  const url = `${LINK.WEBSITE}/${encodeURIComponent(top.permalink)}/tops/${top.name}`;

  const html = {
    title: top.nameFrench,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': top.nameFrench,
    'og:image': top.image,
    'og:url': url,
    'og:description': description,
  };
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    name: top.nameFrench,
    image: top.image,
    description: description,
    numberOfItems: (items || []).length,
    itemListOrder: 'Descending',
    itemListElement: items.map((item) => {
      const serie = item.serie || {};
      const album = item.album || { serie: {} };
      return {
        '@type': 'ListItem',
        position: item.position,
        name:
          top.dataType === 'series'
            ? [serie.title, serie.collection].filter((row) => row).join(' - ')
            : [album.serie.title, album.title, album.tome ? 'Tome ' + album.tome : '']
                .filter((row) => row)
                .join(' - '),
        url: `${LINK.WEBSITE}${
          top.dataType === 'series'
            ? `/${serie.permalink}/serie/${serie.objectId}`
            : `/${album.permalink}/album/${album.objectId}`
        }`,
      };
    }),
  };
  return { html, openGraph, structuredData };
};

export const getSeoForSerie = (serie) => {
  const title = [serie.title, 'Découvrez sur Bubble'].filter((row) => row).join(' - ');
  const url = `${LINK.WEBSITE}/${serie.permalink}/serie/${serie.objectId}`;
  const imageUrl = ((serie.images || {}).front || {}).large;

  const html = {
    title: title,
    description: serie.descriptionShort,
    canonical: url,
    noIndex: serie.hasSexualContent || serie.numberOfNotes < minimumNumberOfNotesToIndex,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imageUrl,
    'og:url': url,
    'og:image:width': 1440,
    'og:image:height': 1872,
    'og:description': serie.descriptionShort,
  };
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'BookSeries',
    name: title,
    url: url,
    about: {
      '@type': 'Book',
    },
    image: ((serie.images || {}).front || {}).large,
    description: serie.descriptionShort,
    aggregateRating: serie.numberOfNotes
      ? createAggregateRatings(serie.note, serie.numberOfNotes)
      : null,
    author: Array.isArray(serie.authors)
      ? (serie.authors || []).map((author) => ({
          '@type': 'Person',
          name: author.displayName,
          url: `${LINK.WEBSITE}/${author.permalink}/author/${author.objectId}`,
        }))
      : null,
  };
  return { html, openGraph, structuredData };
};

export const getSeoForSerieReviews = (serie, numberOfNotes, numberOfComments) => {
  const serietitle = [serie.title, serie.collection].filter((row) => row).join(' - ');
  const title = `Tous les avis de la série ${serietitle}`;
  const description = `Découvrez les ${numberOfNotes ? numberOfNotes + ' ' : ''}notes et ${
    numberOfComments ? numberOfComments + ' ' : ''
  }commentaires de la série ${serietitle}`;
  const url = `${LINK.WEBSITE}/${serie.permalink}/serie/${serie.objectId}/avis`;
  const imageUrl = ((serie.images || {}).front || {}).large;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imageUrl,
    'og:url': url,
    'og:image:width': 1440,
    'og:image:height': 1872,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForAlbumReviews = (album, numberOfNotes, numberOfComments) => {
  const albumtitle = [
    album.serie ? album.serie.title : null,
    album.tome ? ', tome ' + album.tome : null,
    album.title ? ' : ' + album.title : null,
  ]
    .filter((row) => row)
    .join('');
  const title = `Tous les avis de l'album ${albumtitle}`;
  const description = `Découvrez les ${numberOfNotes ? numberOfNotes + ' ' : ''}notes et ${
    numberOfComments ? numberOfComments + ' ' : ''
  }commentaires de l'album ${albumtitle}`;
  const url = `${LINK.WEBSITE}/${album.permalink}/album/${album.objectId}/avis`;
  const imageUrl = ((album.images || {}).front || {}).large;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imageUrl,
    'og:url': url,
    'og:image:width': 1440,
    'og:image:height': 1872,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForReview = (review, serie, album) => {
  const title = `L'avis de ${review?.username}`;
  const description = review?.comment ? `${review?.comment}` : 'Lire son avis sur Bubble';
  const url = serie
    ? `${LINK.WEBSITE}/${serie?.permalink}/serie/${serie?.objectId}/avis/${review.objectId}`
    : `${LINK.WEBSITE}/${album?.permalink}/album/${album?.objectId}/avis/${review.objectId}`;
  const imageUrl = (((serie || album)?.images || {}).front || {}).large;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imageUrl,
    'og:url': url,
    'og:image:width': 1440,
    'og:image:height': 1872,
    'og:description': description,
  };
  return { html, openGraph };
};

export const getSeoForAuthor = (author) => {
  const url = `${LINK.WEBSITE}/${author?.permalink}/author/${author?.objectId}`;
  const description = `Découvrez les albums de ${author?.displayName} et les autres auteurs avec qui il/elle a collaboré`;

  const html = {
    title: author?.displayName,
    description: description,
    canonical: url,
    noIndex: true,
  };
  const openGraph = {
    'og:title': author?.displayName,
    'og:type': 'profile',
    'og:image': imagePlaceholderPeople,
    'og:url': url,
    'og:description': description,
    'profile:first_name': author?.firstName,
    'profile:last_name': author?.lastName,
    'profile:username': author?.pseudo,
  };
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    url: url,
    name: author?.displayName,
    givenName: author?.firstName,
    familyName: author?.lastName,
    additionalName: author?.pseudo,
    description: description,
    jobTitle: 'Author',
    birthDate: author?.yearOfBirth,
    deathDate: author?.yearOfDeath,
  };
  return { html, openGraph, structuredData };
};

export const getSeoForStore = (store) => {
  const url = `${LINK.WEBSITE}/${store.permalink}/store/${store.objectId}`;
  const description = `Librairie ${
    store.isClickAndCollectSeller ? 'partenaire Bubble' : ''
  } située à ${store.city}.\n${store.descriptionTitle || ''}`;
  const imageUrl = store.coverImage;

  const html = {
    title: 'Librairie ' + store.name,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': store.name,
    'og:type': 'profile',
    'og:image': imageUrl,
    'og:url': url,
    'og:description': description,
    'profile:username': store.name,
  };
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'BookStore',
    url: url,
    name: store.name,
    description: description,
    priceRange: '$$',
    address: {
      '@type': 'PostalAddress',
      addressCountry: store.countryNameFrench,
      postalCode: store.zipcode,
      streetAddress: store.line1 + ' ' + store.city,
    },
    email: store.email,
    telephone: store.phone,
    faxNumber: store.fax,
    image: imageUrl,
    logo: {
      '@type': 'ImageObject',
      width: 100,
      height: 100,
      url: store.logo,
    },
  };
  return { html, openGraph, structuredData };
};

export const getSeoForStoresMap = () => {
  const title = `Carte des librairies partenaires Bubble`;
  const description =
    'La carte de toutes les librairies partenaires Bubble, en France et en Belgique';
  const url = `${LINK.WEBSITE}/cartes-des-librairies-partenaires`;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imageStoresMap,
    'og:url': url,
    'og:description': description,
  };
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'StoresMapPage',
    keywords: ['bubble', 'recherche', 'librairies', 'partenaires'],
  };
  return { html, openGraph, structuredData };
};

export const getSeoForAlbum = (album, serie, print) => {
  /**
   * Asterix tome 38 - La Fille de Vercingetorix
   * Asterix tome 38
   * Asterix - La Fille de Vercingetorix
   * Asterix
   */
  const title = getFormattedTitle(serie, album);
  const url = `${LINK.WEBSITE}/${album.permalink}/album/${album.objectId}`;
  const imageUrl = ((print.images || {}).front || {}).large;
  const availabilities = bubbleUtils.album.getAvailabilities(print);
  const price = (print.sellingInfo || {}).price;
  const description =
    !album.summary || album.summary === 'Pas de description'
      ? [
          serie.title,
          album.tome ? `Tome ${album.tome}` : null,
          album.title,
          []
            .concat(album.authors || [])
            .slice(0, 2)
            .map((author) => (isString(author) ? author : author.displayName))
            .join(', ') || null,
        ]
          .filter(Boolean)
          .join(', ') +
        '. Bubble, le meilleur endroit pour découvrir, organiser et acheter des BD, comics et mangas. Livraison à domicile ou réservation en magasin.'
      : album.summary;

  const html = {
    title: title,
    description: description,
    canonical: url,
    noIndex: serie.hasSexualContent || album.numberOfNotes < minimumNumberOfNotesToIndex,
  };
  const openGraph = {
    'og:title': title,
    'og:type': 'book',
    'og:image': imageUrl,
    'og:url': url,
    'og:image:width': 1440,
    'og:image:height': 1872,
    'og:image:alt': "Couverture de l'album",
    'og:description': description,
    'book:isbn': print.ean,
    'book:author': Array.isArray(album.authors)
      ? album.authors.map(
          (author) => `${LINK.WEBSITE}/${author.permalink}/author/${author.objectId}`,
        )
      : null,
  };
  const twitterCard = {
    'twitter:image:alt': "Couverture de l'album",
  };
  const structuredData = [
    {
      '@context': 'http://schema.org',
      '@type': 'Book',
      url: url,
      name: title,
      isbn: print.ean,
      numberOfPages: print.numberOfPages || null,
      publisher: print.publisher ? { '@type': 'Organization', name: print.publisher } : null,
      description: description,
      image: imageUrl,
      aggregateRating: album.numberOfNotes
        ? createAggregateRatings(album.note, album.numberOfNotes)
        : null,
      author: Array.isArray(album.authors)
        ? (album.authors || []).map((author) => ({
            '@type': 'Person',
            name: author.displayName,
            url: `${LINK.WEBSITE}/${author.permalink}/author/${author.objectId}`,
          }))
        : null,
    },
  ].concat(
    price
      ? [
          {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: title,
            url: url,
            image: imageUrl,
            description: description,
            sku: print.ean,
            gtin13: print.ean,
            brand: print.publisher ? { '@type': 'Brand', name: print.publisher } : null,
            category: 'Book',
            aggregateRating: album.numberOfNotes
              ? createAggregateRatings(album.note, album.numberOfNotes)
              : null,
            offers: {
              '@type': 'Offer',
              url: url,
              priceCurrency: 'EUR',
              price: price ? Number(price).toFixed(2) : null,
              itemCondition: 'https://schema.org/NewCondition',
              availability: availabilities.online
                ? 'https://schema.org/InStock'
                : 'https://schema.org/OutOfStock',
              seller: {
                '@type': 'Organization',
                name: 'Bubble',
              },
              // prettier-ignore
              shippingDetails: {
                '@type': 'OfferShippingDetails',
                shippingRate: { '@type': 'MonetaryAmount', value: Number(price) >= 35 ? 0.01 : 3, currency: 'EUR', },
                shippingDestination: { '@type': 'DefinedRegion', addressCountry: 'FR', },
                deliveryTime: {
                  '@type': 'ShippingDeliveryTime',
                  handlingTime: { '@type': 'QuantitativeValue', minValue: 0, maxValue: 1, unitCode: 'DAY', },
                  transitTime: { '@type': 'QuantitativeValue', minValue: 1, maxValue: 3, unitCode: 'DAY', },
                },
              },
            },
          },
        ]
      : [],
  );
  return { html, openGraph, twitterCard, structuredData };
};
export const getSeoForGiftCard = (product) => {
  const title = product.title;
  const url = `${LINK.WEBSITE}/${product.permalink}/product/gift-card/${product.objectId}`;
  const price = product.price;
  const description =
    'Anniversaire, Noël, nouveau diplôme ou simple envie de faire plaisir, toutes les occasions sont bonnes pour gâter un amateur de BD !';

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:image': imageGiftCard,
    'og:url': url,
    'og:description': description,
    'og:image:alt': 'Cheque cadeau',
  };
  const twitterCard = {
    'twitter:image:alt': 'Cheque cadeau',
  };
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: title,
    url: url,
    image: imageGiftCard,
    description: description,
    sku: product.sku,
    keywords: ['gift', 'card', 'cheque', 'cadeau', 'noël'],
    brand: { '@type': 'Brand', name: 'Bubble' },
    category: 'GiftCard',
    offers: {
      '@type': 'Offer',
      url: url,
      priceCurrency: 'EUR',
      price: price ? Number(price).toFixed(2) : null,
      itemCondition: 'https://schema.org/NewCondition',
      availability: 'https://schema.org/InStock',
      seller: {
        '@type': 'Organization',
        name: 'Bubble',
      },
    },
  };
  return { html, openGraph, twitterCard, structuredData };
};

const createAggregateRatings = (note, numberOfNotes) => {
  return {
    '@type': 'AggregateRating',
    ratingValue: note,
    ratingCount: numberOfNotes,
  };
};

export const getSeoForUser = (user, stats = {}, url) => {
  const title = user.username ? `Profil de ${user.username}` : 'Profil utilisateur Bubble';
  url = url ? `${LINK.WEBSITE}${url}` : '';
  const imageUrl = user.imageUrl ? `${user.imageUrl.split('.jpg')[0]}_314x.jpg` : imageMyProfile;

  const description = `Jetez (discrètement) un oeil à la bibliothèque de ${
    user.username || 'cet utilisateur'
  }, sa liste de souhaits, et même ses statistiques`;

  const html = {
    title: title,
    description: description,
    canonical: url,
    noIndex: true,
  };
  const openGraph = {
    'og:title': title,
    'og:type': 'profile',
    'og:image': imageUrl,
    'og:url': url,
    'profile:username': title,
    'og:description': description,
    'og:image:width': 314,
    'og:image:height': 314,
    'twitter:card': 'summary',
  };
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: user.username || 'Utilisateur',

    image: imageUrl,
    description: description,
  };
  return { html, openGraph, structuredData };
};

export const getSeoForLandings = (category) => {
  const title = `Le meilleur service pour les fans de ${category}`;
  const url = `${LINK.WEBSITE}/fan-de-${category}`;

  const description = `Découvrir, acheter et organiser sa collection de ${category}`;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:image':
      category === GENERAL.MANGAS
        ? imageLandingMangas
        : category === GENERAL.COMICS
          ? imageLandingComics
          : imageLandingBd,
    'og:url': url,
    'og:description': description,
    'og:image:width': 314,
    'og:image:height': 314,
  };

  return { html, openGraph };
};

export const getSeoForIndexedSeries = (index) => {
  const title =
    index === 'new'
      ? `Toutes les séries du moment avec des nouveautés`
      : `Toutes les séries de Bubble commençant par "${index.toUpperCase()}"`;
  const url = `${LINK.WEBSITE}/series/${index === 'new' ? 'nouveautes' : index}`;

  const description =
    index === 'new'
      ? `Découvrez toutes les séries du moment avec des nouveautés`
      : `Découvrez toutes les séries commençant par un "${index.toUpperCase()}"`;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:url': url,
    'og:description': description,
  };

  return { html, openGraph };
};

export const getSeoForIndexedAuthors = (index) => {
  const title =
    index === 'top'
      ? `Les auteurs les plus prolifiques`
      : `Tous les auteurs de Bubble commençant par "${index.toUpperCase()}"`;
  const url = `${LINK.WEBSITE}/auteurs/${index}`;

  const description =
    index === 'top'
      ? `Découvrez les auteurs les plus prolifiques`
      : `Découvrez tous les auteurs commençant par un "${index.toUpperCase()}"`;

  const html = {
    title: title,
    description: description,
    canonical: url,
  };
  const openGraph = {
    'og:title': title,
    'og:url': url,
    'og:description': description,
  };

  return { html, openGraph };
};
