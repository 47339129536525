import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import bubbleUtils from 'bubble-utils';

const NArtPagination = ({ page }) => {
  const location = useLocation();

  const pageNumber = Number(page.page || 1) || 1;

  const isNearStart = pageNumber <= 2;
  const isNearEnd = pageNumber >= page.totalPages - 1;

  let pagesArray = [];
  if (page.totalPages < 5) {
    pagesArray = [...Array(page.totalPages)].map((v, index) => index + 1);
  } else if (isNearStart) {
    pagesArray = [1, 2, 3, '...', page.totalPages];
  } else if (isNearEnd) {
    pagesArray = [1, '...', page.totalPages - 2, page.totalPages - 1, page.totalPages];
  } else {
    pagesArray = [
      1,
      pageNumber - 1 === 2 ? null : '...',
      pageNumber - 1,
      pageNumber,
      pageNumber + 1,
      pageNumber + 1 === page.totalPages - 1 ? null : '...',
      page.totalPages,
    ];
  }
  pagesArray = pagesArray.filter((row) => row);
  const getPrevPath = () => {
    const path = location.pathname;
    const urlParams = new URLSearchParams(location.search);
    const currentPage = Number(urlParams.get('page')) || 1;
    urlParams.set('page', currentPage - 1);
    return `${path}${bubbleUtils.string.buildSortedQueryStringFromParams(urlParams)}`;
  };
  const getNextPath = () => {
    const path = location.pathname;
    const urlParams = new URLSearchParams(location.search);
    const currentPage = Number(urlParams.get('page')) || 1;
    urlParams.set('page', currentPage + 1);
    return `${path}${bubbleUtils.string.buildSortedQueryStringFromParams(urlParams)}`;
  };

  const getPathForPage = (page) => {
    const path = location.pathname;
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('page', page);
    return `${path}${bubbleUtils.string.buildSortedQueryStringFromParams(urlParams)}`;
  };

  const scrollToSection = () => {
    window.scrollTo(0, (page.anchor || {}).offsetTop);
  };

  return (
    <div className="row pb-5">
      <div className="col-4">
        <Link
          onClick={scrollToSection}
          className={`text-primary ${
            pageNumber <= 1 ? 'no-decoration text-secondary bb-link-no-event' : ''
          }`}
          to={getPrevPath()}
        >
          Précédente
        </Link>
      </div>
      <div className="col-4 d-flex justify-content-center align-items-center">
        <div className="d-none d-md-block h-separator me-2 w-25 reverse" />
        {pagesArray.map((data, index) => {
          return (
            <React.Fragment key={'page_' + index + data}>
              {data === '...' ? (
                '...'
              ) : (
                <Link
                  onClick={scrollToSection}
                  className={`px-2 text-primary ${
                    data === pageNumber ? 'bb-link-no-event no-decoration text-secondary' : ''
                  }`}
                  to={getPathForPage(data)}
                >
                  {data}
                </Link>
              )}
            </React.Fragment>
          );
        })}
        <div className="d-none d-md-block h-separator ms-2 w-25" />
      </div>
      <div className="col-4 d-flex justify-content-end">
        <Link
          onClick={scrollToSection}
          className={`text-primary ${
            pageNumber >= page.totalPages ? 'no-decoration text-secondary bb-link-no-event' : ''
          }`}
          to={getNextPath()}
        >
          Suivante
        </Link>
      </div>
    </div>
  );
};

export default NArtPagination;
