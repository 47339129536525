import { Link } from 'react-router-dom';

import './NartNavItem.scss';

export default function Navbar9eArtNavItemMobile({ text, selected, to }) {
  return (
    <div className="d-flex py-3">
      <div
        className={`bg-blacker rounded-circle nart-burger-circle${
          selected ? ' nart-bg-highlight' : ''
        }`}
      />
      <Link className="no-decoration text-black fw-bold" to={to}>
        <div className={`h4 ${selected ? `nart-text-highlight` : ''}`}>{text}</div>
        {!!selected && (
          <div className={`nart-burger-menu-element-bar rounded-medium nart-bg-highlight`} />
        )}
      </Link>
    </div>
  );
}
