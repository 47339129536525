import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { buildQueryString } from 'bubble-utils/src/string-utils';

import { multiIndexSearch, search, searchStores as searchAlgoliaStores } from '../services/algolia';
import { searchCollectionsApi, searchRelaysApi, searchTypesApi } from '../services/api';

import bubbleConfig from '../../../bubble-config';
import bubbleUtils from '../../../bubble-utils';
import { getClientToken } from '../selectors';

export const SEARCH_MULTI_INDEX = 'search/searchMultiIndex';
const SEARCH_AUTHORS = 'search/searchAuthors';
const SEARCH_SERIES = 'search/searchSeries';
const SEARCH_ALBUMS = 'search/searchAlbums';
const SEARCH_ARTICLES = 'search/searchArticles';
const SEARCH_PUBLISHERS = 'search/searchPublishers';
const SEARCH_TAGS = 'search/searchTags';
const SEARCH_STORES = 'search/searchStores';
const SEARCH_TYPES = 'search/searchTypes';
const SEARCH_COLLECTIONS = 'search/searchCollections';
const SEARCH_RELAYS = 'search/searchRelays';

const initialState = {
  hitsSeries: { type: 'serie', data: [] },
  hitsAuthors: { type: 'author', data: [] },
  hitsStores: { type: 'store', data: [] },
  hitsArticles: { type: 'article', data: [] },
  hitsAlbums: { type: 'album', data: [] },
  hitsPublishers: { type: 'publisher', data: [] },
  hitsCollections: { type: 'collection', data: [] },
  hitsTypes: { type: 'type', data: [] },
  hitsTags: { type: 'tag', data: [] },
  hitsSeriesPersistent: { type: 'serie', data: [] },
  hitsAuthorsPersistent: { type: 'author', data: [] },
  hitsStoresPersistent: { type: 'store', data: [] },
  hitsArticlesPersistent: { type: 'article', data: [] },
  hitsAlbumsPersistent: { type: 'album', data: [] },
  hitsPublishersPersistent: { type: 'publisher', data: [] },
  hitsCollectionsPersistent: { type: 'collection', data: [] },
  hitsTypesPersistent: { type: 'type', data: [] },
  hitsTagsPersistent: { type: 'tag', data: [] },
  sorting: [
    'hitsSeries',
    'hitsAlbums',
    'hitsAuthors',
    'hitsArticles',
    'hitsStores',
    'hitsPublishers',
  ],
  hitsRelays: [],

  text: '',
  searching: false,
  isActive: false,
  relays: [],
  places: [],

  paginatedArticlesHits: {},
  loading: {},
  errors: {},
};

export const searchMultiIndex = createAsyncThunk(
  SEARCH_MULTI_INDEX,
  async (params, { getState }) => {
    const indexes = params?.indexes || null;
    const text = params?.text || null;
    const persistent = params?.persistent || false;

    const hits = await multiIndexSearch(indexes, text);
    return { hits, persistent };
  },
);

export const searchAuthors = createAsyncThunk(SEARCH_AUTHORS, async (params, { getState }) => {
  const text = params?.text || null;
  const options = params?.options || null;
  const persistent = params?.persistent || false;

  const results = await search(bubbleConfig.algolia.authorsIndex, text, options);
  return { results, persistent };
});

export const searchSeries = createAsyncThunk(SEARCH_SERIES, async (params, { getState }) => {
  const text = params?.text || null;
  const options = params?.options || null;
  const persistent = params?.persistent || false;

  const results = await search(bubbleConfig.algolia.seriesIndex, text, options);
  return { results, persistent };
});

export const searchAlbums = createAsyncThunk(SEARCH_ALBUMS, async (params, { getState }) => {
  const text = params?.text || null;
  const options = params?.options || null;
  const persistent = params?.persistent || false;

  const results = await search(bubbleConfig.algolia.albumsIndex, text, options);
  return { results, persistent };
});

export const searchPublishers = createAsyncThunk(
  SEARCH_PUBLISHERS,
  async (params, { getState }) => {
    const text = params?.text || null;
    const options = params?.options || null;
    const persistent = params?.persistent || false;

    const results = await search(bubbleConfig.algolia.publishersIndex, text, options);
    return { results, persistent };
  },
);

export const searchTags = createAsyncThunk(SEARCH_TAGS, async (params, { getState }) => {
  const text = params?.text || null;
  const options = params?.options || null;
  const persistent = params?.persistent || false;

  const results = await search(bubbleConfig.algolia.tagsIndex, text, options);
  return { results, persistent };
});

export const searchArticles = createAsyncThunk(SEARCH_ARTICLES, async (params, { getState }) => {
  const text = params?.text || null;
  const options = params?.options || null;
  const persistent = params?.persistent || false;
  const paginated = params?.paginated || false;

  const results = await search(bubbleConfig.algolia.articlesIndex, text, options);
  return { results, persistent, text, options, paginated };
});

export const searchStores = createAsyncThunk(SEARCH_STORES, async (params, { getState }) => {
  const text = params?.text || null;
  const options = params?.options || null;
  const persistent = params?.persistent || false;

  if (!text) {
    // if no text is entered (or text is erased), bypass algolia so it doesn't return the first
    // stores in the list
    return { results: [], persistent };
  }

  const results = await searchAlgoliaStores(text, options);
  return { results, persistent };
});

export const searchTypes = createAsyncThunk(SEARCH_TYPES, async (params, { getState }) => {
  const text = params?.text || null;
  const options = params?.options || null;
  const persistent = params?.persistent || false;

  const clientToken = getClientToken(getState());
  const queryString = buildQueryString({ text });
  const hits = await searchTypesApi(clientToken, queryString);
  return { hits, persistent };
});

export const searchCollections = createAsyncThunk(
  SEARCH_COLLECTIONS,
  async (params, { getState }) => {
    const text = params?.text || null;
    const options = params?.options || null;
    const persistent = params?.persistent || false;

    const clientToken = getClientToken(getState());
    const queryString = buildQueryString({ text });
    const hits = await searchCollectionsApi(clientToken, queryString);
    return { hits, persistent };
  },
);

export const searchRelays = createAsyncThunk(SEARCH_RELAYS, async (params, { getState }) => {
  const zipcode = params?.zipcode || null;
  const countryCode = params?.countryCode || null;

  const clientToken = getClientToken(getState());
  const options = {
    zipcode: (zipcode || '').toLowerCase() || null,
    'country-code': (countryCode || '').toLowerCase() || null,
  };
  const hits = await searchRelaysApi(clientToken, options);

  return { hits };
});

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearch: {
      reducer: (state, action) => initialState,
    },
    resetRelays: {
      reducer: (state, action) => {
        state.relays = [];
      },
    },
    setIsActive: {
      reducer: (state, action) => {
        state.isActive = action.payload.state;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // searchMultiIndex
      .addCase(searchMultiIndex.pending, (state, action) => {
        state.text = action.meta.arg.text;

        state.loading[SEARCH_MULTI_INDEX] = true;
        state.errors[SEARCH_MULTI_INDEX] = null;
      })
      .addCase(searchMultiIndex.fulfilled, (state, action) => {
        let hits = action.payload.hits.results;

        state.hitsAlbums = { ...initialState.hitsAlbums, ...{ totalHits: 0, data: [] } };
        state.hitsSeries = { ...initialState.hitsSeries, ...{ totalHits: 0, data: [] } };
        state.hitsAuthors = { ...initialState.hitsAuthors, ...{ totalHits: 0, data: [] } };
        state.hitsPublishers = { ...initialState.hitsPublishers, ...{ totalHits: 0, data: [] } };
        state.hitsStores = { ...initialState.hitsStores, ...{ totalHits: 0, data: [] } };
        state.hitsArticles = { ...initialState.hitsArticles, ...{ totalHits: 0, data: [] } };
        state.hitsAlbumsPersistent = { ...state.hitsAlbumsPersistent };
        state.hitsSeriesPersistent = { ...state.hitsSeriesPersistent };
        state.hitsAuthorsPersistent = { ...state.hitsAuthorsPersistent };
        state.hitsPublishersPersistent = { ...state.hitsPublishersPersistent };
        state.hitsStoresPersistent = { ...state.hitsStoresPersistent };
        state.hitsArticlesPersistent = { ...state.hitsArticlesPersistent };

        hits.forEach((index) => {
          const baseIndex = `hits${index.index}`;
          state[baseIndex + (action.payload.persistent ? 'Persistent' : '')].data = index.hits;
          state[baseIndex + (action.payload.persistent ? 'Persistent' : '')].totalHits =
            index.nbHits;
        });

        state.loading[SEARCH_MULTI_INDEX] = false;
        state.errors[SEARCH_MULTI_INDEX] = null;
      })
      .addCase(searchMultiIndex.rejected, (state, action) => {
        state.loading[SEARCH_MULTI_INDEX] = false;
        state.errors[SEARCH_MULTI_INDEX] = action.error;
      })
      // searchAuthors
      .addCase(searchAuthors.pending, (state, action) => {
        state.loading[SEARCH_AUTHORS] = true;
        state.errors[SEARCH_AUTHORS] = null;
      })
      .addCase(searchAuthors.fulfilled, (state, action) => {
        let newHitsAuthors = Object.assign({}, state.hitsAuthors);
        newHitsAuthors.data = action.payload.results?.results?.[0].hits || [];
        state.hits = resetHits();
        state['hitsAuthors' + (action.payload.persistent ? 'Persistent' : '')] = newHitsAuthors;

        state.loading[SEARCH_AUTHORS] = false;
        state.errors[SEARCH_AUTHORS] = null;
      })
      .addCase(searchAuthors.rejected, (state, action) => {
        state.loading[SEARCH_AUTHORS] = false;
        state.errors[SEARCH_AUTHORS] = action.error;
      })
      // searchSeries
      .addCase(searchSeries.pending, (state, action) => {
        state.text = action.meta.arg.text;

        state.loading[SEARCH_SERIES] = true;
        state.errors[SEARCH_SERIES] = null;
      })
      .addCase(searchSeries.fulfilled, (state, action) => {
        let newHitsSeries = Object.assign({}, state.hitsSeries);
        newHitsSeries.data = action.payload.results?.results?.[0].hits || [];
        state.hits = resetHits();
        state['hitsSeries' + (action.payload.persistent ? 'Persistent' : '')] = newHitsSeries;

        state.loading[SEARCH_SERIES] = false;
        state.errors[SEARCH_SERIES] = null;
      })
      .addCase(searchSeries.rejected, (state, action) => {
        state.loading[SEARCH_SERIES] = false;
        state.errors[SEARCH_SERIES] = action.error;
      })
      // searchAlbums
      .addCase(searchAlbums.pending, (state, action) => {
        state.loading[SEARCH_ALBUMS] = true;
        state.errors[SEARCH_ALBUMS] = null;
      })
      .addCase(searchAlbums.fulfilled, (state, action) => {
        let newHitsAlbums = { ...state.hitsAlbums };
        newHitsAlbums.data = action.payload.results?.results?.[0].hits || [];
        state.hits = resetHits();
        state['hitsAlbums' + (action.payload.persistent ? 'Persistent' : '')] = newHitsAlbums;

        state.loading[SEARCH_ALBUMS] = false;
        state.errors[SEARCH_ALBUMS] = null;
      })
      .addCase(searchAlbums.rejected, (state, action) => {
        state.loading[SEARCH_ALBUMS] = false;
        state.errors[SEARCH_ALBUMS] = action.error;
      })
      // searchPublishers
      .addCase(searchPublishers.pending, (state, action) => {
        state.loading[SEARCH_PUBLISHERS] = true;
        state.errors[SEARCH_PUBLISHERS] = null;
      })
      .addCase(searchPublishers.fulfilled, (state, action) => {
        let newHitsPublishers = { ...state.hitsPublishers };
        newHitsPublishers.data = action.payload.results?.results?.[0].hits || [];
        state.hits = resetHits();
        state['hitsPublishers' + (action.payload.persistent ? 'Persistent' : '')] =
          newHitsPublishers;

        state.loading[SEARCH_PUBLISHERS] = false;
        state.errors[SEARCH_PUBLISHERS] = null;
      })
      .addCase(searchPublishers.rejected, (state, action) => {
        state.loading[SEARCH_PUBLISHERS] = false;
        state.errors[SEARCH_PUBLISHERS] = action.error;
      })
      // searchTags
      .addCase(searchTags.pending, (state, action) => {
        state.loading[SEARCH_TAGS] = true;
        state.errors[SEARCH_TAGS] = null;
      })
      .addCase(searchTags.fulfilled, (state, action) => {
        let newHitsTags = { ...state.hitsTags };
        newHitsTags.data = action.payload.results?.results?.[0].hits || [];
        state.hits = resetHits();
        state['hitsTags' + (action.payload.persistent ? 'Persistent' : '')] = newHitsTags;

        state.loading[SEARCH_TAGS] = false;
        state.errors[SEARCH_TAGS] = null;
      })
      .addCase(searchTags.rejected, (state, action) => {
        state.loading[SEARCH_TAGS] = false;
        state.errors[SEARCH_TAGS] = action.error;
      })
      // searchArticles
      .addCase(searchArticles.pending, (state, action) => {
        state.loading[SEARCH_ARTICLES] = true;
        state.errors[SEARCH_ARTICLES] = null;
      })
      .addCase(searchArticles.fulfilled, (state, action) => {
        const newHitsArticles = { ...state.hitsArticles };
        newHitsArticles.data = action.payload.results?.results?.[0].hits || [];
        const paginatedArticlesHits = { ...state.paginatedArticlesHits };
        if (action.payload.paginated) {
          paginatedArticlesHits[
            bubbleUtils.string.buildSortedQueryString({
              text: action.payload.text,
              ...action.payload.options,
            })
          ] = { hits: action.payload.results?.results?.[0].hits || [] };
        }
        state.hits = resetHits();
        state.paginatedArticlesHits = paginatedArticlesHits;
        state['hitsArticles' + (action.payload.persistent ? 'Persistent' : '')] = newHitsArticles;

        state.loading[SEARCH_ARTICLES] = false;
        state.errors[SEARCH_ARTICLES] = null;
      })
      .addCase(searchArticles.rejected, (state, action) => {
        state.loading[SEARCH_ARTICLES] = false;
        state.errors[SEARCH_ARTICLES] = action.error;
      })
      // searchStores
      .addCase(searchStores.pending, (state, action) => {
        state.loading[SEARCH_STORES] = true;
        state.errors[SEARCH_STORES] = null;
      })
      .addCase(searchStores.fulfilled, (state, action) => {
        let newHitsStores = { ...state.hitsStores };
        newHitsStores.data = action.payload.results?.results?.[0].hits || [];
        state.hits = resetHits();
        state['hitsStores' + (action.payload.persistent ? 'Persistent' : '')] = newHitsStores;

        state.loading[SEARCH_STORES] = false;
        state.errors[SEARCH_STORES] = null;
      })
      .addCase(searchStores.rejected, (state, action) => {
        state.loading[SEARCH_STORES] = false;
        state.errors[SEARCH_STORES] = action.error;
      })
      // searchTypes
      .addCase(searchTypes.pending, (state, action) => {
        state.loading[SEARCH_TYPES] = true;
        state.errors[SEARCH_TYPES] = null;
      })
      .addCase(searchTypes.fulfilled, (state, action) => {
        let newHitsTypes = { ...state.hitsTypes };
        newHitsTypes.data = action.payload.hits || [];
        state.hits = resetHits();
        state['hitsTypes' + (action.payload.persistent ? 'Persistent' : '')] = newHitsTypes;

        state.loading[SEARCH_TYPES] = false;
        state.errors[SEARCH_TYPES] = null;
      })
      .addCase(searchTypes.rejected, (state, action) => {
        state.loading[SEARCH_TYPES] = false;
        state.errors[SEARCH_TYPES] = action.error;
      })
      // searchCollections
      .addCase(searchCollections.pending, (state, action) => {
        state.loading[SEARCH_COLLECTIONS] = true;
        state.errors[SEARCH_COLLECTIONS] = null;
      })
      .addCase(searchCollections.fulfilled, (state, action) => {
        let newHitsCollections = { ...state.hitsCollections };
        newHitsCollections.data = action.payload.hits || [];
        state.hits = resetHits();
        state['hitsCollections' + (action.payload.persistent ? 'Persistent' : '')] =
          newHitsCollections;

        state.loading[SEARCH_COLLECTIONS] = false;
        state.errors[SEARCH_COLLECTIONS] = null;
      })
      .addCase(searchCollections.rejected, (state, action) => {
        state.loading[SEARCH_COLLECTIONS] = false;
        state.errors[SEARCH_COLLECTIONS] = action.error;
      })
      // searchRelays
      .addCase(searchRelays.pending, (state, action) => {
        state.loading[SEARCH_RELAYS] = true;
        state.errors[SEARCH_RELAYS] = null;
      })
      .addCase(searchRelays.fulfilled, (state, action) => {
        state.hitsRelays = action.payload.hits;
        state.loading[SEARCH_RELAYS] = false;
        state.errors[SEARCH_RELAYS] = null;
      })
      .addCase(searchRelays.rejected, (state, action) => {
        state.loading[SEARCH_RELAYS] = false;
        state.errors[SEARCH_RELAYS] = action.error;
      });
  },
});

export default searchSlice.reducer;

export const { resetSearch, resetRelays, setIsActive } = searchSlice.actions;

//TODO: use initialState
const resetHits = () => {
  return {
    hitsSeries: Object.assign({}, initialState.hitsSeries, { totalHits: 0, data: [] }),
    hitsAuthors: Object.assign({}, initialState.hitsAuthors, { totalHits: 0, data: [] }),
    hitsStores: Object.assign({}, initialState.hitsStores, { totalHits: 0, data: [] }),
    hitsArticles: Object.assign({}, initialState.hitsArticles, { totalHits: 0, data: [] }),
    hitsAlbums: Object.assign({}, initialState.hitsAlbums, { totalHits: 0, data: [] }),
    hitsPublishers: Object.assign({}, initialState.hitsPublishers, { totalHits: 0, data: [] }),
    hitsCollections: Object.assign({}, initialState.hitsCollections, { totalHits: 0, data: [] }),
    hitsTypes: Object.assign({}, initialState.hitsTypes, { totalHits: 0, data: [] }),
    hitsTags: Object.assign({}, initialState.hitsTags, { totalHits: 0, data: [] }),
    hitsSeriesPersistent: Object.assign({}, initialState.hitsSeriesPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsAuthorsPersistent: Object.assign({}, initialState.hitsAuthorsPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsStoresPersistent: Object.assign({}, initialState.hitsStoresPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsArticlesPersistent: Object.assign({}, initialState.hitsArticlesPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsAlbumsPersistent: Object.assign({}, initialState.hitsAlbumsPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsPublishersPersistent: Object.assign({}, initialState.hitsPublishersPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsCollectionsPersistent: Object.assign({}, initialState.hitsCollectionsPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsTypesPersistent: Object.assign({}, initialState.hitsTypesPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsTagsPersistent: Object.assign({}, initialState.hitsTagsPersistent, {
      totalHits: 0,
      data: [],
    }),
  };
};
