import React from 'react';

import { Link } from 'react-router-dom';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import booksellerImage from '@/assets/home/library_pic.jpg';
import booksellerImageWebp from '@/assets/home/library_pic.webp';

import './BubbleBooksellerPartner.scss';

export default function BubbleBooksellerPartner() {
  return (
    <div className="row py-5 px-3 px-lg-5 home-store-partner-background text-white">
      <div className="d-md-none col-12 h1 fw-bold pb-4">
        <div>Bubble soutient les libraires indépendants</div>
        <div className="d-md-none overflow-auto py-4">
          <WebpWrapper
            default={booksellerImage}
            webp={booksellerImageWebp}
            className="bookseller-partner-image rounded-medium w-100"
            alt="librairie"
          />
        </div>
      </div>

      <div className="col-md-6 d-flex flex-column justify-content-center">
        <div className="d-none d-md-block h1 fw-bold">
          Bubble soutient les libraires indépendants
        </div>
        <p className="pb-4 m-0 h5 pe-0 pe-md-6">
          Chez Bubble, on met un point d'honneur à soutenir le commerce du livres et les boutiques
          indépendantes. Réservez vos albums et allez les chercher directement dans nos librairies
          partenaires.
        </p>
        <div className="d-flex align-items-center">
          <Link
            to={'/cartes-des-librairies-partenaires'}
            className="btn btn-outline-white px-5 bb-medium-text-size bookseller-partner-btn"
          >
            Trouver une librairie partenaire
          </Link>
        </div>
      </div>
      <div className="d-none d-md-block col-6">
        <WebpWrapper
          default={booksellerImage}
          webp={booksellerImageWebp}
          alt="librairie"
          className="bookseller-partner-image rounded-medium w-100"
        />
      </div>
    </div>
  );
}
