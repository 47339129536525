import React from 'react';

import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import PrintListCell from './components/PrintListCell';

import './Modals.css';

const PrintListModal = (props) => {
  const { prints, currentPrintObjectId, album } = props;

  const isCurrentPrint = (printObjectId) => currentPrintObjectId === printObjectId;

  const dismiss = () => {
    props.callback();
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator pb-4">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3 modal-body-scroll">
        <div className="text-center">
          <div className="fw-bold bb-l-text-size">Toutes les éditions</div>
          <div className="bb-medium-large-text-size text-secondary pt-2">
            Selectionnez l'édition de votre choix
          </div>
        </div>

        {(prints || []).map((print) => (
          <PrintListCell
            key={print.objectId}
            album={album}
            print={print}
            selected={isCurrentPrint(print.objectId)}
            callback={dismiss}
          />
        ))}
      </div>
      <div className="modal-footer justify-content-start">
        <div className="d-flex flex-fill justify-content-between align-items-center">
          <Link className="w-50" to="/ajouter-un-album">
            Je ne trouve pas mon édition
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default PrintListModal;
