import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadAlbumReviews, loadSerieReviews } from 'bubble-reducers/src/reducers/reviews';

import ReviewCard from '@/components/ReviewCard/ReviewCard';
import StarLine from '@/components/StarLine/StarLine';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

const ItemReviews = (props) => {
  const dispatch = useDispatch();
  const isSerieReviewType = props.reviewType === 'serie';

  const reviews = useSelector(
    (state) => state.reviews[isSerieReviewType ? 'serieReviewsMap' : 'albumReviewsMap'],
  );
  const serie = useSelector((state) => state.series.series[props.serieObjectId]) || {};
  const album = useSelector((state) => state.albums.albums[props.albumObjectId]) || {};

  const generateReviewsObjectForList = (reviews, objectKey) => {
    const reviewsWithComments = (reviews.comments || []).filter((comment) => comment.comment);
    const reviewsWithoutComments = (reviews.comments || []).filter((comment) => !comment.comment);
    let reviewsToShow = (reviewsWithComments.length !== 0 ? reviewsWithComments : [])
      .sort((a, b) => b.likes - a.likes)
      .slice(0, 3);

    if (
      !isSerieReviewType &&
      reviewsToShow.length < 3 &&
      (reviews.comments || []).length !== reviewsToShow.length
    ) {
      reviewsToShow = reviewsToShow.concat(
        reviewsWithoutComments.slice(0, 3 - reviewsToShow.length),
      );
    }

    return reviewsToShow.length !== 0
      ? reviewsToShow.map((comment) => (
          <div key={comment.objectId} className="col-12 col-md-6 col-lg-4 col-xl-4 mt-3 d-flex">
            <ReviewCard
              reviewType={props.reviewType}
              sourcePath={
                isSerieReviewType
                  ? `/${serie.permalink}/serie/${serie.objectId}`
                  : `/${album.permalink}/album/${album.objectId}`
              }
              shouldBeTruncated={true}
              review={{ ...comment, reviewer: comment.username }}
              albumObjectId={props.albumObjectId}
              serieObjectId={props.serieObjectId}
            />
          </div>
        ))
      : [
          <div className="p-3" key={'no_note' + objectKey}>
            Cette série n'a pas encore d'avis
          </div>,
        ];
  };

  const reviewsObjects = generateReviewsObjectForList(
    reviews[props.albumObjectId || props.serieObjectId] || { comments: [] },
    props.albumObjectId || props.serieObjectId,
  );
  const objectId = props.albumObjectId || props.serieObjectId;

  useEffect(() => {
    if (objectId) {
      if (isSerieReviewType) {
        dispatch(loadSerieReviews({ serieObjectId: objectId }));
      } else {
        dispatch(loadAlbumReviews({ albumObjectId: objectId }));
      }
    }
  }, [dispatch, objectId, isSerieReviewType]);

  const note = isSerieReviewType ? serie.note : album.note;

  return (
    <TitledListContainer
      noEqualityChecks
      title={
        <div className="d-md-flex">
          <div>Avis des lecteurs de {isSerieReviewType ? 'la série' : "l'album"}</div>
          {!isSerieReviewType && (
            <div className="d-flex my-2 my-md-0">
              <div className="mt-n1 mx-md-2">
                <StarLine size="medium" note={note} />
              </div>
              {note ? <span className="me-2">{note.toFixed(1)}</span> : null}
              <span className="bb-regular-text-size text-secondary fw-normal">
                ({(isSerieReviewType ? serie.numberOfNotes : album.numberOfNotes) || 0} avis)
              </span>
            </div>
          )}
          <Link
            className="d-block d-md-none bb-regular-text-size fw-normal"
            to={
              isSerieReviewType
                ? `/${serie.permalink}/serie/${serie.objectId}/avis`
                : `/${album.permalink}/album/${album.objectId}/avis`
            }
          >
            Voir tous les avis
          </Link>
        </div>
      }
      topRightLink={
        <Link
          className="d-none d-md-flex"
          to={
            isSerieReviewType
              ? `/${serie.permalink}/serie/${serie.objectId}/avis`
              : `/${album.permalink}/album/${album.objectId}/avis`
          }
        >
          Voir tous les avis
        </Link>
      }
      list={reviewsObjects}
    />
  );
};

ItemReviews.propTypes = {
  serieObjectId: PropTypes.string,
  albumObjectId: PropTypes.string,
};

export default ItemReviews;
