import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { loadProducts } from 'bubble-reducers/src/reducers/products';
import { getProductType } from 'bubble-reducers/src/selectors';

import { getSeoForGiftCard } from '@/services/seo-utils';

import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import ShareZone from '@/components/ShareZone/ShareZone';

import GiftCardChooser from './components/GiftCardChooser';

const GiftCard = () => {
  const dispatch = useDispatch();

  const { productObjectId } = useParams();

  const product = useSelector((state) => state.products.products[productObjectId]) || {};
  const products = useSelector((state) => getProductType(state, 'gift-card') || []);

  useEffect(() => {
    if (productObjectId) {
      dispatch(loadProducts({ type: 'gift-card' }));
    }
  }, [productObjectId]);

  return (
    <>
      <BubbleHelmet seo={getSeoForGiftCard(product)} />
      <div className="bb-background-light-grey">
        <div className="container pb-6">
          <div className="d-flex justify-content-end">
            <BreadCrumbs currentObject={product} />
            <ShareZone title={`Partager`} />
          </div>
          <div className="row px-sm-3">
            <div className="col-md-6">
              <img
                alt="cheque cadeau"
                className="d-flex flex-fill w-100 pb-5 pb-md-0 border"
                src={((product.images || {}).front || {}).large}
              />
            </div>
            <div className="col-md-6">
              <GiftCardChooser selected={product} products={products} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftCard;
