import React, { useEffect, useRef } from 'react';

import config from '@/project-config';
import { useSelector } from 'react-redux';

const BubbleIntercom = () => {
  const user = useSelector((state) => state.user.user);

  const lastUserSeen = useRef(user);

  useEffect(() => {
    setTimeout(() => {
      initialize(); // will only initialize on client side because of useEffect
    }, config.intercom.initializationDelay);
  }, []);

  useEffect(() => {
    if (user !== lastUserSeen.current) {
      lastUserSeen.current = user;
      updateIntercomUserWithLastUserSeen();
    }
  }, [user]);

  const initialize = () => {
    if (!config.intercom.appId) {
      console.error('[bubble-intercom] tracking IDs are required.');
      return;
    }

    window.intercomSettings = {
      app_id: config.intercom.appId,
    };

    // Placing the code within <head> tags reduces the chances of browsers or third-party
    // code blocking the execution.
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;

        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + config.intercom.appId;
        var head = d.getElementsByTagName('head')[0];
        head.appendChild(s);
      }
    })();

    // Initialize Intercon
    window.Intercom('boot', {
      app_id: config.intercom.appId,
    });

    updateIntercomUserWithLastUserSeen();
  };

  const updateIntercomUserWithLastUserSeen = () => {
    if (!window.Intercom) {
      return;
    }

    if (lastUserSeen.current) {
      window.Intercom('update', {
        user_id: lastUserSeen.current?.objectId,
        user_hash: lastUserSeen.current?.intercomHashWeb,
        email: lastUserSeen.current?.email,
        name: lastUserSeen.current?.email,
      });
    }
  };

  return null;
};

export default BubbleIntercom;
