import PropTypes from 'prop-types';
import React from 'react';

import './LabeledTextInput.css';

const LabeledTextInput = (props) => {
  const id = props.id || `input_${Math.random() * 10}`;
  const mandatory = 'mandatory' in props;

  const handleKeyPress = (event) => {
    if (event.which === 13 && props.onEnterPressed) {
      return props.onEnterPressed(event);
    }
    if (props.onKeyPress) {
      props.onKeyPress(event);
    }
  };

  const handleBlur = () => props.onBlur && props.onBlur();
  const handleFocus = () => props.onFocus && props.onFocus();
  const handleButton = () => props.buttonCallback && props.buttonCallback(props.value);

  return (
    <div className="mb-3">
      <div className="d-flex text-secondary">
        {props.title && (
          <label className="mb-0" htmlFor={id}>
            {props.title}:&nbsp;{mandatory && <span className="text-danger">*</span>}
          </label>
        )}
        <div className="flex-fill" />
        {!props.rightComponent && !('noRightLabel' in props) && (
          <label className="mb-0">{!mandatory && 'Optionnel'}</label>
        )}
        {!!props.rightComponent && props.rightComponent}
      </div>
      <div className="d-flex input-group">
        <input
          disabled={'disabled' in props && !props.disabled}
          ref={(ref) => (props.inputRef ? props.inputRef(ref) : null)}
          onKeyPress={handleKeyPress}
          className={`form-control labeled-text-input${'white' in props ? '-white' : ''} ${
            props.buttonCallback ? 'no-end-border' : ''
          } ${props.className || ''}`}
          type={props.type || 'text'}
          autoComplete={props.autoComplete || ''}
          onChange={props.onChange}
          value={props.value || ''}
          id={id}
          readOnly={props.readOnly || false}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={props.placeholder || ''}
        />
        {props.buttonCallback && (
          <button
            type="button"
            className={props.buttonStyle || 'btn btn-secondary text-white no-start-border'}
            onClick={handleButton}
          >
            {props.buttonLabel || 'Valider'}
          </button>
        )}
      </div>
      {props.subtext && <small className="form-text text-muted">{props.subtext}</small>}
    </div>
  );
};

LabeledTextInput.propTypes = {
  disabled: PropTypes.any,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  mandatory: PropTypes.bool,
  inputRef: PropTypes.func,
  onKeyPress: PropTypes.func,
  onEnterPressed: PropTypes.func,
  rightComponent: PropTypes.any,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonCallback: PropTypes.func,
  border: PropTypes.bool,
  white: PropTypes.any,
  noRightLabel: PropTypes.any,
};

export default LabeledTextInput;
