import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { compressAuthorName } from 'bubble-utils/src/author-utils';

import Tooltip from '@/components/Tooltip/Tooltip';

import { GENERAL } from 'bubble-constants';

const defaultObject = {};

export default function AlbumCardAgendaSource({ album }) {
  const sourceFlag = album.userAgendaExtras?.sourceFlag || 0;
  const isExplicitFollow =
    (GENERAL.STEVEN_USER_AGENDA_SERIE_FOLLOWED & sourceFlag) ===
    GENERAL.STEVEN_USER_AGENDA_SERIE_FOLLOWED;
  const isAuthor =
    (GENERAL.STEVEN_USER_AGENDA_AUTHOR_FOLLOWED & sourceFlag) ===
    GENERAL.STEVEN_USER_AGENDA_AUTHOR_FOLLOWED;
  const isWishlist =
    (GENERAL.STEVEN_USER_AGENDA_ALBUM_WISHLISTED & sourceFlag) ===
    GENERAL.STEVEN_USER_AGENDA_ALBUM_WISHLISTED;

  const author = useMemo(
    () =>
      Array.isArray(album.authors)
        ? album.authors.find(
            (author) => author.objectId === album.userAgendaExtras?.authorObjectId,
          ) || defaultObject
        : defaultObject,
    [album],
  );

  return (
    <>
      <div className="mt-2 mb-n2 d-flex justify-content-center bb-s-text-size no-wrap">
        {isExplicitFollow ? (
          <span className="text-truncate">
            Série{`\xa0`}suivie:{`\xa0`}
            <Link to={`/${album.serie.permalink}/serie/${album.serie.objectId}`}>
              <Tooltip tip={album.serie?.title}>{album.serie?.title}</Tooltip>
            </Link>
          </span>
        ) : isAuthor ? (
          <span className="text-truncate">
            Auteur{`\xa0`}abonné:{`\xa0`}
            <Link to={`/${author.permalink}/author/${author.objectId}`}>
              <Tooltip tip={author.displayName}>{compressAuthorName(author)}</Tooltip>
            </Link>
          </span>
        ) : isWishlist ? (
          <Link to="ma-wishlist">Ma liste de souhaits</Link>
        ) : (
          <span className="text-truncate">
            Série{`\xa0`}suivie:{`\xa0`}
            <Link to={`/${album.serie?.permalink}/serie/${album.serie?.objectId}`}>
              <Tooltip tip={album.serie?.title || ''}>{album.serie?.title}</Tooltip>
            </Link>
          </span>
        )}
      </div>
    </>
  );
}
