import React, { useState } from 'react';

import config from '@/project-config';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { loginGuest } from 'bubble-reducers/src/reducers/auth';
import { createAvailabilityAlert } from 'bubble-reducers/src/reducers/availability-alerts';

import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';

import bubbleUtils from 'bubble-utils';

import './Modals.css';

const GuestAvailabilityAlertModal = (props) => {
  const dispatch = useDispatch();

  const printObjectId = props.printObjectId;

  const [email, setEmail] = useState('');
  const [creationDone, setCreationDone] = useState(false);

  const dismiss = () => {
    props.callback();
  };

  const checkEmail = (email = '') => bubbleUtils.validity.isValidEmail(email);

  const onSubmit = async () => {
    if (!checkEmail(email)) {
      return alert('Votre email semble incorrect');
    }

    try {
      await dispatch(
        loginGuest({
          email,
          cookie: { name: config.cookies.jwtToken.name, options: config.cookies.defaultOptions },
          localStorage: { keyName: config.localStorage.user.keyName },
          platform: 'website',
        }),
      ).unwrap();
      await dispatch(createAvailabilityAlert({ printObjectId })).unwrap();

      setCreationDone(true);
    } catch (e) {}
  };

  const renderForm = () => (
    <>
      <div className="bb-l-text-size text-center">Activer l'alerte disponibilité 🔔</div>
      <div className="text-center mt-2 mb-3">
        Inscrivez-vous à l'alerte disponibilité et on vous préviendra quand cet article sera
        commandable.
      </div>
      <div className="w-100">
        <LabeledTextInput
          white
          buttonLabel="Activer"
          noRightLabel
          placeholder="Votre email"
          buttonCallback={onSubmit}
          value={email || ''}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          aria-describedby="emailHelp"
        />
      </div>
      <Link to="login">Me connecter / M'inscrire sur Bubble</Link>
    </>
  );

  const renderDone = () => (
    <>
      <div className="bb-l-text-size text-center">Bravo ! Alerte activée 🔔</div>
      <div className="text-center mt-2 mb-3">
        Vous avez reçu un email de confirmation. Si vous voulez gérer vos alertes vous pouvez créer
        un compte, ça prend 1 minute et c'est très pratique 🤓
      </div>
      <Link to="login">Créer un compte sur Bubble</Link>
    </>
  );

  return (
    <Modal show={props.show} onHide={dismiss} size="md" centered contentClassName="bg-infinity">
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3 d-flex flex-column align-items-center">
        {!creationDone && renderForm()}
        {!!creationDone && renderDone()}
      </div>
    </Modal>
  );
};

export default GuestAvailabilityAlertModal;
