import React from 'react';

import { Link } from 'react-router-dom';

const AlphanumericBar = ({ selected = '', type = 'series' }) => {
  const alphas =
    type === 'series'
      ? ['Nouveautés'].concat('#ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''))
      : ['Top'].concat('#ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''));
  const translatedAlphas =
    type === 'series'
      ? ['new'].concat('$abcdefghijklmnopqrstuvwxyz'.split(''))
      : ['top'].concat('$abcdefghijklmnopqrstuvwxyz'.split(''));

  const alphaToTranslated = (index) => translatedAlphas[alphas.findIndex((l) => l === index)];

  const getLink = (alpha) => {
    const translatedAlpha =
      alphaToTranslated(alpha) === 'new'
        ? type === 'series'
          ? 'nouveautes'
          : 'top'
        : alphaToTranslated(alpha);
    return `/${type === 'series' ? 'series' : 'auteurs'}/${translatedAlpha}`.toLowerCase();
  };

  return (
    <div className="d-flex" style={{ overflow: 'auto' }}>
      {alphas.map((alpha, index) => (
        <Link className="no-decoration" key={'alphabar_' + alpha} to={getLink(alpha)}>
          <div
            className={`d-flex border-top border-bottom py-1 ${
              index === 0 ? 'border-start rounded-start' : ''
            } ${index === alphas.length - 1 ? 'border-end rounded-end' : ''}`}
          >
            <div
              className={`px-3 ${alphaToTranslated(alpha) === selected ? 'text-bubble-color' : ''}`}
            >
              {alpha || ''}
            </div>
            {index !== alphas.length - 1 && <div className="v-separator my-n1" />}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default AlphanumericBar;
