import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatDateWithFormat, isToday } from 'bubble-utils/src/date-utils';

import './ArticleBriefCard.scss';

const ArticleBriefCard = ({ articleObjectId }) => {
  const article =
    useSelector((state) => state.articles.articlesMapByObjectId[articleObjectId]) || {};
  const category = article.category;

  const isPublishedToday = isToday(article.publicationDate);

  const tags =
    article.tags
      ?.filter((tag) => ['bd', 'mangas', 'comics'].includes(tag))
      .map((tag) =>
        tag === 'bd' ? 'BD' : `${tag.charAt(0).toUpperCase()}${tag.substring(1, tag.length)}`,
      ) || [];

  return (
    <Link
      to={article.path ? article.path : ''}
      className="article-brief-card d-flex no-decoration text-black my-4 hoverable transition-short"
    >
      <div className={`article-brief-card-highlight-line rounded bg-9eart-${category}`} />
      <div className="pt-2 ps-3">
        <div className="d-flex pb-1">
          {tags.map((tag) => (
            <span
              key={`article${articleObjectId}_${tag}`}
              className={`text-9eart-${(tag || '').toLowerCase()} fw-bold pe-2`}
            >
              {tag}
            </span>
          ))}
          <span className="text-muted ps-2">
            {isPublishedToday
              ? "Aujourd'hui"
              : formatDateWithFormat(article.publicationDate, {
                  day: 'numeric',
                  month: 'short',
                  year: null,
                })}
          </span>
        </div>
        <div className="article-brief-card-title fw-bold">{article.title}</div>
      </div>
    </Link>
  );
};

export default ArticleBriefCard;
