import PropTypes from 'prop-types';
import React from 'react';

import { PROJECT } from '@/project-constants';

import './ArticleSectionBadge.scss';

const ArticleSectionBadge = ({ categories, tag, title = null, color }) => {
  if ((!tag || !tag.id) && !title) return null;

  // find the first matching category
  //TODO: this ignores multiples categories like bd,comics,mangas
  const category = categories?.filter((category) =>
    PROJECT.PAPER_CATEGORIES.includes(category),
  )?.[0];
  return (
    <div className="me-1 mb-1 text-nowrap">
      <span
        className={`article-section-badge bg-9eart-${category || color} text-white text-uppercase`}
      >
        {tag?.title || title}
      </span>
    </div>
  );
};

ArticleSectionBadge.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default ArticleSectionBadge;
