import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Cover from '@/components/Cover/Cover';
import StarLine from '@/components/StarLine/StarLine';

import albumPlaceholder from '@/assets/placeholders/placeholder-item.jpg';

import './SerieCard.scss';

export default function SerieCard({ serie, serieObjectId, grey }) {
  const serieObject = useSelector((state) => state.series.series[serieObjectId]) || serie || {};

  return (
    <div className={grey ? 'bb-background-light-grey' : 'bg-white'}>
      <Link
        to={`/${serieObject.permalink}/serie/${serieObject.objectId}`}
        className="no-decoration d-block nart-shadow p-3 rounded"
      >
        <div className="d-flex align-items-center">
          <div className="pe-3">
            <Cover
              height={160}
              rounded
              shadow
              imageUrl={serieObject.images?.front?.smallFramed || albumPlaceholder}
            />
          </div>
          <div className="d-flex flex-fill flex-column">
            <div className="h6 m-0 fw-bold text-black">{serieObject.title}</div>
            <div className="text-secondary pb-1">{serieObject.authors?.[0]?.displayName}</div>
            <div className="d-flex">
              <div className="text-black">
                <StarLine short note={serieObject.note} />
              </div>
              {!!serieObject.albums?.length && (
                <>
                  <div className="serie-card-separator mx-2" />
                  <div className="fw-bold">
                    {serieObject.albums?.length} volume{serieObject.albums?.length > 1 ? 's' : ''}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
