import config from '@/project-config';
import { UAParser } from 'ua-parser-js';

const parser = new UAParser();

export const globalParams = {
  environment: config.env,

  // uniqueId: null,
  manufacturer: parser.getDevice().vendor,
  brand: parser.getDevice().vendor,
  model: parser.getDevice().model,
  // deviceId: null,
  browserName: parser.getBrowser().name,
  browserVersion: parser.getBrowser().version,
  systemName: parser.getOS().name,
  systemVersion: parser.getOS().version,
  // bundleId: null,
  appVersion: config.version,
  appBuildNumber: config.buildNumber,
  // appReadableVersion: null,
  // deviceName: null,
  userAgent: parser.getUA(),
  deviceLocale: Intl.DateTimeFormat().resolvedOptions().locale,
  // deviceCountry: null,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  // instanceId: null,
  // isEmulator: null,
  isTablet: parser.getDevice().type === 'tablet',
  platform: 'website',
};

export const isSent = config.event.isSent;

const facebookAnalyticsWebTracker = (name, value, properties, type) => {
  // console.log('facebookAnalyticsWebTracker', name, value, properties, type);
  if (window && !window.fbq) {
    console.log('Facebook Pixel not found');
    return;
  }
  window.fbq(type === 'standard' ? 'track' : 'trackCustom', name, properties);
};

const googleAnalyticsWebTracker = (name, value, properties, type) => {
  // console.log('googleAnalyticsWebTracker', name, value, properties, type);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(Object.assign({ event: name }, properties));
};

export const trackers = [
  { type: 'facebookPixel', fct: facebookAnalyticsWebTracker },
  { type: 'googleAnalytics', fct: googleAnalyticsWebTracker },
];
