import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  CREATE_AVAILABILITY_ALERT,
  createAvailabilityAlert,
  deleteAvailabilityAlert,
} from 'bubble-reducers/src/reducers/availability-alerts';
import { getUserId, getUserOrGuest } from 'bubble-reducers/src/selectors';

import usePrevious from '@/services/hooks/usePrevious';
import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import Toaster from '@/components/DUPLICATE/Toaster/Toaster';
import { Icon } from '@/components/Icon/Icon';
import GuestAvailabilityAlertModal from '@/components/Modals/GuestAvailabilityAlertModal';
import Tooltip from '@/components/Tooltip/Tooltip';

export default function AlbumBuySectionAvailabilityAlert({ printObjectId }) {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const availabilityAlert = useSelector(
    (state) => state.availabilityAlerts.availabilityAlertsMapByPrintObjectId[printObjectId],
  );
  const userObjectId = useSelector(getUserId);
  const guestUserId = useSelector(getUserOrGuest)?.objectId;
  const isLoadingCreation = useSelector(
    (state) => state.availabilityAlerts.loading[CREATE_AVAILABILITY_ALERT],
  );
  const wasLoadingCreation = usePrevious(isLoadingCreation);

  const [showGuestAvailabilityAlertModal, setShowGuestAvailabilityAlertModal] = useState(false);

  if (!isLoadingCreation && wasLoadingCreation) {
    // Toaster.addToast(
    //   'Bravo ! Alerte activée 🔔',
    //   'On vous préviendra par email quand cet article sera commandable.\nAssurez vous que votre email soit bien renseigné dans mon profil>mes informations',
    // );
  }

  const handleAlert = useCallback(() => {
    if (!!availabilityAlert && !userObjectId && !!guestUserId) {
      return dispatch(
        deleteAvailabilityAlert({
          userObjectId: guestUserId,
          availabilityAlertObjectId: availabilityAlert.objectId,
        }),
      );
    }
    if (isUserlogged(false, null, true)) {
      if (!!availabilityAlert) {
        dispatch(
          deleteAvailabilityAlert({
            userObjectId,
            availabilityAlertObjectId: availabilityAlert.objectId,
          }),
        );
      } else {
        dispatch(createAvailabilityAlert({ userObjectId, printObjectId }));
      }
    } else {
      handleShowGuestAvailabilityAlertModal();
    }
  }, [dispatch, isUserlogged, guestUserId, userObjectId, availabilityAlert, printObjectId]);

  const handleShowGuestAvailabilityAlertModal = () => {
    setShowGuestAvailabilityAlertModal(true);
  };

  return (
    <div className="d-flex flex-fill flex-fill align-items-end">
      {showGuestAvailabilityAlertModal && (
        <GuestAvailabilityAlertModal
          show={showGuestAvailabilityAlertModal}
          printObjectId={printObjectId}
          callback={() => setShowGuestAvailabilityAlertModal(false)}
        />
      )}
      <button
        disabled={!!availabilityAlert}
        onClick={handleAlert}
        className="btn btn-lg text-white btn-success mt-3"
      >
        {isLoadingCreation ? (
          <span className="me-2 spinner-border spinner-border-sm" role="status" />
        ) : (
          <Icon name="bell" className="pe-2" />
        )}
        {!!availabilityAlert ? (
          <span>
            Alerte <span className="d-none d-xl-inline-block">disponibilité&nbsp;</span>activée
          </span>
        ) : (
          'Alertez-moi par email'
        )}
      </button>
      {!!availabilityAlert && (
        <Tooltip tip="Retirer l'alerte">
          <button className="btn btn-lg btn-danger ms-2" onClick={handleAlert}>
            <Icon name="times" />
          </button>
        </Tooltip>
      )}
    </div>
  );
}
