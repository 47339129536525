import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const isNavigatingOnProfile = pathname.includes('/user');
    const isNavigatingOnStore = pathname.includes('/store');
    if (!isNavigatingOnProfile && !isNavigatingOnStore) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
};
