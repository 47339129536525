export const randomIntFromRange = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

export const roundTo = (number, decimalNumber = 2) => {
  return Number(Number(number).toFixed(decimalNumber));
};

const trimZeros = (numberAsString) => {
  const last = numberAsString?.slice(numberAsString.length - 1);
  const hasDecimal = numberAsString.includes('.');
  if ((last === '0' && !!hasDecimal) || last === '.') return trimZeros(numberAsString.slice(0, -1));
  return numberAsString;
};

const zeroIfNaN = (number) => (Number.isNaN(Number(number)) ? 0 : Number(number));

/**
 * 545545 => 545k
 * 54554  => 54.6k
 * 1653   => 1.65k
 * 1650   => 1.6k
 * 15000  => 15k
 * 600    => 600
 */
export const compressNumber = (number) => {
  if (!number || number < 1000) return zeroIfNaN(number);
  let newNumber = (number / 1000).toFixed(number > 100000 ? 0 : number > 10000 ? 1 : 2);
  return `${trimZeros(newNumber)}k`;
};
