import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

/**
 * Renders a highlight store card
 */
const HighlightStoreCard = (props) => {
  const store = props.store || {};

  return (
    <Link className="no-decoration" to={`/${store.permalink}/store/${store.objectId}`}>
      <div className="rounded bb-shadow-hover d-flex align-items-center bb-background-light-grey">
        <div style={{ height: 110 }} className="ms-2 me-3 d-flex align-items-center">
          <img src={store.logo} alt={store.name} className="rounded" style={{ height: 70 }} />
        </div>
        <div className="d-flex flex-fill flex-column overflow-hidden py-1">
          <div className="bb-medium-text-size bb-text-gray-dark text-truncate">{store.name}</div>
          <div className="font-secondary text-truncate">
            {store.zipcode} {store.city}
          </div>
          <div className="d-flex flex-fill" />
          <div className="text-primary text-truncate">Découvrir la librairie</div>
        </div>
      </div>
    </Link>
  );
};

HighlightStoreCard.propTypes = {
  store: PropTypes.object,
};

export default HighlightStoreCard;
