// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

// A nice helper to tell us if we're using the production env
export const isProductionEnvironment =
  (process.env.REACT_APP_HOST_ENV ||
    process.env.VITE_HOST_ENV ||
    process.env.NODE_ENV ||
    process.env.MODE) === 'production';

// user to return `typeof window !== 'undefined' && window.navigator && window.navigator.sendBeacon;` but it seems that there is a CORS
// issue https://stackoverflow.com/questions/44139967/sending-application-json-data-with-navigation-sendbeacon-preflight-succeedes-bu
export const isSendBeaconAvailable = false;
