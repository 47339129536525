import { roundTo } from './number-utils';
import { getShippingMethod } from './order-utils';

export const getPropertiesForOrder = (order) => {
  const shippingMethod = getShippingMethod(order);
  return {
    albumsNumber: roundTo(order.numberOfItems),
    cartAmount: roundTo(shippingMethod.totalAmount),
    numberOfItems: roundTo(order.numberOfItems),
    orderObjectId: order.objectId,
    currencyCode: 'EUR',
    totalAmount: roundTo(shippingMethod.totalAmount),
    totalAmountWithoutTax: roundTo(shippingMethod.totalAmountWithoutTax),
    shippingAmount: roundTo(shippingMethod.shippingAmount),
    taxAmount: roundTo(shippingMethod.totalAmount - shippingMethod.totalAmountWithoutTax),
    facebookItems: order.items
      .map((item) => ({
        id: item.sku || item.extra.ean || null,
        price: roundTo(item.price),
        quantity: Number(item.quantity),
      }))
      .filter((product) => product.id),
    googleAnalyticsItems: order.items
      .map((item, index) => ({
        item_id: item.sku || item.extra.ean || null,
        item_name: item.title || null,
        index: index + 1,
        price: roundTo(item.price),
        currency: 'EUR',
        quantity: Number(item.quantity),
      }))
      .filter((product) => product.item_id),
    productSkus: order.items.map((item) => item.sku || item.extra.ean).filter((row) => row),
  };
};

export const getPropertiesForWishlist = (albums, isBatch) => {
  return Object.assign(
    {
      albumObjectIds: albums.map((album) => album.objectId),
    },
    isBatch ? { isBatch: true } : {},
    {
      currencyCode: 'EUR',
      totalAmount: albums.reduce((prev, album) => prev + roundTo(album.price), 0),
      facebookItems: albums
        .map((album) => ({
          id: album.sku || album.ean || null,
          price: roundTo(album.price),
          quantity: 1,
        }))
        .filter((product) => product.id),
      googleAnalyticsItems: albums
        .map((album, index) => ({
          item_id: album.sku || album.ean || null,
          item_name: album.title || null,
          index: index + 1,
          price: roundTo(album.price),
          currency: 'EUR',
          quantity: 1,
        }))
        .filter((product) => product.item_id),
      productSkus: albums.map((album) => album.sku || album.ean).filter((row) => row),
    },
  );
};

export const getPropertiesForCart = (items, isBatch) => {
  return Object.assign(
    {
      albumObjectIds: items.map((item) => item?.album?.objectId || item?.extra?.album?.objectId),
      printObjectIds: items.map((item) => item?.objectId || item?.itemObjectId),
    },
    isBatch ? { type: 'Batch', isBatch: true } : {}, // because legacy event batch property is `type: 'Batch'`
    {
      currencyCode: 'EUR',
      totalAmount: items.reduce((prev, item) => prev + roundTo(item.sellingInfo.price), 0),
      facebookItems: items
        .map((item) => ({
          id: item.sku || item?.ean || item?.extra?.ean || null,
          price: roundTo(item.sellingInfo.price),
          quantity: item.quantity || 1,
        }))
        .filter((product) => product.id),
      googleAnalyticsItems: items
        .map((item, index) => ({
          item_id: item.sku || item?.ean || item?.extra?.ean || null,
          item_name: item.title || null,
          index: index + 1,
          price: roundTo(item.sellingInfo.price),
          currency: 'EUR',
          quantity: item.quantity || 1,
        }))
        .filter((product) => product.item_id),
      productSkus: items
        .map((item) => item.sku || item?.ean || item?.extra?.ean)
        .filter((row) => row),
    },
  );
};
