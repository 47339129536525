import { useSelector } from 'react-redux';

const AgendaSectionsFilter = ({ children, sections, filters = {} }) => {
  const prints = useSelector((state) => state.prints.prints);

  if (Object.keys(filters).length === 0) return children(sections);

  const newSections = sections.map((section) => {
    const newSection = Object.assign({}, section);
    newSection.albums = newSection.albums.filter((album) => {
      const boolArray = [];
      const print = prints[album.defaultSellingPrintObjectId];
      if (!print) return false;

      // OR filters
      if (filters.availability?.inStockOnline)
        boolArray.push(!!print.sellingInfo?.online?.numberOfSellers);
      if (filters.availability?.inStockClickAndCollect)
        boolArray.push(!!print.sellingInfo?.clickAndCollect?.numberOfSellers);

      // KO filters
      if (filters.price?.max && Number(print.sellingInfo?.price) > filters.price?.max) return false;
      if (filters.price?.min && Number(print.sellingInfo?.price) < filters.price?.min) return false;

      return boolArray.length === 0 ? true : boolArray.some((bool) => bool);
    });
    return newSection;
  });

  return children(newSections);
};

export default AgendaSectionsFilter;
