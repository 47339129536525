import React from 'react';

import { PROJECT } from '@/project-constants';
import { Link, matchPath, useLocation } from 'react-router-dom';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import iconSocialFacebook from '@/assets/9eart/icon_facebook_rond.svg';
import iconSocialInstragam from '@/assets/9eart/icon_instagram_rond.svg';
import iconSocialTwitter from '@/assets/9eart/icon_twitter_rond.svg';
import comicsblogLogo from '@/assets/9eart/logo_cb.png';
import comicsblogLogoWebp from '@/assets/9eart/logo_cb.webp';

import NArtHeaderLink from '../../components/NArtHeaderLink/NArtHeaderLink';
import Navbar9eArtNavItem from './NartNavItem/Navbar9eArtNavItem';

import { LINK } from 'bubble-constants';

import './NArtNavbar.scss';

const NavbarNArt = () => {
  const location = useLocation();
  let match;
  match = matchPath({ path: '/9emeart/:nArtCategory/:nArtSection/*' }, location.pathname);
  if (!match) {
    match = matchPath({ path: '/9emeart/:urlFirstParam' }, location.pathname);
    if (match) {
      const urlFirstParam = match?.params?.urlFirstParam;
      match.params.nArtCategory = PROJECT.PAPER_CATEGORIES.includes(urlFirstParam)
        ? urlFirstParam
        : null;
      match.params.nArtSection = PROJECT.PAPER_SECTIONS_NAMES.includes(urlFirstParam)
        ? urlFirstParam
        : null;
    }
  }
  const { nArtCategory, nArtSection } = match?.params || {};
  const nextPath = ['/9emeart', nArtCategory].filter((row) => row).join('/');

  return (
    <>
      <nav className="d-none d-lg-block p-0">
        <div className="bb-background-light-grey d-none d-lg-flex flex-row flex-fill px-lg-3 justify-content-between">
          <nav className="d-flex">
            <Navbar9eArtNavItem
              to={`${nextPath}`}
              selected={!nArtSection}
              text="En ce moment"
              category={nArtCategory}
            />
            <Navbar9eArtNavItem
              to={`${nextPath}/actualites`}
              selected={nArtSection === 'actualites'}
              text="Actualités"
              category={nArtCategory}
            />
            <Navbar9eArtNavItem
              to={`${nextPath}/critiques`}
              selected={nArtSection === 'critiques'}
              text="Critiques"
              category={nArtCategory}
            />
            <Navbar9eArtNavItem
              to={`${nextPath}/incontournables`}
              selected={nArtSection === 'incontournables'}
              text="Incontournables"
              category={nArtCategory}
            />
            <Navbar9eArtNavItem
              to={`${nextPath}/edito`}
              selected={nArtSection === 'edito'}
              text="Edito"
              category={nArtCategory}
            />
            <Navbar9eArtNavItem
              to={`${nextPath}/en-video`}
              selected={nArtSection === 'en-video'}
              category={nArtCategory}
              text={
                <>
                  <div className="d-none d-xl-inline-block pe-1">En vidéo</div>
                  <div className="d-inline-block d-xl-none">Vidéo</div>
                </>
              }
            />
            <div className="v-separator-no-gradient my-3 mx-4" />
            <div className="nav-item py-3 px-4">
              <Link className="no-decoration" to={`/agenda`}>
                Agenda
              </Link>
            </div>
          </nav>
          <div className="pe-3 d-flex align-items-center">
            <a target="_blank" rel="noopener noreferrer" href={LINK.FACEBOOK_NART_URL}>
              <img
                className="social-icon-small"
                src={iconSocialFacebook}
                alt="Facebook social icon"
              />
            </a>
            <a
              className="mx-3"
              target="_blank"
              rel="noopener noreferrer"
              href={LINK.TWITTER_NART_URL}
            >
              <img
                className="social-icon-small"
                src={iconSocialTwitter}
                alt="Twitter social icon"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LINK.INSTAGRAM_NART_URL}
              className="pe-1"
            >
              <img
                className="social-icon-small"
                src={iconSocialInstragam}
                alt="Instagram social icon"
              />
            </a>
          </div>
        </div>
      </nav>

      <nav className="d-flex d-lg-none bb-background-grey overflow-auto pt-2 justify-content-between">
        <NArtHeaderLink mobile to="/9emeart" noHighlight>
          Accueil
        </NArtHeaderLink>
        <div className="nart-navbar-separator v-separator-no-gradient-heavy rounded" />
        <NArtHeaderLink mobile to="/9emeart/bd">
          BD
        </NArtHeaderLink>
        <div className="nart-navbar-separator v-separator-no-gradient-heavy rounded" />
        <NArtHeaderLink mobile to="/9emeart/comics">
          Comics
        </NArtHeaderLink>
        <div className="nart-navbar-separator v-separator-no-gradient-heavy rounded" />
        <NArtHeaderLink mobile to="/9emeart/mangas">
          Mangas
        </NArtHeaderLink>
        <div className="nart-navbar-separator v-separator-no-gradient-heavy rounded" />
        <NArtHeaderLink mobile to="/9emeart/jeunesse">
          Jeunesse
        </NArtHeaderLink>
        <div className="nart-navbar-separator v-separator-no-gradient-heavy rounded" />
        <NArtHeaderLink mobile to="/9emeart/webtoon">
          Webtoon
        </NArtHeaderLink>
        <div className="nart-navbar-separator v-separator-no-gradient-heavy rounded" />
        <a
          className="pe-3 d-flex align-items-center"
          href="http://www.comicsblog.fr?utm_source=appbubble&utm_medium=header"
        >
          <WebpWrapper
            className="comicsblog-logo mx-3 mb-1"
            alt="Logo de Comicsblog"
            default={comicsblogLogo}
            webp={comicsblogLogoWebp}
          />
        </a>
      </nav>
    </>
  );
};

export default NavbarNArt;
