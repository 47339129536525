import PropTypes from 'prop-types';
import React from 'react';

import { useLocation } from 'react-router-dom';

import { copyToClipboard } from 'bubble-utils/src/string-utils';

import Toaster from '@/components/DUPLICATE/Toaster/Toaster';
import Tooltip from '@/components/Tooltip/Tooltip';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import iconCopy from '@/assets/cc_icon.svg';
import iconFacebook from '@/assets/facebook_icon.svg';
import iconTwitter from '@/assets/twitter_icon.svg';
import iconWhatsapp from '@/assets/whatsapp_icon.svg';

const ShareZone = (props) => {
  const location = useLocation();

  const link = `${`https://www.bubblebd.com${location.pathname}${location.search || ''}`}`;
  const title = props.title || 'Partager cette page avec un ami';

  const onClickFacebook = () => {
    window.open(`https://www.facebook.com/share.php?u=${link}`, '', 'width=250,height=100');
  };

  const onClickTwitter = () =>
    window.open(
      `https://twitter.com/intent/tweet?url=${link}${
        props.body ? `&text=${props.body}` : ''
      }&via=BubbleBD_`,
      '',
      'width=500,height=300',
    );

  const onClickWhatsapp = () => window.open(`https://api.whatsapp.com/send?text=${link}`);

  const copyLink = () => {
    copyToClipboard(link);
    // Toaster.addToast('Lien copié !', `${link} copié dans le presse-papier`);
  };

  const isWhite = 'white' in props;
  return (
    <div className=" justify-content-end px-sm-3">
      <div
        className={`d-flex align-items-center bb-s-text-size  ${props.className || ''} ${
          isWhite ? 'text-white' : 'text-secondary'
        }`}
      >
        <div className="d-none d-lg-block text-nowrap">{title} : </div>
        <Tooltip tip="Copier le lien dans le presse-papier">
          <WithClickHandler className="ps-2 d-block" onClick={copyLink}>
            <img alt="copy" src={iconCopy} />
          </WithClickHandler>
        </Tooltip>
        <WithClickHandler className="ps-2" onClick={onClickFacebook}>
          <img alt="fb" src={iconFacebook} />
        </WithClickHandler>
        <WithClickHandler className="ps-2" onClick={onClickTwitter}>
          <img alt="twitter" src={iconTwitter} />
        </WithClickHandler>
        <WithClickHandler className="ps-2" onClick={onClickWhatsapp}>
          <img alt="whatsapp" src={iconWhatsapp} />
        </WithClickHandler>
      </div>
    </div>
  );
};

ShareZone.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  subject: PropTypes.string,
};

export default ShareZone;
