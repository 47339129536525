import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import Cover from '@/components/Cover/Cover';

import StarLine from '../../StarLine/StarLine';

const SearchAlbumCard = (props) => {
  const album = props.album || {};

  return (
    <Link
      className={`${props.className || ''} no-decoration`}
      to={`/${album.permalink}/album/${album.objectId}/${album.defaultSellingPrintObjectId}`}
    >
      <div className="d-flex align-items-center">
        <div className="me-2">
          <Cover shadow rounded height={120} imageUrl={album.imageUrl} />
        </div>
        <div className="overflow-hidden">
          <div className="bb-medium-text-size bb-text-gray-dark text-truncate">
            {album.title || album.serieTitle}
          </div>
          <div className="text-secondary">{album.tome !== null ? 'Tome ' + album.tome : ''}</div>
          <StarLine note={album.note} />
        </div>
      </div>
    </Link>
  );
};

SearchAlbumCard.propTypes = {
  serie: PropTypes.shape({
    objectId: PropTypes.string,
    title: PropTypes.string,
    serieTitle: PropTypes.string,
    isTerminated: PropTypes.bool,
    note: PropTypes.number,
    imageUrl: PropTypes.string,
  }),
};

export default SearchAlbumCard;
