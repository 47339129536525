import { isString } from './string-utils';

export const isValidObjectId = (objectId) =>
  !!(objectId && objectId.toString().match(/^([a-z0-9]{14}|[a-z0-9]{10})$/i));

export const isValidAddress = (address) =>
  !!(
    address &&
    address.firstName &&
    address.lastName &&
    address.line1 &&
    address.zipcode &&
    address.city &&
    address.countryObjectId &&
    isValidPhone(address.phone)
  );

// check for:
// - if letters instead of numbers
// - else if phone nmuber has strictly less than 9 digits, we consider it as unvalid
export const isValidPhone = (phone) => {
  if (isNaN(Number((phone || '').toString().replace(/ /g, '')).toString())) {
    return false;
  } else if ((phone || '').toString().replace(/ /g, '').length < 9) {
    return false;
  }
  return true;
};

export const isValidEmail = (email) =>
  !!(
    email &&
    isString(email) &&
    email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  ); // https://emailregex.com/

export const isValidUrl = (url) =>
  !!(
    url &&
    isString(url) &&
    url.match(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    )
  ); // https://urlregex.com/

export const isValidUsername = (username) =>
  isString(username) && username.includes('@') && username.length >= 2;

export const isValidImageExtension = (name, allowed = ['jpg', 'png', 'jpeg', 'gif']) => {
  if (!name) {
    return false;
  }
  const nameSplit = name.split('.');
  const extension = nameSplit[nameSplit.length - 1].toLowerCase();

  return allowed.some((word) => extension === word);
};

export const isValidImageType = (type, allowed = ['image/jpeg', 'image/jpg', 'image/png']) => {
  if (!type) {
    return false;
  }
  return allowed.includes(type);
};

export const isValidTrackingId = (trackingId) => {
  if (!trackingId) {
    return false;
  }

  // LaPoste checks
  if (laPosteCodes.some((tag) => trackingId.toUpperCase().startsWith(tag))) {
    if (trackingId.length !== 13) {
      return false;
    }
    return true;
  }

  // MondialRelay checks
  if (trackingId.length !== 8 || trackingId.length !== 26) {
    return false;
  }

  return true;
};

//TODO: move to constant file
const laPosteCodes = [
  '9L',
  'EY',
  'CQ',
  'CU',
  'CT',
  '6R',
  '6W',
  '1A',
  '1B',
  '1C',
  '1D',
  '1E',
  '1F',
  '1H',
  '1J',
  '1K',
  '1L',
  '1N',
  '1P',
  '1R',
  '1Q',
  '1S',
  '1T',
  '1V',
  '1W',
  '1X',
  '1Y',
  '2B',
  '2C',
  '2D',
  '2K',
  '2G',
  '2K',
  '2H',
  '2L',
  '2M',
  '2P',
  '2Q',
  '2R',
  '2S',
  '2T',
  '2X',
  '2V',
  '2W',
  '3A',
  '3B',
  '3C',
  '3D',
  '3E',
  '3F',
  '3G',
  '3K',
  '3L',
  '3P',
  '3Q',
  '3Z',
  '4A',
  '4B',
  '4C',
  '4E',
  '4G',
  '4J',
  '4V',
  '5H',
  '5W',
  '5X',
  '5Y',
  '5Z',
  '6A',
  '6C',
  '6G',
  '6H',
  '6M',
  '6N',
  '6P',
  '6Q',
  '6S',
  '6W',
  '6X',
  '6Y',
  '6Z',
  '7A',
  '7D',
  '7E',
  '7F',
  '7G',
  '7H',
  '7I',
  '7K',
  '7M',
  '7O',
  '7R',
  '7Q',
  '7T',
  '7Z',
  '8A',
  '8B',
  '8C',
  '8D',
  '8E',
  '8F',
  '8G',
  '8H',
  '8I',
  '8J',
  '8K',
  '8L',
  '8M',
  '8N',
  '8O',
  '8P',
  '8Q',
  '8R',
  '8S',
  '8T',
  '8U',
  '8V',
  '8W',
  '8X',
  '8Y',
  '8Z',
  '9A',
  '9B',
  '9C',
  '9D',
  '9E',
  '9J',
  '9M',
  '9N',
  '9P',
  '9R',
  '9Q',
  '9S',
  '9T',
  '9V',
  '9W',
  '9X',
  'CA',
  'CB',
  'CI',
  'CM',
  'CP',
  'LA',
  'LE',
  'LX',
  'LY',
  'LW',
  'VD',
];
