import config from '@/project-config';

import { LINK } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

const LinkUtils = {};

LinkUtils.getLinkDownloadFromFooterIos = () => {
  const utms = bubbleUtils.utm.buildParams('website', 'footer', 'download-on-store', 'app-store');
  const params = bubbleUtils.utm.buildPtCt(config.bubbleIos.providerToken, 'download-on-store');
  return `${LINK.APP_STORE_LINK}?${utms}&${params}`;
};
LinkUtils.getLinkDownloadFromFooterAndroid = () => {
  const utms = bubbleUtils.utm.buildParams('website', 'footer', 'download-on-store', 'play-store');
  return `${LINK.PLAY_STORE_LINK}&${utms}`;
};

LinkUtils.getLinkDownloadFromLandingIos = () => {
  // prettier-ignore
  const utms = bubbleUtils.utm.buildParams('website', 'mobile-landing', 'download-on-store', 'app-store');
  const params = bubbleUtils.utm.buildPtCt(config.bubbleIos.providerToken, 'download-on-store');
  return `${LINK.APP_STORE_LINK}?${utms}&${params}`;
};
LinkUtils.getLinkDownloadFromLandingAndroid = () => {
  // prettier-ignore
  const utms = bubbleUtils.utm.buildParams('website', 'mobile-landing', 'download-on-store', 'play-store');
  return `${LINK.PLAY_STORE_LINK}&${utms}`;
};

/**
 * to use with Hashlink
 * yOffset default to -80 for header height
 */
LinkUtils.scrollWithOffset = (el, yOffset = -80) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'auto' });
};

export default LinkUtils;
