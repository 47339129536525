import React from 'react';

import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import iconSocialFacebook from '@/assets/icon-social-facebook-dark-gray.svg';
import iconSocialInstragam from '@/assets/icon-social-instagram-dark-gray.svg';
import iconSocialTwitter from '@/assets/icon-social-twitter-dark-gray.svg';

import { LINK } from 'bubble-constants';

import './SocialNetworkFollow.css';

const SocialNetworkFollow = () => {
  return (
    <div className="d-flex flex-fill px-5 py-4 rounded bg-infinity align-items-center justify-content-center">
      <span className="bb-medium-large-text-size fw-bold">
        Suivez-nous sur les réseaux sociaux :
      </span>
      <WithClickHandler onClick={null}>
        <a
          className="ms-3"
          target="_blank"
          rel="noopener noreferrer"
          href={LINK.FACEBOOK_BUBBLE_URL}
        >
          <img className="icon-social-image" src={iconSocialFacebook} alt="Facebook social icon" />
        </a>
      </WithClickHandler>
      <WithClickHandler onClick={null}>
        <a
          className="ms-3"
          target="_blank"
          rel="noopener noreferrer"
          href={LINK.TWITTER_BUBBLE_URL}
        >
          <img className="icon-social-image" src={iconSocialTwitter} alt="Twitter social icon" />
        </a>
      </WithClickHandler>
      <WithClickHandler onClick={null}>
        <a
          className="ms-3"
          target="_blank"
          rel="noopener noreferrer"
          href={LINK.INSTAGRAM_BUBBLE_URL}
        >
          <img
            className="icon-social-image"
            src={iconSocialInstragam}
            alt="Instagram social icon"
          />
        </a>
      </WithClickHandler>
    </div>
  );
};

export default SocialNetworkFollow;
