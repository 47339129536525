import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ArticleHorizontalBigCard from '@/components/ArticleHorizontalBigCard/ArticleHorizontalBigCard';

import './ArticleFocusSection.scss';

const ArticleFocusSection = ({ articleObjectId }) => {
  const article =
    useSelector((state) => state.articles.articlesMapByObjectId[articleObjectId]) || {};
  return (
    <div className="article-focus-section-background text-white">
      <div className="p-4 p-lg-paper">
        <div className="row">
          <div className="d-md-none col-12 h3 m-0 fw-bold pb-4"> {article.title}</div>
          <div className="col-md-8">
            <ArticleHorizontalBigCard noShadow noTitle noButton article={article} />
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-end">
            <div className="d-none d-md-block h3 m-0 fw-bold">{article.title}</div>
            <p className="py-4 m-0">{article.excerpt || article.excerptGenerated}</p>
            <Link
              to={article.path ? article.path : ''}
              className="btn article-focus-section-button text-white mb-0 mb-md-4"
            >
              Lire la chronique
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleFocusSection;
