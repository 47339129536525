import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { addToCart } from 'bubble-reducers/src/reducers/cart';
import { makeIsAlbumInCart } from 'bubble-reducers/src/selectors';

import { formatDateWithFormat } from 'bubble-utils/src/date-utils';

import {
  useAddPrintsToLibrary,
  useRemoveFromCart,
  useRemovePrintsFromLibrary,
} from '@/services/hooks/useLibraryUtils';
import { useIsUserInfinity, useIsUserlogged } from '@/services/hooks/useUserUtils';
import { getAssetImage } from '@/services/image-utils';

import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import placeholderJpegUrl from '@/assets/placeholders/placeholder-item.jpg';

import InfinityFunctionalityModal from '../InfinityFunctionalityModal';

import './PrintListCell.scss';

const PrintListCell = (props) => {
  const dispatch = useDispatch();

  const isUserlogged = useIsUserlogged();
  const isUserInfinity = useIsUserInfinity();
  const addPrintsToLibrary = useAddPrintsToLibrary();
  const removePrintsFromLibrary = useRemovePrintsFromLibrary();
  const removeFromCart = useRemoveFromCart();

  const [showInfinityFunctionalityModal, setShowInfinityFunctionalityModal] = useState(false);

  const print = props.print || {};
  const album = props.album || {};
  const user = useSelector((state) => state.user.user);
  const isPrintOwned = useSelector((state) => state.profiles.ownedPrintsIdsMap[print.objectId]);
  const isPrintInCart = useSelector((state) => makeIsAlbumInCart()(state, print.objectId));

  const sIOnline = print.sellingInfo.online;
  const sICandC = print.sellingInfo.clickAndCollect;
  const isAvailableSomewhere = sIOnline.numberOfSellers > 0 || sICandC.numberOfSellers > 0;
  const date =
    formatDateWithFormat(print.publicationDate, {
      month: 'long',
      day: null,
    }) || '';
  const imageUrl = print?.images?.front?.smallFramed || placeholderJpegUrl;

  const handleCollectionClick = () => {
    if (isPrintOwned) {
      removePrintsFromLibrary(user, [print]);
    } else if (print.objectId === album.defaultPrintObjectId) {
      addPrintsToLibrary(user, [print]);
    } else {
      if (isUserlogged()) {
        if (isUserInfinity(user)) {
          addPrintsToLibrary(user, [print]);
        } else {
          setShowInfinityFunctionalityModal(true);
        }
      }
    }
  };

  const handleAddToCart = () => {
    if (isPrintOwned) {
      if (
        window.confirm(
          "Cet album fait déjà parti de votre collection, Êtes vous sûr de vouloir l'ajouter à votre panier ?",
        )
      ) {
        dispatch(addToCart({ prints: print }));
      }
    } else {
      dispatch(addToCart({ prints: print }));
    }
  };

  const onClickRemoveFromCart = () => removeFromCart({ prints: [print] });
  const handleCartClick = () => {
    isPrintInCart ? onClickRemoveFromCart() : handleAddToCart();
  };
  return (
    <div
      className={`row rounded p-2 my-2 my-md-3 mx-1 mx-md-0 bb-s-text-size text-secondary ${
        props.selected ? 'print-cell-selected' : 'print-cell'
      }`}
    >
      {showInfinityFunctionalityModal && (
        <InfinityFunctionalityModal
          show={showInfinityFunctionalityModal}
          infinityFunctionalityType={InfinityFunctionalityModal.types.OWNED}
          callback={() => {
            if (props.callback) {
              props.callback();
            }
            setShowInfinityFunctionalityModal(false);
          }}
        />
      )}
      <div className="col-12 col-md-10 d-flex ms-n2 bb-small-text-size">
        <img className="print-list-img" alt="print-list" src={imageUrl} />
        <div className="ps-3">
          <div className="print-cell-full-height-container d-flex flex-column justify-content-between py-1">
            <Link to={`/${album.permalink}/album/${album.objectId}/${print.objectId}`}>
              <span className="text-secondary">
                <div className="text-primary">{album.serie?.title}</div>
                {album.tome && ` - Tome ${album.tome}`}{' '}
              </span>
              <div className="fw-bold bb-medium-text-size text-black">
                {date && date.charAt(0).toUpperCase() + date.slice(1)}
              </div>
            </Link>
            <div>
              <div>
                {[print.publisher, print.numberOfPages ? `${print.numberOfPages} pages` : null]
                  .filter((row) => row)
                  .join(' - ')}
              </div>
              <div>
                {[print.width && print.length ? `${print.width}x${print.length}` : null, print.ean]
                  .filter((row) => row)
                  .join(' - ')}
              </div>
            </div>
            <div className="d-flex d-md-none align-items-center">
              <WithClickHandler onClick={handleCollectionClick}>
                <img
                  className="mb-2 collection-array-picto"
                  alt="add"
                  src={
                    isPrintOwned
                      ? getAssetImage('icon_series_green.svg')
                      : getAssetImage('icon_series_grey.svg')
                  }
                />
              </WithClickHandler>
              <img alt="edition" className="pe-2" src={getAssetImage(`icon_add_cart_grey.svg`)} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-2 px-3 d-none d-md-flex flex-column align-items-center ">
        <div className="col-12 p-0 d-flex flex-row justify-items-center align-items-center">
          <div className="col-6 px-2">
            <WithClickHandler onClick={handleCollectionClick} className="zindex-tooltip">
              <img
                className="collection-array-picto"
                alt="add"
                src={
                  isPrintOwned
                    ? getAssetImage('icon_series_green.svg')
                    : getAssetImage('icon_series_grey.svg')
                }
              />
            </WithClickHandler>
          </div>
          <div className="col-6 px-2">
            {(isAvailableSomewhere && (
              <WithClickHandler className="zindex-tooltip" onClick={handleCartClick}>
                <img
                  alt="edition"
                  className=" mb-1 collection-array-picto"
                  src={
                    isPrintInCart
                      ? getAssetImage('icon_remove_cart_red.svg')
                      : getAssetImage(`icon_add_cart_black.svg`)
                  }
                />
              </WithClickHandler>
            )) || (
              <img
                alt="edition"
                className="mb-1 collection-array-picto"
                src={getAssetImage(`icon_add_cart_grey.svg`)}
              />
            )}
          </div>
        </div>
        {!isAvailableSomewhere && (
          <div className="col-12 p-0 text-muted mt-n3 me-n2">
            <span>Non disponible</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrintListCell;
