import React, { Component } from 'react';

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        importedComponent: null,
      };
    }

    async componentDidMount() {
      const { default: importedComponent } = await importComponent();

      this.setState({
        importedComponent: importedComponent,
      });
    }

    render() {
      const ImportedComponent = this.state.importedComponent;

      return ImportedComponent ? (
        <ImportedComponent {...this.props} />
      ) : (
        <div className="d-flex align-items-center justify-content-center py-6">
          <div className="d-flex flex-fill h-75 justify-content-center align-items-center">
            <div className="spinner-border" role="status" />
          </div>
        </div>
      );
    }
  }

  return AsyncComponent;
}
