import React from 'react';

function FlaggedComment(props) {
  return (
    <div key={props.commentObjectId} className="row my-4 border-top">
      <div className="col-12 text-center">
        <p className="pt-5 pb-2">Ce commentaire a été signalé comme innaproprié.</p>
      </div>
    </div>
  );
}

export default FlaggedComment;
