import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import PromotionCard from '@/components/PromotionCard/PromotionCard';

export default function PromotionSectionWrapper({ serieObjectId, albumObjectId }) {
  const promotions = useSelector((state) => state.promotions.promotionsMapByObjectId);

  //TODO: create selector
  const associatedPromotions = useMemo(
    () =>
      Object.values(promotions).filter((promotion) => {
        let attachedObject = null;
        if (albumObjectId) {
          attachedObject =
            promotion.attachedAlbums.find((album) => album.objectId === albumObjectId) ||
            promotion.attachedSeries.find((serie) =>
              serie.albums.some((album) => album.objectId === albumObjectId),
            );
        }
        if (serieObjectId) {
          attachedObject = promotion.attachedSeries.find(
            (serie) => serie.objectId === serieObjectId,
          );
        }

        return !!attachedObject;
      }),
    [serieObjectId, albumObjectId, promotions],
  );
  if (!associatedPromotions.length) return null;

  return (
    <div id="promotionsection" className="pt-3">
      {associatedPromotions.map((associatedPromotion) => (
        <PromotionCard key={associatedPromotion.objectId} promotion={associatedPromotion} />
      ))}
    </div>
  );
}
