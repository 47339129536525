const environment =
  process.env.REACT_APP_HOST_ENV ||
  process.env.VITE_HOST_ENV ||
  process.env.NODE_ENV ||
  process.env.MODE ||
  'production';

const defaultConfig = {
  env: environment,
  bubbleAndroid: {
    appId: 'com.heappi.android.Bubble',
  },
  bubbleIos: {
    appId: '1006844761',
    providerToken: '117769897',
  },
  intercom: {
    appId: 'mwga0v1k',
    initializationDelay: 10000,
  },
  facebook: {
    apiVersion: '18.0',
    appId: '836242259786620',
    isPixelActive: true,
    pixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID || process.env.VITE_FACEBOOK_PIXEL_ID,
    options: {
      autoConfig: true,
      debug: false,
    },
  },
  sentry: {
    isActive: true,
    dsnWeb: 'https://76ecc988d6984803b17025680765bc10@sentry.io/1494286', // prod web
  },
  algolia: {
    appID: '6A891Z72ZD', // prod
    apiKey: '7b4e51bd140b7d73c3241e3a00984f5b', // prod search only
    hitsPerPage: 10,
    typoTolerance: 'strict',
    seriesIndex: 'Series',
    albumsIndex: 'Albums',
    authorsIndex: 'Authors',
    articlesIndex: 'Articles',
    storesIndex: 'Stores',
    publishersIndex: 'Publishers',
    tagsIndex: 'Tags',
  },
  google: {
    gtm: {
      id: process.env.REACT_APP_GTM_ID || process.env.VITE_GTM_ID,
      auth: process.env.REACT_APP_GTM_AUTH || process.env.VITE_GTM_AUTH,
      env: process.env.REACT_APP_GTM_ENV || process.env.VITE_GTM_ENV,
    },
  },
  event: {
    isSent: true,
  },
  welcomeKit: {
    id: 'd476WgV',
  },
  stripe: {
    appleMerchantId: 'merchant.com.heappi.ios.Bubble', // must be set to use Apple Pay
    googleMerchantId: 'BCR2DN4TXDZ4N4CB',
    androidPayMode: 'production',
    publishableKey: 'pk_live_b7IGiEf9ib4iHZ8KTgS7gYCT',
  },
  affilae: {
    host: 'https://lb.affilae.com',
    key: '5d9c82ed93ff845c38b1c3d5-5d9c82e093ff845c38b1c332',
    programId: '5d9c82e093ff845c38b1c332',
  },
  bubbleApi: {
    host: 'https://api.bubblebd.com',
  },
  bubbleWebSite: {
    host: 'https://www.bubblebd.com',
  },
  bubbleServices: {
    host: 'https://services.bubblebd.com',
  },
  resources: {
    assets: 'https://assets.bubblebd.com',
    domain: 'https://static.bubblebd.com',
  },
  crowdfunding: {
    loadDelayInMs: 30000,
  },
  storeBubble: {
    objectId: 'kGtA9LcFuK',
  },
  storeBdFugue: {
    objectId: 'iiKouErDB6',
  },
  ipstack: {
    apiKey: 'b7d6612a484125c33e0939262d58cd3f',
  },
};

const devConfig = {
  intercom: {
    appId: 'wqj97229',
    initializationDelay: 10000,
  },
  facebook: {
    apiVersion: '18.0',
    appId: '845463985531114',
    isPixelActive: false,
    options: {
      autoConfig: true,
      debug: false,
    },
  },
  sentry: {
    isActive: false,
    dsnWeb: 'https://76ecc988d6984803b17025680765bc10@sentry.io/1494286', // prod web
  },
  stripe: {
    publishableKey: 'pk_test_BxqLJkZeHvXdMaMXisJjSe0P',
    androidPayMode: 'test',
  },
  bubbleApi: {
    host: 'https://local.bubblebd.com:4000',
  },
  bubbleWebSite: {
    host: 'https://local.bubblebd.com:3001',
  },
  bubbleServices: {
    host: 'https://local.bubblebd.com:3000',
  },
  resources: {
    assets: 'https://s3.eu-central-1.amazonaws.com/test.assets.bubblebd.com',
    domain: '',
  },
  crowdfunding: {
    loadDelayInMs: 10000,
  },
};

export default Object.assign(defaultConfig, environment === 'development' ? devConfig : {});
