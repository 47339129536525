import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  checkJwtTokenValidityApi,
  loginApi,
  loginGuestApi,
  loginWithFacebookApi,
  loginWithFacebookJwtTokenApi,
  resetPasswordApi,
  signupApi,
  updatePasswordApi,
  updatePasswordFromEmailApi,
  updatePasswordVerifyTokenApi,
} from '../services/api';

import { getClientToken, getGuestId, getSessionId } from '../selectors';
import { resetAvailabilityAlertsReducer } from './availability-alerts';
import { resetCartReducer } from './cart';
import { logEvent } from './events';
import { resetOrdersReducer } from './orders';
import { resetPaymentMethodsReducer } from './payment-methods';
import { resetProfilesReducer } from './profiles';
import { resetReviewsReducer } from './reviews';
import { resetUserReducer, storeGuest, storeUser } from './user';

import { EVENT } from 'bubble-constants';

export const SIGNUP = 'auth/signup';
export const LOGIN = 'auth/login';
const LOGOUT = 'auth/logout';
const LOGIN_GUEST = 'auth/loginGuest';
const LOGIN_WITH_FACEBOOK = 'auth/loginWithFacebook';
const LOGIN_WITH_FACEBOOK_JWT_TOKEN = 'auth/loginWithFacebookJwtToken';
const LOGIN_WITH_APPLE = 'auth/loginWithApple';
export const UPDATE_PASSWORD = 'auth/updatePassword';
export const UPDATE_PASSWORD_FROM_EMAIL = 'auth/updatePasswordFromEmail';
export const UPDATE_PASSWORD_VERIFY_TOKEN = 'auth/updatePasswordVerifyToken';
export const RESET_PASSWORD = 'auth/resetPassword';
const CHECK_JWT_TOKEN_VALIDITY = 'auth/checkJwtTokenValidity';

const initialState = {
  jwtToken: null,
  sessionId: null,
  loading: {},
  errors: {},
};

export const signup = createAsyncThunk(SIGNUP, async (params, { getState, dispatch }) => {
  const email = params?.email || null;
  const password = params?.password || null;
  const hasAcceptedNewsletter =
    'hasAcceptedNewsletter' in params ? params.hasAcceptedNewsletter : null;
  const isGdprCompliant = params?.isGdprCompliant || null;
  const token = params?.token || null;
  const cookie = params?.cookie || null;
  const localStorage = params?.localStorage || null;
  const referralCode = params?.referralCode || null;
  const platform = params?.platform || null;

  const sessionId = getSessionId(getState());
  const guestId = getGuestId(getState());

  const options = {};
  if (guestId) {
    options.guestId = guestId;
  } else {
    options.sessionId = sessionId;
  }
  if (referralCode) {
    options.referralCode = referralCode;
  }
  const authData = await signupApi(
    email,
    password,
    hasAcceptedNewsletter,
    isGdprCompliant,
    token,
    platform,
    options,
  );

  dispatch(saveJwtToken({ jwtToken: authData.token }));
  dispatch(storeUser({ user: authData.user }));
  dispatch(logEvent(EVENT.SIGNUP, { method: 'Email' }));

  return { initialPayload: params, authData };
});

export const login = createAsyncThunk(LOGIN, async (params, { getState, dispatch }) => {
  const email = params?.email || null;
  const password = params?.password || null;
  const stayLogged = params?.stayLogged || null;
  const cookie = params?.cookie || null;
  const localStorage = params?.localStorage || null;
  const platform = params?.platform || null;

  const sessionId = getSessionId(getState());
  const guestId = getGuestId(getState());
  const options = {};
  if (guestId) {
    options.guestId = guestId;
  } else {
    options.sessionId = sessionId;
  }
  const authData = await loginApi(email, password, platform, options);

  await dispatch(saveJwtToken({ jwtToken: authData.token }));
  await dispatch(storeUser({ user: authData.user }));
  dispatch(logEvent(EVENT.LOGIN, { method: 'Email' }));

  return { initialPayload: params, authData };
});

export const logout = createAsyncThunk(LOGOUT, async (params, { getState, dispatch }) => {
  const cookie = params?.cookie || null;
  const localStorage = params?.localStorage || null;

  // call event first to get user information before deletion
  dispatch(logEvent(EVENT.LOGOUT));

  dispatch(deleteJwtToken());
  dispatch(resetUserReducer());
  dispatch(resetProfilesReducer());
  dispatch(resetPaymentMethodsReducer());
  dispatch(resetOrdersReducer());
  dispatch(resetCartReducer());
  dispatch(resetAvailabilityAlertsReducer());
  dispatch(resetReviewsReducer());
  return { cookie, localStorage };
});

export const loginGuest = createAsyncThunk(LOGIN_GUEST, async (params, { getState, dispatch }) => {
  const email = params?.email || null;
  const cookie = params?.cookie || null;
  const localStorage = params?.localStorage || null;
  const platform = params?.platform || null;

  const sessionId = getSessionId(getState());
  const guestId = getGuestId(getState());

  const options = {};
  if (guestId) {
    options.guestId = guestId;
  } else {
    options.sessionId = sessionId;
  }
  const authData = await loginGuestApi(email, platform, options);

  dispatch(saveJwtToken({ jwtToken: authData.token }));
  dispatch(storeGuest({ guest: authData.guest }));
  dispatch(logEvent(EVENT.SIGNUP_GUEST, { method: 'Email' }));

  return { initialPayload: params, authData };
});

export const loginWithFacebook = createAsyncThunk(
  LOGIN_WITH_FACEBOOK,
  async (params, { getState, dispatch }) => {
    const accessToken = params?.accessToken || null;
    const expirationTime = params?.expirationTime || null;
    const platform = params?.platform || null;
    const method = params?.method || null;
    const cookie = params?.cookie || null;
    const localStorage = params?.localStorage || null;
    const referralCode = params?.referralCode || null;

    const authData = await loginWithFacebookApi(
      accessToken,
      expirationTime,
      method,
      platform,
      referralCode,
    );
    dispatch(saveJwtToken({ jwtToken: authData.token }));
    dispatch(storeUser({ user: authData.user }));
    dispatch(
      logEvent(authData.user.isNewUser ? EVENT.SIGNUP : EVENT.LOGIN, { method: 'Facebook' }),
    );

    return { initialPayload: params, authData };
  },
);

export const loginWithFacebookJwtToken = createAsyncThunk(
  LOGIN_WITH_FACEBOOK_JWT_TOKEN,
  async (params, { getState, dispatch }) => {
    const authenticationToken = params?.authenticationToken || null;
    const platform = params?.platform || null;
    const method = params?.method || null;
    const nonce = params?.nonce || null;
    const referralCode = params?.referralCode || null;

    const authData = await loginWithFacebookJwtTokenApi(
      authenticationToken,
      method,
      platform,
      nonce,
      referralCode,
    );
    dispatch(saveJwtToken({ jwtToken: authData.token }));
    dispatch(storeUser({ user: authData.user }));
    dispatch(
      logEvent(authData.user.isNewUser ? EVENT.SIGNUP : EVENT.LOGIN, { method: 'Facebook' }),
    );

    return { initialPayload: params, authData };
  },
);

export const loginWithApple = createAsyncThunk(
  LOGIN_WITH_APPLE,
  async (params, { getState, dispatch }) => {
    const authentificationData = params?.authentificationData || null;
    const platform = params?.platform || null;
    const referralCode = params?.referralCode || null;

    const authData = await loginWithAppleApi(authentificationData, platform, referralCode);
    dispatch(saveJwtToken({ jwtToken: authData.token }));
    dispatch(storeUser({ user: authData.user }));
    dispatch(logEvent(authData.user.isNewUser ? EVENT.SIGNUP : EVENT.LOGIN, { method: 'Apple' }));

    return { initialPayload: params, authData };
  },
);

export const updatePassword = createAsyncThunk(
  UPDATE_PASSWORD,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const newPassword = params?.newPassword || null;

    const clientToken = getClientToken(getState());
    await updatePasswordApi(clientToken, userObjectId, newPassword);
  },
);

export const updatePasswordFromEmail = createAsyncThunk(
  UPDATE_PASSWORD_FROM_EMAIL,
  async (params, { getState, dispatch }) => {
    const email = params?.email || null;
    const token = params?.token || null;
    const newPassword = params?.newPassword || null;

    await updatePasswordFromEmailApi(email, token, newPassword);
  },
);

export const updatePasswordVerifyToken = createAsyncThunk(
  RESET_PASSWORD,
  async (params, { getState, dispatch }) => {
    const email = params?.email || null;
    const token = params?.token || null;

    await updatePasswordVerifyTokenApi(email, token);
  },
);

export const resetPassword = createAsyncThunk(
  UPDATE_PASSWORD_VERIFY_TOKEN,
  async (params, { getState, dispatch }) => {
    const email = params?.email || null;

    await resetPasswordApi(email);
  },
);

export const checkJwtTokenValidity = createAsyncThunk(
  CHECK_JWT_TOKEN_VALIDITY,
  async (params, { getState, dispatch }) => {
    const clientToken = getClientToken(getState());
    const authData = await checkJwtTokenValidityApi(clientToken);
    dispatch(saveJwtToken({ jwtToken: authData.token }));
    return { token: authData.token };
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveJwtToken: {
      reducer: (state, action) => {
        state.jwtToken = action.payload.jwtToken;
      },
    },
    deleteJwtToken: {
      reducer: (state, action) => {
        state.jwtToken = null;
      },
    },
    saveSessionId: {
      reducer: (state, action) => {
        state.sessionId = action.payload.sessionId;
      },
    },
    resetErrors: {
      reducer: (state, action) => {
        state.errors = initialState.errors;
        state.signupErrors = initialState.errors;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // signup
      .addCase(signup.pending, (state, action) => {
        state.loading[SIGNUP] = true;
        state.errors[SIGNUP] = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading[SIGNUP] = false;
        state.errors[SIGNUP] = null;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading[SIGNUP] = false;
        state.errors[SIGNUP] = action.error;
      })
      // login
      .addCase(login.pending, (state, action) => {
        state.loading[LOGIN] = true;
        state.errors[LOGIN] = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading[LOGIN] = false;
        state.errors[LOGIN] = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading[LOGIN] = false;
        state.errors[LOGIN] = action.error;
      })
      // logout
      .addCase(logout.pending, (state, action) => {
        state.jwtToken = null;
        state.loading[LOGOUT] = true;
        state.errors[LOGOUT] = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading[LOGOUT] = false;
        state.errors[LOGOUT] = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading[LOGOUT] = false;
        state.errors[LOGOUT] = action.error;
      })
      // loginGuest
      .addCase(loginGuest.pending, (state, action) => {
        state.loading[LOGIN_GUEST] = true;
        state.errors[LOGIN_GUEST] = null;
      })
      .addCase(loginGuest.fulfilled, (state, action) => {
        state.loading[LOGIN_GUEST] = false;
        state.errors[LOGIN_GUEST] = null;
      })
      .addCase(loginGuest.rejected, (state, action) => {
        state.loading[LOGIN_GUEST] = false;
        state.errors[LOGIN_GUEST] = action.error;
      })
      // loginWithFacebook
      .addCase(loginWithFacebook.pending, (state, action) => {
        state.loading[LOGIN_WITH_FACEBOOK] = true;
        state.errors[LOGIN_WITH_FACEBOOK] = null;
      })
      .addCase(loginWithFacebook.fulfilled, (state, action) => {
        state.loading[LOGIN_WITH_FACEBOOK] = false;
        state.errors[LOGIN_WITH_FACEBOOK] = null;
      })
      .addCase(loginWithFacebook.rejected, (state, action) => {
        state.loading[LOGIN_WITH_FACEBOOK] = false;
        state.errors[LOGIN_WITH_FACEBOOK] = action.error;
      })
      // loginWithFacebookJwtToken
      .addCase(loginWithFacebookJwtToken.pending, (state, action) => {
        state.loading[LOGIN_WITH_FACEBOOK_JWT_TOKEN] = true;
        state.errors[LOGIN_WITH_FACEBOOK_JWT_TOKEN] = null;
      })
      .addCase(loginWithFacebookJwtToken.fulfilled, (state, action) => {
        state.loading[LOGIN_WITH_FACEBOOK_JWT_TOKEN] = false;
        state.errors[LOGIN_WITH_FACEBOOK_JWT_TOKEN] = null;
      })
      .addCase(loginWithFacebookJwtToken.rejected, (state, action) => {
        state.loading[LOGIN_WITH_FACEBOOK_JWT_TOKEN] = false;
        state.errors[LOGIN_WITH_FACEBOOK_JWT_TOKEN] = action.error;
      })
      // loginWithApple
      .addCase(loginWithApple.pending, (state, action) => {
        state.loading[LOGIN_WITH_APPLE] = true;
        state.errors[LOGIN_WITH_APPLE] = null;
      })
      .addCase(loginWithApple.fulfilled, (state, action) => {
        state.loading[LOGIN_WITH_APPLE] = false;
        state.errors[LOGIN_WITH_APPLE] = null;
      })
      .addCase(loginWithApple.rejected, (state, action) => {
        state.loading[LOGIN_WITH_APPLE] = false;
        state.errors[LOGIN_WITH_APPLE] = action.error;
      })
      // updatePassword
      .addCase(updatePassword.pending, (state, action) => {
        state.loading[UPDATE_PASSWORD] = true;
        state.errors[UPDATE_PASSWORD] = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.loading[UPDATE_PASSWORD] = false;
        state.errors[UPDATE_PASSWORD] = null;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loading[UPDATE_PASSWORD] = false;
        state.errors[UPDATE_PASSWORD] = action.error;
      })
      // updatePasswordFromEmail
      .addCase(updatePasswordFromEmail.pending, (state, action) => {
        state.loading[UPDATE_PASSWORD_FROM_EMAIL] = true;
        state.errors[UPDATE_PASSWORD_FROM_EMAIL] = null;
      })
      .addCase(updatePasswordFromEmail.fulfilled, (state, action) => {
        state.loading[UPDATE_PASSWORD_FROM_EMAIL] = false;
        state.errors[UPDATE_PASSWORD_FROM_EMAIL] = null;
      })
      .addCase(updatePasswordFromEmail.rejected, (state, action) => {
        state.loading[UPDATE_PASSWORD_FROM_EMAIL] = false;
        state.errors[UPDATE_PASSWORD_FROM_EMAIL] = action.error;
      })
      // updatePasswordVerifyToken
      .addCase(updatePasswordVerifyToken.pending, (state, action) => {
        state.loading[UPDATE_PASSWORD_VERIFY_TOKEN] = true;
        state.errors[UPDATE_PASSWORD_VERIFY_TOKEN] = null;
      })
      .addCase(updatePasswordVerifyToken.fulfilled, (state, action) => {
        state.loading[UPDATE_PASSWORD_VERIFY_TOKEN] = false;
        state.errors[UPDATE_PASSWORD_VERIFY_TOKEN] = null;
      })
      .addCase(updatePasswordVerifyToken.rejected, (state, action) => {
        state.loading[UPDATE_PASSWORD_VERIFY_TOKEN] = false;
        state.errors[UPDATE_PASSWORD_VERIFY_TOKEN] = action.error;
      })
      // resetPassword
      .addCase(resetPassword.pending, (state, action) => {
        state.loading[RESET_PASSWORD] = true;
        state.errors[RESET_PASSWORD] = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading[RESET_PASSWORD] = false;
        state.errors[RESET_PASSWORD] = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading[RESET_PASSWORD] = false;
        state.errors[RESET_PASSWORD] = action.error;
      })
      // checkJwtTokenValidity
      .addCase(checkJwtTokenValidity.pending, (state, action) => {
        state.loading[CHECK_JWT_TOKEN_VALIDITY] = true;
        state.errors[CHECK_JWT_TOKEN_VALIDITY] = null;
      })
      .addCase(checkJwtTokenValidity.fulfilled, (state, action) => {
        state.loading[CHECK_JWT_TOKEN_VALIDITY] = false;
        state.errors[CHECK_JWT_TOKEN_VALIDITY] = null;
      })
      .addCase(checkJwtTokenValidity.rejected, (state, action) => {
        state.loading[CHECK_JWT_TOKEN_VALIDITY] = false;
        state.errors[CHECK_JWT_TOKEN_VALIDITY] = action.error;
      });
  },
});

export default authSlice.reducer;

export const { saveJwtToken, saveSessionId, deleteJwtToken, resetErrors } = authSlice.actions;
