import React from 'react';

import BubbleCheckbox from '@/components/BubbleCheckbox/BubbleCheckbox';

import { GENERAL } from 'bubble-constants';

import '../Promotions.scss';

const PromotionsFilter = ({ callback, selected = [] }) => {
  const onClickCategory = (genre) => callback(genre);
  const resetCategories = () => callback(null, true);

  return (
    <div className="bg-white rounded p-3 py-md-4 d-flex flex-column flex-md-row align-items-center justify-content-center">
      <div className="promotion-checkbox px-md-3 py-1 py-md-0">
        <BubbleCheckbox
          onChange={resetCategories}
          label="Tous les genres"
          checked={selected.length === 3 || selected.length === 0}
        />
      </div>
      <div className="promotion-checkbox px-md-3 py-1 py-md-0">
        <BubbleCheckbox
          onChange={() => onClickCategory(GENERAL.BD)}
          label="Bandes dessinées"
          checked={selected.includes(GENERAL.BD)}
        />
      </div>
      <div className="promotion-checkbox px-md-3 py-1 py-md-0">
        <BubbleCheckbox
          onChange={() => onClickCategory(GENERAL.COMICS)}
          label="Comics"
          checked={selected.includes(GENERAL.COMICS)}
        />
      </div>
      <div className="promotion-checkbox px-md-3 py-1 py-md-0">
        <BubbleCheckbox
          onChange={() => onClickCategory(GENERAL.MANGAS)}
          label="Mangas"
          checked={selected.includes(GENERAL.MANGAS)}
        />
      </div>
    </div>
  );
};

export default PromotionsFilter;
