import React from 'react';

import { Link } from 'react-router-dom';

import { getAssetImage } from '@/services/image-utils';

import './UserProfileNavItem.css';

const UserProfileNavItem = (props) => {
  return (
    <li className="nav-item d-flex">
      <Link
        className={`no-decoration nav-link px-lg-5 px-3 py-3 transition bb-medium-text-size ${
          props.active ? ' bb-background-light-grey text-black' : 'text-white'
        }`}
        to={`/user/${props.userObjectId}/${props.tab}`}
      >
        <span className="d-none d-lg-block">{props.text}</span>
        <img
          style={{ width: 35 }}
          alt={props.tab}
          className="d-block d-lg-none"
          src={getAssetImage(`${props.imgPath}${props.active ? 'black' : 'white'}.svg`)}
        />
      </Link>
    </li>
  );
};

export default UserProfileNavItem;
