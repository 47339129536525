import React from 'react';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './ElementCell.scss';

const ElementCell = (props) => {
  const { text, iconName, onClick } = props;

  return (
    (text && (
      <div className="d-flex justify-content-between align-items-center container element-cell text-primary p-2 rounded mt-1">
        <div>{text}</div>
        <WithClickHandler onClick={onClick || null}>
          {iconName && <Icon className="" name={iconName} />}
        </WithClickHandler>
      </div>
    )) ||
    null
  );
};
export default ElementCell;
