import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadTypesApi } from '../services/api';

import { getClientToken } from '../selectors';

const LOAD_TYPES = 'types/loadTypes';

const initialState = {
  types: [],
  loading: {},
  errors: {},
};

export const loadTypes = createAsyncThunk(LOAD_TYPES, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const types = await loadTypesApi(clientToken);
  return { types };
});

const typesSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadTypes
      .addCase(loadTypes.pending, (state, action) => {
        state.loading[LOAD_TYPES] = true;
        state.errors[LOAD_TYPES] = null;
      })
      .addCase(loadTypes.fulfilled, (state, action) => {
        state.types = action.payload.types;
        state.loading[LOAD_TYPES] = false;
        state.errors[LOAD_TYPES] = null;
      })
      .addCase(loadTypes.rejected, (state, action) => {
        state.loading[LOAD_TYPES] = false;
        state.errors[LOAD_TYPES] = action.error;
      });
  },
});

export default typesSlice.reducer;
