import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Cover from '@/components/Cover/Cover';

import './AttachedPromotionCard.scss';

export default function AttachedPromotionCard({ albumObjectId, serieObjectId }) {
  const promotions = useSelector((state) => state.promotions.promotionsMapByObjectId);

  const associatedPromotion = useMemo(
    () =>
      Object.values(promotions).find((promotion) => {
        let attachedObject = null;
        if (albumObjectId) {
          attachedObject =
            promotion.attachedAlbums.find((album) => album.objectId === albumObjectId) ||
            promotion.attachedSeries.find((serie) =>
              serie.albums.some((album) => album.objectId === albumObjectId),
            );
        }
        if (serieObjectId) {
          attachedObject = promotion.attachedSeries.find(
            (serie) => serie.objectId === serieObjectId,
          );
        }

        return !!attachedObject;
      }),
    [serieObjectId, albumObjectId, promotions],
  );

  if (!associatedPromotion) return null;

  return (
    <div className="my-4 text-start rounded-top bb-shadow-hover attached-promotion-card">
      <div className="bg-pink text-white text-end bb-medium-text-size fw-bold px-3 pt-1 rounded-top">
        Promotion en cours
      </div>
      <div className="bg-white py-2 px-3 d-flex">
        <div className="bb-shadow-hover d-flex align-self-start attached-promotion-cover">
          <Link to={{ hash: 'promotionsection' }} className="text-primary">
            <Cover width={67} imageUrl={associatedPromotion.imageUrl} />
          </Link>
        </div>
        <div className="ps-3 d-flex flex-column justify-content-between">
          <div className="fw-bold">{associatedPromotion.title}</div>
          <div>
            <Link to={{ hash: 'promotionsection' }} className="text-primary">
              En savoir plus
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
