import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { loadAlbum } from 'bubble-reducers/src/reducers/albums';
import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { loadReview } from 'bubble-reducers/src/reducers/reviews';
import { loadSerie } from 'bubble-reducers/src/reducers/series';

import { getSeoForReview } from '@/services/seo-utils';

import ArticleCard from '@/components/ArticleCard/ArticleCard';
import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import ReviewCard from '@/components/ReviewCard/ReviewCard';

const Review = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const reviewObjectId = params?.reviewObjectId;
  const serieObjectId = params?.serieObjectId;
  const albumObjectId = params?.albumObjectId;

  const reviewsMap = useSelector((state) => state.reviews.reviewsMap);
  const series = useSelector((state) => state.series.series);
  const albums = useSelector((state) => state.albums.albums);
  const highlightedArticleObjectIds = useSelector(
    (state) => state.articles.highlightedArticleObjectIds,
  );
  const articlesMap = useSelector((state) => state.articles.articlesMapByObjectId);
  const articles = Object.values(articlesMap);

  useEffect(() => {
    if (!highlightedArticleObjectIds?.length) {
      dispatch(loadArticles());
      dispatch(loadArticles({ options: { highlighted: 1 } }));
    }
    if (reviewObjectId) {
      dispatch(loadReview({ reviewObjectId }));
    }
    if (serieObjectId) {
      dispatch(loadSerie({ serieObjectId }));
    }
    if (albumObjectId) {
      dispatch(loadAlbum({ albumObjectId }));
    }
  }, [reviewObjectId, serieObjectId, albumObjectId, highlightedArticleObjectIds?.length]);

  const review = reviewsMap[reviewObjectId];
  const serie = series[serieObjectId];
  const album = albums[albumObjectId];

  const newsArticles = highlightedArticleObjectIds.map(
    (articleObjectId) => articlesMap[articleObjectId],
  );
  const isSerieReviewType = !!serie?.objectId;

  const relatedArticles =
    articles &&
    articles
      .filter((article) => !~highlightedArticleObjectIds.indexOf(article.objectId))
      .slice(0, 2);

  return (
    <div className="bb-background-light-grey">
      {!!review?.objectId && <BubbleHelmet seo={getSeoForReview(review, serie, album)} />}
      <div className="container">
        <div className="pt-4 d-flex align-items-center">
          {!!serie ? (
            <Link to={`/${serie?.permalink}/serie/${serie?.objectId}/avis`}>Retour aux avis</Link>
          ) : (
            <Link to={`/${album?.permalink}/album/${album?.objectId}/avis`}>Retour aux avis</Link>
          )}
        </div>

        <div className="row py-5">
          <div className="col-lg-8">
            <div className="bb-medium-large-text-size bb-text-500 d-md-flex">
              <div>L'avis de: {review?.username} </div>
            </div>
            <div className="mt-3" key={review?.objectId}>
              <ReviewCard
                reviewType={isSerieReviewType ? 'serie' : 'album'}
                serieObjectId={serie?.objectId}
                review={{ ...review, reviewer: review?.username }}
                removeLinkToOpenReview={true}
              />
            </div>
          </div>
          <div className="col-lg-4">
            {!!relatedArticles?.length && (
              <div className="mb-4">
                <div className="d-block d-md-flex bb-medium-large-text-size bb-text-500 ">
                  <div>
                    Suivez toute l'actualité de {album?.title || 'cette série'} sur notre blog
                  </div>
                </div>
                {relatedArticles.map((article) => (
                  <ArticleCard className="pt-3" key={article.objectId} article={article} />
                ))}
              </div>
            )}
            {!!newsArticles?.length && (
              <div>
                <div className="d-block d-md-flex bb-medium-large-text-size bb-text-500 ">
                  <div>Actualités</div>
                </div>
                {newsArticles.map((article) => (
                  <ArticleHorizontalCardWhite
                    className="pt-3"
                    key={article.objectId}
                    article={article}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
