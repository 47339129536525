import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  deleteItemTagApi,
  deleteTagApi,
  loadTagApi,
  loadTagsApi,
  loadUserTagsApi,
  updateItemTagApi,
  updateTagApi,
} from '../services/api';

import { getClientToken } from '../selectors';
import { loadAlbum } from './albums';

const LOAD_TAG = 'tags/loadTag';
const UPDATE_TAG = 'tags/updateTag';
const DELETE_TAG = 'tags/deleteTag'; // only used in BO
const LOAD_TAGS = 'tags/loadTags';
const LOAD_USER_TAGS = 'tags/loadUserTags';
const UPDATE_ITEM_TAG = 'tags/updateItemTag'; // only used in BO
const DELETE_ITEM_TAG = 'tags/deleteItemTag'; // only used in BO

const initialState = {
  // this only stores the user tags and bdcat tags, as album tags each have specific values like "weight"
  // user tags are stored with the itemTagObjectId as key
  userTags: {},
  tags: {},
  loading: {},
  errors: {},
};

export const loadTag = createAsyncThunk(LOAD_TAG, async (params, { getState }) => {
  const tagObjectId = params?.tagObjectId || null;

  const clientToken = getClientToken(getState());
  const tag = await loadTagApi(clientToken, tagObjectId);
  return { tag };
});

export const updateTag = createAsyncThunk(UPDATE_TAG, async (params, { getState }) => {
  const tagObjectId = params?.tagObjectId || null;
  const tag = params?.tag || null;

  const clientToken = getClientToken(getState());
  const updateTag = await updateTagApi(clientToken, tagObjectId, tag);
  return { tag: updateTag };
});

export const deleteTag = createAsyncThunk(DELETE_TAG, async (params, { getState }) => {
  const tagObjectId = params?.tagObjectId || null;

  const clientToken = getClientToken(getState());
  await deleteTagApi(clientToken, tagObjectId);
  return { tagObjectId };
});

export const loadTags = createAsyncThunk(LOAD_TAGS, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const tags = await loadTagsApi(clientToken);
  return { tags };
});

export const loadUserTags = createAsyncThunk(LOAD_USER_TAGS, async (params, { getState }) => {
  const userObjectId = params?.userObjectId || null;

  const clientToken = getClientToken(getState());
  const userTags = await loadUserTagsApi(clientToken, userObjectId);
  return { userTags };
});

export const updateItemTag = createAsyncThunk(UPDATE_ITEM_TAG, async (params, { getState }) => {
  const itemTagObjectId = params?.itemTagObjectId || null;
  const itemTag = params?.itemTag || null;

  const clientToken = getClientToken(getState());
  await updateItemTagApi(clientToken, itemTagObjectId, itemTag);
});

export const deleteItemTag = createAsyncThunk(
  DELETE_ITEM_TAG,
  async (params, { getState, dispatch }) => {
    const itemTagObjectId = params?.itemTagObjectId || null;
    const albumObjectId = params?.albumObjectId || null;

    const clientToken = getClientToken(getState());
    await deleteItemTagApi(clientToken, itemTagObjectId);
    if (albumObjectId) {
      dispatch(loadAlbum({ albumObjectId }));
    }
  },
);

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadTag
      .addCase(loadTag.pending, (state, action) => {
        state.loading[LOAD_TAG] = true;
        state.errors[LOAD_TAG] = null;
      })
      .addCase(loadTag.fulfilled, (state, action) => {
        const tags = { ...state.tags.tags };
        tags[action.payload.tag?.objectId] = action.payload.tag;
        state.tags = tags;

        state.loading[LOAD_TAG] = false;
        state.errors[LOAD_TAG] = null;
      })
      .addCase(loadTag.rejected, (state, action) => {
        state.loading[LOAD_TAG] = false;
        state.errors[LOAD_TAG] = action.error;
      })
      // updateTag
      .addCase(updateTag.pending, (state, action) => {
        state.loading[UPDATE_TAG] = true;
        state.errors[UPDATE_TAG] = null;
      })
      .addCase(updateTag.fulfilled, (state, action) => {
        const tags = { ...state.tags };
        tags[action.payload.tag?.objectId] = action.payload.tag;
        state.tags = tags;

        state.loading[UPDATE_TAG] = false;
        state.errors[UPDATE_TAG] = null;
      })
      .addCase(updateTag.rejected, (state, action) => {
        state.loading[UPDATE_TAG] = false;
        state.errors[UPDATE_TAG] = action.error;
      })
      // deleteTag
      .addCase(deleteTag.pending, (state, action) => {
        state.loading[DELETE_TAG] = true;
        state.errors[DELETE_TAG] = null;
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        const tags = { ...state.tags };
        delete tags[action.payload.tagObjectId];
        state.tags = tags;

        state.loading[DELETE_TAG] = false;
        state.errors[DELETE_TAG] = null;
      })
      .addCase(deleteTag.rejected, (state, action) => {
        state.loading[DELETE_TAG] = false;
        state.errors[DELETE_TAG] = action.error;
      })
      // loadTags
      .addCase(loadTags.pending, (state, action) => {
        state.loading[LOAD_TAGS] = true;
        state.errors[LOAD_TAGS] = null;
      })
      .addCase(loadTags.fulfilled, (state, action) => {
        state.tags = action.payload.tags.reduce((acc, cur) => ((acc[cur.objectId] = cur), acc), {});

        state.loading[LOAD_TAGS] = false;
        state.errors[LOAD_TAGS] = null;
      })
      .addCase(loadTags.rejected, (state, action) => {
        state.loading[LOAD_TAGS] = false;
        state.errors[LOAD_TAGS] = action.error;
      })
      // loadUserTags
      .addCase(loadUserTags.pending, (state, action) => {
        state.loading[LOAD_USER_TAGS] = true;
        state.errors[LOAD_USER_TAGS] = null;
      })
      .addCase(loadUserTags.fulfilled, (state, action) => {
        const userTags = {};
        action.payload.userTags.forEach((tag) => {
          userTags[tag.itemTagObjectId] = tag;
        });
        state.userTags = userTags;

        state.loading[LOAD_USER_TAGS] = false;
        state.errors[LOAD_USER_TAGS] = null;
      })
      .addCase(loadUserTags.rejected, (state, action) => {
        state.loading[LOAD_USER_TAGS] = false;
        state.errors[LOAD_USER_TAGS] = action.error;
      })
      // updateItemTag
      .addCase(updateItemTag.pending, (state, action) => {
        state.loading[UPDATE_ITEM_TAG] = true;
        state.errors[UPDATE_ITEM_TAG] = null;
      })
      .addCase(updateItemTag.fulfilled, (state, action) => {
        state.loading[UPDATE_ITEM_TAG] = false;
        state.errors[UPDATE_ITEM_TAG] = null;
      })
      .addCase(updateItemTag.rejected, (state, action) => {
        state.loading[UPDATE_ITEM_TAG] = false;
        state.errors[UPDATE_ITEM_TAG] = action.error;
      })
      // deleteItemTag
      .addCase(deleteItemTag.pending, (state, action) => {
        state.loading[DELETE_ITEM_TAG] = true;
        state.errors[DELETE_ITEM_TAG] = null;
      })
      .addCase(deleteItemTag.fulfilled, (state, action) => {
        state.loading[DELETE_ITEM_TAG] = false;
        state.errors[DELETE_ITEM_TAG] = null;
      })
      .addCase(deleteItemTag.rejected, (state, action) => {
        state.loading[DELETE_ITEM_TAG] = false;
        state.errors[DELETE_ITEM_TAG] = action.error;
      });
  },
});

export default tagsSlice.reducer;
