import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAuthor } from 'bubble-reducers/src/selectors';

import Tooltip from '@/components/Tooltip/Tooltip';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

import AuthorSubscribeBadge from './AuthorSubscribeBadge';

const defautObject = {};

const RelatedAuthorCard = ({ authorObjectId, relatedAuthorObjectId }) => {
  const author = useSelector((state) => getAuthor(state, relatedAuthorObjectId)) || defautObject;
  const relatedSeriesObjectIds = useSelector(
    (state) =>
      state.authors.relatedAuthorsRelatedSeriesObjectIdsMap[authorObjectId]?.[
        relatedAuthorObjectId
      ],
  );

  return (
    <Link
      to={`/${author?.permalink}/author/${author?.objectId}`}
      className="no-decoration text-black d-flex nart-shadow shadow-when-hovered flex-fill"
    >
      <div className="rounded bg-white d-flex flex-fill p-2">
        <img
          alt={author.displayName || 'auteur'}
          className="rounded author-related-image me-2"
          src={author?.images?.front?.medium || placeholderProfileJpg}
        />
        <div className="d-flex flex-fill flex-column justify-content-between">
          <div className="bb-medium-text-size fw-bold pt-2">{author.displayName || 'Auteur'}</div>
          <div className="text-secondary d-flex align-items-end justify-content-between">
            <Tooltip tip="Séries en commun">
              <span className="link-underline">
                {relatedSeriesObjectIds.length} série{relatedSeriesObjectIds.length > 1 && 's'}
              </span>
            </Tooltip>
            <div className="font-normal">
              <AuthorSubscribeBadge authorObjectId={relatedAuthorObjectId} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default RelatedAuthorCard;
