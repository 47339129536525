import React from 'react';

import { useSelector } from 'react-redux';

import { getRecoAlbumIds } from 'bubble-reducers/src/selectors';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import GiftCardAlbumCard from '@/components/AlbumCard/variants/GiftCardAlbumCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

const CartRecommendations = ({ max = 1 }) => {
  const recommendedAlbumIds = useSelector((state) => getRecoAlbumIds(state, max));

  return (
    <TitledListContainer
      title="D'autres albums qui pourraient vous plaire :"
      list={recommendedAlbumIds
        .map((id) => (
          <AlbumCard className="col-md-4 col-6" key={'recommended_' + id} albumObjectId={id} />
        ))
        // anthologie
        .concat(
          <AlbumCard
            className="col-md-4 col-6"
            key="recoantho"
            print={{
              objectId: 'dzBZD684IR',
              ean: '9782956991502',
              printDate: '01/11/2020',
              images: {
                front: {
                  original: 'https://assets.bubblebd.com/img/6dhbwbmny6/irwbtocbd2.jpg',
                  large: 'https://assets.bubblebd.com/img/6dhbwbmny6/j50ejsoipi.jpg',
                  medium: 'https://assets.bubblebd.com/img/6dhbwbmny6/k5as2tm269.jpg',
                  mediumFramedGradient: 'https://assets.bubblebd.com/img/6dhbwbmny6/b7o8oirgca.jpg',
                  smallFramed: 'https://assets.bubblebd.com/img/6dhbwbmny6/xoc9h8hy4c.jpg',
                },
              },
              hasSexualContent: false,
              publisher: 'Bubble éditions',

              sellingInfo: {
                price: '25.00',
                online: {
                  numberOfSellers: 1,
                  estimatedDeliveryDate: '2020-11-19T00:00:00.000Z',
                  dueShippingDate: '2020-11-16T00:00:00.000Z',
                  numberOfDaysBeforeShipping: 22,
                  numberOfDaysToDelivery: 25,
                  availability: {
                    message: 'Précommande (sortie le 16 Novembre)',
                    code: 103,
                    color: '#92D148',
                  },
                },
                clickAndCollect: {
                  numberOfSellers: 0,
                  estimatedDeliveryDate: null,
                  dueShippingDate: null,
                  numberOfDaysBeforeShipping: null,
                  numberOfDaysToDelivery: null,
                  availability: {
                    message: '0 librairie',
                    code: 102,
                    color: '#ea0717',
                  },
                },
              },
            }}
            album={{
              objectId: 'sIDi1qNcvfX9t5',
              permalink: 'anthologie-du-9eme-art-tome-1-anthologie-du-9eme-art',
              ean: '9782956991502',
              title: 'Anthologie du 9ème art',
              tome: 1,
              note: 5,
              numberOfNotes: 11,
              publisher: 'Bubble éditions',
              authors: [
                {
                  objectId: 'LFj4guuuYj',
                  permalink: 'thomas-mourier',
                  firstName: 'Thomas',
                  lastName: 'Mourier',
                  fullName: 'Thomas Mourier',
                  pseudo: null,
                  otherNames: null,
                  yearOfBirth: '1984',
                  yearOfDeath: null,
                  websiteUrl: null,
                  facebookUrl: null,
                  twitterUsername: null,
                  instagramUsername: null,
                  biography: null,
                },
              ],
              serie: {
                objectId: 'Gj4kz4BL62',
                title: 'Anthologie du 9ème art',
                note: null,
                category: 'BD',
                permalink: 'anthologie-du-9eme-art',
              },
              editorChoice: {
                objectId: 'HvwQoDDl6w',
                category: 'bd',
                section: {
                  permalink: 'incontournables',
                },
                title:
                  'Bubble éditions publie son Anthologie du 9ème art : 150 oeuvres cultes BD, comics & mangas',
                imageUrl:
                  'https://i1.wp.com/bubblediscovery.wpcomstaging.com/wp-content/uploads/2020/10/Cover-Paper@1x.jpg?fit=1350%2C675&ssl=1',
                permalink:
                  'bubble-editions-publie-son-anthologie-du-9eme-art-150-oeuvres-cultes-bd-comics-mangas',
              },
            }}
          />,
        )
        .concat(<GiftCardAlbumCard className="col-md-4 col-6" key={'recogift'} />)}
    />
  );
};

export default CartRecommendations;
