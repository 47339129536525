export const createObjectFromPaths = (paths, values = {}) => {
  const result = {};
  (paths || []).forEach((path) => {
    const parts = path.key.split('.');
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }
    target[parts[0]] = path.type && path.type === 'static' ? path.value : values[path.value];
  });
  return result;
};
