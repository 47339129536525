import React, { useCallback, useState } from 'react';

const WebpWrapper = (props) => {
  const [forcedDefault, _setForcedDefault] = useState(null);

  const switchToDefault = useCallback(
    () => _setForcedDefault(props.default),
    [_setForcedDefault, props.default],
  );

  return (
    <picture>
      <source type="image/webp" srcSet={forcedDefault || props.webp} />
      <img
        onError={switchToDefault}
        className={props.className || ''}
        src={props.default}
        alt={props.alt}
        style={props.style || null}
      />
    </picture>
  );
};

export default WebpWrapper;
