import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Layout from '@/scenes/Layout/Layout';

import Article from './scenes/NArt/scenes/Article/Article';
import NArtHome from './scenes/NArt/scenes/NArtHome/NArtHome';
import NArtListing from './scenes/NArt/scenes/NArtListing/NArtListing';
import NArtSearch from './scenes/NArt/scenes/NArtSearch/NArtSearch';

export default function AppNart() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<NArtHome />} />
        <Route path="/bd" element={<NArtHome nArtCategory={'bd'} />} />
        <Route path="/comics" element={<NArtHome nArtCategory={'comics'} />} />
        <Route path="/mangas" element={<NArtHome nArtCategory={'mangas'} />} />
        <Route path="/jeunesse" element={<NArtHome nArtCategory={'jeunesse'} />} />
        <Route path="/webtoon" element={<NArtHome nArtCategory={'webtoon'} />} />
        <Route path="/articles" element={<NArtListing />} />
        <Route path="/bd/:nArtSection" element={<NArtListing category={'bd'} />} />
        <Route path="/comics/:nArtSection" element={<NArtListing category={'comics'} />} />
        <Route path="/mangas/:nArtSection" element={<NArtListing category={'mangas'} />} />
        <Route path="/jeunesse/:nArtSection" element={<NArtListing category={'jeunesse'} />} />
        <Route path="/webtoon/:nArtSection" element={<NArtListing category={'webtoon'} />} />
        <Route path="/actualites" element={<NArtListing section={'actualites'} />} />
        <Route path="/critiques" element={<NArtListing section={'critiques'} />} />
        <Route path="/incontournables" element={<NArtListing section={'incontournables'} />} />
        <Route path="/edito" element={<NArtListing section={'edito'} />} />
        <Route path="/en-video" element={<NArtListing section={'en-video'} />} />
        <Route path="/tag/:tag" element={<NArtListing />} />
        <Route path="/recherche" element={<NArtSearch />} />
        <Route path="/:nArtCategory/:nArtSection/:permalink/*" element={<Article />} />
      </Routes>
    </Layout>
  );
}
