import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { createOrderApi, loadOrderApi, updateOrderApi } from '../services/api';

import { getClientToken } from '../selectors';

export const LOAD_ORDER = 'orders/loadOrder';
export const CREATE_ORDER = 'orders/createOrder';
export const UPDATE_ORDER = 'orders/updateOrder';

const initialState = {
  order: null,
  loading: {},
  errors: {},
};

export const loadOrder = createAsyncThunk(LOAD_ORDER, async (params, { getState }) => {
  const orderObjectId = params?.orderObjectId || null;

  const clientToken = getClientToken(getState());
  const order = await loadOrderApi(clientToken, orderObjectId);
  return { order };
});

export const createOrder = createAsyncThunk(CREATE_ORDER, async (params, { getState }) => {
  const order = params?.order || null;

  const clientToken = getClientToken(getState());
  const createdOrder = await createOrderApi(clientToken, order);
  return { order: createdOrder };
});

export const updateOrder = createAsyncThunk(UPDATE_ORDER, async (params, { getState }) => {
  const orderObjectId = params?.orderObjectId || null;
  const order = params?.order || null;
  const options = params?.options || null;

  const clientToken = getClientToken(getState());
  const updatedOrder = await updateOrderApi(clientToken, orderObjectId, order);

  return { order: updatedOrder };
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetOrdersReducer: {
      reducer: (state, action) => initialState,
    },
  },
  extraReducers: (builder) => {
    builder
      // loadOrder
      .addCase(loadOrder.pending, (state, action) => {
        state.loading[LOAD_ORDER] = true;
        state.errors[LOAD_ORDER] = null;
      })
      .addCase(loadOrder.fulfilled, (state, action) => {
        state.order = action.payload.order;
        state.loading[LOAD_ORDER] = false;
        state.errors[LOAD_ORDER] = null;
      })
      .addCase(loadOrder.rejected, (state, action) => {
        state.loading[LOAD_ORDER] = false;
        state.errors[LOAD_ORDER] = action.error;
      })
      // createOrder
      .addCase(createOrder.pending, (state, action) => {
        state.order = null;
        state.loading[CREATE_ORDER] = true;
        state.errors[CREATE_ORDER] = null;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.order = action.payload.order;
        state.loading[CREATE_ORDER] = false;
        state.errors[CREATE_ORDER] = null;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading[CREATE_ORDER] = false;
        state.errors[CREATE_ORDER] = action.error;
      })
      // updateOrder
      .addCase(updateOrder.pending, (state, action) => {
        state.loading[UPDATE_ORDER] = action.meta.arg?.options?.shouldNotDisplayLoading
          ? false
          : true;
        state.errors[UPDATE_ORDER] = null;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.order = action.payload.order;
        state.loading[UPDATE_ORDER] = false;
        state.errors[UPDATE_ORDER] = null;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.loading[UPDATE_ORDER] = false;
        state.errors[UPDATE_ORDER] = action.error;
      });
  },
});

export default ordersSlice.reducer;

export const { resetOrdersReducer } = ordersSlice.actions;
