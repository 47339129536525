import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { CSSTransition } from 'react-transition-group';

import { Icon } from '@/components/Icon/Icon';

import './BubbleCheckbox.css';

const BubbleCheckbox = (props) => {
  const nodeRef = useRef(null);
  return (
    <label
      className={`checkbox-container mb-0 d-flex align-items-center ${
        props.className ? props.className : ''
      }`}
    >
      <div
        type="checkbox"
        className="bb-checkbox d-flex align-items-center justify-content-center"
        style={Object.assign(
          {
            background: props.checked ? '#F5FBEF' : 'white',
            borderColor: props.checked ? '#7aca22' : '#e2e2e2',
          },
          props.style,
        )}
      >
        <CSSTransition
          nodeRef={nodeRef}
          in={props.checked}
          unmountOnExit
          timeout={100}
          classNames="basic-fade"
        >
          <Icon
            innerRef={nodeRef}
            className="text-success"
            style={{ fontSize: ((props.style || {}).width || 18) / 1.8 }}
            name="check"
          />
        </CSSTransition>
      </div>
      {!!props.label && (
        <span
          className={`ms-2 transition-short form-check-label ${
            props.checked && !props.doNotColorLabel ? 'text-success' : ''
          }`}
        >
          {props.label}
        </span>
      )}
      {!props.disabled && <input type="checkbox" {...props} className="hidden-checkbox" />}
    </label>
  );
};

BubbleCheckbox.propTypes = {
  checked: PropTypes.bool,
  doNotColorLabel: PropTypes.bool,
  label: PropTypes.any,
};

export default BubbleCheckbox;
