import React from 'react';

import { useSelector } from 'react-redux';

import KeySellingClickAndCollect from '@/components/KeySellingClickAndCollect/KeySellingClickAndCollect';

import bubbleUtils from 'bubble-utils';

const StoreDetails = (props) => {
  const storeObjectId = props.storeObjectId;
  const store = useSelector((state) => state.stores.stores[storeObjectId]) || {};

  const isClosed = store.isClosed;
  const isPartnered = store.isClickAndCollectSeller;

  const renderStoreInfos = () => {
    const embedQuery = (store.name + ' ' + store.line1).replace('/ /g', '+');

    return (
      <div className="d-flex justify-content-center py-5">
        <div className="row g-0 flex-fill bb-background-light-grey p-2">
          <div className="col-md-6 ">
            <div className="p-5 bg-white">
              <table>
                <tbody>
                  <tr>
                    <td className="pe-4 align-top">
                      <h2 className="mb-2 bb-medium-text-size fw-bold">Adresse&nbsp;:</h2>
                    </td>
                    <td className="pb-4">
                      <div>
                        <div>{store.name}</div>
                        <div>{store.line1}</div>
                        <div>
                          {store.zipcode} {store.city}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-top">
                      <h2 className="mb-2 bb-medium-text-size fw-bold">Horaires&nbsp;:</h2>
                    </td>
                    <td className="pb-4">
                      <div>{store.schedule}</div>
                    </td>
                  </tr>

                  {!!store.phone && (
                    <tr>
                      <td className="align-top">
                        <h2 className="mb-2 bb-medium-text-size fw-bold">Téléphone&nbsp;:</h2>
                      </td>
                      <td className="pb-4">
                        <div>{store.phone}</div>
                      </td>
                    </tr>
                  )}

                  {!!store.websiteUrl && bubbleUtils.string.isString(store.websiteUrl) && (
                    <tr>
                      <td className="align-top">
                        <h2 className="bb-medium-text-size fw-bold">Site web&nbsp;:</h2>
                      </td>
                      <td className="pb-1">
                        <div>
                          <a target="_blank" rel="noopener noreferrer" href={store.websiteUrl}>
                            {store.websiteUrl}
                          </a>
                        </div>
                      </td>
                    </tr>
                  )}

                  {!!store.facebookUrl && bubbleUtils.string.isString(store.facebookUrl) && (
                    <tr>
                      <td className="align-top">
                        <h2 className="bb-medium-text-size fw-bold">Facebook&nbsp;:</h2>
                      </td>
                      <td className="pb-1">
                        <div>
                          <a target="_blank" rel="noopener noreferrer" href={store.facebookUrl}>
                            {store.facebookUrl}
                          </a>
                        </div>
                      </td>
                    </tr>
                  )}

                  {!!store.twitterUsername &&
                    bubbleUtils.validity.isValidUsername(store.twitterUsername) && (
                      <tr>
                        <td className="align-top">
                          <h2 className="bb-medium-text-size fw-bold">Twitter&nbsp;:</h2>
                        </td>
                        <td className="pb-1">
                          <div>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                'https://www.twitter.com/' + store.twitterUsername.replace('@', '')
                              }
                            >
                              {store.twitterUsername}
                            </a>
                          </div>
                        </td>
                      </tr>
                    )}

                  {!!store.instagramUsername &&
                    bubbleUtils.validity.isValidUsername(store.instagramUsername) && (
                      <tr>
                        <td className="align-top">
                          <h2 className="bb-medium-text-size fw-bold">Instagram&nbsp;:</h2>
                        </td>
                        <td className="pb-1">
                          <div>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                'https://www.instagram.com/' +
                                store.instagramUsername.replace('@', '')
                              }
                            >
                              {store.instagramUsername}
                            </a>
                          </div>
                        </td>
                      </tr>
                    )}

                  {!!store.pagesJaunesUrl && bubbleUtils.string.isString(store.pagesJaunesUrl) ? (
                    <tr>
                      <td className="align-top">
                        <h2 className="bb-medium-text-size fw-bold">Pages jaunes&nbsp;:</h2>
                      </td>
                      <td className="pb-1">
                        <div>
                          <a target="_blank" rel="noopener noreferrer" href={store.pagesJaunesUrl}>
                            {store.pagesJaunesUrl}
                          </a>
                        </div>
                      </td>
                    </tr>
                  ) : null}

                  {!!store.mangaNewsUrl && bubbleUtils.string.isString(store.mangaNewsUrl) ? (
                    <tr>
                      <td className="align-top">
                        <h2 className="bb-medium-text-size fw-bold">Manga News : </h2>
                      </td>
                      <td className="pb-1">
                        <div>
                          <a target="_blank" rel="noopener noreferrer" href={store.mangaNewsUrl}>
                            {store.mangaNewsUrl}
                          </a>
                        </div>
                      </td>
                    </tr>
                  ) : null}

                  {!!store.canalBdUrl && bubbleUtils.string.isString(store.canalBdUrl) ? (
                    <tr>
                      <td className="align-top">
                        <h2 className="bb-medium-text-size fw-bold">Canal BD : </h2>
                      </td>
                      <td className="pb-1">
                        <div>
                          <a target="_blank" rel="noopener noreferrer" href={store.canalBdUrl}>
                            {store.canalBdUrl}
                          </a>
                        </div>
                      </td>
                    </tr>
                  ) : null}

                  {!!store.linkedInUrl && bubbleUtils.string.isString(store.linkedInUrl) ? (
                    <tr>
                      <td className="align-top">
                        <h2 className="bb-medium-text-size fw-bold">LinkedIn : </h2>
                      </td>
                      <td className="pb-1">
                        <div>
                          <a target="_blank" rel="noopener noreferrer" href={store.linkedInUrl}>
                            {store.linkedInUrl}
                          </a>
                        </div>
                      </td>
                    </tr>
                  ) : null}

                  {!!store.bedeFrUrl && bubbleUtils.string.isString(store.bedeFrUrl) ? (
                    <tr>
                      <td className="align-top">
                        <h2 className="bb-medium-text-size fw-bold">Bede.fr : </h2>
                      </td>
                      <td className="pb-1">
                        <div>
                          <a target="_blank" rel="noopener noreferrer" href={store.bedeFrUrl}>
                            {store.bedeFrUrl}
                          </a>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              {!isClosed && isPartnered && (
                <div className="mt-3">
                  <h2 className="mb-2 bb-medium-text-size fw-bold">Services&nbsp;:</h2>
                  <div>
                    <div className="fw-bold">
                      <span className="text-success">✔</span> Réservation en ligne
                    </div>
                  </div>
                  <div>
                    <div className="fw-bold">
                      <span className="text-success">✔</span> Retrait gratuit sous 2h
                    </div>
                  </div>
                </div>
              )}
              {isClosed && (
                <div className="pt-3 fw-bold text-danger bb-l-text-size">
                  Cette librairie est temporairement fermée
                </div>
              )}
              {!isClosed && !isPartnered && (
                <div className="mt-2 text-danger">
                  <span className="me-2" role="img" aria-label="x">
                    ❌
                  </span>
                  Cette librairie n'est pas partenaire Bubble,{' '}
                  <span>
                    <button
                      className="btn btn-link"
                      onClick={() =>
                        window.Intercom(
                          'showNewMessage',
                          `Bonjour ! J'aimerais que ma librairie ${store.name} à ${store.city} soit partenaire Bubble, merci :)`,
                        )
                      }
                    >
                      Cliquez ici pour nous envoyer une demande
                    </button>
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6">{store.name && renderMap(embedQuery)}</div>
        </div>
      </div>
    );
  };

  const renderMap = (query) => {
    return (
      <iframe
        className="border-0 position-relative w-100 h-100"
        title="storeMap"
        src={`https://www.google.com/maps/embed/v1/place?q=${query}&key=AIzaSyAMJAldSRmNbgXPwlCceYD23ShY2HclS2k`}
        allowFullScreen
      />
    );
  };

  return (
    <div className="container">
      <div className="px-sm-3">
        {isClosed && (
          <div className="pt-5 fw-bold text-danger bb-l-text-size text-center">
            Cette librairie est temporairement fermée
          </div>
        )}
        {!isClosed && !isPartnered && (
          <div className="pt-5 fw-bold text-danger bb-l-text-size text-center">
            Cette librairie n'est pas partenaire Bubble
          </div>
        )}
        <div className="pt-4">
          <h2>{store.title || store.name}</h2>
          {store.description && <p className="pt-3 wrap-on-return">{store.description}</p>}
        </div>
        <div className="col-md-10 offset-md-1">{renderStoreInfos()}</div>
        {!isClosed && isPartnered && (
          <div className="pb-4">
            <KeySellingClickAndCollect />
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreDetails;
