import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { LOAD_AGENDAS, loadAgendas } from 'bubble-reducers/src/reducers/agendas';
import { getAgendaAlbums } from 'bubble-reducers/src/selectors';

import { getSeoForAgenda } from '@/services/seo-utils';

import AgendaSections from '@/components/AgendaSections/AgendaSections';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';

import AgendaFilter from './components/AgendaFilter/AgendaFilter';

import './Agenda.scss';

const Agenda = () => {
  const dispatch = useDispatch();

  const agendaAlbums = useSelector((state) => getAgendaAlbums(state));
  const agendaLoading = useSelector((state) => state.agendas.loading[LOAD_AGENDAS]);

  useEffect(() => {
    dispatch(loadAgendas());
  }, []);

  // remove sexual content to please google SEO otherwise it will see us as a sex website
  const albums = agendaAlbums.filter((album) => !album.hasSexualContent);

  return (
    <div className="bb-background-light-grey">
      {!!albums && <BubbleHelmet seo={getSeoForAgenda(albums)} />}
      <div alt="placeholder" className="top-image-container">
        <div className="container">
          <div className="px-sm-3">
            <div className="row">
              <div className="col-12">
                <h1 className="d-flex justify-content-center flex-column py-5">
                  <div className="d-flex justify-content-center">
                    <span className="badge bg-bubble-color badge-padding bb-medium-text-size px-2 fw-normal">
                      Agenda des sorties
                    </span>
                  </div>
                  <div className="text-center bb-xxl-text-size fw-bold agenda-title-line-height pt-4">
                    BD, Comics et Mangas
                    <br />à paraître prochainement
                  </div>
                </h1>
              </div>
            </div>

            <AgendaFilter list={albums}>
              {(sections) => <AgendaSections sections={sections} agendaLoading={agendaLoading} />}
            </AgendaFilter>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
