import color from './src/color';
import date from './src/date';
import event from './src/event';
import general from './src/general';
import keycodes from './src/keycodes';
import link from './src/link';
import text from './src/text';

export const GENERAL = general;
export const DATE = date;
export const EVENT = event;
export const COLOR = color;
export const LINK = link;
export const TEXT = text;
export const KEYCODES = keycodes;
