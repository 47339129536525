import React from 'react';

import { Link } from 'react-router-dom';

import iconShipping from '@/assets/footer/icon_expedition.svg';
import iconSecure from '@/assets/footer/icon_lock.svg';
import iconPacking from '@/assets/footer/icon_packing.svg';
import iconReturns from '@/assets/footer/icon_returns.svg';
import iconStore from '@/assets/footer/icon_shop.svg';
import iconTruck from '@/assets/footer/icon_truck.svg';

import KeySellingPointCell from './components/KeySellingPointCell';

import { LINK } from 'bubble-constants';

import './KeySellingPointsBanner.css';

const KeySellingPointsBanner = () => {
  return (
    <div className="ksp-background col-12 py-2 pt-3">
      <div className="d-flex overflow-auto justify-content-between py-3 px-3 px-lg-5">
        <Link
          to={'/9emeart/bd/edito/bubble-expedie-vos-albums-emballage-avec-ou-sans-bulle'}
          className="text-black no-decoration"
        >
          <KeySellingPointCell img={iconPacking} line1="Emballage super soigné" />
        </Link>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={LINK.FAQ_BUY_ONLINE_URL}
          className="text-black no-decoration"
        >
          <KeySellingPointCell img={iconSecure} line1="Paiement 100% sécurisé" />
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={LINK.FAQ_DELIVERY_DELAY}
          className="text-black no-decoration"
        >
          <KeySellingPointCell img={iconShipping} line1="Expédition sous 24h" />
        </a>

        <Link to={'/aide-ecommerce/frais-de-port'} className="text-black no-decoration">
          <KeySellingPointCell img={iconTruck} line1="Livraison à domicile sous 48h" />
        </Link>

        <Link to={'/cartes-des-librairies-partenaires'} className="text-black no-decoration">
          <KeySellingPointCell img={iconStore} line1="Retrait gratuit en librairie" />
        </Link>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={LINK.FAQ_ORDER_RETURN}
          className="text-black no-decoration"
        >
          <KeySellingPointCell img={iconReturns} line1="14 jours pour échanger" />
        </a>
      </div>
    </div>
  );
};

export default KeySellingPointsBanner;
