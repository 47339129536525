export default {
  FAQ_PRESENTATION_FOR_STORES_URL:
    'https://bubblebd.slite.page/p/_~fXnhKV3A/FAQ-des-librairies-partenaires',

  APP_STORE_LINK: 'https://apps.apple.com/app/bubble-bd-comics-manga/id1006844761',
  PLAY_STORE_LINK: 'https://play.google.com/store/apps/details?id=com.heappi.android.Bubble',

  PRIVACY_BUBBLE_URL: 'https://www.bubblebd.com/protection-de-vos-donnees-personnelles',
  PRIVACY_BUBBLE_CGU: 'https://www.bubblebd.com/conditions-generales-de-vente-ecommerce',
  FAQ_URL: 'https://help.bubblebd.com/',
  IP_STACK: 'http://api.ipstack.com/',
  GOOGLE_MAPS_BASE_URL: 'https://www.google.com/maps/place/',
  PRIVACY_URL: 'https://www.bubblebd.com/protection-de-vos-donnees-personnelles',

  // FAQ links
  FAQ_BUY_ONLINE_URL: 'https://help.bubblebd.com/collections/247048-acheter-avec-bubble',
  FAQ_ALBUM_ADDITION: 'https://help.bubblebd.com/fr/articles/1771894-ajouter-un-album-manquant',
  FAQ_CATALOG_SIGNALEMENT:
    'https://help.bubblebd.com/articles/1816879-corriger-une-erreur-dans-le-catalogue',
  FAQ_BUY_RESERVATION_URL:
    'https://help.bubblebd.com/articles/2318361-reserver-des-albums-chez-mon-libraire',
  FAQ_BOOKSELLER_PARTNERSHIP:
    'https://help.bubblebd.com/fr/articles/3369473-le-partenariat-avec-les-libraires',
  FAQ_DELIVERY_DELAY: 'https://help.bubblebd.com/fr/articles/3364137-delais-de-livraison',
  FAQ_ORDER_RETURN: 'https://help.bubblebd.com/fr/articles/3281470-retourner-ma-commande',
  FAQ_PROMO_CODE: 'https://help.bubblebd.com/fr/articles/4392823-ou-inserer-un-code-promo',

  // social media links
  FACEBOOK_BUBBLE_URL: 'https://www.facebook.com/BubbleBDcom',
  INSTAGRAM_BUBBLE_URL: 'https://www.instagram.com/bubblebd_',
  TWITTER_BUBBLE_URL: 'https://twitter.com/BubbleBD_',
  LINKEDIN_BUBBLE_URL: 'https://www.linkedin.com/company/heappi-sas',
  WIKIPEDIA_BUBBLE_URL: 'https://fr.wikipedia.org/wiki/Bubble_(BD_Comics_Manga)',

  BUBBLE_GOODIES_SHOP: 'https://bubble-bd.myspreadshop.fr',

  REWARD_PROGRAM_LANDING: 'https://www.bubblebd.com/programme-de-fidelite',

  // Nart social media links
  FACEBOOK_NART_URL: 'https://www.facebook.com/9eArt',
  INSTAGRAM_NART_URL: 'https://www.instagram.com/9emeart/',
  TWITTER_NART_URL: 'https://twitter.com/9emeArt',

  WEBSITE: 'https://www.bubblebd.com',
  MONDIAL_RELAY_RETURN_FR: 'https://retours.mondialrelay.com/d/F2VINGEA/',
  MONDIAL_RELAY_RETURN_BE: 'https://retours.mondialrelay.com/d/F2VINGEA/',
  MONDIAL_RELAY_RETURN_SP: 'https://devoluciones.puntopack.es/d/F2VINGEA/',
};
