import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';

import {
  checkStocksForEansApi,
  createStorePartnershipRequestApi,
  loadStoreApi,
  loadStoreBestSellersApi,
  loadStoresApi,
} from '../services/api';

import { storeAlbums } from '../reducers/albums';
import { storePrints } from '../reducers/prints';
import { getClientToken } from '../selectors';

const LOAD_STORE = 'stores/loadStore';
const LOAD_STORE_BEST_SELLERS = 'stores/loadStoreBestSellers';
const CREATE_STORE_PARTNERSHIP_REQUEST = 'stores/createStorePartnershipRequest';
const LOAD_STORES = 'stores/loadStores';
const CHECK_STOCKS_FOR_EANS = 'stores/checkStocksForEans';

const initialState = {
  stores: {},
  storesBestSellers: {},
  itemsAvailabilitiesPerStores: [],
  favoriteSearchFlag: false,
  availabilitySearchFlag: false,
  reservationsStoresToShow: [],
  loading: {},
  errors: {},
};

export const loadStore = createAsyncThunk(LOAD_STORE, async (params, { getState }) => {
  const storeObjectId = params?.storeObjectId || null;

  const clientToken = getClientToken(getState());
  const store = await loadStoreApi(clientToken, storeObjectId);
  return { store };
});

export const loadStoreBestSellers = createAsyncThunk(
  LOAD_STORE_BEST_SELLERS,
  async (params, { getState, dispatch }) => {
    const storeObjectId = params?.storeObjectId || null;

    const clientToken = getClientToken(getState());
    const albumsResponse = await loadStoreBestSellersApi(clientToken, storeObjectId);

    const { albums, prints } = extractAlbumAndPrints(albumsResponse);

    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));
    return {
      storeObjectId,
      bestSellingAlbumObjectIds: albums.map((album) => ({
        objectId: album.objectId,
        score: album.score,
      })),
    };
  },
);

export const createStorePartnershipRequest = createAsyncThunk(
  CREATE_STORE_PARTNERSHIP_REQUEST,
  async (params, { getState }) => {
    const storeObjectId = params?.storeObjectId || null;
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    await createStorePartnershipRequestApi(clientToken, storeObjectId, userObjectId);
  },
);

export const loadStores = createAsyncThunk(LOAD_STORES, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const stores = await loadStoresApi(clientToken);
  return { stores };
});

export const checkStocksForEans = createAsyncThunk(
  CHECK_STOCKS_FOR_EANS,
  async (params, { getState }) => {
    const storeObjectId = params?.storeObjectId || null;
    const eans = params?.eans || null;

    const clientToken = getClientToken(getState());
    const stocks = await checkStocksForEansApi(clientToken, eans, storeObjectId);
    return { stocks };
  },
);

const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    storeStores: {
      reducer: (state, action) => {
        const stores = action.payload.stores.reduce((acc, cur) => {
          acc[cur.objectId] = cur;
          return acc;
        }, {});
        state.stores = Object.assign({}, state.stores, stores);
      },
    },
    setReservationsStoresToShow: {
      reducer: (state, action) => {
        state.reservationsStoresToShow = action.payload.stores;
      },
    },
    setFavoriteSearchFlag: {
      reducer: (state, action) => {
        state.favoriteSearchFlag = action.payload.favoriteSearchFlag;
      },
    },
    setAvailabilitySearchFlag: {
      reducer: (state, action) => {
        state.availabilitySearchFlag = action.payload.availabilitySearchFlag;
      },
    },
    clearOneClickRelatedInfos: {
      reducer: (state, action) => {
        state.favoriteSearchFlag = initialState.favoriteSearchFlag;
        state.availabilitySearchFlag = initialState.availabilitySearchFlag;
        state.reservationsStoresToShow = initialState.reservationsStoresToShow;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadStore
      .addCase(loadStore.pending, (state, action) => {
        state.loading[LOAD_STORE] = true;
        state.errors[LOAD_STORE] = null;
      })
      .addCase(loadStore.fulfilled, (state, action) => {
        const store = action.payload.store;
        state.stores = { ...state.stores, [store.objectId]: store };

        state.loading[LOAD_STORE] = false;
        state.errors[LOAD_STORE] = null;
      })
      .addCase(loadStore.rejected, (state, action) => {
        state.loading[LOAD_STORE] = false;
        state.errors[LOAD_STORE] = action.error;
      })
      // loadStoreBestSellers
      .addCase(loadStoreBestSellers.pending, (state, action) => {
        state.loading[LOAD_STORE_BEST_SELLERS] = true;
        state.errors[LOAD_STORE_BEST_SELLERS] = null;
      })
      .addCase(loadStoreBestSellers.fulfilled, (state, action) => {
        const storesBestSellers = state.storesBestSellers;
        storesBestSellers[action.payload.storeObjectId] = action.payload.bestSellingAlbumObjectIds;
        state.storesBestSellers = storesBestSellers;

        state.loading[LOAD_STORE_BEST_SELLERS] = false;
        state.errors[LOAD_STORE_BEST_SELLERS] = null;
      })
      .addCase(loadStoreBestSellers.rejected, (state, action) => {
        state.loading[LOAD_STORE_BEST_SELLERS] = false;
        state.errors[LOAD_STORE_BEST_SELLERS] = action.error;
      })
      // createStorePartnershipRequest
      .addCase(createStorePartnershipRequest.pending, (state, action) => {
        state.loading[CREATE_STORE_PARTNERSHIP_REQUEST] = true;
        state.errors[CREATE_STORE_PARTNERSHIP_REQUEST] = null;
      })
      .addCase(createStorePartnershipRequest.fulfilled, (state, action) => {
        state.loading[CREATE_STORE_PARTNERSHIP_REQUEST] = false;
        state.errors[CREATE_STORE_PARTNERSHIP_REQUEST] = null;
      })
      .addCase(createStorePartnershipRequest.rejected, (state, action) => {
        state.loading[CREATE_STORE_PARTNERSHIP_REQUEST] = false;
        state.errors[CREATE_STORE_PARTNERSHIP_REQUEST] = action.error;
      })
      // loadStores
      .addCase(loadStores.pending, (state, action) => {
        state.loading[LOAD_STORES] = true;
        state.errors[LOAD_STORES] = null;
      })
      .addCase(loadStores.fulfilled, (state, action) => {
        const stores = action.payload.stores.reduce((acc, cur) => {
          acc[cur.objectId] = cur;
          return acc;
        }, {});
        state.stores = Object.assign({}, state.stores, stores);

        state.loading[LOAD_STORES] = false;
        state.errors[LOAD_STORES] = null;
      })
      .addCase(loadStores.rejected, (state, action) => {
        state.loading[LOAD_STORES] = false;
        state.errors[LOAD_STORES] = action.error;
      })
      // checkStocksForEans
      .addCase(checkStocksForEans.pending, (state, action) => {
        state.itemsAvailabilitiesPerStores = initialState.itemsAvailabilitiesPerStores;

        state.loading[CHECK_STOCKS_FOR_EANS] = true;
        state.errors[CHECK_STOCKS_FOR_EANS] = null;
      })
      .addCase(checkStocksForEans.fulfilled, (state, action) => {
        let itemsAvailabilitiesPerStores = {};
        action.payload.stocks.map((item) => {
          item.stores.forEach((store) => {
            if (!itemsAvailabilitiesPerStores[store.objectId]) {
              itemsAvailabilitiesPerStores[store.objectId] = {
                objectId: store.objectId,
                isClosed: store.isClosed,
                availableItems: [],
                orderableItems: [],
              };
            }
            if (store.availabilityCode === 100) {
              itemsAvailabilitiesPerStores[store.objectId].availableItems.push(item.ean);
            } else if (store.availabilityCode === 104) {
              itemsAvailabilitiesPerStores[store.objectId].orderableItems.push(item.ean);
            }
          });
        });

        state.itemsAvailabilitiesPerStores = Object.values(itemsAvailabilitiesPerStores);

        state.loading[CHECK_STOCKS_FOR_EANS] = false;
        state.errors[CHECK_STOCKS_FOR_EANS] = null;
      })
      .addCase(checkStocksForEans.rejected, (state, action) => {
        state.loading[CHECK_STOCKS_FOR_EANS] = false;
        state.errors[CHECK_STOCKS_FOR_EANS] = action.error;
      });
  },
});

export default storesSlice.reducer;

export const {
  storeStores,
  setReservationsStoresToShow,
  setFavoriteSearchFlag,
  setAvailabilitySearchFlag,
  clearOneClickRelatedInfos,
} = storesSlice.actions;
