import React from 'react';

import PrivateProfileNotice from './PrivateProfileNotice';

const PrivacyRerouter = (props) => {
  const allowed = props.otherUser
    ? (props.otherUser.profilePrivacyFlags & props.flag) === props.flag
    : true;

  return allowed ? props.route : <PrivateProfileNotice />;
};

export default PrivacyRerouter;
