import React from 'react';

import { Link } from 'react-router-dom';

import './TopHorizontalCard.css';

const TopHorizontalCard = (props) => {
  const topInfo = props.topInfo || {};

  return (
    <Link
      className="no-decoration d-flex flex-fill"
      to={`/${topInfo.permalink}/tops/${topInfo.name}`}
    >
      <div
        className="top-horizontal-card-bg rounded d-flex flex-fill"
        style={{ backgroundImage: `url(${topInfo.image})` }}
      >
        <div className="top-horizontal-card-bg-inside p-4 d-flex flex-fill align-items-center justify-content-center fw-bold bb-medium-large-text-size text-white text-center">
          {topInfo.nameFrench}
        </div>
      </div>
    </Link>
  );
};

export default TopHorizontalCard;
