import React from 'react';

import { useSelector } from 'react-redux';

import { LOAD_RELATED_AUTHORS } from 'bubble-reducers/src/reducers/authors';

import RelatedAuthorCard from '../components/RelatedAuthorCard';

export default function AuthorRelatedAuthorsSection({ authorObjectId }) {
  const relatedAuthorsObjectIds =
    useSelector((state) => state.authors.relatedAuthorsMap[authorObjectId]) || null;

  const isLoading = useSelector((state) => state.authors.loading[LOAD_RELATED_AUTHORS]);

  return (
    <>
      {/* <div style={{ position: 'absolute', top: 25, right: 8 }}>Trier par ... v</div> TODO:Filters */}
      <div className="row">
        {isLoading && (
          <div className="col-12 d-flex justify-content-center pb-5">
            <span className="spinner-border spinner-lg" />
          </div>
        )}
        {relatedAuthorsObjectIds?.map((relatedAuthorObjectId) => (
          <div
            key={`author_related_${relatedAuthorObjectId}`}
            className="col-12 col-md-6 col-lg-4 pb-3"
          >
            <RelatedAuthorCard
              authorObjectId={authorObjectId}
              relatedAuthorObjectId={relatedAuthorObjectId}
            />
          </div>
        ))}
        {!isLoading && !relatedAuthorsObjectIds?.length && (
          <div className="col-12">
            Nous n'avons pas encore trouvé d'autres auteurs{' '}
            <span role="img" aria-label="pray">
              🙏
            </span>
          </div>
        )}
      </div>
    </>
  );
}
