import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import AddTagModal from '@/components/Modals/AddTagModal/AddTagModal';
import RevisionModals from '@/components/Modals/RevisionModals';
import TagSection from '@/components/TagSection/TagSection';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import bubbleUtils from 'bubble-utils';

import './AlbumDetails.scss';

const AlbumDetails = (props) => {
  const album = props.album || {};
  const serie = props.serie || {};
  const print = props.print || {};
  const isUserlogged = useIsUserlogged();

  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [enableRevisionModals, setEnableRevisionModals] = useState(false);

  const onClickRevision = useCallback(() => {
    if (isUserlogged()) {
      setEnableRevisionModals(true);
    }
  }, [isUserlogged, album.objectId, album.serie?.objectId, print.objectId]);

  const openTags = useCallback(() => {
    if (isUserlogged()) {
      handleShowAddTagModal();
    }
  }, [isUserlogged, album.objectId]);

  const handleShowAddTagModal = () => {
    setShowAddTagModal(true);
  };

  return (
    <div className="row px-sm-3 py-5">
      {showAddTagModal && (
        <AddTagModal
          show={showAddTagModal}
          itemObjectId={album.objectId}
          itemType={'albums'}
          callback={() => setShowAddTagModal(false)}
        />
      )}
      {enableRevisionModals && (
        <RevisionModals
          sourcePage="album"
          albumObjectId={album.objectId}
          serieObjectId={album.serie.objectId}
          printObjectId={print.objectId}
          callback={(value) => setEnableRevisionModals(value)}
        />
      )}

      <div className="col-md-6">
        <h2 className="mb-2 bb-medium-text-size fw-bold">Résumé de l'album</h2>
        {!!print.extraInfo && <p className="detail-summary">{print.extraInfo}</p>}
        <p className="detail-summary">{album.summary}</p>
        {(!album.summary || album.summary === 'Pas de description') && (
          <button className="btn btn-link mx-0 px-0" onClick={onClickRevision}>
            Ajouter une description
          </button>
        )}
        <div className="d-flex mb-2 pt-4 align-items-center">
          <h2 className="bb-medium-text-size fw-bold p-0 m-0 pe-3">Thèmes</h2>
          <button className="btn btn-link p-0 m-0" onClick={openTags}>
            + Ajouter
          </button>
        </div>
        <TagSection tags={album.tags} />
      </div>
      <div className="col-md-6 pt-4 pt-md-0">
        <h3 className="mb-2 bb-medium-text-size fw-bold">A propos de cet album</h3>

        <table>
          <tbody>
            <tr>
              <td className="text-muted detail-type">Catégorie</td>
              <td>
                <Link to={`/list?category=${serie.category}`}>{serie.category}</Link>
              </td>
            </tr>
            <tr>
              <td className="text-muted detail-type pt-1">Thèmes</td>
              <td className="d-flex align-items-center flex-wrap">
                {!!album.tags?.length &&
                  (album.tags || [])
                    .map((tag) => (
                      <Link key={`detail_tag_${tag.name}`} to={`/list?genre=${tag.name}`}>
                        {tag.name}
                      </Link>
                    ))
                    .slice(0, 4)
                    .reduce((prev, cur) => {
                      return [
                        prev,
                        <div key={`separator_${prev.key}`} className="px-1">
                          /
                        </div>,
                        cur,
                      ];
                    })}
                {!!album.tags?.length && <div className="px-1">/</div>}
                <WithClickHandler onClick={openTags} className={`btn btn-link p-0 m-0`}>
                  Plus (...)
                </WithClickHandler>
              </td>
            </tr>
            {print.type && (
              <tr>
                <td className="text-muted detail-type pt-1">Type</td>
                <td>{print.type}</td>
              </tr>
            )}
            <tr>
              <td className="text-muted detail-type pt-1">Collection</td>
              <td>{print.collection || '-'}</td>
            </tr>
          </tbody>
        </table>

        <h3 className="pt-2 mb-2 bb-medium-text-size fw-bold">Caractéristiques</h3>
        <table>
          <tbody>
            <tr>
              {print.authors && <td className="text-muted detail-type">Auteurs</td>}
              <td>
                {print.authors?.map((author) => (
                  <Link
                    key={author.objectId}
                    className="me-1"
                    to={`/${author.permalink}/author/${author.objectId}?a=${album.objectId}`}
                  >
                    {author.displayName}
                  </Link>
                ))}
              </td>
            </tr>
            <tr>
              <td className="text-muted detail-type pt-1">Editeur</td>
              <td>{print.publisher || '-'}</td>
            </tr>
            <tr>
              <td className="text-muted detail-type pt-1">Tome</td>
              <td>
                {album.tome || 0}/{serie.numberOfAlbums}
              </td>
            </tr>
            <tr>
              <td className="text-muted detail-type pt-1">Date de parution</td>
              <td>{bubbleUtils.date.formatDateWithFormat(print.publicationDate) || '-'}</td>
            </tr>
            <tr>
              <td className="text-muted detail-type pt-1">ISBN/EAN</td>
              <td>{print.ean || '-'}</td>
            </tr>
            <tr>
              <td className="text-muted detail-type pt-1">Nombre de pages</td>
              <td>{print.numberOfPages || '-'}</td>
            </tr>
            <tr>
              <td className="text-muted detail-type pt-1">Poids</td>
              <td>{Number(print.weight) * 1000 ? Number(print.weight) * 1000 + ' gr' : '-'}</td>
            </tr>
            <tr>
              <td className="text-muted detail-type pt-1">Format</td>
              <td>
                {print.width || print.length || print.height
                  ? [print.width || '-', print.length || '-', print.height || '-'].join(' x ') +
                    ' cm'
                  : '-'}
              </td>
            </tr>
          </tbody>
        </table>

        <button className="mt-4 btn btn-link mx-0 px-0" onClick={onClickRevision}>
          Signaler une erreur sur l'album
        </button>
      </div>
    </div>
  );
};

AlbumDetails.propTypes = {
  album: PropTypes.object,
  serie: PropTypes.object,
  print: PropTypes.object,
};

export default AlbumDetails;
