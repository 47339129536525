import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  DELETE_COMMENT,
  EDITING_COMMENT,
  UPDATE_COMMENT,
  abortEditComment,
  deleteComment,
  editComment,
  flagComment,
  updateComment,
} from 'bubble-reducers/src/reducers/comments';

import { formatDateWithFormat } from 'bubble-utils/src/date-utils';

import LikeZone from '@/components/ReviewCard/component/LikeZone';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

import LinkButton from '../LinkButton/LinkButton';

import './Comment.css';

function formatDate(dateString) {
  const options = {
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
  };
  return formatDateWithFormat(dateString, options, 'fr-FR');
}

function displayDate(comment) {
  const { creationDate, updateDate } = comment;
  const displayCreationDate = formatDate(new Date(creationDate));
  let displayUpdateDate = '';
  if (creationDate !== updateDate) {
    displayUpdateDate = `/ Modifié le ${formatDate(new Date(updateDate))}`;
  }
  return (
    <p className="text-muted">
      <small>
        Posté le {displayCreationDate} {displayUpdateDate}
      </small>
    </p>
  );
}

const Comment = (props) => {
  const dispatch = useDispatch();

  const comment = props.comment;
  const commentsBeingMutated = useSelector((state) => state.comments.commentsBeingMutated);
  const user = useSelector((state) => state.user.user);
  const userLikes = useSelector((state) => state.user.likes);

  const [editedComment, setEditedComment] = useState({ ...comment });

  const commentObjectId = comment?.objectId;

  const onClickFlag = () => {
    dispatch(flagComment({ commentObjectId }));
  };

  const onClickDelete = () => {
    dispatch(deleteComment({ commentObjectId }));
  };

  const onClickEdit = () => {
    dispatch(editComment({ commentObjectId: comment.objectId }));
  };

  const onClickUpdate = () => {
    dispatch(updateComment({ commentObjectId, comment: editedComment }));
  };

  const handleChange = (e) => {
    setEditedComment({ ...updateComment, content: e.target.value });
  };

  const resetEditedComment = () => {
    setEditedComment({ ...updateComment, content: comment.content });
    dispatch(abortEditComment({ commentObjectId: comment.objectId }));
  };

  const isLogged = user ? Boolean(user.objectId) : false;
  const userCommentsLikes = userLikes ? userLikes.comments : [];
  const { content, objectId } = comment;
  const isLiked = userCommentsLikes ? userCommentsLikes.includes(objectId) : false;
  const isAdmin = user && user.roles && user.roles.includes('admin');
  const isAuthor =
    comment && comment.userObjectId && user && user.objectId
      ? comment.userObjectId === user.objectId
      : false;
  const canModify = isAuthor;
  const canDelete = isAuthor || isAdmin;
  const commentStatus = commentsBeingMutated ? commentsBeingMutated[comment.objectId] : null;
  const isBeingEdited = commentStatus === EDITING_COMMENT;
  const isBeingDeleted = commentStatus === DELETE_COMMENT;
  const isBeingUpdated = commentStatus === UPDATE_COMMENT;
  const avatar = comment.avatar ? comment.avatar : placeholderProfileJpg;

  return (
    <div data-testid="comment">
      <div className="row py-4 border-top">
        <div className="col Comment-avatar-div">
          <img
            className={`${avatar ? '' : 'bg-white'} comment-avatar-img rounded-circle `}
            alt="user"
            src={avatar}
          />
        </div>
        <div className="col">
          <h5 className="truncate">{comment.displayName}</h5>
          {displayDate(comment)}
        </div>
      </div>
      <div className="row comment-shift">
        <div className="col">
          {isBeingEdited ? (
            <div className="form-group">
              <textarea
                className="form-control"
                id="editableComment"
                rows="3"
                value={editedComment.content}
                onChange={handleChange}
              ></textarea>
            </div>
          ) : (
            <p className="Comment-content">{content}</p>
          )}
        </div>
      </div>
      <div className="row py-3 comment-shift">
        <div className="col-auto">
          <span className="inlineLikeZone">
            <LikeZone
              comment={comment}
              commentObjectId={comment.objectId}
              isCommentLiked={isLiked}
            />
          </span>
        </div>
        {canModify && !isBeingEdited && isLogged ? (
          <div data-testid="CommentModify" className="col-auto">
            <LinkButton action={onClickEdit}>Modifier</LinkButton>
          </div>
        ) : null}
        {canDelete && !isBeingEdited && isLogged ? (
          <div
            data-testid="CommentDelete"
            className={`col-auto ${isAdmin && !isAuthor ? 'ms-auto' : ''}`}
          >
            <LinkButton busy={isBeingDeleted} action={onClickDelete}>
              Supprimer
            </LinkButton>
          </div>
        ) : null}
        {isBeingEdited && isLogged ? (
          <>
            <div className="col-auto">
              <LinkButton busy={isBeingUpdated} action={onClickUpdate}>
                Sauvegarder
              </LinkButton>
            </div>
            <div className="col-auto">
              <LinkButton action={resetEditedComment}>Annuler</LinkButton>
            </div>
          </>
        ) : null}
        {!isAuthor && isLogged && (
          <div data-testid="CommentFlag" className="col-auto">
            <LinkButton action={onClickFlag}>Signaler</LinkButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
