import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  deleteCommentApi,
  flagCommentApi,
  loadArticleCommentsApi,
  storeCommentApi,
  updateCommentApi,
} from '../services/api';

import { getClientToken } from '../selectors';

export const LOAD_ARTICLE_COMMENTS = 'comments/loadArticleComments';
const STORE_COMMENT = 'comments/storeComment';
export const DELETE_COMMENT = 'comments/deleteComment';
export const UPDATE_COMMENT = 'comments/updateComment';
const FLAG_COMMENT = 'comments/flagComment';

export const EDITING_COMMENT = 'editing';

const initialState = {
  creatingComment: false,
  comments: [],
  commentsBeingMutated: {},
  loading: {},
  errors: {},
};

export const loadArticleComments = createAsyncThunk(
  LOAD_ARTICLE_COMMENTS,
  async (params, { getState }) => {
    const articleObjectId = params?.articleObjectId || null;

    const clientToken = getClientToken(getState());
    const comments = await loadArticleCommentsApi(clientToken, articleObjectId);
    return { comments };
  },
);

export const storeComment = createAsyncThunk(
  STORE_COMMENT,
  async (params, { getState, dispatch }) => {
    const articleObjectId = params?.articleObjectId || null;
    const comment = params?.comment || null;
    const userObjectId = params?.userObjectId || null;
    const user = params?.user || null;

    if (user) {
      dispatch(editUser({ userObjectId, user }));
    }

    const clientToken = getClientToken(getState());
    const createdComment = await storeCommentApi(clientToken, articleObjectId, comment);
    return { comment: createdComment };
  },
);

export const deleteComment = createAsyncThunk(DELETE_COMMENT, async (params, { getState }) => {
  const commentObjectId = params?.commentObjectId || null;

  const clientToken = getClientToken(getState());
  await deleteCommentApi(clientToken, commentObjectId);
  return { commentObjectId };
});

export const updateComment = createAsyncThunk(UPDATE_COMMENT, async (params, { getState }) => {
  const commentObjectId = params?.commentObjectId || null;
  const comment = params?.comment || null;

  const clientToken = getClientToken(getState());
  const updatedComment = await updateCommentApi(clientToken, commentObjectId, comment);
  return { comment };
});

export const flagComment = createAsyncThunk(FLAG_COMMENT, async (params, { getState }) => {
  const commentObjectId = params?.commentObjectId || null;

  const clientToken = getClientToken(getState());
  const flagcount = await flagCommentApi(clientToken, commentObjectId);
  return { flagcount };
});

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    editComment: {
      reducer: (state, action) => {
        const commentsBeingMutated = Object.assign({}, state.commentsBeingMutated);
        const commentObjectId = action.payload.commentObjectId;
        commentsBeingMutated[commentObjectId] = EDITING_COMMENT;
        state.commentsBeingMutated = commentsBeingMutated;
      },
    },
    abortEditComment: {
      reducer: (state, action) => {
        const commentsBeingMutated = Object.assign({}, state.commentsBeingMutated);
        const commentObjectId = action.payload.commentObjectId;
        delete commentsBeingMutated[commentObjectId];
        state.commentsBeingMutated = commentsBeingMutated;
      },
    },
    likeComment: {
      reducer: (state, action) => {
        const comments = Object.assign({}, state.comments);
        const { commentObjectId } = action.payload;
        comments[commentObjectId].likes++;
        state.comments = comments;
      },
    },
    unlikeComment: {
      reducer: (state, action) => {
        const comments = Object.assign({}, state.comments);
        const { commentObjectId } = action.payload;
        comments[commentObjectId].likes--;
        state.comments = comments;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadArticleComments
      .addCase(loadArticleComments.pending, (state, action) => {
        state.loading[LOAD_ARTICLE_COMMENTS] = true;
        state.errors[LOAD_ARTICLE_COMMENTS] = null;
      })
      .addCase(loadArticleComments.fulfilled, (state, action) => {
        const comments = {};
        action.payload.comments.forEach((comment) => (comments[comment.objectId] = comment));
        state.comments = comments;
        state.loading[LOAD_ARTICLE_COMMENTS] = false;
        state.errors[LOAD_ARTICLE_COMMENTS] = null;
      })
      .addCase(loadArticleComments.rejected, (state, action) => {
        state.loading[LOAD_ARTICLE_COMMENTS] = false;
        state.errors[LOAD_ARTICLE_COMMENTS] = action.error;
      })
      // storeComment
      .addCase(storeComment.pending, (state, action) => {
        state.creatingComment = true;
        state.loading[STORE_COMMENT] = true;
        state.errors[STORE_COMMENT] = null;
      })
      .addCase(storeComment.fulfilled, (state, action) => {
        const commentData = action.payload.comment;
        const comment = { [commentData.objectId]: commentData };
        const comments = Object.assign({}, comment, state.comments);
        state.creatingComment = false;
        state.comments = comments;
        state.loading[STORE_COMMENT] = false;
        state.errors[STORE_COMMENT] = null;
      })
      .addCase(storeComment.rejected, (state, action) => {
        state.creatingComment = false;
        state.loading[STORE_COMMENT] = false;
        state.errors[STORE_COMMENT] = action.error;
      })
      // deleteComment
      .addCase(deleteComment.pending, (state, action) => {
        const { commentObjectId } = action.meta.arg;
        const commentsBeingMutated = {
          [commentObjectId]: DELETE_COMMENT,
          ...state.commentsBeingMutated,
        };
        state.commentsBeingMutated = commentsBeingMutated;
        state.loading[DELETE_COMMENT] = true;
        state.errors[DELETE_COMMENT] = null;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        const commentObjectId = action.payload.commentObjectId;
        const comments = Object.assign({}, state.comments);
        delete comments[commentObjectId];
        const commentsBeingMutated = Object.assign({}, state.commentsBeingMutated);
        delete commentsBeingMutated[commentObjectId];
        state.commentsBeingMutated = commentsBeingMutated;
        state.comments = comments;
        state.loading[DELETE_COMMENT] = false;
        state.errors[DELETE_COMMENT] = null;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.loading[DELETE_COMMENT] = false;
        state.errors[DELETE_COMMENT] = action.error;
      })
      // updateComment
      .addCase(updateComment.pending, (state, action) => {
        const { commentObjectId } = action.meta.arg;
        const commentsBeingMutated = {
          ...state.commentsBeingMutated,
          [commentObjectId]: UPDATE_COMMENT,
        };
        state.commentsBeingMutated = commentsBeingMutated;
        state.loading[UPDATE_COMMENT] = true;
        state.errors[UPDATE_COMMENT] = null;
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        const commentData = action.payload.comment;
        const comment = { [commentData.objectId]: commentData };
        const comments = Object.assign({}, state.comments, comment);
        state.comments = comments;
        state.loading[UPDATE_COMMENT] = false;
        state.errors[UPDATE_COMMENT] = null;
      })
      .addCase(updateComment.rejected, (state, action) => {
        state.loading[UPDATE_COMMENT] = false;
        state.errors[UPDATE_COMMENT] = action.error;
      })
      // flagComment
      .addCase(flagComment.pending, (state, action) => {
        state.loading[FLAG_COMMENT] = true;
        state.errors[FLAG_COMMENT] = null;
      })
      .addCase(flagComment.fulfilled, (state, action) => {
        const flagcount = action.payload.flagcount;
        const comments = Object.assign({}, state.comments);
        comments[flagcount.commentObjectId] = flagcount;
        state.comments = comments;
        state.commentsBeingMutated = {};
        state.loading[FLAG_COMMENT] = false;
        state.errors[FLAG_COMMENT] = null;
      })
      .addCase(flagComment.rejected, (state, action) => {
        state.loading[FLAG_COMMENT] = false;
        state.errors[FLAG_COMMENT] = action.error;
      });
  },
});

export default commentsSlice.reducer;

export const { editComment, abortEditComment, likeComment, unlikeComment } = commentsSlice.actions;
