import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadCollectionsApi } from '../services/api';

import { getClientToken } from '../selectors';

const LOAD_COLLECTIONS = 'collections/loadCollections';

const initialState = {
  collections: [],
  loading: {},
  errors: {},
};

export const loadCollections = createAsyncThunk(LOAD_COLLECTIONS, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const collections = await loadCollectionsApi(clientToken);
  return { collections };
});

const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadCollections
      .addCase(loadCollections.pending, (state, action) => {
        state.collections = initialState.collections;
        state.loading[LOAD_COLLECTIONS] = true;
        state.errors[LOAD_COLLECTIONS] = null;
      })
      .addCase(loadCollections.fulfilled, (state, action) => {
        state.collections = action.payload.collections;
        state.loading[LOAD_COLLECTIONS] = false;
        state.errors[LOAD_COLLECTIONS] = null;
      })
      .addCase(loadCollections.rejected, (state, action) => {
        state.loading[LOAD_COLLECTIONS] = false;
        state.errors[LOAD_COLLECTIONS] = action.error;
      });
  },
});

export default collectionsSlice.reducer;
