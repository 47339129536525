import React, { useCallback, useEffect, useState } from 'react';

import { PROJECT } from '@/project-constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { loadAlbum } from 'bubble-reducers/src/reducers/albums';
import { loadSerie } from 'bubble-reducers/src/reducers/series';
import { getAuthor } from 'bubble-reducers/src/selectors';

import { compressDates, getAuthorAllNames } from 'bubble-utils/src/author-utils';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import Breadcrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import AuthorRevisionModal from '@/components/Modals/AuthorRevisionModal';
import ShareZone from '@/components/ShareZone/ShareZone';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

import AuthorSocials from '../AuthorSocials';
import AuthorSubscriberSection from '../AuthorSubscribeSection';
import AuthorHeaderTagsSection from './components/AuthorHeaderTagsSection';

const defautObject = {};

export default function AuthorHeader({ authorObjectId }) {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const location = useLocation();
  const author = useSelector((state) => getAuthor(state, authorObjectId)) || defautObject;
  const urlParams = new URLSearchParams(location.search);
  const previousObjectIdAlbum = urlParams.get('a') || '' || null;
  const previousObjectIdSerie = urlParams.get('s') || '' || null;
  const previousAlbum = useSelector((state) => state.albums.albums[previousObjectIdAlbum]) || null;
  const previousSerie = useSelector((state) => state.series.series[previousObjectIdSerie]) || null;

  const [showAuthorRevisionModal, setShowAuthorRevisionModal] = useState(false);

  useEffect(() => {
    if (previousObjectIdAlbum) dispatch(loadAlbum({ previousObjectIdAlbum }));
    if (previousObjectIdSerie) dispatch(loadSerie({ serieObjectId: previousObjectIdSerie }));
  }, [dispatch, previousObjectIdAlbum, previousObjectIdSerie]);

  const onClickRevision = useCallback(() => {
    if (isUserlogged()) {
      handleShowAuthorRevisionModal();
    }
  }, [isUserlogged, authorObjectId]);

  const handleShowAuthorRevisionModal = () => {
    setShowAuthorRevisionModal(true);
  };

  const datesString = compressDates(author);
  return (
    <div className="nart-background-grey">
      {showAuthorRevisionModal && (
        <AuthorRevisionModal
          show={showAuthorRevisionModal}
          authorObjectId={authorObjectId}
          callback={() => setShowAuthorRevisionModal(false)}
        />
      )}
      <div className="container">
        <div className="d-flex align-items-center">
          <Breadcrumbs previousObject={previousAlbum || previousSerie} currentObject={author} />
          <ShareZone title={`Faire découvrir ${author.displayName || "l'auteur"} à des amis`} />
        </div>
        <div className="row px-sm-3 pt-3">
          <div className="col-8 offset-2 col-md-2 offset-md-0">
            <img
              className="rounded w-100"
              alt={author.displayName || 'auteur'}
              src={author.images?.front?.medium || placeholderProfileJpg}
            />
            <div className="d-none d-md-block py-3 text-center">
              <button onClick={onClickRevision} className="btn btn-link m-0 p-0">
                Signaler une erreur sur l'auteur
              </button>
            </div>
          </div>
          <div className="col-md-10">
            <div className="row d-flex align-items-center">
              <div className="col-md-9">
                <div className="pt-4 pt-md-0 pb-3 pb-md-2 d-flex align-items-center justify-content-center justify-content-md-start flex-wrap">
                  <h1 className="fw-bold m-0">{getAuthorAllNames(author)}</h1>
                  {!!datesString && <span className="fw-bold ps-2 pt-1">{`(${datesString})`}</span>}
                </div>
                <AuthorSocials author={author} />
              </div>
              <div className="col-md-3 pt-3 pt-md-0 px-5 px-md-0 d-flex flex-fill justify-content-center justify-content-md-end">
                <AuthorSubscriberSection authorObjectId={authorObjectId} />
              </div>
              <div className="col-md-9 wrap-on-return pt-4 pt-md-2 px-4 px-md-2">
                {!!author.biography && <p className="author-social-text">{author.biography}</p>}
              </div>
            </div>
            <div className="row">
              <div className="col-md-9 px-4 px-md-2 d-flex justify-content-center justify-content-md-start flex-wrap">
                <AuthorHeaderTagsSection authorObjectId={authorObjectId} />
              </div>
            </div>
          </div>

          <div className="col-12 text-center d-md-none py-3">
            <button onClick={onClickRevision} className="btn btn-link m-0 p-0">
              Signaler une erreur sur l'auteur
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
