export const formatCurrency = (amount, params = {}) => {
  if (amount === 'Free') return 'Gratuit';
  if (typeof window !== 'undefined' && !('Intl' in window)) {
    return `${Number(amount).toFixed(
      'minimumFractionDigits' in params ? params.minimumFractionDigits : 2,
    )} €`;
  }

  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 'minimumFractionDigits' in params ? params.minimumFractionDigits : 2,
    maximumFractionDigits: 'maximumFractionDigits' in params ? params.maximumFractionDigits : 2,
  }).format(amount);
};
