import React, { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './NArtHeaderLink.scss';

const HeaderLink = ({ children, to, noHighlight, mobile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const selected = location.pathname.includes(to);

  const onClick = useCallback(() => navigate(`${to}`), [navigate, to]);

  return (
    <div className={`nav-item fw-bold nart-header-link pb-2 pb-lg-0`}>
      <WithClickHandler
        onClick={onClick}
        className={`paper-nav-element no-decoration px-4 py-2 ${
          selected && !noHighlight ? `nart-bg-highlight` : ''
        }`}
      >
        {children}
      </WithClickHandler>
    </div>
  );
};

export default HeaderLink;
