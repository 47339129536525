import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import StarLine from '@/components/StarLine/StarLine';
import VerifiedPurchaseBadge from '@/components/VerifiedPurchaseBadge/VerifiedPurchaseBadge';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

import LikeZone from './component/LikeZone';

import utils from 'bubble-utils';

import './ReviewCard.css';

const MAX_WORDS = 50;
const regex =
  /((?:http|https):\/\/(?:[\w_-]+(?:(?:\.[\w_-]+)+))[\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-]?)/g;

const ReviewCard = (props) => {
  const review = props.review || {};
  const comment = review.comment || '';
  const isSerieReviewType = props.reviewType === 'serie';

  let isTruncated = false;
  let totalNumberOfWords = 0;
  const commentWithLinks = comment.split(regex).map((text) => {
    const partNumberOfWords = text.trim().split(' ').length;
    const newTotalNumberOfWords = totalNumberOfWords + partNumberOfWords;
    if (!!props.shouldBeTruncated && newTotalNumberOfWords >= MAX_WORDS) {
      isTruncated = true;
      text = text
        .split(' ')
        .slice(0, MAX_WORDS - totalNumberOfWords)
        .join(' ');
    }
    totalNumberOfWords = newTotalNumberOfWords;
    return text.match(regex) ? (
      <a key={text} href={text} rel="nofollow">
        {text.length > 80 ? `${text.slice(0, 80)}...` : text}{' '}
      </a>
    ) : (
      text
    );
  });

  return (
    <div
      className={`d-flex h-100 w-100 flex-column rounded bg-white p-3 shadow-sm ${
        props.className || ''
      }`}
    >
      <div className="d-flex mb-3 align-items-center">
        <img
          alt={`Profil de ${review.reviewer || review.userName || 'Anonyme'}`}
          className="review-image rounded-circle me-3"
          src={
            review.imageUrl || review.gravatarUrl || review.userImageUrl || placeholderProfileJpg
          }
        />
        <div>
          <div>
            <Link
              className="d-block text-truncate"
              to={`/user/${review.userObjectId}/ma-collection`}
            >
              {review.reviewer || review.userName || 'Anonyme'}
            </Link>
          </div>
          {!isSerieReviewType && <StarLine note={review.note} />}
          {review.reviewerSubtitle && <div>{review.reviewerSubtitle}</div>}
        </div>

        {!isSerieReviewType && review.isVerifiedPurchase && (
          <div className="d-flex justify-content-end ms-3">
            <VerifiedPurchaseBadge />
          </div>
        )}

        <div className="d-flex flex-fill justify-content-end text-secondary text-end">
          <span>
            <span className="ms-1">
              {!props.removeLinkToOpenReview ? (
                <Link className="no-decoration" to={`${props.sourcePath}/avis/${review.objectId}`}>
                  {utils.date.formatDateWithFormat(review.creationDate)}
                </Link>
              ) : (
                <>{utils.date.formatDateWithFormat(review.creationDate)}</>
              )}
            </span>
            <br />
            {review?.ean}
          </span>
        </div>
      </div>
      <p className="m-0 wrap-on-return">
        {commentWithLinks}
        {isTruncated ? (
          <>
            {` (...) `}
            <Link to={`${props.sourcePath}/avis/${review.objectId}`}>Voir le commentaire</Link>
          </>
        ) : (
          ''
        )}
      </p>
      {!('noLike' in props) && comment && (
        <LikeZone
          review={review}
          reviewObjectId={review.objectId}
          serieObjectId={props.serieObjectId}
          albumObjectId={props.albumObjectId}
        />
      )}
    </div>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.shape({
    imageUrl: PropTypes.string,
    date: PropTypes.string,
    note: PropTypes.number,
    reviewer: PropTypes.string,
    reviewerSubtitle: PropTypes.string,
    comment: PropTypes.string,
  }),
};

export default ReviewCard;
