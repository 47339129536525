import React from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';

export default function CheckoutFooter(props) {
  return (
    <footer className="page-footer py-3 font-small bb-background-light-grey">
      <div className="container">
        <div className="d-flex justify-content-between text-center">
          <div className="ms-md-4">
            <Icon className="text-secondary bb-s-text-size" name="chevron-left" />{' '}
            <Link to={'/cart'}>
              Retour <span className="d-none d-md-block">au panier</span>
            </Link>
          </div>
          <div className="flex-fill" />
          <div className="me-2 me-md-3">
            <Link to="/mentions-legales">Mentions légales</Link>
          </div>
          <div className="me-2 me-md-3">
            <Link to="/conditions-generales-de-vente-ecommerce">
              <span className="d-none d-md-block">CGV ecommerce</span>
              <span className="d-block d-md-none">CGV</span>
            </Link>
          </div>
          <div className="me-2 me-md-3">
            <Link to="/conditions-generales-de-vente-et-d-utilisation-marketplace">
              <span className="d-none d-md-block">CGVU marketplace</span>
              <span className="d-block d-md-none">CGVU</span>
            </Link>
          </div>
          <div className="me-2 me-md-3">
            <Link to="/protection-de-vos-donnees-personnelles">
              <span className="d-none d-md-block">Protection de vos données personnelles</span>
              <span className="d-block d-md-none">Données personnelles</span>
            </Link>
          </div>
          <div className="me-2 me-md-3">
            <Link to="/cookies">Cookies</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
