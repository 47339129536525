import { useCallback } from 'react';

import { Link } from 'react-router-dom';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import nArtlogo from '@/assets/9eart/logo_9emeart_new.svg';
import bubbleLogo from '@/assets/9eart/logo_bubble.svg';
import comicsblogLogo from '@/assets/9eart/logo_cb.png';
import comicsblogLogoWebp from '@/assets/9eart/logo_cb.webp';
import iconSocialFacebook from '@/assets/icon-social-facebook-light-gray.svg';
import iconSocialInstragam from '@/assets/icon-social-instagram-light-gray.svg';
import iconSocialTwitter from '@/assets/icon-social-twitter-light-gray.svg';

import { LINK } from 'bubble-constants';

import '../Footer.scss';

const NArtFooter = () => {
  const onClickShowIntercom = useCallback(
    () => (window.Intercom ? window.Intercom('show') : null),
    [],
  );

  return (
    <footer className="pb-6 d-flex align-items-center justify-content-center">
      <div className="nart-container bb-background-light-grey p-paper">
        <div className="row">
          <div className="col-sm-3 col-6 d-flex flex-column align-items-center justify-content-center justify-content-sm-start">
            <img className="nart-logo" src={nArtlogo} alt="9eme art" />

            <div className="mt-3">
              <a target="_blank" rel="noopener noreferrer" href={LINK.FACEBOOK_NART_URL}>
                <img className="social-icon" src={iconSocialFacebook} alt="Facebook social icon" />
              </a>
              <a
                className="ms-2"
                target="_blank"
                rel="noopener noreferrer"
                href={LINK.TWITTER_NART_URL}
              >
                <img className="social-icon" src={iconSocialTwitter} alt="Twitter social icon" />
              </a>
              <a
                className="ms-2"
                target="_blank"
                rel="noopener noreferrer"
                href={LINK.INSTAGRAM_NART_URL}
              >
                <img
                  className="social-icon"
                  src={iconSocialInstragam}
                  alt="Instagram social icon"
                />
              </a>
            </div>
          </div>

          <div className="col-sm-3 col-6 d-flex justify-content-center text-start">
            <div>
              <div className="h5 fw-bold">Explorez 9e Art</div>
              <Link to="/9emeart/bd" className="d-block no-decoration pb-2">
                Bande dessinée
              </Link>
              <Link to="/9emeart/comics" className="d-block no-decoration pb-2">
                Comics
              </Link>
              <Link to="/9emeart/mangas" className="d-block no-decoration pb-2">
                Mangas
              </Link>
              <Link to="/9emeart/jeunesse" className="d-block no-decoration pb-2">
                Jeunesse
              </Link>
              <Link to="/agenda" className="d-block no-decoration">
                Agenda
              </Link>
            </div>
          </div>

          <div className="col-sm-3 col-6 pt-4 pt-sm-0 d-flex justify-content-center text-start">
            <div>
              <div className="h5 fw-bold">A propos de 9e Art</div>
              <Link to="/qui-sommes-nous" className="d-block no-decoration pb-2">
                Qui sommes-nous ?
              </Link>
              <a href="mailto:contact@bubblebd.com" className="d-block no-decoration pb-2">
                Publicité
              </a>
              <div className="pt-2">
                <a
                  href="http://www.comicsblog.fr?utm_source=appbubble&utm_medium=footer"
                  className="d-block"
                >
                  <WebpWrapper
                    className="comicsblog-logo"
                    alt="Logo de Comicsblog"
                    default={comicsblogLogo}
                    webp={comicsblogLogoWebp}
                  />
                </a>
                <Link
                  to="/"
                  className="pt-2 d-flex align-items-center no-decoration text-black fw-bold"
                >
                  <img className="social-icon" src={bubbleLogo} alt="bubble" />
                  <div className="pt-1 ps-1">bubble</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-sm-3 col-6 pt-4 pt-sm-0 d-flex justify-content-center text-start">
            <div>
              <WithClickHandler
                onClick={onClickShowIntercom}
                className="h5 fw-bold d-block no-decoration pb-2 text-black"
              >
                Contactez-nous
              </WithClickHandler>
              <Link
                to="/mentions-legales"
                className="h5 fw-bold d-block no-decoration pb-2 text-black"
              >
                Mentions légales
              </Link>
              <Link
                to="/conditions-generales-de-vente-et-d-utilisation"
                className="h5 fw-bold d-block no-decoration text-black"
              >
                CGU
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default NArtFooter;
