import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadAppOptionsApi, updateAppOptionsApi } from '../services/api';

import { getClientToken } from '../selectors';

export const LOAD_APP_OPTIONS = 'appOptions/loadAppOptions';
export const UPDATE_APP_OPTIONS = 'appOptions/updateAppOptions';

const initialState = {
  appOptions: {
    infinity: {
      valueOfOneMonth: 2.99,
      amountStepPerMonth: 50,
      isInfinityMarchActive: true,
      isInfinityTransitionPeriodActive: false,
    },
  },
  loading: {},
  errors: {},
};

export const loadAppOptions = createAsyncThunk(LOAD_APP_OPTIONS, async (params, { getState }) => {
  const buildNumber = params?.buildNumber || null;

  const clientToken = getClientToken(getState());
  const appOptions = await loadAppOptionsApi(clientToken, buildNumber);
  return { appOptions };
});

export const updateAppOptions = createAsyncThunk(
  UPDATE_APP_OPTIONS,
  async (params, { getState }) => {
    const fieldsAndData = params?.fieldsAndData || null;

    const clientToken = getClientToken(getState());
    const appOptions = await updateAppOptionsApi(clientToken, fieldsAndData);
    return { appOptions };
  },
);

const appOptionsSlice = createSlice({
  name: 'appOptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadAppOptions
      .addCase(loadAppOptions.pending, (state, action) => {
        state.loading[LOAD_APP_OPTIONS] = true;
        state.errors[LOAD_APP_OPTIONS] = null;
      })
      .addCase(loadAppOptions.fulfilled, (state, action) => {
        state.appOptions = action.payload.appOptions;
        state.loading[LOAD_APP_OPTIONS] = false;
        state.errors[LOAD_APP_OPTIONS] = null;
      })
      .addCase(loadAppOptions.rejected, (state, action) => {
        state.loading[LOAD_APP_OPTIONS] = false;
        state.errors[LOAD_APP_OPTIONS] = action.error;
      })
      // updateAppOptions
      .addCase(updateAppOptions.pending, (state, action) => {
        state.loading[UPDATE_APP_OPTIONS] = true;
        state.errors[UPDATE_APP_OPTIONS] = null;
      })
      .addCase(updateAppOptions.fulfilled, (state, action) => {
        state.appOptions = action.payload.appOptions;
        state.loading[UPDATE_APP_OPTIONS] = false;
        state.errors[UPDATE_APP_OPTIONS] = null;
      })
      .addCase(updateAppOptions.rejected, (state, action) => {
        state.loading[UPDATE_APP_OPTIONS] = false;
        state.errors[UPDATE_APP_OPTIONS] = action.error;
      });
  },
});

export default appOptionsSlice.reducer;
