import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { loadXmasSelection } from 'bubble-reducers/src/reducers/albums';
import { loadTop, loadTops } from 'bubble-reducers/src/reducers/tops';

import { getSeoForXmas2024 } from '@/services/seo-utils';

import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import xmasListingMobileJpg from '@/assets/xmas/listing_xmas_banner_mobile.jpg';
import xmasListingMobileWebp from '@/assets/xmas/listing_xmas_banner_mobile.webp';

import LeftSide from './components/LeftSide';
import Christmas2024Home from './scenes/Christmas2024Home';
import Christmas2024Section from './scenes/Christmas2024Section';

import './Christmas2024.scss';

export default function Christmas2024() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTops());
    dispatch(loadTop({ topName: 'topMostAddedAlbums30Days' }));
    dispatch(loadXmasSelection({ options: { tags: 'noel2024,oneshot' } }));
    dispatch(loadXmasSelection({ options: { tags: 'noel2024,jeunesse' } }));
    dispatch(loadXmasSelection({ options: { tags: 'noel2024', types: 'intégrale|coffret' } }));
  }, []);

  return (
    <>
      <BubbleHelmet seo={getSeoForXmas2024()} />
      <div className="bb-background-light-grey">
        <WebpWrapper
          alt="xmas"
          className="d-md-none w-100"
          default={xmasListingMobileJpg}
          webp={xmasListingMobileWebp}
        />
        <div className="container">
          <div className="row py-4">
            <div className="d-none d-lg-block col-md-2">
              <LeftSide />
            </div>
            <div className="col-12 col-lg-10">
              <Routes>
                <Route path="/" element={<Christmas2024Home />} />
                <Route path="/:sectionSlug" element={<Christmas2024Section />} />
                <Route path="/:sectionSlug/:subSectionSlug" element={<Christmas2024Section />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
