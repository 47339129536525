import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAlbumsForChristmasId } from 'bubble-reducers/src/selectors';

import { getDefaultSellingPrint } from 'bubble-utils/src/album-utils';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import sectionsMap from '../sections_map.json';

export default function HomeSection({ id }) {
  const section = sectionsMap[id];
  const subSections = section.subs?.map((subId) => sectionsMap[subId]);
  const albums = useSelector((state) => getAlbumsForChristmasId(state, id)) || [];

  return (
    <div className="py-4">
      <div className="mb-n4">
        <h1 className="h3 fw-bold">{section.title}</h1>
        <div className="d-flex align-items-center">
          <Link
            className="me-md-5 me-3 no-decoration text-primary"
            to={section.link || `/noel/${section.slug}`}
          >
            {section.allTitle}
          </Link>
          {subSections.map((subSection, index) => (
            <Link
              key={index}
              className="me-md-5 me-3 no-decoration text-primary"
              to={subSection.link || `/noel/${section.slug}/${subSection.slug}`}
            >
              {subSection.title}
            </Link>
          ))}
        </div>
      </div>

      <TitledListContainer
        noWrap
        list={
          !!albums.length
            ? albums
                .slice(0, 6)
                .map((album) => (
                  <Fragment key={album.objectId}>
                    {id === 'tops' ? (
                      <AlbumCard
                        className="col-7 col-md-2"
                        album={album}
                        print={getDefaultSellingPrint(album)}
                        serie={album.serie}
                      />
                    ) : (
                      <AlbumCard className="col-7 col-md-2" albumObjectId={album.objectId} />
                    )}
                  </Fragment>
                ))
            : Array(6)
                .fill(1)
                .map((_, i) => (
                  <div className="col-7 col-md-2 pb-5" key={`placeholder${id}${i}`}>
                    <div
                      className="rounded"
                      style={{ width: 151, height: 197, backgroundColor: 'lightgrey' }}
                    />
                  </div>
                ))
        }
      />

      <div className="d-flex align-items-center justify-content-center mt-n5">
        <Link
          to={section.link || `/noel/${section.slug}`}
          className="no-decoration text-primary pt-3 fw-bold"
        >
          Voir toute la sélection
        </Link>
      </div>
    </div>
  );
}
