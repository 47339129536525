import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Modal } from 'react-bootstrap';

import Cover from '@/components/Cover/Cover';
import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './Modals.css';

const ImageBrowsingModal = (props) => {
  const startIndex = props.startIndex || 0;
  const images = props.images || [];

  const [index, setIndex] = useState(startIndex);

  const currentImage = images[index] || null;

  const dismiss = () => {
    setIndex(0);
    props.callback();
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyboardEvent);
    window.onpopstate = function (event) {
      event.preventDefault();
    };
    return () => {
      document.removeEventListener('keyup', handleKeyboardEvent);
      window.onpopstate = () => {};
    };
  }, [index]);

  const handleKeyboardEvent = (event) => {
    if (event.keyCode === 27) {
      dismiss();
    } else if (event.keyCode === 39) {
      nextImage();
    } else if (event.keyCode === 37) {
      previousImage();
    }
  };

  const previousImage = () => {
    const oldIndex = index;
    const newIndex = oldIndex - 1 < 0 ? images.length - 1 : oldIndex - 1;
    setIndex(newIndex);
  };

  const nextImage = () => {
    const oldIndex = index;
    const newIndex = (oldIndex + 1) % images.length;
    setIndex(newIndex);
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bg-transparent border-0"
    >
      <div className="d-flex justify-content-center align-items-center text-black text-center">
        <WithClickHandler onClick={previousImage}>
          <Icon
            style={{ fontSize: 75 }}
            className="flex-fill text-white me-3"
            name="chevron-left"
          />
        </WithClickHandler>
        <Cover fullWidth imageUrl={currentImage || null} />
        <WithClickHandler onClick={nextImage}>
          <Icon
            style={{ fontSize: 75 }}
            className="flex-fill text-white ms-3"
            name="chevron-right"
          />
        </WithClickHandler>
      </div>
    </Modal>
  );
};

ImageBrowsingModal.propTypes = {
  images: PropTypes.array,
};

export default ImageBrowsingModal;
