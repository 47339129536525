import PropTypes from 'prop-types';
import React from 'react';

import { OverlayTrigger, Tooltip as RbTooltip } from 'react-bootstrap';

const Tooltip = (props) => {
  return (
    <OverlayTrigger
      placement={props.position || 'top'}
      overlay={<RbTooltip>{props.tip}</RbTooltip>}
    >
      <span className={props.className || ''}>{props.children}</span>
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  tip: PropTypes.string.isRequired,
};

export default Tooltip;
