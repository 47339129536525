import React from 'react';

import { Link } from 'react-router-dom';

import Cover from '@/components/Cover/Cover';
import StarLine from '@/components/StarLine/StarLine';

import '../AlbumCard.scss';

const GiftCardAlbumCard = (props) => {
  return (
    <div className={`${props.className || ''} album-card`}>
      <div className="rounded bg-white p-3 nart-shadow">
        <Link className="mb-3 no-decoration" to={`/bb-gift-card-80/product/gift-card/w9pdlcpOnl`}>
          <div className="position-relative">
            <Cover
              alt={`cheque cadeau bubble`}
              className="album-card-image"
              imageUrl={'https://static-assets.bubblebd.com/app/gift-cards/cheque_cadeau_small.jpg'}
            />
          </div>
        </Link>
        <div className="d-grid pt-2">
          <div className="fw-bold overflow-hidden" style={{ height: 43 }}>
            Chèques cadeaux
            <div className="text-secondary">20/30/50/80/100 €</div>
          </div>
          <div className="text-truncate text-secondary" style={{ height: 20 }}></div>
          <div className="d-flex justify-content-between pt-1">
            <StarLine short note={5} />
          </div>

          <Link
            to="/bb-gift-card-80/product/gift-card/w9pdlcpOnl"
            className="btn btn-add-to-cart mt-2  no-decoration"
          >
            <div>Voir les offres</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GiftCardAlbumCard;
