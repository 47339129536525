import React from 'react';

import { Icon } from '@/components/Icon/Icon';
import SerieTerminatedBadge from '@/components/SerieTerminatedBadge/SerieTerminatedBadge';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import placeholderJpegUrl from '@/assets/placeholders/placeholder-item.jpg';

import './SerieSelectionCell.scss';

const SerieSelectionCell = (props) => {
  const { serie, onClick, selectedSerieObjectId } = props;
  const isSelected = selectedSerieObjectId === serie.objectId;
  const imageUrl = serie.imageUrl || placeholderJpegUrl;
  return (
    <WithClickHandler onClick={onClick}>
      <div
        className={`d-flex rounded p-3 my-2 align-items-center ${
          (isSelected && 'border border-success selected-cell') || 'bg-white'
        }`}
      >
        <img style={{ height: '5rem' }} alt="revision_album" src={imageUrl} />
        <div className="d-flex flex-column">
          <div className="d-flex col-6 ms-2">
            <SerieTerminatedBadge isTerminated={serie.isTerminated} />
          </div>
          <div className="d-flex flex-column">
            <span className="ps-4 bb-medium-text-size text-black fw-bold">{serie.title || ''}</span>
            <span className="ps-4 bb-small-text-size">{serie.type || ''}</span>
          </div>
        </div>
        <div className="flex-fill"></div>
        {isSelected && <Icon className="ps-3 text-success" name="check" />}
      </div>
    </WithClickHandler>
  );
};

export default SerieSelectionCell;
