import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

const HighlightAuthorCard = (props) => {
  const author = props.author || {};

  const subtitle = [author.pseudo, author.yearOfBirth, author.yearOfDeath]
    .filter((row) => row)
    .join(' - ');

  return (
    <Link className="no-decoration" to={`/${author.permalink}/author/${author.objectId}`}>
      <div className="rounded bb-shadow-hover d-flex align-items-center bb-background-light-grey">
        <div style={{ height: 110 }} className="ms-2 me-3 d-flex align-items-center">
          <img
            src={placeholderProfileJpg}
            alt={author.displayName}
            className="rounded"
            style={{ height: 70 }}
          />
        </div>
        <div className="d-flex flex-fill flex-column">
          <div className="d-flex flex-column align-items-centeroverflow-hidden py-1">
            <div className="bb-medium-text-size bb-text-gray-dark text-truncate">
              {author.displayName}
            </div>
            <div className="font-secondary text-truncate">{subtitle}</div>
          </div>
          <div className="text-primary text-truncate">Découvrir l'auteur</div>
        </div>
      </div>
    </Link>
  );
};

HighlightAuthorCard.propTypes = {
  author: PropTypes.object,
};

export default HighlightAuthorCard;
