import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import {
  isPrintInWishlist as isPrintInWishlistSelector,
  isPrintLoaned as isPrintLoanedSelector,
  isPrintOwned as isPrintOwnedSelector,
  isPrintRead as isPrintReadSelector,
  makeGetAlbumPrints,
} from 'bubble-reducers/src/selectors';

import { isBinaryFlagEnabled } from 'bubble-utils/src/code-utils';

import {
  useAddPrintsToLibrary,
  useAddPrintsToWishlist,
  useRemovePrintsFromLibrary,
  useRemovePrintsFromLoan,
  useRemovePrintsFromWishlist,
  useSetReadFlagOnPrints,
} from '@/services/hooks/useLibraryUtils';
import { useIsUserInfinity, useIsUserlogged } from '@/services/hooks/useUserUtils';
import { getAssetImage } from '@/services/image-utils';

import { Icon } from '@/components/Icon/Icon';
import CollectionBuyAndSellModal from '@/components/Modals/CollectionBuyAndSellModal/CollectionBuyAndSellModal';
import CollectionInfoModal from '@/components/Modals/CollectionInfoModal/CollectionInfoModal';
import InfinityFunctionalityModal from '@/components/Modals/InfinityFunctionalityModal';
import LoanAlbumModal from '@/components/Modals/LoanAlbumModal';
import PrintListModal from '@/components/Modals/PrintListModal';
import Tooltip from '@/components/Tooltip/Tooltip';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import { GENERAL } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

import '../AlbumHeader.scss';

const defaultArray = [];

const CollectionActionsArray = ({ albumObjectId, printObjectId }) => {
  const isUserlogged = useIsUserlogged();
  const isUserInfinity = useIsUserInfinity();
  const addPrintsToWishlist = useAddPrintsToWishlist();
  const removePrintsFromWishlist = useRemovePrintsFromWishlist();
  const setReadFlagOnPrints = useSetReadFlagOnPrints();
  const addPrintsToLibrary = useAddPrintsToLibrary();
  const removePrintsFromLibrary = useRemovePrintsFromLibrary();
  const removePrintsFromLoan = useRemovePrintsFromLoan();

  const album = useSelector((state) => state.albums.albums[albumObjectId]);
  const print = useSelector((state) => state.prints.prints[printObjectId]);

  const user = useSelector((state) => state.user.user);
  const getAlbumPrints = useMemo(() => makeGetAlbumPrints(albumObjectId), [albumObjectId]);
  const albumPrints = useSelector((state) => getAlbumPrints(state, albumObjectId));

  const isPrintOwned = useSelector((state) => isPrintOwnedSelector(state, printObjectId));
  const isPrintRead = useSelector((state) => isPrintReadSelector(state, printObjectId));
  const isPrintLoaned = useSelector((state) => isPrintLoanedSelector(state, printObjectId));
  const isPrintInWishlist = useSelector((state) => isPrintInWishlistSelector(state, printObjectId));
  const albumInLibrary = useSelector((state) => state.profiles.myAlbums[albumObjectId]);
  const printMemo = useSelector((state) => state.profiles.memoPrintsIdsMap[printObjectId]);

  const [showMemo, _setShowMemo] = useState(false);
  const [showBuyAdSellModal, setShowBuyAdSellModal] = useState(false);
  const [showCollectionInfoModal, setShowCollectionInfoModal] = useState(false);
  const [showLaonModal, setShowLaonModal] = useState(false);
  const [showPrintListModal, setShowPrintListModal] = useState(false);
  const [showInfinityFunctionalityModal, setShowInfinityFunctionalityModal] = useState(false);

  const infnityModalFunction = useRef(null);

  const ownedPrints = albumInLibrary?.userPrints
    ? Object.values(albumInLibrary?.userPrints || {}).filter((flags) =>
        isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.OWNED),
      )
    : defaultArray;

  const handleCollectionClick = useCallback(() => {
    if (isPrintOwned) {
      removePrintsFromLibrary(user, [print]);
    } else if (print?.objectId === album?.defaultPrintObjectId) {
      addPrintsToLibrary(user, [print]);
    } else {
      if (isUserlogged()) {
        if (isUserInfinity(user)) {
          addPrintsToLibrary(user, [print]);
        } else {
          infnityModalFunction.current = InfinityFunctionalityModal.types.OWNED;
          setShowInfinityFunctionalityModal(true);
        }
      }
    }
  }, [isUserlogged, user, print, album?.defaultPrintObjectId, isPrintOwned]);

  const sortedPrints = useMemo(
    () =>
      (albumPrints || defaultArray).sort((a, b) => {
        if (
          b.objectId === album.defaultPrintObjectId &&
          a.objectId !== album.defaultPrintObjectId
        ) {
          return 1;
        } else if (b?.sellingInfo?.price && !a?.sellingInfo?.price) {
          return 1;
        } else if (new Date(b.publicationDate) > new Date(a.publicationDate)) {
          return 1;
        } else return -1;
      }),
    [albumPrints, album?.defaultPrintObjectId],
  );

  const handleReadClick = useCallback(() => {
    if (isUserlogged()) {
      if (isUserInfinity(user)) {
        setReadFlagOnPrints(user, [print], !isPrintRead);
      } else {
        if (!isPrintRead === true) {
          infnityModalFunction.current = InfinityFunctionalityModal.types.READ;
          setShowInfinityFunctionalityModal(true);
        }
      }
    }
  }, [user, print, isPrintRead]);

  const handleClickCollectionInfo = useCallback(() => {
    handleShowCollectionInfoModal();
  }, [print?.objectId, album?.objectId]);

  const handleClickBuySellInfo = useCallback(() => {
    handleShowBuyAndSellModal();
  }, [print?.objectId, album?.objectId]);

  const handleLoanClick = useCallback(() => {
    if (isUserlogged()) {
      if (isUserInfinity(user)) {
        !!isPrintLoaned ? removePrintsFromLoan(user.objectId, [print]) : handleShowLaonModal();
      } else {
        console.log('isUserInfinity NOK');
        infnityModalFunction.current = InfinityFunctionalityModal.types.LOAN;
        setShowInfinityFunctionalityModal(true);
      }
    }
  }, [isUserlogged, user, print, isPrintLoaned]);

  const handleWishlistClick = useCallback(() => {
    if (isUserlogged()) {
      isPrintInWishlist
        ? removePrintsFromWishlist(user, [print])
        : addPrintsToWishlist(user, [print]);
    }
  }, [isUserlogged, user, print, isPrintInWishlist]);

  const handleShowBuyAndSellModal = () => {
    setShowBuyAdSellModal(true);
  };
  const handleShowCollectionInfoModal = () => {
    setShowCollectionInfoModal(true);
  };
  const handleShowLaonModal = () => {
    setShowLaonModal(true);
  };
  const handleShowPrintListModal = () => {
    setShowPrintListModal(true);
  };

  const switchShowMemo = useCallback(() => _setShowMemo((old) => !old), [_setShowMemo]);

  return (
    <>
      {showBuyAdSellModal && (
        <CollectionBuyAndSellModal
          show={showBuyAdSellModal}
          albumObjectId={album?.objectId}
          printObjectId={print?.objectId}
          callback={() => setShowBuyAdSellModal(false)}
        />
      )}
      {showCollectionInfoModal && (
        <CollectionInfoModal
          show={showCollectionInfoModal}
          albumObjectId={album?.objectId}
          printObjectId={print?.objectId}
          callback={() => setShowCollectionInfoModal(false)}
        />
      )}
      {showLaonModal && (
        <LoanAlbumModal
          show={showLaonModal}
          albumObjectId={album?.objectId}
          printObjectId={print?.objectId}
          callback={() => setShowLaonModal(false)}
        />
      )}
      {showPrintListModal && (
        <PrintListModal
          show={showPrintListModal}
          prints={sortedPrints}
          album={album}
          currentPrintObjectId={print.objectId}
          callback={() => setShowPrintListModal(false)}
        />
      )}
      {showInfinityFunctionalityModal && (
        <InfinityFunctionalityModal
          show={showInfinityFunctionalityModal}
          infinityFunctionalityType={infnityModalFunction.current}
          callback={() => setShowInfinityFunctionalityModal(false)}
        />
      )}
      <div className="d-flex flex-fill flex-md-column align-items-center justify-content-between justify-content-md-start px-md-2 pt-3 pt-md-0">
        <Tooltip tip={isPrintOwned ? 'Retirer de ma collection' : 'Ajouter à ma collection'}>
          <div className="d-flex flex-column align-items-center">
            <WithClickHandler onClick={handleCollectionClick}>
              <img
                className="mb-2 collection-array-picto"
                alt="add"
                src={
                  isPrintOwned
                    ? getAssetImage('icon_series_green.svg')
                    : getAssetImage('icon_series_grey.svg')
                }
              />
            </WithClickHandler>
            <div className="d-md-none">Collection</div>
          </div>
        </Tooltip>
        <Tooltip tip={!!isPrintInWishlist ? 'Retirer de ma wishlist' : 'Ajouter à ma wishlist'}>
          <div className="d-flex flex-column align-items-center">
            <WithClickHandler onClick={handleWishlistClick}>
              <img
                className="mb-md-4 mb-2 collection-array-picto"
                alt="wishlist"
                src={
                  !!isPrintInWishlist
                    ? getAssetImage('icon_wishlist_on.svg')
                    : getAssetImage('icon_wishlist_off.svg')
                }
              />
            </WithClickHandler>
            <div className="d-md-none">Souhaits</div>
          </div>
        </Tooltip>
        <Tooltip tip={isPrintRead ? 'Marquer comme non lu' : 'Marquer comme lu'}>
          <div className="d-flex flex-column align-items-center">
            <WithClickHandler onClick={handleReadClick}>
              <img
                className="mb-2 collection-array-picto"
                alt="read"
                src={
                  isPrintRead
                    ? getAssetImage('icon_album_read_green.svg')
                    : getAssetImage('icon_album_read_grey.svg')
                }
              />
            </WithClickHandler>
            <div className="d-md-none">Lu</div>
          </div>
        </Tooltip>

        <Tooltip
          tip={
            !!isPrintLoaned
              ? `Prêté à ${isPrintLoaned.name} il y a ${
                  bubbleUtils.date.diffInDays(isPrintLoaned.date) || 0
                } jours`
              : 'Marquer comme Prêté'
          }
        >
          <div className="d-flex flex-column align-items-center">
            <WithClickHandler onClick={handleLoanClick}>
              <img
                className="mb-2 collection-array-picto"
                alt="prêt"
                src={
                  !!isPrintLoaned
                    ? getAssetImage('icon_album_loan_green.svg')
                    : getAssetImage('icon_album_loan_grey.svg')
                }
              />
            </WithClickHandler>
            <div className="d-md-none">Prêt</div>
          </div>
        </Tooltip>

        <Tooltip
          tip={
            ownedPrints.length
              ? `Je possède ${ownedPrints.length} édition${
                  ownedPrints.length > 1 ? 's' : ''
                } de cet album`
              : `Je ne possède aucune édition de cet album`
          }
        >
          <WithClickHandler onClick={handleShowPrintListModal}>
            <div className="d-flex flex-column align-items-center flex-column align-items-center">
              <img
                className="mb-2 collection-array-picto"
                alt="édition"
                src={
                  ownedPrints.length
                    ? getAssetImage('icon_edition_green.svg')
                    : getAssetImage('icon_edition_grey.svg')
                }
              />
              <div className="d-md-none">Editions</div>
            </div>
          </WithClickHandler>
        </Tooltip>

        <div className="d-none d-md-flex">
          <Tooltip tip="Modifier mes informations de collection (EO, tirage de tête, dédicace, ex-libris...)">
            <WithClickHandler onClick={handleClickCollectionInfo}>
              <div className="d-flex flex-column align-items-center flex-column align-items-center">
                <img
                  className="mb-2 collection-array-picto"
                  alt="infos"
                  src={getAssetImage('icon_gestion_avancee.svg')}
                />
              </div>
            </WithClickHandler>
          </Tooltip>
        </div>
        <div className="d-none d-md-flex">
          <Tooltip tip="Modifier mes informations d'achat-vente (état, prix d'achat, cote...)">
            <WithClickHandler onClick={handleClickBuySellInfo}>
              <div className="d-flex flex-column align-items-center flex-column align-items-center">
                <img
                  className="mb-2 collection-array-picto"
                  alt="infos"
                  src={getAssetImage('icon_price.svg')}
                />
              </div>
            </WithClickHandler>
          </Tooltip>
        </div>
      </div>

      <WithClickHandler onClick={handleShowPrintListModal}>
        <div className="d-flex d-md-none bg-white border rounded mt-3 align-items-center px-2 py-3">
          <img
            className="collection-array-picto"
            alt="édition"
            src={
              ownedPrints.length
                ? getAssetImage('icon_edition_green.svg')
                : getAssetImage('icon_edition_grey.svg')
            }
          />
          <div className="ps-3">
            <div className="text-secondary">
              {`${ownedPrints.length} édition${ownedPrints.length > 1 ? 's' : ''} possédée${
                ownedPrints.length > 1 ? 's' : ''
              } / `}
              {albumPrints.length} édition{albumPrints.length > 1 ? 's' : ''} disponibles
            </div>
          </div>
        </div>
      </WithClickHandler>

      <div className="p-2 d-md-none d-flex bg-white border rounded mt-2">
        <WithClickHandler onClick={handleClickCollectionInfo}>
          <div className="d-flex align-items-center">
            <img
              className="collection-array-picto me-3"
              alt="more1"
              src={getAssetImage('icon_gestion_avancee.svg')}
            />
            <div className="text-secondary">
              Modifier mes informations de collection (EO, tirage de tête, dédicace, ex-libris...)
            </div>
            <div className="px-3 ps-4">
              <Icon name="chevron-end" className="text-secondary" />
            </div>
          </div>
        </WithClickHandler>
        <div className="d-flex flex-fill" />
        {printMemo?.length > 30 && (
          <div className="pe-2">
            <WithClickHandler onClick={switchShowMemo}>
              <div className="mt-1">
                <Icon name={`chevron-${showMemo ? 'up' : 'down'}`} className="text-secondary" />
              </div>
            </WithClickHandler>
          </div>
        )}
      </div>
      <div className="p-2 d-md-none d-flex bg-white border rounded mt-2">
        <WithClickHandler onClick={handleClickBuySellInfo}>
          <div className="d-flex align-items-center">
            <img
              className="collection-array-picto me-3"
              alt="more1"
              src={getAssetImage('icon_price.svg')}
            />
            <div className="text-secondary">
              Modifier mes informations d'achat-vente (état, prix d'achat, cote...)
            </div>
            <div className="px-3 ps-4">
              <Icon name="chevron-end" className="text-secondary" />
            </div>
          </div>
        </WithClickHandler>
        <div className="d-flex flex-fill" />
        {printMemo?.length > 30 && (
          <div className="pe-2">
            <WithClickHandler onClick={switchShowMemo}>
              <div className="mt-1">
                <Icon name={`chevron-${showMemo ? 'up' : 'down'}`} className="text-secondary" />
              </div>
            </WithClickHandler>
          </div>
        )}
      </div>
    </>
  );
};

CollectionActionsArray.propTypes = {
  albumObjectId: PropTypes.string,
  printObjectId: PropTypes.string,
};

export default CollectionActionsArray;
