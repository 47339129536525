import {
  addPrintsToLibrary,
  addPrintsToLoan,
  addPrintsToWishlist,
  removePrintsFromLibrary,
  removePrintsFromLoan,
  removePrintsFromWishlist,
  setBuyPriceFlagOnPrints,
  setConditionFlagOnPrints,
  setDeluxeFlagOnPrints,
  setDigitalFlagOnPrints,
  setEoFlagOnPrints,
  setExlibrisFlagOnPrints,
  setForSaleFlagOnPrints,
  setLimitedFlagOnPrints,
  setNumberingFlagOnPrints,
  setReadFlagOnPrints,
  setResellValueFlagOnPrints,
  setSignedFlagOnPrints,
  updateLibrary,
  updateMemoPrints,
} from '../reducers/profiles';

export const addProfilesListeners = (startListening) => {
  startListening({
    actionCreator: setEoFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setReadFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setSignedFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setDeluxeFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setLimitedFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setExlibrisFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setDigitalFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setForSaleFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setNumberingFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setBuyPriceFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setResellValueFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: setConditionFlagOnPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: updateMemoPrints,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: addPrintsToLibrary,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: removePrintsFromLibrary,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: addPrintsToLoan,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: removePrintsFromLoan,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: addPrintsToWishlist,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
  startListening({
    actionCreator: removePrintsFromWishlist,
    effect: async (action, { getState, dispatch }) => {
      dispatch(updateLibrary(action.payload));
    },
  });
};
