import React from 'react';

import { Modal } from 'react-bootstrap';

import './Modals.css';

const ThankYouModal = (props) => {
  const dismiss = () => {
    props.callback();
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="md"
      centered
      contentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        <div className="row pb-2">
          <div className="col py-1">
            <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
              <span className="fw-bold">Merci Pour votre contribution !</span>
            </div>
            <div className="text-secondary text-center">
              Votre signalement va être traité par notre équipe dans les jours à venir, vous en
              serez le premier prévenu !
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center p-4 align-items-center">
        <button onClick={dismiss} className="btn btn-success btn-lg text-white">
          Bien compris !
        </button>
      </div>
    </Modal>
  );
};

export default ThankYouModal;
