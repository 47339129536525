import { useEffect } from 'react';

import config from '@/project-config';
import { useLocation } from 'react-router-dom';

export const useBubbleFacebookPixel = () => {
  const location = useLocation();

  useEffect(() => {
    if (config.facebook.isPixelActive) {
      initialize();
    }
  }, []);

  useEffect(() => {
    sendPageView();
  }, [location]);

  const initialize = () => {
    if (!config.facebook.pixelId) {
      console.error('Pixel ID is required');
      return;
    }

    // Code from Facebook Website install instructions
    // <!-- Facebook Pixel Code -->
    // <script>
    //   !function(f,b,e,v,n,t,s)
    //   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    //   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    //   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    //   n.queue=[];t=b.createElement(e);t.async=!0;
    //   t.src=v;s=b.getElementsByTagName(e)[0];
    //   s.parentNode.insertBefore(t,s)}(window, document,'script',
    //   'https://connect.facebook.net/en_US/fbevents.js');
    //   fbq('init', '638637260220948');
    //   fbq('track', 'PageView');
    // </script>
    // <noscript><img height="1" width="1" style="display:none"
    //   src="https://www.facebook.com/tr?id=638637260220948&ev=PageView&noscript=1"
    // /></noscript>
    // <!-- End Facebook Pixel Code -->

    // Placing the code within <head> tags reduces the chances of browsers or third-party
    // code blocking the pixel's execution.
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName('head')[0];
      s.appendChild(t);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // Initialize Facebook Pixel
    if (config.facebook.options.autoConfig === false) {
      window.fbq('set', 'autoConfig', false, config.facebook.pixelId);
    }
    window.fbq('init', config.facebook.pixelId, {});
  };

  const sendPageView = () => {
    // Do nothing if Facebook Pixel was not initialized due to a missing tracking ID.
    if (!window.fbq) {
      return;
    }

    // Sending the pageview no longer requires passing the page
    // value since it's now stored on the tracker object.
    window.fbq('track', 'PageView');
    if (config.facebook.options.debug) {
      console.log("called fbq('track', 'PageView');");
    }
  };
};
