import React from 'react';

// import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './Root';

// we must stay with the <BrowserRouter> component because of an error with
// the `createBrowserRouter` function in server side rendering
// const router = createBrowserRouter([{ path: '*', element: <Root /> }], {
//   future: {
//     v7_relativeSplatPath: true,
//     v7_startTransition: true,
//     v7_fetcherPersist: true,
//     v7_normalizeFormMethod: true,
//     v7_partialHydration: true,
//     v7_skipActionErrorRevalidation: true,
//   },
// });

const App = () => {
  return <Root />;
  //   return <RouterProvider router={router} />;
};

export default App;
