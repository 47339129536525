import React, { useMemo, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { makeGetSerieAlbums } from 'bubble-reducers/src/selectors';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import AlbumSelectionCell from './components/AlbumSelectionCell';

import './Modals.css';

const DuplicateAlbumModal = (props) => {
  const { serieObjectId, allowGoBack } = props;

  const [selectedAlbumObjectId, setSelectedAlbumObjectId] = useState(null);

  const serie = useSelector((state) => state.series.series[serieObjectId]);
  const getSerieAlbums = useMemo(() => makeGetSerieAlbums(serieObjectId), [serieObjectId]);
  const albums = useSelector((state) => getSerieAlbums(state, serieObjectId));

  const dismiss = () => {
    setSelectedAlbumObjectId(null);
    props.callback();
  };

  const handleGoBack = () => {
    dismiss();
    props.handleGoBack();
  };

  const sendRevision = () => {
    dismiss();
    const album = albums.find((el) => el.objectId === selectedAlbumObjectId);
    window &&
      window.Intercom &&
      window.Intercom(
        'showNewMessage',
        `Série: ${serie.title || ''} ${serie.type || ''}\nAlbum: ${album.title || ''} ${
          album.tome || ''
        } ${album.ean || ''}\nErreur: Cet album est en double, il faudrait le supprimer.`,
      );
  };

  const handleSelectAlbumObjectId = (objectId) => {
    if (selectedAlbumObjectId !== objectId) setSelectedAlbumObjectId(objectId);
    else setSelectedAlbumObjectId(null);
  };

  const renderAlbumsSection = () => {
    return albums.map((album) => (
      <AlbumSelectionCell
        key={album.objectId}
        album={album}
        onClick={() => handleSelectAlbumObjectId(album.objectId)}
        selectedAlbumObjectId={selectedAlbumObjectId}
      />
    ));
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      {(serie && (
        <>
          <div className="modal-header header-no-separator">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <Icon name="chevron-left" />
              </WithClickHandler>
            )}
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={dismiss}
            ></button>
          </div>
          <div className="modal-body mx-sm-3">
            <div className="row">
              <div className="col">
                <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
                  <span>Signalement</span>
                  <span className="fw-bold">{serie.title || ''}</span>
                </div>
                <div className="text-secondary d-flex justify-content-center">
                  Rechercher l'album dont celui-ci est une édition
                </div>
              </div>
            </div>
            <div className="py-2"></div>
            <div className="px-2">{renderAlbumsSection()}</div>

            <div className="h-separator mt-2" />
            <div className="d-flex flex-row justify-content-between p-4 align-items-center">
              {allowGoBack && (
                <WithClickHandler onClick={handleGoBack}>
                  <div className="text-start bb-medium-text-size">
                    <u>Retour</u>
                  </div>
                </WithClickHandler>
              )}
              <div className="text-end">
                <button
                  onClick={selectedAlbumObjectId ? sendRevision : null}
                  className={`btn ${
                    selectedAlbumObjectId ? 'btn-success' : 'btn-secondary'
                  } btn-lg text-white`}
                >
                  Soumettre
                </button>
              </div>
            </div>
          </div>
        </>
      )) ||
        null}
    </Modal>
  );
};

export default DuplicateAlbumModal;
