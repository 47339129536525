import React from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';

import './EditorChoiceBadge.scss';

const EditorChoiceBadge = ({ article }) => (
  <span className="d-flex align-items-center badge text-white bb-editor-choice-badge">
    <Link
      className="text-white no-decoration"
      to={`/9emeart/${article?.category}/${article?.section?.permalink}/${article?.permalink}`}
    >
      Coup de coeur de la rédac <Icon name="chevron-end" />
    </Link>
  </span>
);

export default EditorChoiceBadge;
