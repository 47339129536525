import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { isValidObjectId } from 'bubble-utils/src/validity-utils';

import bubbleUtils from 'bubble-utils';

const ProfileRedirecter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const pathname = location.pathname.split('/');
  const urlUserObjectId = pathname[2];

  if (!bubbleUtils.env.isServer) {
    setTimeout(() => {
      if (user.objectId && urlUserObjectId && !isValidObjectId(urlUserObjectId)) {
        const newPath = `/user/${user.objectId}/${pathname
          .slice(2, pathname.length)
          .join('/')
          .trim()}`;
        navigate(newPath);
      } else if (!user.objectId && !isValidObjectId(urlUserObjectId)) {
        navigate('/login');
      }
    }, 100);
  }

  return null;
};

ProfileRedirecter.propTypes = {
  user: PropTypes.object,
};

export default ProfileRedirecter;
