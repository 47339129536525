import { initEvents, logEvent } from 'bubble-reducers/src/reducers/events';
import { getUserOrGuest } from 'bubble-reducers/src/selectors';

import { isUserInfinity } from 'bubble-utils/src/infinity-utils';

import { addEvent } from '../../services/events';

let isSent = null;
let globalParams = null;

export const addWebsiteEventsListeners = (startListening) => {
  startListening({
    actionCreator: initEvents,
    effect: (action) => {
      isSent = action.payload.isSent;
      globalParams = action.payload.globalParams;
    },
  });
  startListening({
    actionCreator: logEvent,
    effect: (action, { getState }) => {
      if (isSent) {
        const payload = action.payload;
        const user = getUserOrGuest(getState());
        const userParams = {
          userObjectId: user?.objectId || null,
          isUserInfinity: isUserInfinity(user) || null,
        };
        // classic event
        addEvent(
          payload.name,
          payload.value,
          Object.assign(payload.properties || {}, globalParams, userParams),
        );
        // increment event
        if (payload.incrementName) {
          addEvent(
            payload.incrementName,
            payload.incrementValue,
            Object.assign(payload.properties || {}, globalParams, userParams),
          );
        }
      }
    },
  });
};
