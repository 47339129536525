import Cookies from 'universal-cookie';

import { isServer } from './env-utils';

const cookies = new Cookies();

export const parseJwtToken = (jwtToken = '') => {
  try {
    // extracts the middle part of the JWT jwtToken ('jwt xxx.YYY.zzz')
    const noTag = jwtToken.split(' ')[1] || '';
    const userPart = noTag.split('.')[1] || '';
    return JSON.parse(
      isServer ? Buffer.from(userPart, 'base64').toString() : window.atob(userPart),
    );
  } catch (error) {
    return null;
  }
};

export const extractRolesFromToken = (jwtToken) => {
  return (parseJwtToken(jwtToken) || {}).roles || [];
};

export const createCookie = (name, value, options) => {
  const now = new Date();
  cookies.set(
    name,
    value,
    Object.assign(options, { expires: new Date(now.setFullYear(now.getFullYear() + 1)) }),
  );
  return value;
};
export const getCookie = (name) => {
  return cookies.get(name);
};
export const deleteCookie = (name, options) => {
  cookies.remove(name, options);
};

export const saveInLocalStorage = (keyName, value) => {
  localStorage.setItem(keyName, JSON.stringify(value));
};
export const getInLocalStorage = (keyName) => {
  return JSON.parse(localStorage.getItem(keyName)) || {};
};
export const deleteInLocalStorage = (keyName) => {
  localStorage.removeItem(keyName);
};
