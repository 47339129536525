import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { getHighlightedArticles } from 'bubble-reducers/src/selectors';

import ArticleHorizontalCard from '@/components/ArticleHorizontalCard/ArticleHorizontalCard';
import SocialNetworkFollow from '@/components/SocialNetworksFollow/SocialNetworkFollow';

import NewsletterOptIn from '../NewsletterOptIn/NewsletterOptIn';

const Sidebar = () => {
  const hasAcceptedNewsletter = useSelector((state) => state.user.user?.hasAcceptedNewsletter);
  const newsletterOptIn = useSelector((state) => state.websiteInterface.newsletterOptIn);

  const dispatch = useDispatch();
  const highlightedArticles = useSelector(getHighlightedArticles);

  useEffect(() => {
    dispatch(loadArticles({ options: { highlighted: 1 } }));
  }, []);

  return (
    <>
      <div className="mb-3 fw-bold">Toute l'actualité BD, Comics et Manga sur le Blog Bubble :</div>
      {highlightedArticles.map((article) => (
        <ArticleHorizontalCard key={article.objectId} className="mb-3" article={article} />
      ))}
      <div className="d-grid">
        <button className="btn btn-lg btn-primary mb-5">
          <Link className="text-white no-decoration " to="/9emeart">
            Voir tous les articles sur le Blog
          </Link>
        </button>
      </div>
      {!newsletterOptIn && hasAcceptedNewsletter ? <SocialNetworkFollow /> : <NewsletterOptIn />}
    </>
  );
};

export default Sidebar;
