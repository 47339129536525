import React from 'react';

import '../KeySellingPointsBanner.css';

const KeySellingPointCell = (props) => (
  <div className="ksp-item">
    <div className="d-flex justify-content-center ksp-image-zone">
      <img className="ksp-image" src={props.img} alt="keypt" />
    </div>
    <div className="bb-s-text-size text-center mt-2">{props.line1}</div>
    {props.line2 && <div className="bb-s-text-size text-center">{props.line2}</div>}
  </div>
);

export default KeySellingPointCell;
