import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  checkEanStatusApi,
  createAlbumAdditionApi,
  createAlbumRevisionApi,
  createPrintRevisionApi,
  createSerieRevisionApi,
} from '../services/api';

import { getClientToken } from '../selectors';

const CREATE_ALBUM_ADDITION = 'catalogs/createAlbumAddition';
const CREATE_ALBUM_REVISION = 'catalogs/createAlbumRevision';
const CREATE_SERIE_REVISION = 'catalogs/createSerieRevision';
const CREATE_PRINT_REVISION = 'catalogs/createPrintRevision';
const CREATE_AUTHOR_REVISION = 'catalogs/createAuthorRevision';
const CHECK_EAN_STATUS = 'catalogs/checkEanStatus';

const initialState = {
  printBeingAddedStatus: {},
  printBeingAdded: {},
  actionStatus: null,
  loading: {},
  errors: {},
};

export const createAlbumAddition = createAsyncThunk(
  CREATE_ALBUM_ADDITION,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;
    const userEmail = params?.userEmail || null;
    const addition = params?.addition || null;

    const clientToken = getClientToken(getState());

    await createAlbumAdditionApi(clientToken, userObjectId, userEmail, addition);
  },
);

export const createAlbumRevision = createAsyncThunk(
  CREATE_ALBUM_REVISION,
  async (params, { getState }) => {
    const albumObjectId = params?.albumObjectId || null;
    const revisions = params?.revisions || null;

    const clientToken = getClientToken(getState());

    await createAlbumRevisionApi(clientToken, albumObjectId, revisions);
  },
);

export const createSerieRevision = createAsyncThunk(
  CREATE_SERIE_REVISION,
  async (params, { getState }) => {
    const serieObjectId = params?.serieObjectId || null;
    const revisions = params?.revisions || null;

    const clientToken = getClientToken(getState());

    await createSerieRevisionApi(clientToken, serieObjectId, revisions);
  },
);

export const createPrintRevision = createAsyncThunk(
  CREATE_PRINT_REVISION,
  async (params, { getState }) => {
    const printObjectId = params?.printObjectId || null;
    const revisions = params?.revisions || null;

    const clientToken = getClientToken(getState());

    await createPrintRevisionApi(clientToken, printObjectId, revisions);
  },
);

export const createAuthorRevision = createAsyncThunk(
  CREATE_AUTHOR_REVISION,
  async (params, { getState }) => {
    const authorObjectId = params?.authorObjectId || null;
    const revisions = params?.revisions || null;

    const clientToken = getClientToken(getState());

    await createAuthorRevisionApi(clientToken, authorObjectId, revisions);
  },
);

export const checkEanStatus = createAsyncThunk(CHECK_EAN_STATUS, async (params, { getState }) => {
  const ean = params?.ean || null;

  const clientToken = getClientToken(getState());

  // * 'missingEan': EAN is missing, please fill one
  // * 'notFound': the EAN hasn't been found anywhere
  // * 'foundInProduction': the EAN is already on our production database
  // * 'dataFound': data has been found for the EAN in catalog
  // * 'alreadyAdded': the EAN has already been added
  const eanStatus = await checkEanStatusApi(clientToken, ean);
  return { eanStatus };
});

const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {
    editPrintBeingAdded: {
      reducer: (state, action) => {
        state.printBeingAdded = action.payload.printBeingAdded;
      },
    },
    deletePrintBeingAdded: {
      reducer: (state, action) => {
        state.printBeingAddedStatus = {};
        state.printBeingAdded = {};
      },
    },
    setPrintBeingAddedStatus: {
      reducer: (state, action) => {
        state.printBeingAddedStatus = { status: action.payload.status };
        state.printBeingAdded = {};
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // createAlbumAddition
      .addCase(createAlbumAddition.pending, (state, action) => {
        state.loading[CREATE_ALBUM_ADDITION] = true;
        state.errors[CREATE_ALBUM_ADDITION] = null;
      })
      .addCase(createAlbumAddition.fulfilled, (state, action) => {
        state.loading[CREATE_ALBUM_ADDITION] = false;
        state.errors[CREATE_ALBUM_ADDITION] = null;
      })
      .addCase(createAlbumAddition.rejected, (state, action) => {
        state.loading[CREATE_ALBUM_ADDITION] = false;
        state.errors[CREATE_ALBUM_ADDITION] = action.error;
      })
      // createAlbumRevision
      .addCase(createAlbumRevision.pending, (state, action) => {
        state.loading[CREATE_ALBUM_REVISION] = true;
        state.errors[CREATE_ALBUM_REVISION] = null;
      })
      .addCase(createAlbumRevision.fulfilled, (state, action) => {
        state.loading[CREATE_ALBUM_REVISION] = false;
        state.errors[CREATE_ALBUM_REVISION] = null;
      })
      .addCase(createAlbumRevision.rejected, (state, action) => {
        state.loading[CREATE_ALBUM_REVISION] = false;
        state.errors[CREATE_ALBUM_REVISION] = action.error;
      })
      // createSerieRevision
      .addCase(createSerieRevision.pending, (state, action) => {
        state.loading[CREATE_SERIE_REVISION] = true;
        state.errors[CREATE_SERIE_REVISION] = null;
      })
      .addCase(createSerieRevision.fulfilled, (state, action) => {
        state.loading[CREATE_SERIE_REVISION] = false;
        state.errors[CREATE_SERIE_REVISION] = null;
      })
      .addCase(createSerieRevision.rejected, (state, action) => {
        state.loading[CREATE_SERIE_REVISION] = false;
        state.errors[CREATE_SERIE_REVISION] = action.error;
      })
      // createPrintRevision
      .addCase(createPrintRevision.pending, (state, action) => {
        state.loading[CREATE_PRINT_REVISION] = true;
        state.errors[CREATE_PRINT_REVISION] = null;
      })
      .addCase(createPrintRevision.fulfilled, (state, action) => {
        state.loading[CREATE_PRINT_REVISION] = false;
        state.errors[CREATE_PRINT_REVISION] = null;
      })
      .addCase(createPrintRevision.rejected, (state, action) => {
        state.loading[CREATE_PRINT_REVISION] = false;
        state.errors[CREATE_PRINT_REVISION] = action.error;
      })
      // createAuthorRevision
      .addCase(createAuthorRevision.pending, (state, action) => {
        state.loading[CREATE_AUTHOR_REVISION] = true;
        state.errors[CREATE_AUTHOR_REVISION] = null;
      })
      .addCase(createAuthorRevision.fulfilled, (state, action) => {
        state.loading[CREATE_AUTHOR_REVISION] = false;
        state.errors[CREATE_AUTHOR_REVISION] = null;
      })
      .addCase(createAuthorRevision.rejected, (state, action) => {
        state.loading[CREATE_AUTHOR_REVISION] = false;
        state.errors[CREATE_AUTHOR_REVISION] = action.error;
      })
      // checkEanStatus
      .addCase(checkEanStatus.pending, (state, action) => {
        state.printBeingAddedStatus = {};
        state.printBeingAdded = {};
        state.loading[CHECK_EAN_STATUS] = true;
        state.errors[CHECK_EAN_STATUS] = null;
      })
      .addCase(checkEanStatus.fulfilled, (state, action) => {
        const eanStatus = action.payload.eanStatus;
        state.printBeingAddedStatus = {
          status: eanStatus.status,
          isRejected: eanStatus.data.isRejected,
          isInProduction: eanStatus.data.isInProduction,
        };
        state.printBeingAdded = eanStatus.data && eanStatus.data.print ? eanStatus.data.print : {};
        state.loading[CHECK_EAN_STATUS] = false;
        state.errors[CHECK_EAN_STATUS] = null;
      })
      .addCase(checkEanStatus.rejected, (state, action) => {
        state.printBeingAddedStatus = {};
        state.printBeingAdded = {};
        state.loading[CHECK_EAN_STATUS] = false;
        state.errors[CHECK_EAN_STATUS] = action.error;
      });
  },
});

export default catalogsSlice.reducer;

export const { editPrintBeingAdded, setPrintBeingAddedStatus, deletePrintBeingAdded } =
  catalogsSlice.actions;
