import React from 'react';

import { Icon } from '@/components/Icon/Icon';

import '../Top.scss';

const TopScores = ({ lastPosition, position, index }) => {
  const positionChange =
    !lastPosition || position < lastPosition ? 1 : position > lastPosition ? -0 : 0;

  return (
    <>
      <div className="bg-warning score-panel bb-medium-large-text-size">{index}</div>
      <div
        className={`position-panel
              ${
                positionChange === 1
                  ? 'text-success'
                  : positionChange === -1
                    ? 'text-danger'
                    : 'text-secondary'
              }`}
      >
        <Icon
          size="sm"
          name={positionChange === 1 ? 'arrow-up' : positionChange === -1 ? 'arrow-down' : 'equals'}
        />
      </div>
    </>
  );
};

export default TopScores;
