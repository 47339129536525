import React from 'react';

import asyncComponent from '@/components/AsyncComponent/AsyncComponent';

const AsyncOrder = asyncComponent(() => import('@/scenes/Order/Order'));

const PublicOrder = (props) => {
  return (
    <div className="bb-background-light-grey">
      <div className="container py-4">
        <AsyncOrder />
      </div>
    </div>
  );
};

export default PublicOrder;
