import React from 'react';

import config from '@/project-config';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { logout } from 'bubble-reducers/src/reducers/auth';

import { Icon } from '@/components/Icon/Icon';

import userIcon from '@/assets/header/icon_profile.svg';

import './ProfileTab.css';

const ProfileTab = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const onPressLogout = () => {
    dispatch(
      logout({
        cookie: { name: config.cookies.jwtToken.name, options: config.cookies.defaultOptions },
        localStorage: { keyName: config.localStorage.user.keyName },
      }),
    );
  };

  if (!user.objectId) {
    return (
      <Link className="nav-link d-flex no-decoration" to="/login">
        <img alt="user" src={userIcon} className="text-white header-icon" />
      </Link>
    );
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as="div" className="py-1">
        <img alt="user" src={userIcon} className="text-white header-icon" />
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to="/my-account/home">
            Mon profil
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to="/my-account/orders">
            Mes commandes
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to="/my-account/addresses">
            Mes adresses
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to="/my-account/cards">
            Mes cartes
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to="/my-account/favorite-stores">
            Mes librairies
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to="/my-account/my-settings">
            Mes informations
          </Link>
        </Dropdown.Item>

        <div className="my-2 h-separator" />

        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-pink text-nowrap" to="/my-account/bubble-club">
            Bubble Club (🎁🎁🎁)
          </Link>
        </Dropdown.Item>

        <div className="my-2 h-separator" />

        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to={`/user/${user.objectId}/ma-collection`}>
            Mes BD
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link
            className="no-decoration text-black"
            to={`/user/${user.objectId}/mes-albums-manquants`}
          >
            Albums manquants
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to={`/user/${user.objectId}/ma-wishlist`}>
            Mes souhaits
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to={`/user/${user.objectId}/mon-agenda`}>
            Mes sorties
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to={`/user/${user.objectId}/mes-statistiques`}>
            Mes stats
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to={`/my-account/avis`}>
            Mes notes
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="div" className="px-4">
          <Link className="no-decoration text-black" to="/my-account/mes-alertes">
            Mes alertes
          </Link>
        </Dropdown.Item>

        <div className="my-2 h-separator" />

        <Dropdown.Item as="div" className="px-4">
          <Link onClick={onPressLogout} className="no-decoration text-black" to="/login">
            Déconnexion
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileTab;
