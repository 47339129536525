import React, { useCallback } from 'react';

import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './Tag.scss';

const Tag = ({ tag, onClick, grey }) => {
  const renderContent = useCallback(
    () => (
      <span
        className={`text-nowrap tag-container p-1 px-2 rounded ${grey ? 'tag-container-grey' : ''}`}
      >
        <span className={grey ? '' : 'text-primary'}>{tag.name}</span>
      </span>
    ),
    [grey, tag.name],
  );

  return onClick ? (
    <WithClickHandler className="d-flex" onClick={onClick}>
      {renderContent()}
    </WithClickHandler>
  ) : (
    renderContent()
  );
};

export default Tag;
