import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Layout from '@/scenes/Layout/Layout';

import AppBubble from './AppBubble';
import AppNart from './AppNart';
import RootLayout from './RootLayout';
import Home from './scenes/Home/Home';

const Root = () => {
  return (
    <Routes>
      <Route element={<RootLayout />}>
        <Route path="/9emeart/*" element={<AppNart />} />
        <Route path="/*" element={<AppBubble />} />
      </Route>
    </Routes>
  );
};

export default Root;
