import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import placeholderJpegUrl from '@/assets/placeholders/placeholder-item.jpg';
import placeholderWebpUrl from '@/assets/placeholders/placeholder-item.webp';

import './Cover.css';

const Cover = (props) => {
  const isFullWidth = 'fullWidth' in props;
  const styles = Object.assign(
    {},
    props.width ? { width: props.width } : {},
    !isFullWidth ? { height: props.height ? props.height : null } : {},
  );
  const classNames = [
    'overflow-hidden',
    'rounded' in props ? 'rounded' : '',
    'roundedLeft' in props ? 'rounded-start' : '',
    'shadow' in props ? 'bb-shadow-hover' : '',
    isFullWidth ? 'w-100' : '',
    props.className,
  ]
    .filter((row) => row)
    .join(' ');

  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(props.imageUrl);

  useEffect(() => {
    if (props.imageUrl && props.imageUrl !== placeholderJpegUrl) {
      setImageLoaded(false);
      setImageUrl(props.imageUrl);
    }
  }, [props.imageUrl]);

  const addDefaultSrc = () => {
    setImageLoaded(true);
    setImageUrl(placeholderJpegUrl);
  };

  const addSrc = () => {
    setImageLoaded(true);
  };

  const renderFinalImage = () => {
    return (
      <div>
        <img
          style={styles}
          className={classNames}
          alt={props.alt || ''}
          src={imageUrl || placeholderJpegUrl}
        />
      </div>
    );
  };

  return (
    <>
      {!!imageUrl && !imageLoaded && (
        <div>
          <img
            onLoad={addSrc}
            onError={addDefaultSrc}
            className="d-none"
            alt={props.alt || ''}
            src={imageUrl + '#' + new Date().getTime()}
          />
        </div>
      )}

      {!imageLoaded ? (
        <WebpWrapper
          webp={placeholderWebpUrl}
          default={placeholderJpegUrl}
          style={styles}
          className={classNames}
          alt={props.alt || ''}
        />
      ) : (
        renderFinalImage()
      )}
    </>
  );
};

Cover.propTypes = {
  rounded: PropTypes.bool,
  roundedLeft: PropTypes.bool,
  hoverable: PropTypes.bool,
  shadow: PropTypes.bool,
  height: PropTypes.number,
  fullWidth: PropTypes.bool,
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
};

export default Cover;
