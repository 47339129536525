import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSent: false,
  globalParams: null,
  trackers: [],
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    initEvents: {
      reducer: (state, action) => {
        state.isSent = action.payload.isSent;
        state.globalParams = action.payload.globalParams;
      },
    },
    logEvent: {
      reducer: (state, action) => {},
      prepare: (name, properties, value, incrementName, incrementValue) => {
        return { payload: { name, properties, value, incrementName, incrementValue } };
      },
    },
  },
});

export default eventsSlice.reducer;

export const { initEvents, logEvent } = eventsSlice.actions;
