import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import bubbleConfig from 'bubble-config';
import { useDispatch, useSelector } from 'react-redux';

import { loadPaymentMethods } from 'bubble-reducers/src/reducers/payment-methods';

import InfinityBuyZoneContent from './components/InfinityBuyZoneContent';

const stripePromise = loadStripe(bubbleConfig.stripe.publishableKey);

export default function InfinityBuyZone({ iap, callback }) {
  const dispatch = useDispatch();
  const userObjectId = useSelector((state) => state.user.user?.objectId);

  useEffect(() => {
    dispatch(loadPaymentMethods({ userObjectId }));
  }, [dispatch, userObjectId]);

  return (
    <Elements stripe={stripePromise}>
      <InfinityBuyZoneContent iap={iap} callback={callback} />
    </Elements>
  );
}

InfinityBuyZone.propTypes = {
  iap: PropTypes.shape({
    objectId: PropTypes.string,
    productId: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.any,
    platform: PropTypes.string,
    priceWithoutTax: PropTypes.string,
    price: PropTypes.string,
  }),
  callback: PropTypes.func,
};
