import React, { useCallback, useState } from 'react';

const SerieHeaderSummary = ({ descriptionShort }) => {
  const [show, _setShow] = useState(false);
  const handleClick = useCallback(() => _setShow(true), []);

  return (
    <>
      <div className={`${show ? '' : 'overflow-hidden-3-lines'}`}>{descriptionShort}</div>
      {!show && descriptionShort && (
        <button onClick={handleClick} className="btn btn-link p-0 m-0">
          En savoir plus
        </button>
      )}
    </>
  );
};

export default SerieHeaderSummary;
