import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import { getAssetImage } from '@/services/image-utils';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

const OtherAlbumsInSerie = (props) => {
  const serie = props.serie;
  const album = props.album;

  const allSerieAlbums = (serie.albums || []).filter((row) => row && row !== undefined);
  let otherAlbumsInSerie = allSerieAlbums.slice();
  if (allSerieAlbums.length > 6) {
    const allSerieAlbumsTmp = allSerieAlbums.slice();
    otherAlbumsInSerie = [];
    otherAlbumsInSerie.push(allSerieAlbumsTmp[0]);
    otherAlbumsInSerie = otherAlbumsInSerie
      .concat(allSerieAlbumsTmp.splice(allSerieAlbumsTmp.length - 4, allSerieAlbumsTmp.length))
      .filter((row) => row && row !== undefined);
  }

  return (
    <>
      {!!otherAlbumsInSerie.length && (
        <TitledListContainer
          noWrap
          title={
            <div className="d-block d-md-flex">
              <div className="d-none d-md-block">
                Collectionneur ou néophyte, retrouvez les précédentes aventures de {serie.title}
              </div>
              <div className="d-md-none">Complétez avec les autres albums de {serie.title}</div>
              <Link
                className="fw-normal bb-regular-text-size d-md-none py-2"
                to={`/${serie.permalink}/serie/${serie.objectId}`}
              >
                Voir tous les albums
              </Link>
            </div>
          }
          topRightLink={
            <Link className="d-none d-md-flex" to={`/${serie.permalink}/serie/${serie.objectId}`}>
              Voir tous les albums
            </Link>
          }
          list={[
            <AlbumCard
              className="col-7 col-md-4 col-lg-3 col-xl-2"
              key={`seeAlso_${album.objectId}`}
              albumObjectId={otherAlbumsInSerie[0]}
            />,
            allSerieAlbums.length > 6 ? (
              <div
                key="moreAlbums"
                className="d-flex flex-column col-6 col-md-4 col-lg-3 col-xl-2 justify-content-center align-items-center"
              >
                <Link className="mt-2" to={`/${serie.permalink}/serie/${serie.objectId}`}>
                  <div className="d-flex flex-column align-items-center">
                    <img alt="ellipsis" src={getAssetImage('icon_ellipse.svg')} />
                    <div className="mt-2">Découvrez les tomes 2 à {allSerieAlbums.length - 4}</div>
                  </div>
                </Link>
              </div>
            ) : (
              <React.Fragment key={`more_${album.objectId}`} />
            ),
            otherAlbumsInSerie
              .slice(1, 1 + otherAlbumsInSerie.length)
              .map((albumObjectId, index) => (
                <AlbumCard
                  className="col-6 col-md-4 col-lg-3 col-xl-2"
                  key={`seeAlso_${albumObjectId}_${index}`}
                  albumObjectId={albumObjectId}
                />
              )),
          ]}
        />
      )}
    </>
  );
};

OtherAlbumsInSerie.propTypes = {
  serie: PropTypes.object,
  album: PropTypes.object,
};

const areEqual = (prevProps, nextProps) => prevProps.serie === nextProps.serie;

export default React.memo(OtherAlbumsInSerie, areEqual);
