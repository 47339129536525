import React, { useEffect, useState } from 'react';

import bubbleConfig from 'bubble-config';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import {
  resetSearch,
  searchAlbums,
  searchArticles,
  searchAuthors,
  searchMultiIndex,
  searchSeries,
  searchStores,
} from 'bubble-reducers/src/reducers/search';

import ListSelection from '@/scenes/Listing/components/ListSelections';

import ArticleHorizontalCard from '@/components/ArticleHorizontalCard/ArticleHorizontalCard';
import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import { Icon } from '@/components/Icon/Icon';
import InfinityCard from '@/components/InfinityCard/InfinityCard';
import SearchAlbumCard from '@/components/SearchResults/components/SearchAlbumCard';
import SearchAuthorCard from '@/components/SearchResults/components/SearchAuthorCard';
import SearchSerieCard from '@/components/SearchResults/components/SearchSerieCard';
import SearchStoreCard from '@/components/SearchResults/components/SearchStoreCard';

import './SearchSeeAll.css';

const SearchSeeAll = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const histSorted0 = useSelector((state) => state.search[state.search.sorting[0] + 'Persistent']);
  const histSorted1 = useSelector((state) => state.search[state.search.sorting[1] + 'Persistent']);
  const histSorted2 = useSelector((state) => state.search[state.search.sorting[2] + 'Persistent']);
  const histSorted3 = useSelector((state) => state.search[state.search.sorting[3] + 'Persistent']);
  const histSorted4 = useSelector((state) => state.search[state.search.sorting[4] + 'Persistent']);

  const hits = [histSorted0, histSorted1, histSorted2, histSorted3, histSorted4].filter(Boolean);

  const menuAuthorsObjectIds = useSelector((state) => state.authors.menuAuthorsObjectIds);
  const authors = useSelector((state) => state.authors.authors);

  const urlParams = new URLSearchParams(location.search);

  const [search, setSearch] = useState(decodeURIComponent(urlParams.get('q')) || '');
  const [index, setIndex] = useState(urlParams.get('i'));

  useEffect(() => {
    dispatch(resetSearch());
    if (index === 'albums') {
      dispatch(searchAlbums({ text: search, options: { hitsPerPage: 20 }, persistent: true }));
    } else if (index === 'series') {
      dispatch(searchSeries({ text: search, options: { hitsPerPage: 20 }, persistent: true }));
    } else if (index === 'authors') {
      dispatch(searchAuthors({ text: search, options: { hitsPerPage: 20 }, persistent: true }));
    } else if (index === 'articles') {
      dispatch(
        searchArticles({
          text: search,
          options: { hitsPerPage: 20, filters: 'isPublished=1' },
          persistent: true,
        }),
      );
    } else if (index === 'stores') {
      dispatch(searchStores({ text: search, options: { hitsPerPage: 20 }, persistent: true }));
    } else {
      dispatch(
        searchMultiIndex({
          indexes: [
            { name: bubbleConfig.algolia.albumsIndex, options: { hitsPerPage: 20 } },
            { name: bubbleConfig.algolia.seriesIndex, options: { hitsPerPage: 20 } },
            { name: bubbleConfig.algolia.authorsIndex, options: { hitsPerPage: 20 } },
            {
              name: bubbleConfig.algolia.articlesIndex,
              options: { hitsPerPage: 20, filters: 'isPublished=1' },
            },
            {
              name: bubbleConfig.algolia.storesIndex,
              options: { hitsPerPage: 20, filters: 'isPublic=1' },
            },
          ],
          text: search,
          persistent: true,
        }),
      );
    }
  }, [search, index]);

  const getTitleForType = (type) => {
    const map = {
      serie: 'Séries',
      album: 'Albums populaires',
      author: 'Auteurs / dessinateurs',
      article: 'Articles',
      store: 'Librairies',
    };

    return map[type] || '';
  };

  const renderCard = (type, hit) => {
    switch (type) {
      case 'serie':
        return (
          <SearchSerieCard key={hit.objectId} className="col-sm-6 col-md-4 mt-3" serie={hit} />
        );
      case 'album':
        return (
          <SearchAlbumCard key={hit.objectId} className="col-sm-6 col-md-4 mt-3" album={hit} />
        );
      case 'author':
        return (
          <SearchAuthorCard key={hit.objectId} className="col-sm-6 col-md-4 mt-3" author={hit} />
        );
      case 'article':
        return (
          <div key={hit.objectId} className="col-sm-6 mt-3 d-flex flex-fill">
            <ArticleHorizontalCard article={hit} />
          </div>
        );
      case 'store':
        return (
          <div key={hit.objectId} className="col-sm-6 col-md-4 mt-3">
            <SearchStoreCard store={hit} />
          </div>
        );
      default:
        return (
          <div key={hit.objectId} className="col-6">
            {hit.name}
          </div>
        );
    }
  };

  const authorsMenu = (menuAuthorsObjectIds['bd'] || []).map((objectId) => authors[objectId]);
  return (
    <div className="bb-background-light-grey">
      <div className="container">
        <BreadCrumbs currentObject={{ search: true, text: search, index: index }} />
        <div className="row pt-3 px-3">
          <div className="d-none d-lg-block col-2">
            <ListSelection authors={authorsMenu} />
            <div className="d-none d-md-block">
              <InfinityCard />
            </div>
          </div>
          <div className="col-12 col-lg-10">
            <h1>Résultat de recherche pour "{search}"</h1>
            <div>
              {hits.map((index) => {
                if (index.data.length === 0) return null;

                return (
                  <div key={'search_all_' + index.type}>
                    <div className="mt-5 bb-l-text-size d-flex justify-content-between align-items-center">
                      {getTitleForType(index.type)}
                      {index.type === 'album' && (
                        <div className="div h6 m-0">
                          <Link to="/ajouter-un-album">
                            <Icon name="plus" /> Vous n'avez pas trouvé votre album ?
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {index.data.map((data) => renderCard(index.type, data))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: 150 }} />
    </div>
  );
};

export default SearchSeeAll;
