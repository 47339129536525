import React, { useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Tag from '@/components/Tag/Tag';

const DEFAULT_SHOWN_TAGS = 4;

const TagSection = ({ tags = [], initialTagsCount = DEFAULT_SHOWN_TAGS }) => {
  const [showAll, _setShowAll] = useState(false);
  const navigate = useNavigate();
  const onClickShowAll = useCallback(() => _setShowAll((oldShowAll) => !oldShowAll), [_setShowAll]);

  const tagsToShow = useMemo(
    () => tags?.slice(0, showAll ? Infinity : initialTagsCount),
    [showAll, tags, initialTagsCount],
  );

  const showAllTag = useMemo(
    () => ({ name: showAll ? 'Voir moins' : `+${tags.length - initialTagsCount}` }),
    [tags.length, showAll, initialTagsCount],
  );

  return (
    <div className="py-1 d-flex align-items-center flex-wrap">
      {tagsToShow.map((tag) => (
        <div
          key={tag.objectId || tag.name}
          className="d-flex pe-1 pb-1  align-items-center justify-content-center"
        >
          <TagWrapper tag={tag} navigate={navigate} />
        </div>
      ))}
      {tags.length > initialTagsCount && (
        <div className="d-flex pe-1 pb-1">
          <Tag tag={showAllTag} grey={showAll} onClick={onClickShowAll} />
        </div>
      )}
    </div>
  );
};

const TagWrapper = ({ tag, navigate }) => {
  const onClick = useCallback(() => {
    navigate(`/list?tags=${tag.name}`);
  }, [tag.name, navigate]);
  return <Tag tag={tag} onClick={onClick} />;
};

export default TagSection;
