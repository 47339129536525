import bubbleConfig from 'bubble-config';

import { updateWishlistPrints } from 'bubble-reducers/src/reducers/profiles';

import { createCookie, getCookie } from 'bubble-utils/src/auth-utils';

export const addWebsiteWishlistListeners = (startListening) => {
  startListening({
    actionCreator: updateWishlistPrints.fulfilled,
    effect: (action) => {
      const { libraryObjectId, printIds, wishlistObject } = action.payload;
      const wishlistBookingsCookie = getCookie(bubbleConfig.cookies.wishlistBookings.name) || {
        bookings: {},
      };

      const bookingsForRecipientSet = new Set(
        wishlistBookingsCookie.bookings[libraryObjectId] || [],
      );
      if (wishlistObject.isGifted) printIds.forEach((id) => bookingsForRecipientSet.add(id));
      if (!wishlistObject.isGifted) printIds.forEach((id) => bookingsForRecipientSet.delete(id));

      wishlistBookingsCookie.bookings[libraryObjectId] = Array.from(bookingsForRecipientSet);

      createCookie(
        bubbleConfig.cookies.wishlistBookings.name,
        { bookings: wishlistBookingsCookie.bookings },
        bubbleConfig.cookies.defaultOptions,
      );
    },
  });
};
