import React from 'react';

const SvgGradients = (props) => {
  return (
    <svg
      className="position-absolute"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width="0"
      height="0"
    >
      <defs>
        {[...Array(11)].map((value, index) => (
          <linearGradient key={index} id={`fill-to-${index * 10}%`} x1="0" y1="0" x2="1" y2="0">
            <stop offset={`${index * 10}%`} stopColor="#FCCB31" />
            <stop offset="0%" stopColor="#DCDCDC" />
          </linearGradient>
        ))}
      </defs>
    </svg>
  );
};

export default SvgGradients;
