import React from 'react';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { LOAD_AUTHOR_TAGS, loadAuthorTags } from 'bubble-reducers/src/reducers/authors';

import TagPlaceholder from '@/components/Tag/TagPlaceholder';
import TagSection from '@/components/TagSection/TagSection';

const placeholders = Array(10)
  .fill(null)
  .map((_, index) => (
    <div key={`ph_${index}`} className="pe-1 pb-1 pt-1 d-flex">
      <TagPlaceholder />
    </div>
  ));

export default function AuthorHeaderTagsSection({ authorObjectId }) {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.authors.authorTagsMap[authorObjectId]);
  const isLoading = useSelector((state) => state.authors.loading[LOAD_AUTHOR_TAGS]);

  useEffect(() => {
    if (authorObjectId) {
      dispatch(loadAuthorTags({ authorObjectId }));
    }
  }, [dispatch, authorObjectId]);

  return (
    <>
      {!!isLoading && !tags?.length && placeholders}
      <TagSection tags={tags} initialTagsCount={10} />
    </>
  );
}
