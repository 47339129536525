import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';
import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import burgerIcon from '@/assets/9eart/burger_9emeart.svg';
import nArtlogo from '@/assets/9eart/logo_9emeart_new.svg';
import bubbleLogo from '@/assets/9eart/logo_bubble.svg';
import comicsblogLogo from '@/assets/9eart/logo_cb.png';
import comicsblogLogoWebp from '@/assets/9eart/logo_cb.webp';
import profileLogo from '@/assets/9eart/profile.svg';
import searchLogo from '@/assets/9eart/search.svg';

import NArtHeaderLink from '../components/NArtHeaderLink/NArtHeaderLink';
import NArtBurgerMenu from './components/NArtBurgerMenu/NArtBurgerMenu';
import NavbarNArt from './components/NArtNavbar';

import '../Header.scss';

const NArtHeader = () => {
  const userObjectId = useSelector((state) => state.user.user?.objectId) || null;
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const onClick = useCallback(() => {
    setShowBurgerMenu((old) => {
      !old ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
      return !old;
    });
  }, [setShowBurgerMenu]);

  return (
    <>
      <header
        className={`position-relative bb-s-shadow-hover header-container transition bg-white`}
      >
        <div className="px-lg-3">
          <nav className="transition navbar navbar-expand-lg navbar-light py-0 py-lg-2">
            <div className="d-flex align-items-center">
              <button
                onClick={onClick}
                className={`navbar-toggler border-0 px-1 nart-burger-button ${
                  showBurgerMenu ? 'nart-burger-button-rotated' : ''
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#nartNavbarNav"
                aria-controls="nartNavbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <img className="nart-burger-icon" src={burgerIcon} alt="burger_icon" />
              </button>
              <div className="d-flex d-lg-none p-3">
                <Link to={'/9emeart'}>
                  <img className="nart-logo" src={nArtlogo} alt="9eme art" />
                </Link>
              </div>
            </div>

            <div className="d-flex d-lg-none">
              <Link
                to="/9emeart/recherche"
                className="no-decoration text-black d-flex align-items-center px-1"
              >
                <img className="nart-logo-small" src={searchLogo} alt="search" />
              </Link>
              <Link
                className="no-decoration px-3"
                to={userObjectId ? '/my-account/home' : '/login'}
              >
                <img className="nart-logo-small" src={profileLogo} alt="Login icon" />
              </Link>
              <Link className="no-decoration " to={'/'}>
                <img className="nart-logo-small" src={bubbleLogo} alt="Cart icon" />
              </Link>
            </div>

            <div className="d-none d-lg-flex flex-fill">
              <div className="d-flex flex-fill">
                <div className="d-flex align-items-center">
                  <Link className="navbar-brand me-2" to={'/9emeart'}>
                    <img className="nart-logo" src={nArtlogo} alt="9eme art" />
                  </Link>
                </div>

                <div className="ps-4 navbar-nav d-flex h-100 align-items-center">
                  <NArtHeaderLink to="/9emeart" noHighlight>
                    <Icon name="home" />
                  </NArtHeaderLink>
                  <NArtHeaderLink to="/9emeart/bd">BD</NArtHeaderLink>
                  <NArtHeaderLink to="/9emeart/comics">Comics</NArtHeaderLink>
                  <NArtHeaderLink to="/9emeart/mangas">Mangas</NArtHeaderLink>
                  <NArtHeaderLink to="/9emeart/jeunesse">Jeunesse</NArtHeaderLink>
                  <NArtHeaderLink to="/9emeart/webtoon">Webtoon</NArtHeaderLink>
                  <div className="d-flex h-50 ms-4 me-5 v-separator-no-gradient-heavy rounded" />
                  <a href="http://www.comicsblog.fr?utm_source=appbubble&utm_medium=header">
                    <WebpWrapper
                      className="comicsblog-logo"
                      alt="Logo de Comicsblog"
                      default={comicsblogLogo}
                      webp={comicsblogLogoWebp}
                    />
                  </a>
                </div>
              </div>
              <ul className="navbar-nav d-flex">
                <li className="nav-item d-flex align-items-center px-1">
                  <Link className="text-black" to="/9emeart/recherche">
                    <img src={searchLogo} alt="search" className="nart-logo-small" />
                  </Link>
                </li>

                <li className="nav-item d-flex align-items-center px-3">
                  <Link to={userObjectId ? '/my-account/home' : '/login'}>
                    <img src={profileLogo} alt="search" className="nart-logo-small" />
                  </Link>
                </li>
                <li className="nav-item d-flex align-items-center px-1">
                  <Link to="/">
                    <img className="nart-bubble-logo-small" src={bubbleLogo} alt="Bubble icon" />
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <NavbarNArt />
      </header>
      <div className="position-absolute d-lg-none">
        <NArtBurgerMenu show={showBurgerMenu} onClose={onClick} />
      </div>
    </>
  );
};

export default NArtHeader;
