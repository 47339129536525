import config from '@/project-config';
import { captureException, init, setUser, showReportDialog, withScope } from '@sentry/browser';

const SentryServices = {};

SentryServices.init = () => {
  if (config.sentry.isActive) {
    init({
      dsn: config.sentry.dsnWeb,
      // deactivate replay integration because it is making build 50KB bigger https://github.com/getsentry/sentry-javascript/discussions/10167
      //   integrations: [replayIntegration()],
      // Session Replay
      //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

SentryServices.setUser = (data) => {
  if (config.sentry.isActive) {
    setUser(data);
  }
};

SentryServices.withScope = (fn) => {
  if (config.sentry.isActive) {
    withScope(fn);
  }
};

SentryServices.captureException = (error) => {
  if (config.sentry.isActive) {
    captureException(error);
  }
};

SentryServices.showReportDialog = (data) => {
  if (config.sentry.isActive) {
    showReportDialog(data);
  }
};

export default SentryServices;
