import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';

import {
  createAvailabilityAlertApi,
  deleteAvailabilityAlertApi,
  loadAvailabilityAlertsApi,
} from '../services/api';

import { getClientToken, getGuestId } from '../selectors';
import { storeAlbums } from './albums';
import { storePrints } from './prints';

const LOAD_AVAILABILITY_ALERTS = 'availabilityAlerts/loadAvailabilityAlerts';
export const CREATE_AVAILABILITY_ALERT = 'availabilityAlerts/createAvailabilityAlert';
export const DELETE_AVAILABILITY_ALERT = 'availabilityAlerts/deleteAvailabilityAlert';

const initialState = {
  availabilityAlertsMapByPrintObjectId: {},
  loading: {},
  errors: {},
};

export const loadAvailabilityAlerts = createAsyncThunk(
  LOAD_AVAILABILITY_ALERTS,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    const availabilityAlerts = await loadAvailabilityAlertsApi(clientToken, userObjectId);

    const { albums, prints } = extractAlbumAndPrints(
      availabilityAlerts.map((alert) => alert.album),
    );
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));

    return { availabilityAlerts };
  },
);

export const createAvailabilityAlert = createAsyncThunk(
  CREATE_AVAILABILITY_ALERT,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const printObjectId = params?.printObjectId || null;

    const clientToken = getClientToken(getState());
    const guestId = getGuestId(getState());
    const availabilityAlert = await createAvailabilityAlertApi(
      clientToken,
      userObjectId || guestId,
      printObjectId,
    );

    const { albums, prints } = extractAlbumAndPrints([availabilityAlert.album]);
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));

    return { availabilityAlert };
  },
);

export const deleteAvailabilityAlert = createAsyncThunk(
  DELETE_AVAILABILITY_ALERT,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const availabilityAlertObjectId = params?.availabilityAlertObjectId || null;

    const clientToken = getClientToken(getState());
    const guestId = getGuestId(getState());
    const availabilityAlert = await deleteAvailabilityAlertApi(
      clientToken,
      userObjectId || guestId,
      availabilityAlertObjectId,
    );

    const { albums, prints } = extractAlbumAndPrints(availabilityAlert.map((alert) => alert.album));
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));

    dispatch(loadAvailabilityAlerts({ userObjectId }));
  },
);

const availabilityAlertsSlice = createSlice({
  name: 'availabilityAlerts',
  initialState,
  reducers: {
    resetAvailabilityAlertsReducer: {
      reducer: (state, action) => initialState,
    },
  },
  extraReducers: (builder) => {
    builder
      // loadAvailabilityAlerts
      .addCase(loadAvailabilityAlerts.pending, (state, action) => {
        state.loading[LOAD_AVAILABILITY_ALERTS] = true;
        state.errors[LOAD_AVAILABILITY_ALERTS] = null;
      })
      .addCase(loadAvailabilityAlerts.fulfilled, (state, action) => {
        state.availabilityAlertsMapByPrintObjectId = action.payload.availabilityAlerts.reduce(
          (acc, cur) => {
            delete cur.album;
            acc[cur.printObjectId] = cur;
            return acc;
          },
          {},
        );
        state.loading[LOAD_AVAILABILITY_ALERTS] = false;
        state.errors[LOAD_AVAILABILITY_ALERTS] = null;
      })
      .addCase(loadAvailabilityAlerts.rejected, (state, action) => {
        state.loading[LOAD_AVAILABILITY_ALERTS] = false;
        state.errors[LOAD_AVAILABILITY_ALERTS] = action.error;
      })
      // createAvailabilityAlert
      .addCase(createAvailabilityAlert.pending, (state, action) => {
        state.loading[CREATE_AVAILABILITY_ALERT] = true;
        state.errors[CREATE_AVAILABILITY_ALERT] = null;
      })
      .addCase(createAvailabilityAlert.fulfilled, (state, action) => {
        delete action.payload.availabilityAlert.album;
        state.availabilityAlertsMapByPrintObjectId[action.payload.availabilityAlert.printObjectId] =
          action.payload.availabilityAlert;

        state.loading[CREATE_AVAILABILITY_ALERT] = false;
        state.errors[CREATE_AVAILABILITY_ALERT] = null;
      })
      .addCase(createAvailabilityAlert.rejected, (state, action) => {
        state.loading[CREATE_AVAILABILITY_ALERT] = false;
        state.errors[CREATE_AVAILABILITY_ALERT] = action.error;
      })
      // deleteAvailabilityAlert
      .addCase(deleteAvailabilityAlert.pending, (state, action) => {
        const { availabilityAlertObjectId } = action.meta.arg;
        const printObjectId = Object.values(state.availabilityAlertsMapByPrintObjectId).find(
          (availabilityAlert) => availabilityAlert.objectId === availabilityAlertObjectId,
        ).printObjectId;
        delete state.availabilityAlertsMapByPrintObjectId[printObjectId];

        state.loading[DELETE_AVAILABILITY_ALERT] = true;
        state.errors[DELETE_AVAILABILITY_ALERT] = null;
      })
      .addCase(deleteAvailabilityAlert.fulfilled, (state, action) => {
        state.loading[DELETE_AVAILABILITY_ALERT] = false;
        state.errors[DELETE_AVAILABILITY_ALERT] = null;
      })
      .addCase(deleteAvailabilityAlert.rejected, (state, action) => {
        state.loading[DELETE_AVAILABILITY_ALERT] = false;
        state.errors[DELETE_AVAILABILITY_ALERT] = action.error;
      });
  },
});

export default availabilityAlertsSlice.reducer;

export const { resetAvailabilityAlertsReducer } = availabilityAlertsSlice.actions;
