import React from 'react';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import xmasListingJpg from '@/assets/xmas/listing_xmas_banner.jpg';
import xmasListingWebp from '@/assets/xmas/listing_xmas_banner.webp';

import HomeSection from '../components/HomeSection';

// import { Link } from 'react-router-dom';

export default function Christmas2024Home() {
  return (
    <>
      <WebpWrapper
        alt="xmas"
        className="d-none d-md-flex w-100 christmas-banner"
        default={xmasListingJpg}
        webp={xmasListingWebp}
      />

      <p className="pt-3">
        Ce n'est pas pour rien qu'il y a au moins une BD en dessous de chaque sapin à Noël ! La
        bande dessinée est une valeure sûre. On vous propose une sélection d'albums qu'il ne fallait
        pas rater et qui feront toujours plaisir.
      </p>
      {/* <p>
        Vous cherchez l'album ultime qui a marqué l'année ? En BD, comics ou manga :{' '}
        <Link
          className="text-primary no-decoration"
          to="/9emeart/bd/edito/top-2024-l-essentiel-des-bd-comics-et-manga-qui-ont-marque-l-annee"
        >
          voici le top 2024 des membres de la rédaction
        </Link>
      </p> */}

      <HomeSection id="oneshot" />
      <HomeSection id="jeunesse" />
      <HomeSection id="coffAndInt" />
      <HomeSection id="tops" />
    </>
  );
}
