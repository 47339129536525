import React from 'react';

const CommentsLoading = () => {
  return (
    <>
      <div className="spinner-border spinner-lg"></div>
      <p>...Chargement En Cours...</p>
    </>
  );
};

export default CommentsLoading;
