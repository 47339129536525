import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children }) => {
  const jwtToken = useSelector((state) => state.auth.jwtToken);
  const location = useLocation();

  return jwtToken ? (
    children
  ) : (
    <Navigate to={{ pathname: '/login', state: { from: location.pathname } }} />
  );
};

export default RequireAuth;
