import React from 'react';

import SentryServices from '@/services/sentry';

class ErrorBoundary extends React.Component {
  state = { hasError: false, eventId: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
    SentryServices.withScope((scope) => {
      scope.setExtras(info);
      const eventId = SentryServices.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Oups, quelque chose s'est mal passé</h1>
          <div>Aidez-nous en nous reportant et décrivant l'erreur :) Merci</div>
          <button onClick={() => SentryServices.showReportDialog({ eventId: this.state.eventId })}>
            Reporter l'erreur
          </button>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
