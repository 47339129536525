import { pad } from './string-utils';

import { DATE } from 'bubble-constants';

export const getDateModuloXSeconds = (numberOfSeconds, date = new Date()) =>
  new Date(new Date(date).setSeconds(date.getSeconds() + Number(numberOfSeconds)));

export const getDateModuloXMinutes = (numberOfMinutes, date = new Date()) =>
  new Date(new Date(date).setMinutes(date.getMinutes() + Number(numberOfMinutes)));

export const getDateModuloXHours = (numberOfHours, date = new Date()) =>
  new Date(new Date(date).setHours(date.getHours() + Number(numberOfHours)));

export const getDateModuloXDays = (numberOfDays, date = new Date()) =>
  new Date(new Date(date).setDate(date.getDate() + Number(numberOfDays)));

export const getDateModuloXMonths = (numberOfMonths, date = new Date()) =>
  new Date(new Date(date).setUTCMonth(date.getUTCMonth() + Number(numberOfMonths)));

export const getDateModuloXYears = (numberOfYears, date = new Date()) =>
  new Date(new Date(date).setUTCFullYear(date.getUTCFullYear() + Number(numberOfYears)));

/**
 * options: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 */
export const formatDate = (date, options = {}, locale = 'fr-FR', formatToParts = false) => {
  //TODO: return null
  if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
    return '-';
  }
  let defaultOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };

  Object.keys(options).forEach(
    (key) => !options[key] && delete defaultOptions[key] && delete options[key],
  );
  options = Object.assign({}, defaultOptions, options);

  const formatter = new Intl.DateTimeFormat(locale, { ...defaultOptions, ...options });

  return formatToParts ? formatter.formatToParts(date) : formatter.format(date);
};

// Use this one to wrap value field in date input
export const extractDate = (date) => {
  if (!date) return null;
  return new Date(date).toISOString().split('T')[0];
};

// Use this one to wrap parameter in onChange method of date input
export const extractDateWithTimezone = (date) => {
  if (!date) return null;
  return new Date(date).toISOString();
};

export const getFormatter = (options = {}, locale = 'fr-FR') => {
  // date will be returned as UTC parts
  let defaultOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: 'UTC',
  };

  Object.keys(options).forEach(
    (key) => !options[key] && delete defaultOptions[key] && delete options[key],
  );
  return new Intl.DateTimeFormat(locale, { ...defaultOptions, ...options });
};

export const getDateAsParts = (date = new Date(), options = {}, locale = 'fr-FR') => {
  return getFormatter(options, locale).formatToParts(date);
};

export const getDateForInput = (date, options = {}, locale = 'fr-FR') => {
  if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
    return '';
  }
  const dateParts = getDateAsParts(date, options, locale);
  return [
    pad(dateParts.find((part) => part.type === 'year').value, '0', 4),
    dateParts.find((part) => part.type === 'month').value,
    dateParts.find((part) => part.type === 'day').value,
  ].join('-');
};

export const formatDateFromInputToIsoUtc = (dateString) => {
  if (!dateString) {
    return null;
  }
  const dateParts = dateString.split('-');
  return new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2]));
};

export const formatDateWithFormat = (dateString, options = {}, locale = 'fr-FR') => {
  const date = new Date(dateString);
  if (!dateString || !(date instanceof Date) || isNaN(date.getTime())) {
    return null;
  }
  return getFormatter(options, locale).format(date);
};

export const formatDateWithLongMonth = (dateString, locale = 'fr-FR') => {
  return formatDateWithFormat(dateString, { month: 'long' }, locale);
};

export const getTodayUtcDateWithoutTime = () =>
  new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      0,
      0,
      0,
    ),
  );

// return date formatted like YYYY-MM
export const getDateAsRawFormatMonthWithDash = (date = new Date()) => {
  return date.toISOString().replace(/T/g, '_').substring(0, 7);
};

export const diffInDays = (dateString, referenceDateString) => {
  if (!dateString) {
    return null;
  }
  const referenceDate = referenceDateString ? new Date(referenceDateString) : new Date();
  const date = dateString ? new Date(dateString) : new Date();

  return Math.round((referenceDate - date) / (1000 * 3600 * 24));
};

export const diffInHours = (dateString, referenceDateString) => {
  if (!dateString) {
    return null;
  }
  const referenceDate = referenceDateString ? new Date(referenceDateString) : new Date();
  const date = dateString ? new Date(dateString) : new Date();

  return Math.round((referenceDate - date) / (1000 * 3600));
};

export const diffInMinutes = (dateString, referenceDateString) => {
  if (!dateString) {
    return null;
  }
  const referenceDate = referenceDateString ? new Date(referenceDateString) : new Date();
  const date = dateString ? new Date(dateString) : new Date();

  return Math.round((referenceDate - date) / (1000 * 60));
};

export const getFirstDayOfTheWeekDate = (dateString) => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);

  return new Date(new Date(date).setDate(date.getDate() - (date.getDay() - 1)));
};

export const isToday = (dateAsString) => {
  const dateToCheck = new Date(dateAsString);
  const today = new Date();
  return (
    dateToCheck.getDate() == today.getDate() &&
    dateToCheck.getMonth() == today.getMonth() &&
    dateToCheck.getFullYear() == today.getFullYear()
  );
};

export const formatAsFrenchUtcDoubleDigitFromIso = (
  date,
  includeYear = true,
  includeDay = true,
) => {
  if (!date) return null;

  const displayDate = new Date(date);
  let day = displayDate.getUTCDate();
  day = day < 10 ? '0' + day : day;
  var month = displayDate.getUTCMonth() + 1;
  month = month < 10 ? '0' + month : month;

  return [includeDay ? day : null, month, includeYear ? displayDate.getUTCFullYear() : null]
    .filter((row) => row)
    .join('/');
};

export const formatAsFrenchVerboseDate = (
  date,
  includeYear = true,
  includeWeekDay = true,
  padDay = true,
) => {
  if (!date) return null;

  const dateToFormat = new Date(date);

  let weekDay = dateToFormat.getUTCDay();
  weekDay = DATE.DAYS_LIST[weekDay];

  let day = dateToFormat.getUTCDate();
  if (padDay) {
    day = day < 10 ? '0' + day : day;
  }

  let month = dateToFormat.getUTCMonth();
  month = DATE.MONTHS_LIST[month];

  let year = '';
  if (includeYear) {
    year = dateToFormat.getUTCFullYear();
  }
  return [includeWeekDay ? weekDay : null, day, month, year].filter((row) => row).join(' ');
};

export const formatDateTime = (date) => {
  if (!date) {
    return '';
  }
  const publicationDate = new Date(date);
  let day = publicationDate.getDate();
  day = day < 10 ? '0' + day : day;

  var month = publicationDate.getMonth() + 1;
  month = month < 10 ? '0' + month : month;

  var hour = publicationDate.getHours();
  hour = hour < 10 ? '0' + hour : hour;

  var minute = publicationDate.getMinutes();
  minute = minute < 10 ? '0' + minute : minute;

  return [day, '/', month, '/', publicationDate.getFullYear(), ' ', hour, ':', minute].join('');
};
