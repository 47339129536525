import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

import {
  loadFollowed,
  loadLibrary,
  loadLibraryPrintsOptions,
  loadLoanedAlbums,
  loadSelectedAlbumPrints,
  loadSignedAlbums,
  loadUserAgenda,
  loadUserStats,
  loadWishlistAlbums,
} from 'bubble-reducers/src/reducers/profiles';
import { loadUser } from 'bubble-reducers/src/reducers/user';

import { isValidObjectId } from 'bubble-utils/src/validity-utils';

import { getSeoForUser } from '@/services/seo-utils';

import asyncComponent from '@/components/AsyncComponent/AsyncComponent';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';

import PrivacyRerouter from './components/PrivacyRerouter';
import UserProfileHeader from './components/UserProfileHeader/UserProfileHeader';

import { GENERAL } from 'bubble-constants';

import './UserProfile.scss';

const AsyncMyCollection = asyncComponent(() => import('./components/MyCollection/MyCollection'));
const AsyncMyAgenda = asyncComponent(() => import('./components/MyAgenda'));
const AsyncMyMissingAlbums = asyncComponent(
  () => import('./components/MyMissingAlbums/MyMissingAlbums'),
);
const AsyncMyStats = asyncComponent(() => import('./components/MyStats/MyStats'));
const AsyncMyWishlist = asyncComponent(() => import('./components/MyWishlist/MyWishlist'));

export const tabs = {
  MY_LIBRARY: 'ma-collection',
  MY_MISSING_ALBUMS: 'mes-albums-manquants',
  MY_WISHLIST: 'ma-wishlist',
  MY_AGENDA: 'mon-agenda',
  MY_STATS: 'mes-statistiques',
};

const UserProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const otherProfiles = useSelector((state) => state.profiles.otherProfiles);
  const user = useSelector((state) => state.user.user);
  const stats = useSelector((state) => state.profiles.stats);
  const otherUsers = useSelector((state) => state.user.otherUsers);

  const pathname = location.pathname;
  const userObjectId = user?.objectId;
  const urlUserObjectId = pathname.split('/')[2];
  const isGuest = userObjectId !== urlUserObjectId;

  let otherUser = null;
  let otherProfile = null;
  let seo = null;
  if (isGuest) {
    otherUser = otherUsers[urlUserObjectId];
    otherProfile = otherProfiles[urlUserObjectId];
    seo = getSeoForUser(otherUser || {}, (otherProfile || {}).stats || {}, pathname);
  } else {
    seo = getSeoForUser(user, stats, pathname);
  }

  useEffect(() => {
    if (urlUserObjectId && isValidObjectId(urlUserObjectId)) {
      if (urlUserObjectId !== userObjectId) {
        dispatch(loadLibrary({ libraryObjectId: urlUserObjectId, isGuest }));
        dispatch(loadLibraryPrintsOptions({ libraryObjectId: urlUserObjectId, isGuest }));
        dispatch(loadFollowed({ libraryObjectId: urlUserObjectId, isGuest }));
        dispatch(loadSignedAlbums({ libraryObjectId: urlUserObjectId, isGuest }));
        dispatch(loadLoanedAlbums({ libraryObjectId: urlUserObjectId, isGuest }));
      }
      if (userObjectId && urlUserObjectId === userObjectId) {
        dispatch(loadSelectedAlbumPrints({ libraryObjectId: userObjectId, isGuest }));
      }
      dispatch(loadUserStats({ libraryObjectId: urlUserObjectId, isGuest }));
      dispatch(loadWishlistAlbums({ libraryObjectId: urlUserObjectId, isGuest }));
      dispatch(loadUserAgenda({ libraryObjectId: urlUserObjectId, isGuest }));
      dispatch(loadUser({ userObjectId: urlUserObjectId, isGuest }));
    }
  }, [pathname.split('/')[2], urlUserObjectId, userObjectId]);

  return (
    <>
      <BubbleHelmet seo={seo || {}} />
      <UserProfileHeader
        isGuest={isGuest}
        user={isGuest ? otherUsers[urlUserObjectId] : user}
        userObjectId={isGuest ? urlUserObjectId : null}
      />
      <div className="bb-background-light-grey">
        <Routes>
          <Route
            path={`/:userObjectId/${tabs.MY_LIBRARY}`}
            element={
              <PrivacyRerouter
                otherUser={isGuest ? otherUser : null}
                flag={GENERAL.FLAG_PRIVACY_MY_BDS}
                route={
                  <AsyncMyCollection
                    isGuest={isGuest}
                    userObjectId={isGuest ? urlUserObjectId : null}
                  />
                }
              />
            }
          />
          <Route
            path={`/:userObjectId/${tabs.MY_MISSING_ALBUMS}`}
            element={
              <PrivacyRerouter
                otherUser={isGuest ? otherUser : null}
                flag={GENERAL.FLAG_PRIVACY_MY_MISSING_ALBUMS}
                route={
                  <AsyncMyMissingAlbums
                    isGuest={isGuest}
                    userObjectId={isGuest ? urlUserObjectId : null}
                  />
                }
              />
            }
          />
          <Route
            path={`/:userObjectId/${tabs.MY_WISHLIST}`}
            element={
              <PrivacyRerouter
                otherUser={isGuest ? otherUser : null}
                flag={GENERAL.FLAG_PRIVACY_MY_WISHLIST}
                route={
                  <AsyncMyWishlist
                    isGuest={isGuest}
                    userObjectId={isGuest ? urlUserObjectId : null}
                  />
                }
              />
            }
          />
          <Route
            path={`/:userObjectId/${tabs.MY_AGENDA}`}
            element={
              <PrivacyRerouter
                otherUser={isGuest ? otherUser : null}
                flag={GENERAL.FLAG_PRIVACY_MY_AGENDA}
                route={
                  <AsyncMyAgenda
                    isGuest={isGuest}
                    userObjectId={isGuest ? urlUserObjectId : null}
                  />
                }
              />
            }
          />
          <Route
            path={`/:userObjectId/${tabs.MY_STATS}`}
            element={
              <PrivacyRerouter
                otherUser={isGuest ? otherUser : null}
                flag={GENERAL.FLAG_PRIVACY_MY_STATS}
                route={
                  <AsyncMyStats
                    user={isGuest ? otherUser : user}
                    isGuest={isGuest}
                    userObjectId={isGuest ? urlUserObjectId : null}
                  />
                }
              />
            }
          />
        </Routes>
        <div style={{ height: 100 }} />
      </div>
    </>
  );
};

export default UserProfile;
