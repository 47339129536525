import React from 'react';

export default function YoutubeEmbed({ videoId, height }) {
  if (!videoId) return null;

  const youtubeEmbedUrl = `https://www.youtube.com/embed/${videoId}`;

  const options = {};
  if (height) options.height = height;

  return (
    <iframe
      className="d-flex flex-fill w-100"
      src={youtubeEmbedUrl}
      title={`YouTube video player for ${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      {...options}
    />
  );
}
