import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  ADD_SERIE_REVIEW,
  EDIT_SERIE_REVIEW,
  addSerieReview,
  editSerieReview,
} from 'bubble-reducers/src/reducers/reviews';
import { editUser, loadUser } from 'bubble-reducers/src/reducers/user';

import { countReviewCharacters } from 'bubble-utils/src/string-utils';

import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';

import { GENERAL } from 'bubble-constants';

import './Modals.css';

const AddSerieReviewModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const serieObjectId = props.serieObjectId;
  const reviewObjectId = props.reviewObjectId;

  const user = useSelector((state) => state.user.user);
  const serie = useSelector((state) => state.series.series[props.serieObjectId]);
  const loading = useSelector(
    (state) => state.reviews.loading[ADD_SERIE_REVIEW] || state.reviews.loading[EDIT_SERIE_REVIEW],
  );

  const userObjectId = user?.objectId;
  const initialNote = props.initialNote;
  const initialComment = props.initialComment;

  const [note, setNote] = useState(null);
  const [comment, setComment] = useState(initialNote);
  const [username, setUsername] = useState(initialComment);

  useEffect(() => {
    dispatch(loadUser({ userObjectId }));
  }, []);

  const dismiss = () => {
    setComment(null);
    setNote(null);
    props.callback();
  };

  const sendReview = () => {
    if (!user?.username && !username) {
      return alert('Il vous faut un pseudonyme pour pouvoir commenter 💬');
    }

    if (username) {
      dispatch(editUser({ userObjectId, user: { username: username } }));
    }
    const review = { userObjectId, serieObjectId, note: note, comment: comment };
    reviewObjectId
      ? dispatch(editSerieReview({ reviewObjectId, review }))
      : dispatch(addSerieReview({ review }));

    navigate(`/${serie.permalink}/serie/${serieObjectId}/avis`);
    dismiss();
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centeredcontentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        <div className="bb-l-text-size mb-3">
          Votre avis sur la série <span className="fw-bold">{serie?.title || ''}</span>
        </div>
        {!user?.username && (
          <div className="mt-3">
            <LabeledTextInput
              white
              title="Pseudonyme"
              mandatory
              value={username}
              placeholder="Pseudonyme"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        )}
        <div className="form-group mt-3">
          <label
            className="text-secondary font-style-italic d-block"
            htmlFor="exampleFormControlTextarea1"
          >
            {countReviewCharacters(comment)}/{GENERAL.REWARD_PROGRAM.MINIMUM_REVIEW_LENGTH}
            <div className="text-pink float-end">
              Gagnez {GENERAL.REWARD_PROGRAM.POINTS_FOR_NEW_REVIEW} bubbles pour chaque avis &gt;{' '}
              {GENERAL.REWARD_PROGRAM.MINIMUM_REVIEW_LENGTH} signes
            </div>
          </label>
          <textarea
            value={comment || ''}
            onChange={(e) => setComment(e.target.value)}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          />
        </div>
        <div>
          Charte de bonne conduite : pas de gros mot, pas d'insulte, on reste sympa et bienveillant
          🤓.
          <br />
          <Link to={'programme-de-fidelite'}>Cliquez-ici</Link> pour en savoir plus sur les critères
          de validation de votre avis.
        </div>
      </div>
      <div className="modal-footer">
        <button
          onClick={sendReview}
          disabled={loading}
          className="btn btn-success btn-lg text-white"
        >
          Publier
        </button>
      </div>
    </Modal>
  );
};

AddSerieReviewModal.propTypes = {
  serieObjectId: PropTypes.string,
  initialNote: PropTypes.number,
  initialComment: PropTypes.string,
  reviewObjectId: PropTypes.string,
};

export default AddSerieReviewModal;
