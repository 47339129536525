import addresses from 'bubble-reducers/src/reducers/addresses';
import agendas from 'bubble-reducers/src/reducers/agendas';
import albums from 'bubble-reducers/src/reducers/albums';
import appOptions from 'bubble-reducers/src/reducers/app-options';
import articles from 'bubble-reducers/src/reducers/articles';
import auth from 'bubble-reducers/src/reducers/auth';
import authors from 'bubble-reducers/src/reducers/authors';
import availabilityAlerts from 'bubble-reducers/src/reducers/availability-alerts';
import banners from 'bubble-reducers/src/reducers/banners';
import cart from 'bubble-reducers/src/reducers/cart';
import catalogs from 'bubble-reducers/src/reducers/catalogs';
import categories from 'bubble-reducers/src/reducers/categories';
import collections from 'bubble-reducers/src/reducers/collections';
import comments from 'bubble-reducers/src/reducers/comments';
import countries from 'bubble-reducers/src/reducers/countries';
import events from 'bubble-reducers/src/reducers/events';
import filters from 'bubble-reducers/src/reducers/filters';
import genres from 'bubble-reducers/src/reducers/genres';
import highlightedResources from 'bubble-reducers/src/reducers/highlighted-resources';
import inAppPurchases from 'bubble-reducers/src/reducers/in-app-purchases';
import likes from 'bubble-reducers/src/reducers/likes';
import orders from 'bubble-reducers/src/reducers/orders';
import paymentMethods from 'bubble-reducers/src/reducers/payment-methods';
import prints from 'bubble-reducers/src/reducers/prints';
import products from 'bubble-reducers/src/reducers/products';
import profiles from 'bubble-reducers/src/reducers/profiles';
import promotions from 'bubble-reducers/src/reducers/promotions';
import reviews from 'bubble-reducers/src/reducers/reviews';
import search from 'bubble-reducers/src/reducers/search';
import series from 'bubble-reducers/src/reducers/series';
import stores from 'bubble-reducers/src/reducers/stores';
import tags from 'bubble-reducers/src/reducers/tags';
import tops from 'bubble-reducers/src/reducers/tops';
import types from 'bubble-reducers/src/reducers/types';
import user from 'bubble-reducers/src/reducers/user';

import websiteInterface from '../engine/reducers/website-interface';
import websiteOther from '../engine/reducers/website-other';

const reducers = {
  // from bubble-reducers
  addresses,
  agendas,
  albums,
  appOptions,
  articles,
  auth,
  authors,
  availabilityAlerts,
  banners,
  cart,
  catalogs,
  categories,
  collections,
  comments, // not in app
  countries,
  events,
  filters,
  genres,
  highlightedResources, // not in app
  inAppPurchases,
  likes,
  orders,
  paymentMethods,
  prints,
  products,
  profiles,
  promotions,
  reviews,
  search,
  series,
  stores,
  tags,
  tops,
  types,
  user,
  // from bubble-website
  websiteInterface,
  websiteOther,
};

export default reducers;
