import React from 'react';

import './VerifiedPurchaseBadge.scss';

const VerifiedPurchaseBadge = (props) => {
  return (
    <div className={`d-inline-block verified-purchase-badge bb-s-text-size ${props.className}`}>
      achat vérifié
    </div>
  );
};

VerifiedPurchaseBadge.propTypes = {};

export default VerifiedPurchaseBadge;
