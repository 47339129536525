import PropTypes from 'prop-types';
import React from 'react';

import './TitledListContainer.scss';

/**
 * Renders a container containing a title and an horizontal list
 */

const TitledListContainer = ({
  title,
  list,
  topRightLink,
  noWrap,
  titleClassName,
  className,
  rowClassName,
  rightLinkWrapToBottomThreshold,
}) => (
  <div className={className || 'py-5 w-100'}>
    {(title || topRightLink) && (
      <div className="d-flex align-items-center pb-md-3">
        <div
          className={`flex-fill ${titleClassName || 'bb-medium-large-text-size bb-text-500'} m-0 `}
        >
          {title}
        </div>
        <div
          className={
            !!rightLinkWrapToBottomThreshold
              ? `d-none d-${rightLinkWrapToBottomThreshold}-block`
              : ''
          }
        >
          {topRightLink}
        </div>
      </div>
    )}

    <div
      className={`row ${!!noWrap ? 'flex-nowrap flex-md-wrap titled-list-container-no-wrap' : ''} ${
        rowClassName || ''
      }`}
    >
      {(list || []).map((item) => item)}
    </div>
    {!!rightLinkWrapToBottomThreshold && (
      <div
        className={`d-flex align-items-center justify-content-center pt-4 d-${rightLinkWrapToBottomThreshold}-none`}
      >
        {topRightLink}
      </div>
    )}
  </div>
);

TitledListContainer.propTypes = {
  title: PropTypes.any,
  list: PropTypes.array,
  topRightLink: PropTypes.object,
  noWrap: PropTypes.any,
  rightLinkWrapToBottomThreshold: PropTypes.string,
  rowClassName: PropTypes.string,
};

const areEqual = (prevProps, nextProps) => {
  if (nextProps.noEqualityChecks) {
    return false;
  }
  const hash1 = nextProps.list.reduce((acc, cur) => acc.concat(cur.key), '');
  const hash2 = prevProps.list.reduce((acc, cur) => acc.concat(cur.key), '');
  return prevProps.title === nextProps.title && hash1 === hash2;
};

export default React.memo(TitledListContainer, areEqual);
