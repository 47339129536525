import { remove as removeDiacritics } from 'diacritics';

export const isString = (value) => {
  return typeof value === 'string' || value instanceof String;
};

export const countReviewCharacters = (text) => {
  return (text || '').replace(/\s/gi, '').length;
};

export const removeSpecialChars = (text) => removeDiacritics(text || '');

// almost duplicated in bubble-backend repo
export const generateRandomString = (size, forceHumanReadable = false, addLowerCase = false) => {
  if (size === 0) {
    throw new Error('Zero-length generateRandomString is useless.');
  }
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + '0123456789';
  if (addLowerCase) {
    chars += 'abcdefghijklmnopqrstuvwxyz';
  }
  if (forceHumanReadable) {
    chars = chars.replace(/(i|o|I|O|1|0)/g, '');
  }
  let hash = '';
  const array = new Uint8Array(size);
  const bytes = window.crypto.getRandomValues(array);
  for (var i = 0; i < bytes.length; ++i) {
    hash += chars[bytes[i] % chars.length];
  }
  return hash;
};

export const generateObjectId = () => {
  return generateRandomString(14, false, true);
};

export const pad = (string, letter, length) => {
  const toFill = length - string.length;
  if (toFill <= 0) return string;
  return `${[...Array(length - string.length)].map(() => letter).join('')}${string}`;
};

export const generateIndexedString = (text) => {
  // add a default serie title because we must have one,
  // with '###' to get it at the top of the ordered list
  var titleIndexed = text ? text.toLowerCase() : '###';
  var twoFirst = titleIndexed.slice(0, 2);
  if (twoFirst === "l'") {
    titleIndexed = titleIndexed.slice(2);
  }
  var threeFirst = titleIndexed.slice(0, 3);
  if (threeFirst === 'le ' || threeFirst === 'la ') {
    titleIndexed = titleIndexed.slice(3);
  }
  var fourFirst = titleIndexed.slice(0, 4);
  if (fourFirst === 'les ') {
    titleIndexed = titleIndexed.slice(4);
  }
  return titleIndexed;
};

export const buildQueryString = (params) => {
  const queryString = Object.keys(params || {})
    .map((key) => (params[key] ? `${key}=${encodeURIComponent(params[key])}` : null))
    .filter((value) => value)
    .join('&');
  return queryString ? `?${queryString}` : '';
};

export const buildSortedQueryString = (params) => {
  const queryString = Object.keys(params || {})
    .sort()
    .map((key) => (params[key] ? `${key}=${encodeURIComponent(params[key])}` : null))
    .filter((value) => value)
    .join('&');
  return queryString ? `?${queryString}` : '';
};

export const buildSortedQueryStringFromParams = (urlParams) => {
  urlParams.sort();
  const queryString = urlParams.toString();
  return queryString ? `?${queryString}` : '';
};

export const convertUrlParamsToObject = (urlParams) => {
  return Object.fromEntries(urlParams);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const fallbackCopyToClipboard = (text) => {
  if (!document) {
    console.error('no documen');
    return null;
  }
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed'; //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(textArea);
};

export const copyToClipboard = (text) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(text);
  } else {
    fallbackCopyToClipboard(text);
  }
};

export const formatTextForSearch = (text) => {
  // format ISBN to 13 digits
  const cleanText = text ? text.replace(/-/gi, '') : '';
  // check if the text is a number without tiling spaces
  // isFinite is there to handle the special case where text is "Infinity"
  if (
    Number(cleanText) == cleanText &&
    Number.isFinite(cleanText) &&
    String(Number(cleanText)).length === cleanText.length
  ) {
    text = cleanText;
    // add 978 at the begining for ISBN staring without it
    if (
      text.length > 4 &&
      text.length <= 10 &&
      text.slice(0, 3) !== '978' &&
      text.slice(0, 3) !== '979'
    ) {
      text = '978' + text;
    }
  }
  return text;
};
