import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadProductsApi } from '../services/api';

import { getClientToken } from '../selectors';

const LOAD_PRODUCTS = 'products/loadProducts';

const initialState = {
  products: [],
  loading: {},
  errors: {},
};

export const loadProducts = createAsyncThunk(LOAD_PRODUCTS, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const products = await loadProductsApi(clientToken);
  return { products };
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadProducts
      .addCase(loadProducts.pending, (state, action) => {
        state.loading[LOAD_PRODUCTS] = true;
        state.errors[LOAD_PRODUCTS] = null;
      })
      .addCase(loadProducts.fulfilled, (state, action) => {
        const products = Object.assign({}, state.products);
        action.payload.products.map((product) => (products[product.objectId] = product));
        state.products = products;
        state.loading[LOAD_PRODUCTS] = false;
        state.errors[LOAD_PRODUCTS] = null;
      })
      .addCase(loadProducts.rejected, (state, action) => {
        state.loading[LOAD_PRODUCTS] = false;
        state.errors[LOAD_PRODUCTS] = action.error;
      });
  },
});

export default productsSlice.reducer;
