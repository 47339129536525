import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  getArticlesFromIds,
  getAuthorsFromIds,
  getSeriesFromIds,
} from 'bubble-reducers/src/selectors';

import ArticleHorizontalCard from '@/components/ArticleHorizontalCard/ArticleHorizontalCard';
import SerieCard from '@/components/SerieCard/SerieCard';

const defaultArray = [];

const MenuDropdown = ({ clickedHeaderMenu: type, hideMenu }) => {
  const authorsFromReducer =
    useSelector((state) => getAuthorsFromIds(state, state.authors.menuAuthorsObjectIds[type])) ||
    defaultArray;
  const authors = useMemo(() => authorsFromReducer.slice(0, 15), [authorsFromReducer]);
  const seriesFromReducer =
    useSelector((state) => getSeriesFromIds(state, state.series.menuSeriesObjectIds[type])) ||
    defaultArray;
  const series = useMemo(() => seriesFromReducer.slice(0, 2), [seriesFromReducer]);
  const articles =
    useSelector((state) => getArticlesFromIds(state, state.articles.menuArticlesObjectIds[type])) ||
    defaultArray;
  const allGenres = useSelector((state) => state.genres.genres);
  const genres = useMemo(
    () => allGenres.filter((genre) => !!genre[type]).slice(0, 15),
    [allGenres, type],
  );

  return (
    <>
      <div className="col-md-2">
        <div className="fw-bold">Genres {type}</div>
        {genres.map((genre) => (
          <Link
            key={genre.objectId}
            onClick={hideMenu}
            className="d-block no-decoration text-black"
            to={`/list?category=${type}&genre=${encodeURIComponent(genre.nameFrench)}`}
          >
            {genre.nameFrench}
          </Link>
        ))}
      </div>
      <div className="col-md-2">
        <div className="fw-bold">Auteurs les plus populaires</div>
        {authors.map((author) => (
          <Link
            key={'menu' + author.objectId}
            onClick={hideMenu}
            className="d-block no-decoration text-black"
            to={`/${author.permalink}/author/${author.objectId}`}
          >
            {author.displayName}
          </Link>
        ))}
      </div>
      <div className="col-md-4">
        <div className="fw-bold">Séries populaires</div>
        <div className="row">
          {series.map((serie) => (
            <div className="col-md-12 mt-3" key={serie.objectId} onClick={hideMenu}>
              <SerieCard grey serieObjectId={serie.objectId} />
            </div>
          ))}
        </div>
      </div>

      <div className="col-md-4">
        <div className="fw-bold">Pour bien commencer</div>
        <div className="row">
          {articles.map((article) => (
            <div className="col-md-12 mt-3" key={article.objectId} onClick={hideMenu}>
              <ArticleHorizontalCard article={article} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

MenuDropdown.propTypes = {
  hideMenu: PropTypes.func.isRequired,
};

export default MenuDropdown;
