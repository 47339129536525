import React, { useState } from 'react';

import { formatCurrency } from 'bubble-utils/src/currency-utils';

import './PriceRangeSlider.scss';

const PriceRangeSlider = ({ onChange, minValue = 0, maxValue = 100 }) => {
  const [value, setValue] = useState(maxValue);

  const onShouldNotifyParent = (e) => {
    if (onChange) onChange(e.target.value);
  };

  const onValuechange = (e) => setValue(e.target.value);

  return (
    <div className="slidecontainer">
      <div className="d-flex justify-content-between">
        <div>{formatCurrency(0)}</div>
        <div>{formatCurrency(value)}</div>
      </div>
      <input
        onMouseUp={onShouldNotifyParent}
        onChange={onValuechange}
        type="range"
        min={minValue}
        max={maxValue}
        value={value}
        className="slider"
      />
    </div>
  );
};

export default PriceRangeSlider;
