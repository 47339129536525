import React, { useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';
import Tooltip from '@/components/Tooltip/Tooltip';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './BdcatRedirectButton.scss';

const pathToBdcat = {
  album: {
    idIndex: 4,
    bdcatPath: 'prints',
  },
  serie: {
    idIndex: 3,
    bdcatPath: 'series',
  },
};
const bdcatDomain = 'https://services.bubblebd.com/bdcat';

export default function BdcatRedirectButton() {
  const user = useSelector((state) => state.user.user);
  const location = useLocation();
  const path = location.pathname;
  const isAdmin = user && user.roles && user.roles.includes('admin');
  const [show, _setShow] = useState(true);

  const onHide = useCallback(() => {
    _setShow(false);
  }, [_setShow]);

  const deconstructedPath = useMemo(() => path.split('/'), [path]);
  const dic = useMemo(() => {
    const tag = deconstructedPath[2];
    return pathToBdcat[tag];
  }, [deconstructedPath]);

  const onClickBdcat = useCallback(() => {
    const id = deconstructedPath[dic.idIndex];
    window.open([bdcatDomain, dic.bdcatPath, id].join('/'));
  }, [dic, deconstructedPath]);

  if (!isAdmin) return null;
  if (!dic) return null;
  if (!show) return null;

  return (
    <div className="bdcat-button">
      <div className="bdcat-button-close">
        <WithClickHandler onClick={onHide}>
          <Icon name="times" />
        </WithClickHandler>
      </div>
      <WithClickHandler onClick={onClickBdcat}>
        <Tooltip tip={`bdcat ${dic.bdcatPath}`}>
          <img
            className="bdcat-button-icon"
            src="https://static-assets.bubblebd.com/misc/cat.png"
            alt="cat"
          />
        </Tooltip>
      </WithClickHandler>
    </div>
  );
}
