import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

export default function BottomRightContainer({ albumObjectId, editorChoice = null }) {
  const promotions = useSelector((state) => state.promotions.promotionsMapByObjectId);
  const associatedPromotion = useMemo(
    () =>
      Object.values(promotions).find((promotion) => {
        const potentialAttachedAlbum = promotion.attachedAlbums?.find(
          (album) => album.objectId === albumObjectId,
        );
        const potentialAttachedAlbumInSerie = promotion.attachedSeries?.find((serie) =>
          serie.albums.some((album) => album.objectId === albumObjectId),
        );
        return !!potentialAttachedAlbum || potentialAttachedAlbumInSerie;
      }),
    [albumObjectId, promotions],
  );

  return (
    <>
      {(!!associatedPromotion || !!editorChoice) && (
        <div className="bottom-right-tags-container">
          {!!associatedPromotion && (
            <div className="album-card-tag-container tag-container-promotion">Promotion</div>
          )}
          {!!editorChoice && (
            <div className="album-card-tag-container tag-container-editor-choice">
              Coup de coeur
            </div>
          )}
        </div>
      )}
    </>
  );
}
