import * as albumUtils from './src/album-utils';
import * as arrayUtils from './src/array-utils';
import * as authorUtils from './src/author-utils';
import * as codeUtils from './src/code-utils';
import * as currencyUtils from './src/currency-utils';
import * as dateUtils from './src/date-utils';
import * as envUtils from './src/env-utils';
import * as eventUtils from './src/event-utils';
import * as filterUtils from './src/filter-utils';
import * as imageUtils from './src/image-utils';
import * as infinityUtils from './src/infinity-utils';
import * as numberUtils from './src/number-utils';
import * as objectUtils from './src/object-utils';
import * as orderUtils from './src/order-utils';
import * as stringUtils from './src/string-utils';
import * as utmUtils from './src/utm-utils';
import * as validityUtils from './src/validity-utils';

export default {
  array: arrayUtils,
  author: authorUtils,
  currency: currencyUtils,
  date: dateUtils,
  env: envUtils,
  event: eventUtils,
  infinity: infinityUtils,
  number: numberUtils,
  object: objectUtils,
  order: orderUtils,
  string: stringUtils,
  utm: utmUtils,
  validity: validityUtils,
  filter: filterUtils,
  album: albumUtils,
  image: imageUtils,
  code: codeUtils,
};
