import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { loadAuthorsAtAlphanumericIndex } from 'bubble-reducers/src/reducers/authors';

import { getSeoForIndexedAuthors } from '../../services/seo-utils';

import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';

import AlphanumericBar from './components/AlphanumericBar';
import Header from './components/Header';

const IndexAuthors = () => {
  const dispatch = useDispatch();
  const pageIndex = useParams().pageIndex || '';
  const index = pageIndex === 'nouveautes' ? 'new' : pageIndex;
  const authors = useSelector((state) => state.authors.authorsAlphanumericIndexMap[index]) || [];

  useEffect(() => {
    dispatch(loadAuthorsAtAlphanumericIndex({ index }));
  }, [index]);

  return (
    <>
      <BubbleHelmet seo={getSeoForIndexedAuthors(index)} />
      <Header selected="authors" />
      <div className="container">
        <div className="py-4">
          <AlphanumericBar selected={index} type="authors" />
        </div>
        {authors.map((author) => {
          const titleAddons = [author.pseudo ? author.pseudo : null].filter((row) => row);
          return (
            <div key={author.objectId}>
              <Link
                className="link-to-orange no-decoration"
                to={`/${author.permalink}/author/${author.objectId}`}
              >
                {author.lastName
                  ? author.lastName && author.firstName
                    ? `${author.lastName}, ${author.firstName}`
                    : author.lastName
                  : author.firstName}
                <span className="font-italic">
                  {!!titleAddons.length && ' - '}
                  {[author.pseudo ? author.pseudo : null].filter((row) => row).join(' - ')}
                </span>
              </Link>
            </div>
          );
        })}
        <div className="py-4" />
      </div>
    </>
  );
};

export default IndexAuthors;
