import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Routes, useParams } from 'react-router-dom';

import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { loadStore } from 'bubble-reducers/src/reducers/stores';
import { loadUserFavoriteStores } from 'bubble-reducers/src/reducers/user';

import { getSeoForStore } from '@/services/seo-utils';

import ArticleCard from '@/components/ArticleCard/ArticleCard';
import asyncComponent from '@/components/AsyncComponent/AsyncComponent';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import RelatedArticles from '@/components/RelatedArticles/RelatedArticles';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import StoreDetails from './components/StoreDetails';
import StoreHeader from './components/StoreHeader';
import StoreHome from './components/StoreHome';

import './Store.css';

const AsyncStoreStockSearch = asyncComponent(
  () => import('./components/StoreStockSearch/StoreStockSearch'),
);

export const tabs = {
  HOME: '/',
  EVENTS: 'evenements',
  STORE: 'notre-librairie',
};

const Store = () => {
  const dispatch = useDispatch();

  const { storeObjectId } = useParams();

  const store = useSelector((state) => state.stores.stores[storeObjectId]) || {};
  const user = useSelector((state) => state.user.user);
  const highlightedArticleObjectIds = useSelector(
    (state) => state.articles.highlightedArticleObjectIds,
  );
  const articles = useSelector((state) => state.articles.articlesMapByObjectId); //TODO: whole article list for no reason

  const [searchText, setSearchText] = useState(null);
  const [isSearching, setIsSearching] = useState(!!searchText);

  useEffect(() => {
    dispatch(loadArticles({ options: { highlighted: 1 } }));
    if (user?.objectId) {
      dispatch(loadUserFavoriteStores({ userObjectId: user.objectId }));
    }
  }, [user.objectId]);

  useEffect(() => {
    if (storeObjectId) {
      dispatch(loadStore({ storeObjectId }));
    }
  }, [storeObjectId]);

  return (
    <div className="bb-background-light-grey">
      {!!storeObjectId && <BubbleHelmet seo={getSeoForStore(store)} />}

      <StoreHeader
        storeObjectId={storeObjectId}
        searchText={searchText}
        onSearchTextChange={(text) => {
          setSearchText(text);
          setIsSearching(!!text);
        }}
      />
      {!isSearching && (
        <Routes>
          <Route path="/" element={<StoreHome storeObjectId={storeObjectId} />} />
          <Route path={`/${tabs.STORE}`} element={<StoreDetails storeObjectId={storeObjectId} />} />
        </Routes>
      )}

      {isSearching && (
        <AsyncStoreStockSearch searchText={searchText} storeObjectId={storeObjectId} />
      )}

      <RelatedArticles white resourceObjectId={storeObjectId} />

      <div className="nart-background-grey">
        <div className="container">
          <div className="px-sm-3">
            <TitledListContainer
              noWrap
              title={
                <>
                  <div>Suivez toute l'actualité BD, Comics et Mangas sur notre blog</div>
                  <Link className="d-md-none bb-regular-text-size fw-normal y-2" to="/9emeart">
                    Accéder au blog Bubble
                  </Link>
                </>
              }
              topRightLink={
                <Link className="d-none d-md-block" to="/9emeart">
                  Accéder au blog Bubble
                </Link>
              }
              list={highlightedArticleObjectIds.slice(0, 4).map((articleObjectId) => (
                <ArticleCard
                  className="col-10 col-md-6 col-lg-3 pt-3"
                  key={`article-${articleObjectId}`}
                  article={articles[articleObjectId]}
                />
              ))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
