import React from 'react';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import AlbumCardAgendaSource from '@/components/AlbumCard/components/AlbumCardAgendaSource';
import NewsletterOptInCard from '@/components/NewsletterOptIn/NewsletterOptInCard';

import bubbleUtils from 'bubble-utils';

import '../AgendaSections.css';

const AgendaRow = ({ params, albums, itemsPerRow, isSelf }) => {
  const { key, index, isVisible, style } = params;
  const items = [];
  const fromIndex = index * itemsPerRow;
  const toIndex = Math.min(fromIndex + itemsPerRow, albums.length);

  let pubDate = '';
  for (let i = fromIndex; i < toIndex; i++) {
    const album = albums[i];
    if (album?.isReOptIn) {
      items.push(
        <div key="reoptin" className={`Item${!isSelf ? ' pb-3' : ''}`}>
          <NewsletterOptInCard />
        </div>,
      );
    } else if (!album) {
      items.push(
        <div key={'elem-' + key + i} className="Item agenda-section-filler-element"></div>,
      );
    } else {
      items.push(
        <div key={'elem-' + key + i} className="Item">
          <AlbumCard
            forcePublicationDate={true}
            albumObjectId={album.objectId}
            printObjectId={album.agendaPrintObjectId}
            bottomAddon={isSelf ? <AlbumCardAgendaSource album={album} /> : null}
          />
        </div>,
      );
    }
    if (album && pubDate === '') {
      pubDate = album.publicationDate;
    }
  }

  return (
    <div
      data-publicationdate={
        pubDate
          ? (bubbleUtils.date.getFirstDayOfTheWeekDate(pubDate) || new Date()).toISOString()
          : new Date().toISOString()
      }
      data-isvisible={isVisible}
      className="Row"
      key={key}
      style={style}
    >
      {items}
    </div>
  );
};

export default AgendaRow;
