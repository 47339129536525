import React from 'react';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { loadStoreBestSellers } from 'bubble-reducers/src/reducers/stores';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

const StoreHome = (props) => {
  const dispatch = useDispatch();

  const storeObjectId = props.storeObjectId;
  const store = useSelector((state) => state.stores.stores[storeObjectId]) || {};
  const bestSellers = useSelector((state) => state.stores.storesBestSellers[storeObjectId]) || [];

  useEffect(() => {
    if (storeObjectId) {
      dispatch(loadStoreBestSellers({ storeObjectId }));
    }
  }, [storeObjectId]);

  return (
    <div className="container">
      <div className="px-sm-3">
        <h1 className="pt-3 mb-4 fw-bold bb-xl-text-size">
          Bienvenue chez {store.name || 'votre libraire'}
        </h1>
        <div
          className="d-flex bb-shadow-hover flex-fill rounded bg-cover store-page-showcase-image iso-placeholder mx-md-5"
          style={{
            backgroundImage: `url(${store.coverImage})`,
          }}
        />

        <h2 className="bb-large-text-size mb-n5 mt-5 fw-bold">Nos meilleures ventes</h2>
        <div className="mx-md-4">
          <TitledListContainer
            noWrap
            list={bestSellers.map((idAndScore) => (
              <AlbumCard
                key={`best_seller_${idAndScore.objectId}`}
                className="col-8 col-lg-2 col-md-4 pb-3"
                albumObjectId={idAndScore.objectId}
              />
            ))}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreHome;
