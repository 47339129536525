import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { addLikeApi, deleteLikeApi } from '../services/api';

import { likeComment, unlikeComment } from '../reducers/comments';
import { loadAlbumReviews, loadSerieReviews } from '../reducers/reviews';
import { loadUserLikes } from '../reducers/user';
import { getClientToken } from '../selectors';

const ADD_LIKE = 'likes/addLike';
const DELETE_LIKE = 'likes/deleteLike';

const initialState = {
  loading: {},
  errors: {},
};

export const addLike = createAsyncThunk(ADD_LIKE, async (params, { getState, dispatch }) => {
  const like = params?.like || null;

  const clientToken = getClientToken(getState());
  await addLikeApi(clientToken, like);
  if (like.serieObjectId) {
    await dispatch(loadSerieReviews({ serieObjectId: like.serieObjectId }));
  }
  if (like.albumObjectId) {
    await dispatch(loadAlbumReviews({ albumObjectId: like.albumObjectId }));
  }
  if (like.commentObjectId) {
    await dispatch(likeComment({ commentObjectId: like.commentObjectId }));
  }
  dispatch(loadUserLikes({ userObjectId: like.userObjectId }));
});

export const deleteLike = createAsyncThunk(DELETE_LIKE, async (params, { getState, dispatch }) => {
  const like = params?.like || null;

  const clientToken = getClientToken(getState());
  await deleteLikeApi(clientToken, like);

  if (like.serieObjectId) {
    await dispatch(loadSerieReviews({ serieObjectId: like.serieObjectId }));
  }
  if (like.albumObjectId) {
    await dispatch(loadAlbumReviews({ albumObjectId: like.albumObjectId }));
  }
  if (like.commentObjectId) {
    await dispatch(unlikeComment({ commentObjectId: like.commentObjectId }));
  }
  dispatch(loadUserLikes({ userObjectId: like.userObjectId }));
});

const likesSlice = createSlice({
  name: 'likes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // addLike
      .addCase(addLike.pending, (state, action) => {
        state.loading[ADD_LIKE] = true;
        state.errors[ADD_LIKE] = null;
      })
      .addCase(addLike.fulfilled, (state, action) => {
        state.loading[ADD_LIKE] = false;
        state.errors[ADD_LIKE] = null;
      })
      .addCase(addLike.rejected, (state, action) => {
        state.loading[ADD_LIKE] = false;
        state.errors[ADD_LIKE] = action.error;
      })
      // deleteLike
      .addCase(deleteLike.pending, (state, action) => {
        state.loading[DELETE_LIKE] = true;
        state.errors[DELETE_LIKE] = null;
      })
      .addCase(deleteLike.fulfilled, (state, action) => {
        state.loading[DELETE_LIKE] = false;
        state.errors[DELETE_LIKE] = null;
      })
      .addCase(deleteLike.rejected, (state, action) => {
        state.loading[DELETE_LIKE] = false;
        state.errors[DELETE_LIKE] = action.error;
      });
  },
});

export default likesSlice.reducer;
