const environment = process.env.REACT_APP_HOST_ENV || process.env.NODE_ENV || 'production';
const buildNumber = process.env.REACT_APP_BUILD_NUMBER;

const defaultConfig = {
  version: '1.0.0',
  platform: 'website',
  buildNumber: buildNumber,
  cookies: {
    defaultOptions: {
      domain: '.bubblebd.com',
      path: '/',
      secure: true,
      sameSite: 'lax',
    },
    jwtToken: {
      name: 'bubble-token',
    },
    sessionId: {
      name: 'bubble-sid',
    },
    sessionCount: {
      name: 'session-count',
      count: 0,
      lastSession: null,
    },
    wishlistBookings: {
      name: 'wishlist-bookings',
      bookings: {},
    },
  },
  localStorage: {
    user: {
      keyName: 'bubble-state',
    },
  },
};

const devConfig = {
  cookies: {
    defaultOptions: {
      domain: '.local.bubblebd.com',
      path: '/',
      secure: true,
      sameSite: 'lax',
    },
    jwtToken: {
      name: 'bubble-local-token',
    },
    sessionId: {
      name: 'bubble-local-sid',
    },
    sessionCount: {
      name: 'session-count',
      count: 0,
      lastSession: null,
    },
    wishlistBookings: {
      name: 'wishlist-bookings',
      bookings: {},
    },
  },
};

export default Object.assign(defaultConfig, environment === 'development' ? devConfig : {});
