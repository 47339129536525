import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { addSkusToCart } from 'bubble-reducers/src/reducers/cart';

import { Icon } from '@/components/Icon/Icon';
import AddedToCartModal from '@/components/Modals/AddedToCartModal';

import bubbleUtils from 'bubble-utils';

import '../GiftCard.css';

const GiftCardChooser = (props) => {
  const dispatch = useDispatch();
  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);

  const products = Object.values(props.products);

  const handleAddToCart = () => {
    dispatch(addSkusToCart({ skus: [{ sku: props.selected.sku, quantity: 1 }] }));
    setShowAddedToCartModal(true);
  };

  return (
    <>
      {showAddedToCartModal && (
        <AddedToCartModal
          show={showAddedToCartModal}
          itemIds={[{ sku: props.selected.sku, quantity: 1 }].map((sku) => sku.sku)}
          callback={() => setShowAddedToCartModal(false)}
        />
      )}

      <h1 className="line-height-36 pb-2">
        Chèque cadeau Bubble{' '}
        <span role="img" aria-label="gift">
          🎁
        </span>
      </h1>
      <h4 className="line-height-30 fw-normal pb-2">
        Anniversaire, Noël, nouveau diplôme ou simple envie de faire plaisir, toutes les occasions
        sont bonnes pour gâter un amateur de BD !
      </h4>
      <div className="line-height-24 bb-medium-text-size pb-4">
        <div className="text-success fw-bold">
          <Icon className="gift-card-icon" name="check" size="xs" />
          Valable 1 an sur tout le catalogue Bubble
        </div>
        <div>
          <Icon className="gift-card-icon text-success" name="check" size="xs" />
          Utilisable sur plusieurs commandes jusqu'à épuisement du solde
        </div>
        <div>
          <Icon className="gift-card-icon text-success ps-1" name="info" size="xs" />
          Valable uniquement pour les{' '}
          <span className="fw-bold">commandes en livraison à domicile ou en point relais</span>
        </div>
        <div>
          <Icon className="gift-card-icon text-success" name="check" size="xs" />A télécharger
          instantanément et à imprimer soi-même
        </div>
      </div>
      <div className="bb-medium-text-size pb-1">Choisissez le montant du chèque cadeau :</div>
      <div className="row">
        <div className="col-md-8">
          <div className="row g-0 pb-3">
            {products
              .sort((a, b) => a.price - b.price)
              .map((product, index) => (
                <div key={product.objectId} className="col-2 d-flex">
                  <GiftCardPrice product={product} selected={props.selected} index={index} />
                  {index !== products.length - 1 && <div className="px-1" />}
                </div>
              ))}
          </div>
          <button
            onClick={handleAddToCart}
            style={{ height: '3rem' }}
            className="btn btn-lg btn-success text-white fw-bold bb-medium-text-size"
          >
            Ajouter au panier
          </button>
        </div>
      </div>
    </>
  );
};

const GiftCardPrice = (props) => {
  const isSelected = props.product.objectId === props.selected.objectId;
  return (
    <Link
      style={{ height: 55 }}
      className={`bb-medium-text-size gift-card-price ${
        isSelected ? 'gfp-selected' : ''
      } rounded d-flex flex-fill align-items-center justify-content-center`}
      to={`/${props.product.permalink}/product/gift-card/${props.product.objectId}`}
    >
      {bubbleUtils.currency.formatCurrency(props.product.price, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })}
    </Link>
  );
};

GiftCardChooser.propTypes = {
  selected: PropTypes.shape({
    objectId: PropTypes.string,
    price: PropTypes.string,
    sku: PropTypes.string,
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({ objectId: PropTypes.string, price: PropTypes.string }),
  ),
};

export default GiftCardChooser;
