export const buildParams = (source, medium, campaign, content, term) => {
  return [
    source ? `utm_source=${source}` : null,
    medium ? `utm_medium=${medium}` : null,
    campaign ? `utm_campaign=${campaign}` : null,
    content ? `utm_content=${content}` : null,
    term ? `utm_term=${term}` : null,
  ]
    .filter((row) => row)
    .join('&');
};

export const buildPtCt = (providerToken, campaignToken) => {
  return [
    providerToken ? `pt=${providerToken}` : null,
    campaignToken ? `ct=${campaignToken}` : null,
  ]
    .filter((row) => row)
    .join('&');
};
