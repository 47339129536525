import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { loadAuthor } from 'bubble-reducers/src/reducers/authors';
import { createAuthorRevision } from 'bubble-reducers/src/reducers/catalogs';

import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';

import bubbleUtils from 'bubble-utils';

import './Modals.css';

const AuthorRevisionModal = (props) => {
  const dispatch = useDispatch();

  const authorObjectId = props.authorObjectId;

  const user = useSelector((state) => state.user.user);
  const author = useSelector((state) => state.authors.authors[authorObjectId]) || {};

  const [editedAuthor, setEditedAuthor] = useState({});

  useEffect(() => {
    if (authorObjectId) {
      dispatch(loadAuthor({ authorObjectId }));
    }
  }, [authorObjectId]);

  const dismiss = () => {
    props.callback();
  };

  const displayField = (field) => {
    if (field in editedAuthor) {
      return editedAuthor[field] ? editedAuthor[field] : '';
    } else if (author) {
      return author[field] ? author[field] : '';
    } else {
      return '';
    }
  };

  const onChangeValue = (field, value) =>
    setEditedAuthor({ ...editedAuthor, [field]: value || null });

  const sendRevision = () => {
    const keys = Object.keys(editedAuthor);

    const revisions = keys
      .map((key) => {
        if (editedAuthor[key] && editedAuthor[key] !== author[key]) {
          let newValue = editedAuthor[key];
          // erasing value
          if (newValue === 'unused') {
            newValue = null;
          }
          return {
            userObjectId: user.objectId,
            oldValue: author[key],
            newValue: newValue,
            fieldRevised: key,
          };
        }
        return null;
      })
      .filter((row) => row);

    if (revisions.length) {
      if (!!editedAuthor.websiteUrl && !bubbleUtils.validity.isValidUrl(editedAuthor.websiteUrl)) {
        return alert(
          'L\'url du site semble incorrect, il doit respecter la forme suivante : "https://www.siteauteur.com"',
        );
      }
      if (
        !!editedAuthor.facebookUrl &&
        !bubbleUtils.validity.isValidUrl(editedAuthor.facebookUrl)
      ) {
        return alert(
          'L\'url du profil Facebook semble incorrect, il doit respecter la forme suivante : "https://www.facebook.com/profilauteur"',
        );
      }
      if (
        !!editedAuthor.twitterUsername &&
        !bubbleUtils.validity.isValidUsername(editedAuthor.twitterUsername)
      ) {
        return alert(
          'Le username du profil Twitter semble incorrect, il doit respecter la forme suivante : "@profiltwitterauteur',
        );
      }
      if (
        !!editedAuthor.instagramUsername &&
        !bubbleUtils.validity.isValidUsername(editedAuthor.instagramUsername)
      ) {
        return alert(
          'Le username du profil Instagram semble incorrect, il doit respecter la forme suivante : "@profilinstagramauteur',
        );
      }
      dispatch(createAuthorRevision({ authorObjectId: author.objectId, revisions }));
      alert(
        "C'est envoyé ! un immense MERCI :). Votre contribution permet d'améliorer le catalogue et aide les autres utilisateurs",
      );
      dismiss();
    } else {
      alert("Aucun champ n'a l'air d'avoir été modifié, êtes-vous bien sûr de vos modifications ?");
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        <div className="bb-l-text-size">
          Signalement auteur <span className="fw-bold">{author.displayName}</span>
        </div>
        <div className="text-secondary">
          Une erreur dans les informations de l'auteur ? Remplacez-les ou ajoutez-les directement
          ici, en respectant bien les majuscules{' '}
          <span role="img" aria-label="plz">
            🙏
          </span>
        </div>
        <div className="mt-4">
          <LabeledTextInput
            white
            title="Année de naissance"
            placeholder={author.yearOfBirth}
            value={displayField('yearOfBirth')}
            onChange={(event) => onChangeValue('yearOfBirth', event.target.value)}
            type="number"
          />
        </div>
        <div className="mt-4">
          <LabeledTextInput
            white
            title="Année de décès"
            placeholder={author.yearOfDeath}
            value={displayField('yearOfDeath')}
            onChange={(event) => onChangeValue('yearOfDeath', event.target.value)}
            type="number"
          />
        </div>
        <div className="mt-4">
          <LabeledTextInput
            white
            title="Lien site web"
            placeholder="https://www.siteauteur.com"
            value={displayField('websiteUrl')}
            onChange={(event) => onChangeValue('websiteUrl', event.target.value)}
          />
        </div>
        <div className="mt-4">
          <LabeledTextInput
            white
            title="Lien Facebook"
            placeholder="https://www.facebook.com/profilauteur"
            value={displayField('facebookUrl')}
            onChange={(event) => onChangeValue('facebookUrl', event.target.value)}
          />
        </div>
        <div className="mt-4">
          <LabeledTextInput
            white
            title="Profil Twitter"
            placeholder="@profiltwitterauteur"
            value={displayField('twitterUsername')}
            onChange={(event) => onChangeValue('twitterUsername', event.target.value)}
          />
        </div>
        <div className="mt-4">
          <LabeledTextInput
            white
            title="Profil Instagram"
            placeholder="@profilinstagramauteur"
            value={displayField('instagramUsername')}
            onChange={(event) => onChangeValue('instagramUsername', event.target.value)}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={sendRevision} className="btn btn-success btn-lg text-white">
          Valider
        </button>
      </div>
    </Modal>
  );
};

AuthorRevisionModal.propTypes = {
  authorObjectId: PropTypes.string,
};

export default AuthorRevisionModal;
