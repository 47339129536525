import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { addToCart } from 'bubble-reducers/src/reducers/cart';
import { loadPrint } from 'bubble-reducers/src/reducers/prints';
import { isAlbumInWishlist, isAlbumOwned, makeIsAlbumInCart } from 'bubble-reducers/src/selectors';

import {
  useAddPrintsToWishlist,
  useRemoveFromCart,
  useRemovePrintsFromWishlist,
} from '@/services/hooks/useLibraryUtils';
import { getAssetImage } from '@/services/image-utils';

import ButtonWithIcon from '@/components/ButtonWithIcon/ButtonWithIcon';
import Cover from '@/components/Cover/Cover';
import AddedToCartModal from '@/components/Modals/AddedToCartModal';
import StarLine from '@/components/StarLine/StarLine';

import HorizontalAddToCollectionZone from './components/HorizontalAddToCollectionZone';

import bubbleUtils from 'bubble-utils';

import './AlbumHorizontalCard.css';

const AlbumHorizontalCard = (props) => {
  const dispatch = useDispatch();

  const addPrintsToWishlist = useAddPrintsToWishlist();
  const removePrintsFromWishlist = useRemovePrintsFromWishlist();
  const removeFromCart = useRemoveFromCart();

  const isAlbumInCart = makeIsAlbumInCart();

  const user = useSelector((state) => state.user.user);
  const album =
    useSelector((state) => state.albums.albums[props.albumObjectId]) || props.album || {};
  const print =
    useSelector((state) => state.prints.prints[album.defaultPrintObjectId]) || props.print || {};
  const serie =
    useSelector((state) => state.series.series[(album.serie || {}).objectId]) ||
    props.serie ||
    album.serie ||
    {};

  const isOwned = useSelector((state) => isAlbumOwned(state, album.objectId));
  const isInCart = useSelector((state) => isAlbumInCart(state, (print || {}).objectId));
  const isWishlist = useSelector((state) =>
    isAlbumInWishlist(state, album.objectId, props.userObjectId),
  );

  const printObjectId = album.defaultPrintObjectId;

  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);

  const sellingInfo = print.sellingInfo || { online: {} };
  const images = print.images || {};
  const availabilities = bubbleUtils.album.getAvailabilities(print);

  useEffect(() => {
    if (printObjectId && !print?.objectId) {
      dispatch(loadPrint({ objectId: printObjectId }));
    }
  }, [dispatch, print?.objectId, printObjectId, loadPrint]);

  const handleAddToCart = (e) => {
    e.preventDefault();
    if (isInCart) {
      return removeFromCart({ prints: print });
    }
    if (isOwned) {
      if (
        window.confirm(
          "Cet album fait déjà parti de votre collection, Êtes vous sûr de vouloir l'ajouter à votre panier ?",
        )
      ) {
        dispatch(addToCart({ prints: print }));
        setShowAddedToCartModal(true);
      }
    } else {
      dispatch(addToCart({ prints: print }));
      setShowAddedToCartModal(true);
    }
  };

  const handleAddWishlist = () => {
    if (!isWishlist) {
      addPrintsToWishlist(user, [print]);
    } else {
      removePrintsFromWishlist(user, [print]);
    }
  };

  return (
    <>
      {showAddedToCartModal && (
        <AddedToCartModal
          show={showAddedToCartModal}
          itemIds={[print].map((print) => print.objectId)}
          callback={() => setShowAddedToCartModal(false)}
        />
      )}
      <div className="row d-flex flex-fill py-3">
        <div className="col-md-4">
          <Link
            className="no-decoration d-flex align-items-center"
            to={`/${album.permalink}/album/${album.objectId}`}
          >
            <Cover rounded shadow height={110} imageUrl={(images.front || {}).smallFramed} />
            <div className="ms-3">
              <div className="bb-medium-large-text-size text-black">
                {(album.title || serie.title || '').slice(0, 25)}
              </div>
              {album.tome ? `Tome ${album.tome}` : ' '}
              <div>
                <StarLine note={album.note} />
              </div>
              <div className="fw-bold" style={{ color: availabilities.color }}>
                {availabilities.message}
              </div>
            </div>
          </Link>
        </div>

        <div className="col-md-4 d-flex justify-content-start align-items-center">
          <div>
            <div>
              <Link to={`/${serie.permalink}/serie/${serie.objectId}`} className="no-decoration">
                {serie.title}
              </Link>
            </div>
            {Array.isArray(album.authors)
              ? album.authors.slice(0, 2).map((author, index) => (
                  <span key={author.objectId} className="me-1 text-secondary">
                    <Link
                      className="no-decoration"
                      to={`/${author.permalink}/author/${author.objectId}?a=${album.objectId}`}
                    >
                      {author.displayName}
                    </Link>
                    {album.authors.length > 1 && index !== 1 && ','}
                  </span>
                ))
              : ''}
          </div>
        </div>

        <div className="col-md-4 d-flex justify-content-md-end align-items-center ">
          {!props.isGuest && (
            <div className="album-horizontal-module">
              <HorizontalAddToCollectionZone serie={serie} album={album} />
            </div>
          )}

          {!props.isGuest && (
            <div className="d-flex justify-content-center album-horizontal-module pt-1">
              <ButtonWithIcon
                className={`${isWishlist ? 'text-success' : ''}`}
                onClick={handleAddWishlist}
                icon={
                  isWishlist
                    ? getAssetImage('icon_wishlist_on.svg')
                    : getAssetImage('icon_wishlist_off.svg')
                }
              >
                <div className="mt-2 ">Wishlist</div>
              </ButtonWithIcon>
            </div>
          )}
          <div className="d-flex justify-content-center album-horizontal-module">
            <ButtonWithIcon
              className={`${isInCart ? 'text-danger' : ''}`}
              disabled={!availabilities.atLeastOneChannel}
              onClick={handleAddToCart}
              icon={
                isInCart
                  ? getAssetImage('icon_remove_cart_red.svg')
                  : getAssetImage('icon_add_cart_black.svg')
              }
            >
              {availabilities.atLeastOneChannel
                ? bubbleUtils.currency.formatCurrency(sellingInfo.price)
                : 'Indisponible'}
            </ButtonWithIcon>
          </div>
        </div>
      </div>
    </>
  );
};

AlbumHorizontalCard.propTypes = {
  albumObjectId: PropTypes.string,
  isGuest: PropTypes.bool,
};

export default AlbumHorizontalCard;
