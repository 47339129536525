import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';

import {
  loadArticleApi,
  loadArticleResourcesApi,
  loadArticlesApi,
  loadArticlesForResourceApi,
  loadHomeArticlesApi,
  loadRelatedArticlesApi,
} from '../services/api';
import menuArticles from '../services/menu-articles.json';

import { storeAlbums } from '../reducers/albums';
import { storeAuthors } from '../reducers/authors';
import { storePrints } from '../reducers/prints';
import { storeSeries } from '../reducers/series';
import { getClientToken } from '../selectors';

import bubbleUtils from 'bubble-utils';

const LOAD_ARTICLE = 'articles/loadArticle';
const LOAD_ARTICLE_RESOURCES = 'articles/loadArticleResources';
const LOAD_ARTICLES = 'articles/loadArticles';
const LOAD_HOME_ARTICLES = 'articles/loadHomeArticles';
export const LOAD_ARTICLES_FOR_RESOURCES = 'articles/loadArticlesForResource';
const LOAD_RELATED_ARTICLES = 'articles/loadRelatedArticles';

const initialState = {
  articlesMapByObjectId: menuArticles || {},
  articleContent: null, // web : this is used to avoid saving content of every articles
  articleContentFormatted: null, // app : this is used to avoid saving content of every articles
  articlesObjectIdsMapByPermalink: {},
  relatedArticlesMapByPermalink: {},
  homeArticlesMapByCategory: {},
  paginatedArticleMapByQuery: {},
  articlesMapByCategoryForHome: {},
  highlightedArticleObjectIds: [],
  articlesByResourceId: {},
  menuArticlesObjectIds: {
    bd: ['o9fkSHgbHB', 'cUGVgHLAZk'],
    comics: ['d7kA9H9JVl', 'gsWdLLmvIt'],
    mangas: ['wxFyrDQ7K4', 'jKH0n6ZnTj'],
    jeunesse: ['p7LKscseXD', 'lBQMhcpFOW'],
  },
  resourcesObjectIdsMap: {},
  loading: {},
  errors: {},
};

export const loadArticle = createAsyncThunk(
  LOAD_ARTICLE,
  async (params, { getState, dispatch }) => {
    const articleObjectId = params?.articleObjectId || null;
    const options = params?.options || {};

    const clientToken = getClientToken(getState());
    const article = await loadArticleApi(clientToken, articleObjectId, options);
    dispatch(storeArticles({ articles: [article] }));
  },
);

export const loadArticleResources = createAsyncThunk(
  LOAD_ARTICLE_RESOURCES,
  async (params, { getState, dispatch }) => {
    const articleObjectId = params?.articleObjectId || null;

    const clientToken = getClientToken(getState());
    const resources = await loadArticleResourcesApi(clientToken, articleObjectId);

    const { albums, prints } = extractAlbumAndPrints(resources.albums);
    dispatch(storeSeries({ series: resources.series }));
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));
    dispatch(storeAuthors({ authors: resources.authors }));
    resources.series = resources.series.map((serie) => serie.objectId);
    resources.albums = resources.albums.map((album) => album.objectId);
    resources.authors = resources.authors.map((author) => author.objectId);

    return { resources, articleObjectId };
  },
);

export const loadArticles = createAsyncThunk(
  LOAD_ARTICLES,
  async (params, { getState, dispatch }) => {
    const options = params?.options || null;

    const clientToken = getClientToken(getState());
    const paginatedArticles = await loadArticlesApi(clientToken, options);
    dispatch(storeArticles({ articles: paginatedArticles.articles }));

    return { paginatedArticles, options };
  },
);

export const loadHomeArticles = createAsyncThunk(
  LOAD_HOME_ARTICLES,
  async (params, { getState, dispatch }) => {
    const category = params?.category || null;

    const clientToken = getClientToken(getState());
    const sections = await loadHomeArticlesApi(clientToken, category);
    dispatch(storeArticles({ articles: [...Object.values(sections).flat()] }));
    return { category, sections };
  },
);

export const loadArticlesForResource = createAsyncThunk(
  LOAD_ARTICLES_FOR_RESOURCES,
  async (params, { getState, dispatch }) => {
    const resourceObjectId = params?.resourceObjectId || null;

    const clientToken = getClientToken(getState());
    // TODO: store the resources in their own reducers (storeAlbums, etc)
    const articles = await loadArticlesForResourceApi(clientToken, resourceObjectId);
    dispatch(storeArticles({ articles }));
    return { articles, resourceObjectId };
  },
);

export const loadRelatedArticles = createAsyncThunk(
  LOAD_RELATED_ARTICLES,
  async (params, { getState, dispatch }) => {
    const articleObjectId = params?.articleObjectId || null;

    const clientToken = getClientToken(getState());
    const articles = await loadRelatedArticlesApi(clientToken, articleObjectId);
    dispatch(storeArticles({ articles }));
    return { articleObjectId, articles };
  },
);

const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    storeArticles: {
      reducer: (state, action) => {
        const articlesMapByObjectId = { ...state.articlesMapByObjectId };
        const articlesObjectIdsMapByPermalink = { ...state.articlesObjectIdsMapByPermalink };
        let articleContent = state.articleContent;
        let articleContentFormatted = state.articleContentFormatted;
        action.payload.articles.forEach((article) => {
          articlesMapByObjectId[article.objectId] = article;
          articlesObjectIdsMapByPermalink[article.permalink] = article.objectId;
          if (article.contentWithLinks || article.contentFormatted) {
            articleContent = article.contentWithLinks || article.contentFormatted;
          }
          if (article.contentFormatted) articleContentFormatted = article.contentFormatted;
          articlesMapByObjectId[article.objectId].contentWithLinks = undefined;
          articlesMapByObjectId[article.objectId].contentFormatted = undefined;
        });
        state.articlesMapByObjectId = articlesMapByObjectId;
        state.articleContent = articleContent;
        state.articleContentFormatted = articleContentFormatted;
        state.articlesObjectIdsMapByPermalink = articlesObjectIdsMapByPermalink;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadArticle
      .addCase(loadArticle.pending, (state, action) => {
        state.articleContent = null;

        state.loading[LOAD_ARTICLE] = true;
        state.errors[LOAD_ARTICLE] = null;
      })
      .addCase(loadArticle.fulfilled, (state, action) => {
        state.loading[LOAD_ARTICLE] = false;
        state.errors[LOAD_ARTICLE] = null;
      })
      .addCase(loadArticle.rejected, (state, action) => {
        state.loading[LOAD_ARTICLE] = false;
        state.errors[LOAD_ARTICLE] = action.error;
      })
      // loadArticleResources
      .addCase(loadArticleResources.pending, (state, action) => {
        state.loading[LOAD_ARTICLE_RESOURCES] = true;
        state.errors[LOAD_ARTICLE_RESOURCES] = null;
      })
      .addCase(loadArticleResources.fulfilled, (state, action) => {
        state.resourcesObjectIdsMap[action.payload.articleObjectId] = action.payload.resources;

        state.loading[LOAD_ARTICLE_RESOURCES] = false;
        state.errors[LOAD_ARTICLE_RESOURCES] = null;
      })
      .addCase(loadArticleResources.rejected, (state, action) => {
        state.loading[LOAD_ARTICLE_RESOURCES] = false;
        state.errors[LOAD_ARTICLE_RESOURCES] = action.error;
      })
      // loadArticles
      .addCase(loadArticles.pending, (state, action) => {
        state.loading[LOAD_ARTICLES] = true;
        state.errors[LOAD_ARTICLES] = null;
      })
      .addCase(loadArticles.fulfilled, (state, action) => {
        const params = action.payload.options || {};
        const paginatedArticles = action.payload.paginatedArticles;
        const paginatedArticleMapByQuery = { ...state.paginatedArticleMapByQuery };
        const articleObjectIds = paginatedArticles.articles.map((article) => article.objectId);
        let highlightedArticleObjectIds = [...state.highlightedArticleObjectIds];
        if (params.highlighted) {
          highlightedArticleObjectIds = articleObjectIds;
        }
        paginatedArticles.articles = articleObjectIds;

        paginatedArticleMapByQuery[bubbleUtils.string.buildSortedQueryString(params)] = {
          ...paginatedArticles,
        };

        state.highlightedArticleObjectIds = highlightedArticleObjectIds;
        state.paginatedArticleMapByQuery = paginatedArticleMapByQuery;

        state.loading[LOAD_ARTICLES] = false;
        state.errors[LOAD_ARTICLES] = null;
      })
      .addCase(loadArticles.rejected, (state, action) => {
        state.loading[LOAD_ARTICLES] = false;
        state.errors[LOAD_ARTICLES] = action.error;
      })
      // loadHomeArticles
      .addCase(loadHomeArticles.pending, (state, action) => {
        state.loading[LOAD_HOME_ARTICLES] = true;
        state.errors[LOAD_HOME_ARTICLES] = null;
      })
      .addCase(loadHomeArticles.fulfilled, (state, action) => {
        const category = action.payload.category || 'all';
        const sections = action.payload.sections;
        const homeArticlesMapByCategory = { ...state.homeArticlesMapByCategory };
        homeArticlesMapByCategory[category] = {};
        Object.keys(sections).forEach((key) => {
          homeArticlesMapByCategory[category][key] = sections[key].map(
            (article) => article.objectId,
          );
        });
        state.homeArticlesMapByCategory = homeArticlesMapByCategory;

        state.loading[LOAD_HOME_ARTICLES] = false;
        state.errors[LOAD_HOME_ARTICLES] = null;
      })
      .addCase(loadHomeArticles.rejected, (state, action) => {
        state.loading[LOAD_HOME_ARTICLES] = false;
        state.errors[LOAD_HOME_ARTICLES] = action.error;
      })
      // loadArticlesForResource
      .addCase(loadArticlesForResource.pending, (state, action) => {
        state.loading[LOAD_ARTICLES_FOR_RESOURCES] = true;
        state.errors[LOAD_ARTICLES_FOR_RESOURCES] = null;
      })
      .addCase(loadArticlesForResource.fulfilled, (state, action) => {
        const newArticleObjectIds = action.payload.articles.map((article) => article.objectId);
        state.articlesByResourceId = {
          ...state.articlesByResourceId,
          [action.payload.resourceObjectId]: newArticleObjectIds,
        };

        state.loading[LOAD_ARTICLES_FOR_RESOURCES] = false;
        state.errors[LOAD_ARTICLES_FOR_RESOURCES] = null;
      })
      .addCase(loadArticlesForResource.rejected, (state, action) => {
        state.loading[LOAD_ARTICLES_FOR_RESOURCES] = false;
        state.errors[LOAD_ARTICLES_FOR_RESOURCES] = action.error;
      })
      // loadRelatedArticles
      .addCase(loadRelatedArticles.pending, (state, action) => {
        state.loading[LOAD_RELATED_ARTICLES] = true;
        state.errors[LOAD_RELATED_ARTICLES] = null;
      })
      .addCase(loadRelatedArticles.fulfilled, (state, action) => {
        const relatedArticlesMapByPermalink = {
          ...state.relatedArticlesMapByPermalink,
          [action.payload.articleObjectId]: action.payload.articles.map(
            (article) => article.permalink,
          ),
        };
        state.relatedArticlesMapByPermalink = relatedArticlesMapByPermalink;

        state.loading[LOAD_RELATED_ARTICLES] = false;
        state.errors[LOAD_RELATED_ARTICLES] = null;
      })
      .addCase(loadRelatedArticles.rejected, (state, action) => {
        state.loading[LOAD_RELATED_ARTICLES] = false;
        state.errors[LOAD_RELATED_ARTICLES] = action.error;
      });
  },
});

export default articlesSlice.reducer;

export const { storeArticles } = articlesSlice.actions;
