import PropTypes from 'prop-types';
import React from 'react';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import rewardPng from '@/assets/reward/club-white-small.png';

import './RewardBadge.scss';

const RewardBadge = (props) => {
  return (
    <div className={`reward-badge d-inline-block  bb-s-text-size ${props.className}`}>
      <WebpWrapper className="reward-icon-badge" alt="Reward program icon" default={rewardPng} />
      {props.value ? ` +${props.value}` : ''}
    </div>
  );
};

RewardBadge.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
};

export default RewardBadge;
