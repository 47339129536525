import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Cover from '@/components/Cover/Cover';
import SerieFollowBadge from '@/components/SerieFollowBadge/SerieFollowBadge';
import StarLine from '@/components/StarLine/StarLine';

import './AuthorSerieCard.scss';

export default function AuthorSerieCard({ serieObjectId }) {
  const authorSerie = useSelector((state) => state.authors.authorSeries[serieObjectId]);

  if (!authorSerie) return null;

  const serieDate = authorSerie.date ? new Date(authorSerie.date).getFullYear() : null;

  return (
    <Link
      to={`/${authorSerie.permalink}/serie/${authorSerie.objectId}`}
      className="d-flex nart-shadow shadow-when-hovered no-decoration text-black"
    >
      <div className="bg-white rounded d-flex flex-fill p-2">
        <Cover
          className="me-2"
          rounded
          shadow
          height={110}
          imageUrl={authorSerie.images?.front?.smallFramed}
        />
        <div className="py-2 d-flex flex-fill flex-column justify-content-between">
          <div>
            <div className="author-serie-card-title bb-medium-text-size fw-bold">
              {authorSerie.title}
            </div>
            <div className="text-secondary bb-s-text-size">{authorSerie.type}</div>
          </div>

          <div className="bb-s-text-size d-flex align-items-end justify-content-between">
            <div className="d-flex align-items-end">
              {!!serieDate && !isNaN(serieDate) && (
                <div>
                  {serieDate}
                  <span className="px-1">•</span>
                </div>
              )}
              <StarLine bold={false} short note={authorSerie.note} />
            </div>
            <SerieFollowBadge serieObjectId={authorSerie?.objectId} smallPadding noFlex />
          </div>
        </div>
      </div>
    </Link>
  );
}
