import React from 'react';

import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getAuthor } from 'bubble-reducers/src/selectors';

import RelatedArticles from '@/components/RelatedArticles/RelatedArticles';

import AuthorRelatedAuthors from '../components/AuthorRelatedAuthors';

const defautObject = {};

export default function AuthorAllSectionBottom({ authorObjectId }) {
  const author = useSelector((state) => getAuthor(state, authorObjectId)) || defautObject;
  const { permalink } = useParams();
  const rootPath = '/' + [permalink, 'author', authorObjectId].join('/');

  return (
    <>
      <div className="nart-background-grey">
        <div className="container">
          <div className="row px-sm-3">
            <div className="col-12">
              <AuthorRelatedAuthors authorObjectId={authorObjectId} />
            </div>
          </div>
        </div>
      </div>
      <RelatedArticles
        resourceObjectId={authorObjectId}
        title={`Articles liés à ${author.displayName || "l'auteur"}`}
        topRightLink={<Link to={`${rootPath}/articles#ct1`}>Voir tous les articles</Link>}
      />
    </>
  );
}
