import React from 'react';

import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import iconSocialFacebook from '@/assets/9eart/icon_facebook_rond.svg';
import iconSocialInstragam from '@/assets/9eart/icon_instagram_rond.svg';
import iconSocialTwitter from '@/assets/9eart/icon_twitter_rond.svg';

import Navbar9eArtNavItemMobile from '../NartNavItem/Navbar9eArtNavItemMobile';

import { LINK } from 'bubble-constants';

import './NArtBurgerMenu.scss';

export default function NArtBurgerMenu({ show, onClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  let match;
  match = matchPath({ path: '/9emeart/:nArtCategory/:nArtSection' }, location.pathname);
  if (!match) {
    match = matchPath({ path: '/9emeart/:nArtCategory' }, location.pathname);
  }
  if (!match) {
    match = matchPath({ path: '/9emeart/:nArtSection' }, location.pathname);
  }
  const { nArtCategory, nArtSection } = match?.params || {};
  const nextPath = ['/9emeart', nArtCategory].filter((row) => row).join('/');

  // necessary to prevent the body not being scrollable after agenda click
  const onClickAgenda = (e) => {
    e.stopPropagation();
    onClose();
    setTimeout(() => {
      navigate('/agenda');
    }, 0);
  };

  return (
    <div
      id="nart-burger-menu-container"
      onClick={onClose}
      className={`nart-burger-menu-container ${!!show ? ' show' : ''}`}
    >
      <div className={`nart-burger-menu-content px-4 shadow`}>
        <Navbar9eArtNavItemMobile
          to={`${nextPath}`}
          selected={!nArtSection}
          text="En ce moment"
          category={nArtCategory}
        />
        <Navbar9eArtNavItemMobile
          to={`${nextPath}/actualites`}
          selected={nArtSection === 'actualites'}
          text="Actualités"
          category={nArtCategory}
        />
        <Navbar9eArtNavItemMobile
          to={`${nextPath}/critiques`}
          selected={nArtSection === 'critiques'}
          text="Critiques"
          category={nArtCategory}
        />
        <Navbar9eArtNavItemMobile
          to={`${nextPath}/incontournables`}
          selected={nArtSection === 'incontournables'}
          text="Incontournables"
          category={nArtCategory}
        />
        <Navbar9eArtNavItemMobile
          to={`${nextPath}/edito`}
          selected={nArtSection === 'edito'}
          text="Edito"
          category={nArtCategory}
        />
        <Navbar9eArtNavItemMobile
          to={`${nextPath}/en-video`}
          selected={nArtSection === 'en-video'}
          category={nArtCategory}
          text="En vidéo"
        />

        <div className="nart-burger-menu-separator my-3" />

        <div className="d-flex py-3">
          <div className={`bg-blacker rounded-circle nart-burger-circle`} />
          <WithClickHandler
            className="h4 no-decoration text-black fw-bold"
            onClick={onClickAgenda}
            to={`/agenda`}
          >
            Agenda
          </WithClickHandler>
        </div>

        <div className="d-flex align-items-center py-4">
          <a target="_blank" rel="noopener noreferrer" href={LINK.FACEBOOK_NART_URL}>
            <img className="social-icon" src={iconSocialFacebook} alt="Facebook social icon" />
          </a>
          <a
            className="ms-3"
            target="_blank"
            rel="noopener noreferrer"
            href={LINK.TWITTER_NART_URL}
          >
            <img className="social-icon" src={iconSocialTwitter} alt="Twitter social icon" />
          </a>
          <a
            className="ms-3"
            target="_blank"
            rel="noopener noreferrer"
            href={LINK.INSTAGRAM_NART_URL}
          >
            <img className="social-icon" src={iconSocialInstragam} alt="Instagram social icon" />
          </a>
        </div>
      </div>
    </div>
  );
}
