import React from 'react';

import { Link, useLocation } from 'react-router-dom';

export default function LeftSide() {
  const location = useLocation();
  const path = location.pathname;

  return (
    <>
      <div className="fw-bold mb-1">Sélection</div>
      <ul className="ul-no-indent">
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${path === '/noel/one-shot' ? 'text-primary' : 'text-black'}`}
            to="/noel/one-shot"
          >
            Toutes les One Shot
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/one-shot/bande-dessinee' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/one-shot/bande-dessinee"
          >
            Bande dessinée
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/one-shot/comics' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/one-shot/comics"
          >
            Comics
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/one-shot/mangas' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/one-shot/mangas"
          >
            Mangas
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/one-shot/romans-graphiques' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/one-shot/romans-graphiques"
          >
            Romans graphiques
          </Link>
        </li>
      </ul>
      <div className="fw-bold mb-1 pt-5">Sélection Jeunesse</div>
      <ul className="ul-no-indent">
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${path === '/noel/jeunesse' ? 'text-primary' : 'text-black'}`}
            to="/noel/jeunesse"
          >
            Toutes les BD Jeunesse
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/jeunesse/a-partir-de-3-ans' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/jeunesse/a-partir-de-3-ans"
          >
            Dès 3 ans
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/jeunesse/a-partir-de-6-ans' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/jeunesse/a-partir-de-6-ans"
          >
            Dès 6 ans
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/jeunesse/a-partir-de-9-ans' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/jeunesse/a-partir-de-9-ans"
          >
            Dès 9 ans
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/jeunesse/a-partir-de-12-ans' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/jeunesse/a-partir-de-12-ans"
          >
            Dès 12 ans
          </Link>
        </li>
      </ul>
      <div className="fw-bold mb-1 pt-5">Coffrets & Intégrales</div>
      <ul className="ul-no-indent">
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/coffrets-et-integrales' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/coffrets-et-integrales"
          >
            Coffrets & Intégrales
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/coffrets-et-integrales/coffrets' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/coffrets-et-integrales/coffrets"
          >
            Coffrets
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${
              path === '/noel/coffrets-et-integrales/integrales' ? 'text-primary' : 'text-black'
            }`}
            to="/noel/coffrets-et-integrales/integrales"
          >
            Intégrales
          </Link>
        </li>
      </ul>
      <div className="fw-bold mb-1 pt-5">Tops albums</div>
      <ul className="ul-no-indent">
        <li className="li-no-indent">
          <Link
            className={`no-decoration ${path === '/tops' ? 'text-primary' : 'text-black'}`}
            to="/tops"
          >
            Tous les top 2024
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration text-black`}
            to="/series-les-mieux-notees/tops/topBestNotedSeriesAllTime"
          >
            Top des mieux notés
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration text-black`}
            to="/albums-tendance/tops/topMostAddedAlbums30Days"
          >
            Top des tendances
          </Link>
        </li>
        <li className="li-no-indent">
          <Link
            className={`no-decoration text-black`}
            to="/albums-les-plus-ajoutes-en-2024/tops/topMostAddedAlbumsOf2024"
          >
            Top des plus ajoutés
          </Link>
        </li>
      </ul>
    </>
  );
}
