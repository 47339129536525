import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadArticlesForResource } from 'bubble-reducers/src/reducers/articles';
import { getArticlesForResource } from 'bubble-reducers/src/selectors';

import ArticleHorizontalCard from '@/components/ArticleHorizontalCard/ArticleHorizontalCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

const RelatedArticles = ({ resourceObjectId, title, grey, white, topRightLink }) => {
  const dispatch = useDispatch();
  const articlesForResource = useSelector((state) =>
    getArticlesForResource(state, resourceObjectId),
  );

  useEffect(() => {
    if (resourceObjectId) {
      dispatch(loadArticlesForResource({ resourceObjectId }));
    }
  }, [resourceObjectId, dispatch]);

  const articlesComponent = useMemo(
    () =>
      articlesForResource.slice(0, 3).map((article) => (
        <div key={'related_' + article.objectId} className="col-md-4 mt-3">
          <ArticleHorizontalCard article={article} />
        </div>
      )),
    [articlesForResource],
  );

  return (
    <>
      {!!articlesForResource.length && (
        <div className={grey ? 'bb-background-light-grey' : white ? 'bg-white' : ''}>
          <div className="container">
            <div className="px-sm-3">
              <TitledListContainer
                title={title || `On en parle sur notre Blog`}
                list={articlesComponent}
                topRightLink={
                  topRightLink || (
                    <Link className="d-none d-md-block" to="/9emeart">
                      Accéder au blog Bubble
                    </Link>
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

RelatedArticles.propTypes = {
  resourceObjectId: PropTypes.string,
  grey: PropTypes.any,
};

export default RelatedArticles;
