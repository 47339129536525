import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { PROJECT } from '@/project-constants';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const defaultArray = [];
const defaultObject = {};

const Breadcrumbs = (props) => {
  const series = useSelector((state) => state.series.series);

  const getAnchorsFromObject = useCallback(
    (object, isPrevious) => {
      let anchors = [];
      if (!object) return [];
      else if ('serie' in object) {
        // object is an album
        const serie = series[object.serie.objectId] || defaultObject;
        if (serie) {
          anchors.push({ name: serie.category, link: `/list?category=${serie.category}` });
          if (serie.genre) {
            anchors.push({
              name: serie.genre,
              link: `/list?category=${serie.category}&genre=${serie.genre}`,
            });
          }
          anchors.push({
            name: object.serie.title || serie.title,
            link: `/${object.serie.permalink || serie.permalink}/serie/${
              object.serie.objectId || serie.objectId
            }`,
          });
        }
        anchors.push({
          name: object.title || 'Tome ' + (object.tome || 0),
          link: isPrevious ? `/${object.permalink}/album/${object.objectId}` : null,
        });
      } else if ('descriptionShort' in object) {
        // object is a serie
        anchors.push({ name: object.category, link: `/list?category=${object.category}` });
        if (object.genre) {
          anchors.push({
            name: object.genre,
            link: `/list?category=${object.category}&genre=${object.genre}`,
          });
        }
        anchors.push({
          name: object.title,
          link: isPrevious ? `/${object.permalink}/serie/${object.objectId}` : null,
        });
      } else if ('fullName' in object) {
        // object is an author
        anchors.push({ name: `${object.displayName}` });
      } else if ('excerpt' in object) {
        // object is an article
        anchors.push({ name: 'Paper', link: '/paper' }); //TODO:9eart replace paper?
        anchors.push({
          name: object.category.title,
          link: `/paper/${object.category.permalink}`, //TODO:9eart replace paper?
        });
        anchors.push({ name: object.title });
      } else if ('listing' in object) {
        // object is made up listing object
        anchors.push({
          name: PROJECT.CATEGORY_NAMES_LONG[object.category],
          link: `/list?category=${object.category}`,
        });
        object.genre &&
          anchors.push({ name: object.genre[0].toUpperCase() + object.genre.slice(1, 99) });
      } else if ('search' in object) {
        // made up search object
        anchors.push({ name: 'Recherche' });
        anchors.push({ name: `"${object.text}"` });
      } else if ('custom' in object) {
        // custom object
        anchors.push(object);
      }
      return anchors;
    },
    [series],
  );

  const anchors = (getAnchorsFromObject(props.previousObject, true) || defaultArray)
    .concat(getAnchorsFromObject(props.currentObject) || defaultArray)
    .filter((row) => row.name);

  const lightTheme = 'light' in props;

  return (
    <div className="d-flex flex-fill px-sm-3 py-4">
      <div className="d-none d-md-flex flex-fill">
        <Link to="/">Accueil</Link>
        {anchors.map((anchor, index) => (
          <React.Fragment key={anchor.name + index}>
            <span className={`mx-1 ${lightTheme ? 'text-secondary' : ''}`}>{'>'}</span>
            {anchor.link ? (
              <Link to={`${anchor.link}`}>{anchor.name}</Link>
            ) : (
              <span className="text-secondary">{anchor.name}</span>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="d-flex d-md-none flex-fill ">
        {anchors.slice(-2, -1).map((anchor, index) => (
          <React.Fragment key={anchor.name + index}>
            <span className={`mx-1 ${lightTheme ? 'text-secondary' : ''}`}>{'<'}</span>
            {anchor.link ? <Link to={`${anchor.link}`}>{anchor.name}</Link> : <React.Fragment />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  currentObject: PropTypes.object.isRequired,
  previousObject: PropTypes.object,
  light: PropTypes.any,
};

export default Breadcrumbs;
