import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import Star from './components/Star';

import './StarLine.scss';

const StarLine = ({ note, size, short, bold = true, classes }) => {
  const formattedNote = useMemo(() => (note || 0).toFixed(1), [note]);

  const calculatePercentFill = useCallback((note, starPosition) => {
    if (!note) return '0%';
    if (starPosition <= note) {
      return '100%';
    } else if (starPosition > note + 1) {
      return '0%';
    } else {
      return `${Math.round((1 - (starPosition - note)) * 100)}%`;
    }
  }, []);

  if (short) {
    return (
      <div className="d-inline-block">
        <div className="d-inline-block star-line-note">
          <Star size={size} percentFill={note ? '100%' : '0%'} />
        </div>
        <span className={`ps-1${bold ? ' fw-bold' : ''}`}>{note ? formattedNote : ''}</span>{' '}
      </div>
    );
  }

  return (
    <div className={`d-inline-block ${classes ? classes : ''}`}>
      <Star size={size} percentFill={calculatePercentFill(note, 1)} />
      <Star size={size} percentFill={calculatePercentFill(note, 2)} />
      <Star size={size} percentFill={calculatePercentFill(note, 3)} />
      <Star size={size} percentFill={calculatePercentFill(note, 4)} />
      <Star size={size} percentFill={calculatePercentFill(note, 5)} />
    </div>
  );
};

StarLine.propTypes = {
  note: PropTypes.any,
  size: PropTypes.string,
};

export default StarLine;
