import React from 'react';

import { useDispatch } from 'react-redux';

import { updateTag } from 'bubble-reducers/src/reducers/tags';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './ModalTagRow.scss';

const ModalTagRow = (props) => {
  const tag = props.tag;
  const userAdded = props.userAdded;
  const dispatch = useDispatch();

  const onClick = () => {
    props.onClickPlus(tag.name);
  };

  const onClickReport = () => {
    if (window.confirm(`Êtes-vous sûr de vouloir signaler le thème "${tag.name}" ?`)) {
      dispatch(updateTag({ tagObjectId: tag.objectId, tag: { reported: true } }));
      alert('Le thème va être éxaminé par notre équipe, merci pour votre vigilence 🙏');
    }
  };

  return (
    <div className="modal-tag-row rounded">
      <div className="d-flex justify-content-between">
        <div className="text-primary">{tag.name}</div>
        <div className="d-flex">
          <WithClickHandler onClick={onClickReport} className={`pe-3`}>
            <Icon name="flag" className="text-secondary flag-icon" />
          </WithClickHandler>
          <WithClickHandler
            onClick={onClick}
            className={`d-flex align-items-center justify-content-center text-center rounded-circle plus-icon ${
              userAdded ? 'bg-success' : 'bg-primary'
            }`}
          >
            <div className="plus-icon-text">{userAdded ? '-' : '+'}</div>
          </WithClickHandler>
        </div>
      </div>
    </div>
  );
};

export default ModalTagRow;
