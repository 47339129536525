import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { randomIntFromRange } from 'bubble-utils/src/number-utils';

import { loadBannersApi } from '../services/api';

import { getClientToken } from '../selectors';

import { GENERAL } from 'bubble-constants';

const LOAD_BANNERS = 'banners/loadBanners';

const initialState = {
  banners: {},
  selectedListingBannerIdsMap: {},
  loading: {},
  errors: {},
};

export const loadBanners = createAsyncThunk(LOAD_BANNERS, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const banners = await loadBannersApi(clientToken);
  return { banners };
});

const getBannersForFlag = (banners, flag) => {
  return banners.filter(
    (banner) =>
      (banner.typeFlags & GENERAL[`FLAG_PRODUCT_${flag.toUpperCase()}`]) ===
      GENERAL[`FLAG_PRODUCT_${flag.toUpperCase()}`],
  );
};

const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadBanners
      .addCase(loadBanners.pending, (state, action) => {
        state.loading[LOAD_BANNERS] = true;
        state.errors[LOAD_BANNERS] = null;
      })
      .addCase(loadBanners.fulfilled, (state, action) => {
        let banners = {};
        const payloadBanners = action.payload.banners || [];
        payloadBanners.forEach((banner) => (banners[banner.objectId] = banner));
        const bdBanners = getBannersForFlag(payloadBanners, 'bd');
        const comicsBanners = getBannersForFlag(payloadBanners, 'comics');
        const mangasBanners = getBannersForFlag(payloadBanners, 'mangas');
        const promoBanners = getBannersForFlag(payloadBanners, 'promo');

        state.loading[LOAD_BANNERS] = false;
        state.errors[LOAD_BANNERS] = null;
        state.banners = banners;
        state.selectedListingBannerIdsMap =
          Object.keys(state.selectedListingBannerIdsMap).length === 0
            ? {
                bd: (bdBanners[randomIntFromRange(0, bdBanners.length)] || {}).objectId || null,
                comics:
                  (comicsBanners[randomIntFromRange(0, comicsBanners.length)] || {}).objectId ||
                  null,
                mangas:
                  (mangasBanners[randomIntFromRange(0, mangasBanners.length)] || {}).objectId ||
                  null,
                promo:
                  (promoBanners[randomIntFromRange(0, promoBanners.length)] || {}).objectId || null,
              }
            : state.selectedListingBannerIdsMap;
      })
      .addCase(loadBanners.rejected, (state, action) => {
        state.loading[LOAD_BANNERS] = false;
        state.errors[LOAD_BANNERS] = action.error;
      });
  },
});

export default bannersSlice.reducer;
