import { createSlice } from '@reduxjs/toolkit';

import { MODES } from '@/scenes/Serie/components/SerieModeSwitcher/SerieModeSwitcher';

const initialState = {
  clickedHeaderMenu: 'bd',
  showHeaderMenu: false,
  collectionViewMode: MODES.LIST,
  newsletterOptIn: false,
};

const websiteInterfaceSlice = createSlice({
  name: 'websiteInterface',
  initialState,
  reducers: {
    setClickedHeaderMenu: {
      reducer: (state, action) => {
        state.clickedHeaderMenu = action.payload.clickedHeaderMenu;
      },
    },
    setShowHeaderMenu: {
      reducer: (state, action) => {
        state.showHeaderMenu = action.payload.showHeaderMenu;
      },
    },
    setCollectionViewMode: {
      reducer: (state, action) => {
        state.collectionViewMode = action.payload.collectionViewMode;
      },
    },
    setNewsletterOptIn: {
      reducer: (state, action) => {
        state.newsletterOptIn = action.payload.newsletterOptIn;
      },
    },
  },
});

export default websiteInterfaceSlice.reducer;

export const {
  setClickedHeaderMenu,
  setShowHeaderMenu,
  setCollectionViewMode,
  setNewsletterOptIn,
} = websiteInterfaceSlice.actions;
