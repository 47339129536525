import React from 'react';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';
import StarLine from '@/components/StarLine/StarLine';

import macPhonePngBd from '@/assets/landings/mac_phone_bd.png';
import macPhoneWebpBd from '@/assets/landings/mac_phone_bd.webp';

import './KeyValueCard.scss';

const KeyValueCard = () => (
  <div className="p-4 py-5 text-center text-white key-value-background  h-100">
    <h2 className="fw-bold">
      <span className="text-pink">TOUT</span>, pour les fans de BD Comics et Mangas
    </h2>
    <div className="d-flex flex-column justify-content-between">
      <p className="bb-medium-large-text-size pt-5">
        Ici, achetez auprès de libraires indépendants ! Partez à la découverte de vos futurs coups
        de cœur. Partagez-les et organisez votre collection comme un(e) pro.
      </p>

      <div className="d-flex flex-column align-items-center pt-5">
        <WebpWrapper
          className="key-value-mac-phone"
          default={macPhonePngBd}
          webp={macPhoneWebpBd}
          alt="mac & phone"
        />
        <div className="d-flex align-items-center pt-3">
          <div>
            <StarLine note={4.4} />
          </div>
          <span className="ps-2 pt-1 fw-bold">
            4.4 de moyenne{' '}
            <span role="img" aria-label="trophy">
              🏆
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default KeyValueCard;
