import {
  login,
  loginGuest,
  loginWithFacebook,
  loginWithFacebookJwtToken,
  logout,
  signup,
} from 'bubble-reducers/src/reducers/auth';

import {
  createCookie,
  deleteCookie,
  deleteInLocalStorage,
  saveInLocalStorage,
} from 'bubble-utils/src/auth-utils';

export const addWebsiteAuthListeners = (startListening) => {
  startListening({
    actionCreator: signup.fulfilled,
    effect: (action) => {
      const payload = action.payload.initialPayload;
      const authData = action.payload.authData;

      createCookie(payload.cookie.name, authData.token, payload.cookie.options);
      saveInLocalStorage(payload.localStorage.keyName, { user: authData.user });
      console.log('signup.fulfilled web done');
    },
  });
  startListening({
    actionCreator: login.fulfilled,
    effect: (action) => {
      const payload = action.payload.initialPayload;
      const authData = action.payload.authData;

      createCookie(payload.cookie.name, authData.token, payload.cookie.options);
      saveInLocalStorage(payload.localStorage.keyName, { user: authData.user });
      console.log('login.fulfilled web done');
    },
  });
  startListening({
    actionCreator: loginGuest.fulfilled,
    effect: (action) => {
      const payload = action.payload.initialPayload;
      const authData = action.payload.authData;

      createCookie(payload.cookie.name, authData.token, payload.cookie.options);
      saveInLocalStorage(payload.localStorage.keyName, { guest: authData.guest });
      console.log('loginGuest.fulfilled web done');
    },
  });
  startListening({
    actionCreator: loginWithFacebook.fulfilled,
    effect: (action) => {
      const payload = action.payload.initialPayload;
      const authData = action.payload.authData;

      createCookie(payload.cookie.name, authData.token, payload.cookie.options);
      saveInLocalStorage(payload.localStorage.keyName, { user: authData.user });
      console.log('loginWithFacebook.fulfilled web done');
    },
  });

  startListening({
    actionCreator: loginWithFacebookJwtToken.fulfilled,
    effect: (action) => {
      const payload = action.payload.initialPayload;
      const authData = action.payload.authData;

      createCookie(payload.cookie.name, authData.token, payload.cookie.options);
      saveInLocalStorage(payload.localStorage.keyName, { user: authData.user });
      console.log('loginWithFacebookJwtToken.fulfilled web done');
    },
  });
  startListening({
    actionCreator: logout.fulfilled,
    effect: (action) => {
      const payload = action.payload;

      deleteCookie(payload.cookie.name, payload.cookie.options);
      deleteInLocalStorage(payload.localStorage.keyName);
      console.log('logout.fulfilled web done');
    },
  });
};
