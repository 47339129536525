import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';

import {
  addReviewApi,
  editReviewApi,
  loadAlbumReviewsApi,
  loadAppReviewsApi,
  loadMyReviewForSerieApi,
  loadMyReviewsApi,
  loadMyReviewsForAlbumApi,
  loadReviewApi,
  loadSerieReviewsApi,
} from '../services/api';

import { storeAlbums } from '../reducers/albums';
import { storePrints } from '../reducers/prints';
import { storeSeries } from '../reducers/series';
import { getClientToken } from '../selectors';

const LOAD_REVIEW = 'reviews/loadReview';
const LOAD_APP_REVIEWS = 'reviews/loadAppReviews';
const LOAD_SERIE_REVIEWS = 'reviews/loadSerieReviews';
const LOAD_ALBUM_REVIEWS = 'reviews/loadAlbumReviews';
export const ADD_SERIE_REVIEW = 'reviews/addSerieReview';
export const EDIT_SERIE_REVIEW = 'reviews/editSerieReview';
export const ADD_ALBUM_REVIEW = 'reviews/addAlbumReview';
export const EDIT_ALBUM_REVIEW = 'reviews/editAlbumReview';
export const LOAD_MY_REVIEWS = 'reviews/loadMyReviews';
const LOAD_MY_REVIEW_FOR_SERIE = 'reviews/loadMyReviewForSerie';
const LOAD_MY_REVIEWS_FOR_ALBUM = 'reviews/loadMyReviewsForAlbum';

const initialState = {
  mySerieReviewsMap: {},
  myAlbumReviewsMap: {},
  serieReviewsMap: {},
  albumReviewsMap: {},
  reviewsMap: {},
  appReviews: {},
  loading: {},
  errors: {},
};

export const loadReview = createAsyncThunk(LOAD_REVIEW, async (params, { getState }) => {
  const reviewObjectId = params?.reviewObjectId || null;

  const clientToken = getClientToken(getState());
  const review = await loadReviewApi(clientToken, reviewObjectId);
  return { review };
});

export const loadAppReviews = createAsyncThunk(LOAD_APP_REVIEWS, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const appReviews = await loadAppReviewsApi(clientToken);
  return { appReviews };
});

export const loadSerieReviews = createAsyncThunk(
  LOAD_SERIE_REVIEWS,
  async (params, { getState }) => {
    const serieObjectId = params?.serieObjectId || null;

    const clientToken = getClientToken(getState());
    const serieReviewsMap = await loadSerieReviewsApi(clientToken, serieObjectId);
    return { serieReviewsMap };
  },
);

export const loadAlbumReviews = createAsyncThunk(
  LOAD_ALBUM_REVIEWS,
  async (params, { getState }) => {
    const albumObjectId = params?.albumObjectId || null;

    const clientToken = getClientToken(getState());
    const albumReviewsMap = await loadAlbumReviewsApi(clientToken, albumObjectId);
    return { albumReviewsMap };
  },
);

export const addSerieReview = createAsyncThunk(
  ADD_SERIE_REVIEW,
  async (params, { getState, dispatch }) => {
    const review = params?.review || null;

    const clientToken = getClientToken(getState());

    const createdReview = await addReviewApi(clientToken, review);
    dispatch(loadSerieReviews({ serieObjectId: review.serieObjectId }));
    return { myReview: createdReview, serieObjectId: review.serieObjectId };
  },
);

export const editSerieReview = createAsyncThunk(
  EDIT_SERIE_REVIEW,
  async (params, { getState, dispatch }) => {
    const reviewObjectId = params?.reviewObjectId || null;
    const review = params?.review || null;

    const clientToken = getClientToken(getState());
    const updatedReview = await editReviewApi(clientToken, reviewObjectId, review);
    dispatch(loadSerieReviews({ serieObjectId: review.serieObjectId }));
    dispatch(
      loadMyReviewForSerie({
        userObjectId: review.userObjectId,
        serieObjectId: review.serieObjectId,
      }),
    );
    return { review: updatedReview };
  },
);

export const addAlbumReview = createAsyncThunk(
  ADD_ALBUM_REVIEW,
  async (params, { getState, dispatch }) => {
    const albumObjectId = params?.albumObjectId || null;
    const review = params?.review || null;

    const clientToken = getClientToken(getState());
    const createdReview = await addReviewApi(clientToken, review);
    dispatch(loadAlbumReviews({ albumObjectId }));
    dispatch(loadMyReviewsForAlbum({ userObjectId: review.userObjectId, albumObjectId }));
    return { myReview: createdReview, albumObjectId };
  },
);

export const editAlbumReview = createAsyncThunk(
  EDIT_ALBUM_REVIEW,
  async (params, { getState, dispatch }) => {
    const albumObjectId = params?.albumObjectId || null;
    const reviewObjectId = params?.reviewObjectId || null;
    const review = params?.review || null;

    const clientToken = getClientToken(getState());
    const updatedReview = await editReviewApi(clientToken, reviewObjectId, review);
    dispatch(loadAlbumReviews({ albumObjectId }));
    dispatch(loadMyReviewsForAlbum({ userObjectId: review.userObjectId, albumObjectId }));
    return { review: updatedReview };
  },
);

export const loadMyReviews = createAsyncThunk(
  LOAD_MY_REVIEWS,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    const allReviews = await loadMyReviewsApi(clientToken, userObjectId);
    const { albums, prints } = extractAlbumAndPrints(allReviews[1]?.albums);

    dispatch(storeSeries({ series: allReviews[0]?.series }));
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));

    return { allReviews };
  },
);

export const loadMyReviewForSerie = createAsyncThunk(
  LOAD_MY_REVIEW_FOR_SERIE,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;
    const serieObjectId = params?.serieObjectId || null;

    const clientToken = getClientToken(getState());
    const myReview = await loadMyReviewForSerieApi(clientToken, userObjectId, serieObjectId);
    return { myReview, serieObjectId };
  },
);

export const loadMyReviewsForAlbum = createAsyncThunk(
  LOAD_MY_REVIEWS_FOR_ALBUM,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;
    const albumObjectId = params?.albumObjectId || null;

    const clientToken = getClientToken(getState());
    const myReviews = await loadMyReviewsForAlbumApi(clientToken, userObjectId, albumObjectId);
    return { myReviews, albumObjectId };
  },
);

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    resetReviewsReducer: {
      reducer: (state, action) => initialState,
    },
  },
  extraReducers: (builder) => {
    builder
      // loadReview
      .addCase(loadReview.pending, (state, action) => {
        state.loading[LOAD_REVIEW] = true;
        state.errors[LOAD_REVIEW] = null;
      })
      .addCase(loadReview.fulfilled, (state, action) => {
        const review = action.payload.review;
        state.reviewsMap[review.objectId] = review;

        state.loading[LOAD_REVIEW] = false;
        state.errors[LOAD_REVIEW] = null;
      })
      .addCase(loadReview.rejected, (state, action) => {
        state.loading[LOAD_REVIEW] = false;
        state.errors[LOAD_REVIEW] = action.error;
      })
      // loadAppReviews
      .addCase(loadAppReviews.pending, (state, action) => {
        state.loading[LOAD_APP_REVIEWS] = true;
        state.errors[LOAD_APP_REVIEWS] = null;
      })
      .addCase(loadAppReviews.fulfilled, (state, action) => {
        let reviews = { ...state.appReviews };
        (action.payload.appReviews || []).forEach(
          (appReview) => (reviews[appReview.objectId] = appReview),
        );
        state.appReviews = reviews;

        state.loading[LOAD_APP_REVIEWS] = false;
        state.errors[LOAD_APP_REVIEWS] = null;
      })
      .addCase(loadAppReviews.rejected, (state, action) => {
        state.loading[LOAD_APP_REVIEWS] = false;
        state.errors[LOAD_APP_REVIEWS] = action.error;
      })
      // loadSerieReviews
      .addCase(loadSerieReviews.pending, (state, action) => {
        state.loading[LOAD_SERIE_REVIEWS] = true;
        state.errors[LOAD_SERIE_REVIEWS] = null;
      })
      .addCase(loadSerieReviews.fulfilled, (state, action) => {
        const data = action.payload.serieReviewsMap;
        const reviews = { [data.serieObjectId]: data };
        state.serieReviewsMap = Object.assign({}, state.serieReviewsMap, reviews);

        state.loading[LOAD_SERIE_REVIEWS] = false;
        state.errors[LOAD_SERIE_REVIEWS] = null;
      })
      .addCase(loadSerieReviews.rejected, (state, action) => {
        state.loading[LOAD_SERIE_REVIEWS] = false;
        state.errors[LOAD_SERIE_REVIEWS] = action.error;
      })
      // loadAlbumReviews
      .addCase(loadAlbumReviews.pending, (state, action) => {
        state.loading[LOAD_ALBUM_REVIEWS] = true;
        state.errors[LOAD_ALBUM_REVIEWS] = null;
      })
      .addCase(loadAlbumReviews.fulfilled, (state, action) => {
        const data = action.payload.albumReviewsMap;
        const reviews = { [data.albumObjectId]: data };
        state.albumReviewsMap = Object.assign({}, state.albumReviewsMap, reviews);

        state.loading[LOAD_ALBUM_REVIEWS] = false;
        state.errors[LOAD_ALBUM_REVIEWS] = null;
      })
      .addCase(loadAlbumReviews.rejected, (state, action) => {
        state.loading[LOAD_ALBUM_REVIEWS] = false;
        state.errors[LOAD_ALBUM_REVIEWS] = action.error;
      })
      // addSerieReview
      .addCase(addSerieReview.pending, (state, action) => {
        state.loading[ADD_SERIE_REVIEW] = true;
        state.errors[ADD_SERIE_REVIEW] = null;
      })
      .addCase(addSerieReview.fulfilled, (state, action) => {
        const myReview =
          Object.values(action.payload.myReview).length === 0 ? null : action.payload.myReview;
        const mySerieReviewsMap = Object.assign({}, state.mySerieReviewsMap);
        if (myReview) {
          mySerieReviewsMap[action.payload.serieObjectId] = myReview;
        }

        state.mySerieReviewsMap = mySerieReviewsMap;

        state.loading[ADD_SERIE_REVIEW] = false;
        state.errors[ADD_SERIE_REVIEW] = null;
      })
      .addCase(addSerieReview.rejected, (state, action) => {
        state.loading[ADD_SERIE_REVIEW] = false;
        state.errors[ADD_SERIE_REVIEW] = action.error;
      })
      // editSerieReview
      .addCase(editSerieReview.pending, (state, action) => {
        state.loading[EDIT_SERIE_REVIEW] = true;
        state.errors[EDIT_SERIE_REVIEW] = null;
      })
      .addCase(editSerieReview.fulfilled, (state, action) => {
        state.loading[EDIT_SERIE_REVIEW] = false;
        state.errors[EDIT_SERIE_REVIEW] = null;
      })
      .addCase(editSerieReview.rejected, (state, action) => {
        state.loading[EDIT_SERIE_REVIEW] = false;
        state.errors[EDIT_SERIE_REVIEW] = action.error;
      })
      // addAlbumReview
      .addCase(addAlbumReview.pending, (state, action) => {
        state.loading[ADD_ALBUM_REVIEW] = true;
        state.errors[ADD_ALBUM_REVIEW] = null;
      })
      .addCase(addAlbumReview.fulfilled, (state, action) => {
        const myReview =
          Object.values(action.payload.myReview).length === 0 ? null : action.payload.myReview;
        const myAlbumReviewsMap = Object.assign({}, state.myAlbumReviewsMap);
        if (myReview) {
          myAlbumReviewsMap[action.payload.albumObjectId] = [myReview];
        }
        state.myAlbumReviewsMap = myAlbumReviewsMap;

        state.loading[ADD_ALBUM_REVIEW] = false;
        state.errors[ADD_ALBUM_REVIEW] = null;
      })
      .addCase(addAlbumReview.rejected, (state, action) => {
        state.loading[ADD_ALBUM_REVIEW] = false;
        state.errors[ADD_ALBUM_REVIEW] = action.error;
      })
      // editAlbumReview
      .addCase(editAlbumReview.pending, (state, action) => {
        state.loading[EDIT_ALBUM_REVIEW] = true;
        state.errors[EDIT_ALBUM_REVIEW] = null;
      })
      .addCase(editAlbumReview.fulfilled, (state, action) => {
        state.loading[EDIT_ALBUM_REVIEW] = false;
        state.errors[EDIT_ALBUM_REVIEW] = null;
      })
      .addCase(editAlbumReview.rejected, (state, action) => {
        state.loading[EDIT_ALBUM_REVIEW] = false;
        state.errors[EDIT_ALBUM_REVIEW] = action.error;
      })
      // loadMyReviews
      .addCase(loadMyReviews.pending, (state, action) => {
        state.loading[LOAD_MY_REVIEWS] = true;
        state.errors[LOAD_MY_REVIEWS] = null;
      })
      .addCase(loadMyReviews.fulfilled, (state, action) => {
        const [serieReviewsMapObject, albumReviewsMapObject] = action.payload?.allReviews;
        const mySerieReviewsMap = Object.assign({}, state.mySerieReviewsMap);
        const myAlbumReviewsMap = Object.assign({}, state.myAlbumReviewsMap);
        serieReviewsMapObject.series?.forEach(
          (serie) => (mySerieReviewsMap[serie.objectId] = serie.extraInfos.userReview),
        );
        albumReviewsMapObject.albums?.forEach(
          (album) => (myAlbumReviewsMap[album.objectId] = [album.extraInfos.userReview]),
        );

        state.mySerieReviewsMap = mySerieReviewsMap;
        state.myAlbumReviewsMap = myAlbumReviewsMap;

        state.loading[LOAD_MY_REVIEWS] = false;
        state.errors[LOAD_MY_REVIEWS] = null;
      })
      .addCase(loadMyReviews.rejected, (state, action) => {
        console.log('loadMyReviews.rejected', action.error);
        state.loading[LOAD_MY_REVIEWS] = false;
        state.errors[LOAD_MY_REVIEWS] = action.error;
      })
      // loadMyReviewForSerie
      .addCase(loadMyReviewForSerie.pending, (state, action) => {
        state.loading[LOAD_MY_REVIEW_FOR_SERIE] = true;
        state.errors[LOAD_MY_REVIEW_FOR_SERIE] = null;
      })
      .addCase(loadMyReviewForSerie.fulfilled, (state, action) => {
        const myReview =
          Object.values(action.payload.myReview).length === 0 ? null : action.payload.myReview;
        const mySerieReviewsMap = Object.assign({}, state.mySerieReviewsMap);
        if (myReview) {
          mySerieReviewsMap[action.payload.serieObjectId] = myReview;
        }
        state.mySerieReviewsMap = mySerieReviewsMap;

        state.loading[LOAD_MY_REVIEW_FOR_SERIE] = false;
        state.errors[LOAD_MY_REVIEW_FOR_SERIE] = null;
      })
      .addCase(loadMyReviewForSerie.rejected, (state, action) => {
        state.loading[LOAD_MY_REVIEW_FOR_SERIE] = false;
        state.errors[LOAD_MY_REVIEW_FOR_SERIE] = action.error;
      })
      // loadMyReviewsForAlbum
      .addCase(loadMyReviewsForAlbum.pending, (state, action) => {
        state.loading[LOAD_MY_REVIEWS_FOR_ALBUM] = true;
        state.errors[LOAD_MY_REVIEWS_FOR_ALBUM] = null;
      })
      .addCase(loadMyReviewsForAlbum.fulfilled, (state, action) => {
        const myReviews = action.payload.myReviews.length === 0 ? null : action.payload.myReviews;
        const myAlbumReviewsMap = Object.assign({}, state.myAlbumReviewsMap);
        if (myReviews) {
          myAlbumReviewsMap[action.payload.albumObjectId] = myReviews;
        }
        state.myAlbumReviewsMap = myAlbumReviewsMap;

        state.loading[LOAD_MY_REVIEWS_FOR_ALBUM] = false;
        state.errors[LOAD_MY_REVIEWS_FOR_ALBUM] = null;
      })
      .addCase(loadMyReviewsForAlbum.rejected, (state, action) => {
        state.loading[LOAD_MY_REVIEWS_FOR_ALBUM] = false;
        state.errors[LOAD_MY_REVIEWS_FOR_ALBUM] = action.error;
      });
  },
});

export default reviewsSlice.reducer;

export const { resetReviewsReducer } = reviewsSlice.actions;
