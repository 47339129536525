import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { compressAuthorName } from 'bubble-utils/src/author-utils';

import Cover from '@/components/Cover/Cover';

import albumPlaceholder from '@/assets/placeholders/placeholder-item.jpg';

import './NArtAlbumCard.scss';

export default function NArtAlbumCardLight({ albumObjectId, className = '' }) {
  const album = useSelector((state) => state.albums.albums[albumObjectId]) || {};
  const print =
    useSelector((state) => state.prints.prints[album?.defaultSellingPrintObjectId]) || {};

  const authors = album.authors
    ?.map((author) => ({
      name: compressAuthorName(author),
      permalink: author.permalink,
      objectId: author.objectId,
    }))
    .filter((row) => row)
    .slice(0, 3)
    .map((author) => (
      <Link
        key={author.objectId}
        className="no-decoration"
        to={`/${author.permalink}/author/${author.objectId}`}
      >
        {author.name}
      </Link>
    ));

  return (
    <div
      className={`bg-white d-block nart-shadow p-3 rounded d-flex flex-fill h-100 w-100 ${className}`}
    >
      <div className="h-100 w-100">
        <Link to={`/${album.permalink}/album/${album.objectId}`} className="no-decoration">
          <div className="d-flex justify-content-center">
            <Cover
              height={170}
              shadow
              imageUrl={print.images?.front?.smallFramed || albumPlaceholder}
            />
          </div>
          <div className="pt-4">
            <div className="fw-bold text-black">
              {album.serie?.title}{' '}
              {album.tome && album.tome !== 1 && (
                <span className="text-secondary bb-s-text-size">T{album.tome}</span>
              )}
            </div>
          </div>
        </Link>
        <div className="text-secondary pt-1">
          {authors?.reduce((prev, cur, index) => [prev, index !== 0 && ', ', cur], [])}
        </div>
      </div>

      <div className="nart-bg-highlight nart-album-card-tag rounded-medium">
        <Link
          to={`/list?tags=${album.tags?.[0]?.name || album.category}`}
          className="text-white no-decoration"
        >
          {album.tags?.[0]?.name || album.category}
        </Link>
      </div>
    </div>
  );
}
