import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

const GeneralLink = (props) => {
  const to = props.to || '';
  return to.includes('https://') || to.includes('http://') ? (
    <a {...props} href={props.to} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  ) : (
    <Link {...props}>{props.children}</Link>
  );
};

GeneralLink.propTypes = {
  to: PropTypes.string,
};

export default GeneralLink;
