import React, { useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { makeGetAlbumPrints } from 'bubble-reducers/src/selectors';

import Cover from '@/components/Cover/Cover';
import PrintListModal from '@/components/Modals/PrintListModal';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import bubbleUtils from 'bubble-utils';

export default function AlbumPrintsSection({ album, print }) {
  const navigate = useNavigate();

  const getAlbumPrints = useMemo(() => makeGetAlbumPrints(album?.objectId), [album?.objectId]);
  const albumPrints = useSelector((state) => getAlbumPrints(state, album.objectId));

  const [showPrintListModal, setShowPrintListModal] = useState(false);

  const sortedPrints = useMemo(
    () =>
      albumPrints.sort((a, b) => {
        if (
          b.objectId === album.defaultPrintObjectId &&
          a.objectId !== album.defaultPrintObjectId
        ) {
          return 1;
        } else if (b?.sellingInfo?.price && !a?.sellingInfo?.price) {
          return 1;
        } else if (new Date(b.publicationDate) > new Date(a.publicationDate)) {
          return 1;
        } else return -1;
      }),
    [album.defaultPrintObjectId, albumPrints],
  );

  const onClickHandlePrintSelection = useCallback(
    (printObjectId) => () => {
      navigate(`/${album.permalink}/album/${album.objectId}/${printObjectId}`);
    },
    [navigate, album.permalink, album.objectId],
  );

  const handleShowPrintListModal = () => {
    setShowPrintListModal(true);
  };

  return (
    <div className="row px-3 my-3">
      {showPrintListModal && (
        <PrintListModal
          show={showPrintListModal}
          prints={sortedPrints}
          album={album}
          currentPrintObjectId={print.objectId}
          callback={() => setShowPrintListModal(false)}
        />
      )}
      <div className="col-12 d-flex flex-column bg-white p-4">
        <div className="px-3 fw-bold bb-medium-text-size">Toutes les éditions</div>
        <div className="row py-2 px-md-3 d-flex flex-row flex-wrap justify-content-start">
          {sortedPrints?.length > 0 &&
            sortedPrints.map((sortedPrint, i) => {
              if (i < 4 && i < (album?.prints || []).length) {
                return (
                  <WithClickHandler
                    className="col-md-6 col-12 px-2 py-2"
                    key={sortedPrint.objectId}
                    onClick={onClickHandlePrintSelection(sortedPrint.objectId)}
                  >
                    <div
                      className={`col-12 py-2 px-2 d-flex flex-row justify-content-between print-selection ${
                        sortedPrint.objectId === print.objectId ? 'current-print' : ''
                      }`}
                    >
                      <Cover
                        alt={`Couverture de l'album ${album.title}`}
                        rounded
                        shadow
                        height={85}
                        imageUrl={(sortedPrint.images?.front || {}).smallFramed}
                      />
                      <div className="col-9 d-flex flex-column">
                        <div>
                          {!!sortedPrint.publicationDate &&
                            bubbleUtils.date.formatDateWithFormat(sortedPrint.publicationDate)}
                        </div>
                        <div>{sortedPrint.collection || sortedPrint.type || ''}</div>

                        <div className="text-muted">{sortedPrint.ean}</div>
                        <div>
                          {sortedPrint.sellingInfo.price !== null &&
                            bubbleUtils.currency.formatCurrency(sortedPrint.sellingInfo.price || 0)}
                        </div>
                      </div>
                    </div>
                  </WithClickHandler>
                );
              } else if (i === 4 || i === album?.prints?.length) {
                const plural = album.prints.length - i > 1;
                return (
                  <WithClickHandler
                    key={sortedPrint.objectId}
                    className="col-12 pt-2 ps-2 text-muted link-underline text-end"
                    onClick={handleShowPrintListModal}
                  >
                    <span>{`+ ${album.prints.length - i} édition${plural ? 's' : ''}`}</span>
                  </WithClickHandler>
                );
              } else return null;
            })}
        </div>
      </div>
    </div>
  );
}
