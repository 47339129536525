import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { tabs } from '../../../UserProfile';
import UserProfileNavItem from './UserProfileNavItem';

import bubbleUtils from 'bubble-utils';

const UserProfileNavbar = (props) => {
  const location = useLocation();
  const user = useSelector((state) => state.user.user);

  const currentTab = location.pathname.split('/')[3] || '';
  const isInfinity = bubbleUtils.infinity.isUserInfinity(user);
  const isGuest = props.isGuest;

  return (
    <ul className="nav nav-tabs paper-navbar">
      <UserProfileNavItem
        active={currentTab === tabs.MY_LIBRARY}
        userObjectId={props.userObjectId}
        text={isGuest ? 'Ses BD' : 'Mes BD'}
        tab={tabs.MY_LIBRARY}
        imgPath="user_profile/collection_"
      />
      <UserProfileNavItem
        active={currentTab === tabs.MY_MISSING_ALBUMS}
        userObjectId={props.userObjectId}
        text={isGuest ? 'Ses albums manquants' : 'Mes albums manquants'}
        tab={tabs.MY_MISSING_ALBUMS}
        imgPath="user_profile/albumsmanquants_"
      />
      <UserProfileNavItem
        active={currentTab === tabs.MY_WISHLIST}
        userObjectId={props.userObjectId}
        text={isGuest ? 'Ses souhaits' : 'Mes souhaits'}
        tab={tabs.MY_WISHLIST}
        imgPath="user_profile/wish_"
      />
      <UserProfileNavItem
        active={currentTab === tabs.MY_AGENDA}
        userObjectId={props.userObjectId}
        text={isGuest ? 'Ses sorties' : 'Mes sorties'}
        tab={tabs.MY_AGENDA}
        imgPath="user_profile/sorties_"
      />
      <UserProfileNavItem
        active={currentTab === tabs.MY_STATS}
        userObjectId={props.userObjectId}
        text={isGuest ? 'Ses statistiques' : 'Mes statistiques'}
        tab={tabs.MY_STATS}
        imgPath="user_profile/stats_"
      />
      {!isInfinity && (
        <Link
          to="/infinity"
          className={`text-end no-decoration px-5 py-3 bb-medium-text-size text-white`}
        >
          <li>
            Découvrez <span className="text-infinity">Bubble Infinity</span>
          </li>
        </Link>
      )}
    </ul>
  );
};

UserProfileNavbar.propTypes = {
  isGuest: PropTypes.bool,
};

export default UserProfileNavbar;
