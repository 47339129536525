import React, { useEffect } from 'react';

import config from '@/project-config';

const BubbleAffilae = () => {
  useEffect(() => {
    initialize(); // will only initialize on client side because of useEffect
  }, []);

  const initialize = () => {
    if (!config.affilae.programId) {
      console.error('[bubble-affilae] program ID is required.');
      return;
    }

    window._ae = {
      pid: config.affilae.programId,
      advertiserFirst: 21600, // 15 days in mins
    };

    (function () {
      const element = document.createElement('script');
      element.type = 'text/javascript';
      element.async = true;
      element.src = '//static.affilae.com/ae-v3.5.js';
      const body = document.getElementsByTagName('body')[0];
      body.appendChild(element);
    })();
  };

  return null;
};

export default BubbleAffilae;
