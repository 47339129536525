import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatDateWithFormat } from 'bubble-utils/src/date-utils';

import ImageCover from '@/components/ImageCover/ImageCover';

import './ArticleHorizontalCardWhite.scss';

export default function ArticleHorizontalCardWhite(props) {
  const articleFromReducer = useSelector(
    (state) => state.articles.articlesMapByObjectId[props.articleObjectId],
  );

  const article = articleFromReducer || props.article || {};
  if (!article?.path) return null;
  const isBig = !!props.big;
  const width = isBig ? 240 : 100;

  return (
    <div className={`${props.className || ''}`}>
      <div className="bg-white w-100 rounded-medium">
        <Link
          to={article?.path}
          className="cover-zoom nart-shadow transition-short d-flex h-100 w-100 rounded-medium no-decoration"
        >
          <div
            className="overflow-hidden rounded-start nart-image-cover"
            style={{ minWidth: width }}
          >
            <ImageCover
              hoverable
              classNames="rounded-start nart-image-cover"
              width={`${width}px`}
              height="100%"
              imageUrl={article?.imageSmallUrl || article?.imageUrl}
            />
          </div>
          <div className="d-flex flex-fill flex-column p-3">
            <div>
              <span
                className={`bb-s-text-size fw-bold text-9eart-${article?.category} text-uppercase`}
              >
                {article?.section?.title || ''}
              </span>
              <span className="ps-2 bb-s-text-size">
                {formatDateWithFormat(article?.publicationDate, {
                  month: 'long',
                  day: 'numeric',
                })}
              </span>
            </div>
            <div
              className={`fw-bold text-black ${
                isBig ? 'article-white-title-big' : 'article-white-title'
              }`}
            >
              {article?.title || ''}
            </div>
            {isBig && (
              <div className="d-none d-md-block">
                {article?.excerpt || article?.excerptGenerated}
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}
