import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { checkEanStatus, createAlbumAddition } from 'bubble-reducers/src/reducers/catalogs';

export const ADD_ALBUM_TO_ADDITION = 'websiteOther/addAlbumToAddition';

const initialState = {
  loading: {},
  errors: {},
};

export const addAlbumToAddition = createAsyncThunk(
  ADD_ALBUM_TO_ADDITION,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const userEmail = params?.userEmail || null;
    const addition = params?.addition || null;

    const { payload } = await dispatch(checkEanStatus({ ean: addition.ean }));
    const eanStatus = payload.eanStatus;

    if (eanStatus.status === 'alreadyAdded' || eanStatus.status === 'dataFound') {
      if (
        eanStatus.data.isRejected &&
        window.confirm(
          [
            "Nous avons déjà refusé cet album pour l'une des raisons suivantes :",
            "- C'est une réédition d'un album déjà présent.",
            "- C'est une édition non francophone.",
            "- Ce n'est pas une bande dessinée",
            '',
            'Rien de tout ça ? Clickez sur "OK" pour nous le resoumettre',
          ].join('\n'),
        )
      ) {
        addition.isResubmisison = true;
        await dispatch(createAlbumAddition({ userObjectId, userEmail, addition }));
        alert("C'est envoyé !\nMerci pour votre aide :)");
      } else if (eanStatus.data.isInProduction) {
        alert(
          "Cet album se trouve déjà dans notre catalogue, si vous ne l'avez pas trouvé dans la recherche, n'hésitez pas à nous faire signe sur le chat pour nous en informer.",
        );
      } else if (!eanStatus.data.isRejected && !eanStatus.data.isInProduction) {
        alert(
          "Cet album est déjà dans notre pile d'ajouts à traiter et nous faisons tout pour le faire au plus vite 🏎️ . N'hésitez pas à recommencer votre recherche dans quelques heures.\nMerci pour votre patience 🙏",
        );
      }
    } else if (eanStatus.status === 'foundInProduction') {
      alert(
        "Cet album se trouve déjà dans notre catalogue, si vous ne l'avez pas trouvé dans la recherche, n'hésitez pas à nous faire signe sur le chat pour nous en informer.",
      );
    } else {
      await dispatch(createAlbumAddition({ userObjectId, userEmail, addition }));
      alert("C'est envoyé !\nMerci pour votre aide :)");
    }
  },
);

const websiteOtherSlice = createSlice({
  name: 'websiteOther',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // addAlbumToAddition
      .addCase(addAlbumToAddition.pending, (state, action) => {
        state.loading[ADD_ALBUM_TO_ADDITION] = true;
        state.errors[ADD_ALBUM_TO_ADDITION] = null;
      })
      .addCase(addAlbumToAddition.fulfilled, (state, action) => {
        state.loading[ADD_ALBUM_TO_ADDITION] = false;
        state.errors[ADD_ALBUM_TO_ADDITION] = null;
      })
      .addCase(addAlbumToAddition.rejected, (state, action) => {
        state.loading[ADD_ALBUM_TO_ADDITION] = false;
        state.errors[ADD_ALBUM_TO_ADDITION] = action.error;
      });
  },
});

export default websiteOtherSlice.reducer;
