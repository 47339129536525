import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';

import { loadAgendasApi } from '../services/api';

import { getClientToken } from '../selectors';
import { storeAlbums } from './albums';
import { storePrints } from './prints';

export const LOAD_AGENDAS = 'agendas/loadAgendas';

const initialState = {
  agendaAlbumObjectIds: [],
  pastAgendaLoaded: false,
  loading: {},
  errors: {},
};

export const loadAgendas = createAsyncThunk(
  LOAD_AGENDAS,
  async (params, { getState, dispatch }) => {
    const daysPrior = params?.daysPrior || null;
    const startDate = params?.startDate || null;

    const clientToken = getClientToken(getState());
    const agendas = await loadAgendasApi(clientToken, daysPrior, startDate);

    const { albums, prints } = extractAlbumAndPrints(agendas);

    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));

    return { agendas, daysPrior };
  },
);

const agendasSlice = createSlice({
  name: 'agendas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadAgendas
      .addCase(loadAgendas.pending, (state, action) => {
        state.loading[LOAD_AGENDAS] = true;
        state.errors[LOAD_AGENDAS] = null;
      })
      .addCase(loadAgendas.fulfilled, (state, action) => {
        state.agendaAlbumObjectIds = action.payload.agendas.map((agenda) => agenda.objectId);
        state.pastAgendaLoaded = !!action.payload.daysPrior;
        state.loading[LOAD_AGENDAS] = false;
        state.errors[LOAD_AGENDAS] = null;
      })
      .addCase(loadAgendas.rejected, (state, action) => {
        state.agendaAlbumObjectIds = [];
        state.loading[LOAD_AGENDAS] = false;
        state.errors[LOAD_AGENDAS] = action.error;
      });
  },
});

export default agendasSlice.reducer;
