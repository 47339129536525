import React from 'react';

import { Link } from 'react-router-dom';

import { getAssetImage } from '@/services/image-utils';

import './InfinityCard.css';

const InfinityCard = (props) => {
  return (
    <div className="rounded text-center text-white p-3 my-3 infinity-black-overlay">
      <div className="fw-bold bb-l-text-size infinity-reduce-line-height">
        Essayez
        <br />
        <span className="text-infinity">Bubble Infinity</span>
      </div>
      <img
        alt="inf"
        className="infinity-card-logo"
        src={getAssetImage('icon_infinity_yellow.svg')}
      />
      <div className="h-separator mx-n3 my-3" />
      <div className="text-start fw-bold text-nowrap py-2">
        <div className="pb-1">
          <span role="img" aria-label="check">
            ✅
          </span>{' '}
          Gestion des éditions
        </div>
        <div className="pb-1">
          <span role="img" aria-label="check">
            ✅
          </span>{' '}
          Lu / Non lu
        </div>
        <div className="pb-1">
          <span role="img" aria-label="check">
            ✅
          </span>{' '}
          Statistiques avancées
        </div>
        <div className="pb-1">
          <span role="img" aria-label="check">
            ✅
          </span>{' '}
          EO, dédicaces et prêts
        </div>
        <div className="pb-1">
          <span role="img" aria-label="check">
            ✅
          </span>{' '}
          Notes personnelles
        </div>
        <div className="pb-1">
          <span role="img" aria-label="check">
            ✅
          </span>{' '}
          Pas de publicité
        </div>
        <div>
          <span role="img" aria-label="check">
            ✅
          </span>{' '}
          Images <span className="text-pink">X</span> débloquées
        </div>
      </div>
      <Link to="/infinity#trial" className="no-decoration btn btn-warning fw-bold mt-3">
        Découvrir les autres avantages
      </Link>
    </div>
  );
};

export default InfinityCard;
