import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import bubbleUtils from 'bubble-utils';

import '../AgendaSections.css';

const DATE_FORMAT = {
  year: null,
  month: 'long',
};

const AgendaDates = ({ callback, sectionsArray }) => {
  const onClickCallback = useCallback((e) => callback(e.target.id), [callback]);

  return (
    <div className="speech-bubble d-flex flex-column pt-2 ps-3 bb-shadow-hover">
      <div className="d-flex bg-success ms-n3 mt-n2 rounded-top text-white ps-3 py-3">
        <span className="fw-bold">Calendrier</span> <div className="flex-fill" />
        <Icon className="me-3" name="calendar-alt" />
      </div>
      <div className="pt-2"></div>
      {sectionsArray.map((section, index) => (
        <WithClickHandler
          elemId={index}
          key={`header_agenda_${section.firstDayDate}`}
          onClick={onClickCallback}
        >
          <div id={`header_agenda_${section.firstDayDate}`} className="date-transition mb-2">
            <span className="d-none d-xl-inline-block me-1"></span>
            {bubbleUtils.date.formatDateWithFormat(section.firstDayDate, DATE_FORMAT) || '...'}{' '}
            <span className="bb-s-text-size">({section.albums.length})</span>
          </div>
        </WithClickHandler>
      ))}
    </div>
  );
};

AgendaDates.propTypes = {
  sectionsArray: PropTypes.arrayOf(
    PropTypes.shape({
      firstDayDate: PropTypes.string,
    }),
  ),
};

export default AgendaDates;
