import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { editUser } from 'bubble-reducers/src/reducers/user';
import { getUserOrGuest } from 'bubble-reducers/src/selectors';

import { setNewsletterOptIn } from '@/engine/reducers/website-interface';

import { isValidEmail } from 'bubble-utils/src/validity-utils';

import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';

import pinkLight from '@/assets/icon_alert.svg';

import './NewsletterOptIn.scss';

const NewsletterOptInCard = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUserOrGuest);
  const newsletterOptIn = useSelector((state) => state.websiteInterface.newsletterOptIn);

  const [email, setEmail] = useState(user?.email || '');

  useEffect(() => {
    dispatch(setNewsletterOptIn({ newsletterOptIn: false }));
  }, []);

  const handleButton = useCallback(() => {
    if (!isValidEmail(email)) {
      alert('Votre email semble incorrect');
    } else {
      dispatch(
        editUser({
          userObjectId: user.objectId,
          user: {
            email,
            hasAcceptedNewsletter: true,
            hasAcceptedNewsletterCommercial: true,
          },
        }),
      );
      dispatch(setNewsletterOptIn({ newsletterOptIn: true }));
    }
  }, [email, user?.objectId]);

  return (
    <div className="h-100 sidebar-optin-background rounded">
      <div className="d-flex flex-column flex-fill h-100 align-items-center p-3">
        {!newsletterOptIn && (
          <>
            <div className="d-flex flex-column flex-fill text-center justify-content-between align-items-center">
              <img className="light-icon" alt="light" src={pinkLight} />
              <p className={`fw-bold my-0 p18p`}>
                Vous aimez la nouveauté ?
                <br />
                Restez au courant des dernières sorties
              </p>
              <div>
                <div className="mb-n2 pt-2">
                  {!!user?.objectId ? (
                    <>
                      <LabeledTextInput
                        className="input-small-text"
                        type="email"
                        value={email}
                        noRightLabel
                        white
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button className="btn btn-bubble-color px-4" onClick={handleButton}>
                        Je m'inscris
                      </button>
                    </>
                  ) : (
                    <Link to="/login?nl=t" className="px-4 mt-n5 btn btn-bubble-color">
                      Je m'inscris !
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {newsletterOptIn && (
          <>
            <div className="mt-4 bg-success rounded-circle d-flex align-items-center justify-content-center valid-circle">
              <Icon className="text-white bb-l-text-size" name="check" />
            </div>
            <p className="pt-4 fw-bold my-0 p18p text-center">
              Votre inscription à la newsletter a été prise en compte.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default NewsletterOptInCard;
