import React from 'react';

import { useSelector } from 'react-redux';

import { LOAD_AUTHOR } from 'bubble-reducers/src/reducers/authors';

import AuthorSerieCard from '../components/AuthorSerieCard/AuthorSerieCard';

export default function AuthorSeriesSection({ authorObjectId }) {
  const authorSeriesObjectIdsMap = useSelector(
    (state) => state.authors.authorSeriesObjectIdsMap[authorObjectId],
  );

  const isLoading = useSelector((state) => state.authors.loading[LOAD_AUTHOR]);

  return (
    <>
      {isLoading && (
        <div className="col-12 d-flex justify-content-center pb-5">
          <span className="spinner-border spinner-lg" />
        </div>
      )}
      {/* <div style={{ position: 'absolute', top: 25, right: 8 }}>Trier par ... v</div> TODO:Filters */}
      <div className="row">
        {authorSeriesObjectIdsMap?.map((serieObjectId) => (
          <div key={`author_serie_${serieObjectId}`} className="col-12 col-md-6 col-lg-4 pb-3">
            <AuthorSerieCard serieObjectId={serieObjectId} />
          </div>
        ))}
      </div>
    </>
  );
}
