import React from 'react';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import placeholderJpegUrl from '@/assets/placeholders/placeholder-item.jpg';

import './AlbumSelectionCell.scss';

const AlbumSelectionCell = (props) => {
  const { album, onClick, selectedAlbumObjectId } = props;
  const isSelected = selectedAlbumObjectId === album.objectId;
  const imageUrl = album.imageUrl || album?.images?.front?.smallFramed || placeholderJpegUrl;
  const serieTitle = album?.serieTitle || album?.serie?.title || '';

  return (
    <WithClickHandler onClick={onClick}>
      <div
        className={`d-flex rounded p-3 my-2 align-items-center ${
          (isSelected && 'border border-success album-cell-selected') || 'bg-white'
        }`}
      >
        <img className="album-img" alt="revision_album" src={imageUrl} />
        <div className="d-flex flex-column">
          <div>
            <span className="ps-4 bb-medium-text-size text-primary">{serieTitle || ''}</span>
            <span className="bb-small-text-size">{album.tome ? ` - Tome ${album.tome}` : ''}</span>
          </div>
          <div>
            <span className="ps-4 bb-medium-text-size text-black fw-bold">{album.title || ''}</span>
          </div>
        </div>
        <div className="flex-fill"></div>
        {isSelected && <Icon className="album-cell-icon ps-3" name="check" />}
      </div>
    </WithClickHandler>
  );
};

export default AlbumSelectionCell;
