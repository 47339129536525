import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadPromotionApi, loadPromotionsApi } from '../services/api';

import { getClientToken } from '../selectors';

const LOAD_PROMOTION = 'promotions/loadPromotion';
export const LOAD_PROMOTIONS = 'promotions/loadPromotions';

const initialState = {
  promotionsMapByObjectId: {},
  loading: {},
  errors: {},
};

export const loadPromotion = createAsyncThunk(LOAD_PROMOTION, async (params, { getState }) => {
  const promotionObjectId = params?.promotionObjectId || null;

  const clientToken = getClientToken(getState());
  const promotion = await loadPromotionApi(clientToken, promotionObjectId);
  return { promotion };
});

export const loadPromotions = createAsyncThunk(LOAD_PROMOTIONS, async (params, { getState }) => {
  const paramsString = params?.paramsString || null;

  const clientToken = getClientToken(getState());
  const promotions = await loadPromotionsApi(clientToken, paramsString);
  return { promotions };
});

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadPromotion
      .addCase(loadPromotion.pending, (state, action) => {
        state.loading[LOAD_PROMOTION] = true;
        state.errors[LOAD_PROMOTION] = null;
      })
      .addCase(loadPromotion.fulfilled, (state, action) => {
        state.promotionsMapByObjectId[action.payload.promotion.objectId] = action.payload.promotion;
        state.loading[LOAD_PROMOTION] = false;
        state.errors[LOAD_PROMOTION] = null;
      })
      .addCase(loadPromotion.rejected, (state, action) => {
        state.loading[LOAD_PROMOTION] = false;
        state.errors[LOAD_PROMOTION] = action.error;
      })
      // loadPromotions
      .addCase(loadPromotions.pending, (state, action) => {
        state.loading[LOAD_PROMOTIONS] = true;
        state.errors[LOAD_PROMOTIONS] = null;
      })
      .addCase(loadPromotions.fulfilled, (state, action) => {
        state.promotionsMapByObjectId = (action.payload.promotions || []).reduce((map, cur) => {
          map[cur.objectId] = cur;
          return map;
        }, {});
        state.loading[LOAD_PROMOTIONS] = false;
        state.errors[LOAD_PROMOTIONS] = null;
      })
      .addCase(loadPromotions.rejected, (state, action) => {
        state.loading[LOAD_PROMOTIONS] = false;
        state.errors[LOAD_PROMOTIONS] = action.error;
      });
  },
});

export default promotionsSlice.reducer;
