const getPublicationDateRangesMap = () => {
  const now = new Date();
  const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
  const less3Months = new Date(new Date(today).setMonth(today.getMonth() - 3));
  const less6Months = new Date(new Date(today).setMonth(today.getMonth() - 6));
  const less12Months = new Date(new Date(today).setMonth(today.getMonth() - 12));
  const middleAge = new Date(new Date(today).setYear(1000));
  const future = new Date(new Date(today).setYear(3000));
  return {
    'A paraître': [today, future],
    'Nouveautés entre 0 à 3 mois': [less3Months, today],
    '3 à 6 mois': [less6Months, less3Months],
    '6 à 12 mois': [less12Months, less6Months],
    '+12 mois': [middleAge, less12Months],
  };
};

export const filterSeries = (series, albums, filters, userProfile = {}) => {
  if (!userProfile) {
    return series;
  }

  const filterItemsAsArrays = Object.entries(filters).reduce((acc, curr) => {
    acc[curr[0]] = Object.keys(curr[1]);
    return acc;
  }, {});

  return (
    series
      .map((lightSerie) => {
        const serie = Object.assign({}, lightSerie);
        serie.albums = serie.albums.slice();
        let isIn = true;

        // series
        if (filterItemsAsArrays?.serieObjectIds?.length) {
          isIn = filters?.serieObjectIds?.[serie.serie.objectId];
        }

        // categories
        if (filterItemsAsArrays?.categories?.length) {
          isIn = filterItemsAsArrays?.categories?.some(
            (c) => (serie.serie.category || '').toLowerCase() === c,
          );
        }

        // genre
        if (isIn && filterItemsAsArrays?.genres?.length) {
          isIn = filterItemsAsArrays?.genres?.some((g) => serie.serie.genre === g);
        }

        // started
        if (
          isIn &&
          filterItemsAsArrays?.started?.length &&
          filterItemsAsArrays?.started?.length !== 2
        ) {
          const value = filterItemsAsArrays?.started?.[0] === 'started';
          isIn = value ? serie.albumsOwned !== 0 : serie.albumsOwned === 0;
        }

        // serie is ok, now checking each albums
        if (isIn) {
          let newAlbums = (serie.albums || [])
            .map((albumObjectId) => {
              const album = albums[albumObjectId];
              if (!album) return false;
              let isAlbumIn = true;

              // read
              if (filterItemsAsArrays?.read?.length && filterItemsAsArrays?.read?.length !== 2) {
                const value = filterItemsAsArrays?.read?.[0] === 'read';
                isAlbumIn = !!(userProfile.readAlbumsIdsMap || {})[album.objectId] === value;
              }

              // owned
              if (
                isAlbumIn &&
                filterItemsAsArrays?.owned?.length &&
                filterItemsAsArrays?.owned?.length !== 2
              ) {
                const value = filterItemsAsArrays?.owned?.[0] === 'owned';
                isAlbumIn = !!(userProfile.ownedAlbumsIdsMap || {})[album.objectId] === value;
              }

              // signed
              if (isAlbumIn && filterItemsAsArrays?.signed?.length) {
                isAlbumIn = !!(userProfile.signedAlbumsIdsMap || {})[album.objectId];
              }

              // originalEdition
              if (isAlbumIn && filterItemsAsArrays?.oe?.length) {
                isAlbumIn = !!(userProfile.originalEditionsAlbumsIdsMap || {})[album.objectId];
              }

              // loaned
              if (isAlbumIn && filterItemsAsArrays?.loaned?.length) {
                isAlbumIn = !!(userProfile.loanAlbumsIdsMap || {})[album.objectId];
              }

              // note
              if (isAlbumIn && filterItemsAsArrays?.note?.length) {
                const note = Number(filterItemsAsArrays.note[0]);
                isAlbumIn = !!(serie.serie.note || album.note >= note);
              }

              // publishers
              if (filterItemsAsArrays?.publishers?.length) {
                isAlbumIn = filterItemsAsArrays?.publishers?.some((p) => album.publisher === p);
              }

              // publication date
              if (filterItemsAsArrays?.publicationDates?.length) {
                const publicationDateRangesMap = getPublicationDateRangesMap();
                const publicationDateRanges = filterItemsAsArrays.publicationDates.map(
                  (pub) => publicationDateRangesMap[pub],
                );
                let isAtLeastInOneRange = false;
                if (publicationDateRanges?.length) {
                  const publicationDate = new Date(album.publicationDate);
                  publicationDateRanges.forEach((timePeriod) => {
                    if (publicationDate >= timePeriod[0] && publicationDate <= timePeriod[1]) {
                      isAtLeastInOneRange = true;
                    }
                  });
                }
                isAlbumIn = isAtLeastInOneRange;
              }

              if (isAlbumIn) return album;
              return null;
            })
            .filter((row) => row);

          // remap serie.albums to objectIds
          serie.albums = newAlbums.map((album) => album.objectId);
          // remove serie if no albums match
          if (serie.albums.length === 0) {
            return null;
          }
          return serie;
        }
        return null;
      })
      .filter((row) => row) || []
  );
};

export const filterAlbums = (albums, filters) => {
  const filterItemsAsArrays = Object.entries(filters).reduce((acc, curr) => {
    acc[curr[0]] = Object.keys(curr[1]);
    return acc;
  }, {});

  return albums.filter((albumTmp) => {
    const album = Object.assign({}, albumTmp);
    const serie = album.serie;
    let isAlbumIn = true;

    // categories
    if (filterItemsAsArrays?.categories?.length) {
      isAlbumIn = !!filterItemsAsArrays?.categories?.some(
        (category) => (serie.category || '').toLowerCase() === category,
      );
    }

    // genres
    if (filterItemsAsArrays?.genres?.length) {
      isAlbumIn = !!filterItemsAsArrays?.genres?.some(
        (genre) => serie.genre === genre || !!(album.tags || []).find((tag) => tag.name === genre),
      );
    }

    // publishers
    if (filterItemsAsArrays?.publishers?.length) {
      isAlbumIn = !!filterItemsAsArrays?.publishers?.some(
        (publisher) => album.publisher === publisher,
      );
    }

    return isAlbumIn;
  });
};

const getFilterListsFromAlbums = (
  album,
  genreList,
  publicationList,
  publisherList,
  publicationMap,
) => {
  if (album.genre && genreList.indexOf(album.genre) === -1) {
    genreList.push(album.genre);
  }

  if (album.publisher && publisherList.indexOf(album.publisher) === -1) {
    publisherList.push(album.publisher);
  }

  if (publicationList.length !== 5) {
    for (var i = 0; i < publicationMap.length; ++i) {
      const publicationDate = new Date(album.publicationDate);
      if (
        publicationDate >= publicationMap[i][0] &&
        publicationDate <= publicationMap[i][1] &&
        publicationList.indexOf(publicationMap[i][2]) === -1
      ) {
        publicationList.push(publicationMap[i][2]);
        break;
      }
    }
  }
};

export const getFilterLists = (data, myAlbums, isSeriesData) => {
  let nameList = [];
  let genreList = [];
  let typeList = [];
  let publicationList = [];
  let publisherList = [];

  const now = new Date();
  const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
  const less3Months = new Date(new Date(today).setMonth(today.getMonth() - 3));
  const less6Months = new Date(new Date(today).setMonth(today.getMonth() - 6));
  const less12Months = new Date(new Date(today).setMonth(today.getMonth() - 12));
  const middleAge = new Date(new Date(today).setYear(1000));
  const future = new Date(new Date(today).setYear(3000));

  // 10: 20: etc.. are here to help sorting the list afterward
  const publicationMap = [
    [today, future, '10:A paraître'],
    [less3Months, today, '20:Nouveautés entre 0 à 3 mois'],
    [less6Months, less3Months, '30:3 à 6 mois'],
    [less12Months, less6Months, '40:6 à 12 mois'],
    [middleAge, less12Months, '50:+12 mois'],
  ];

  if (isSeriesData) {
    data.forEach((serie) => {
      if (nameList.indexOf(serie.serie.title) === -1) {
        nameList.push({
          title: serie.serie.title,
          image:
            serie.serie.images && serie.serie.images.front
              ? serie.serie.images.front.smallFramed
              : serie.serie.imagesCompletion && serie.serie.imagesCompletion.front
                ? serie.serie.imagesCompletion.front.smallFramed
                : null,
          objectId: serie.serie.objectId,
        });
      }

      if (serie.serie.genre && genreList.indexOf(serie.serie.genre) === -1) {
        genreList.push(serie.serie.genre);
      }

      serie.albums.forEach((albumObjectId) => {
        const album = myAlbums[albumObjectId] || {};
        getFilterListsFromAlbums(album, genreList, publicationList, publisherList, publicationMap);
      });
    });
  } else {
    // comes from agenda, albums are complete and dont need to be loaded from cache
    data.forEach((album) => {
      getFilterListsFromAlbums(album, genreList, publicationList, publisherList, publicationMap);
    });
  }

  publicationList.sort((itemA, itemB) => {
    return Number.parseInt(itemA, 10) > Number.parseInt(itemB, 10);
  });
  for (var i = 0; i < publicationList.length; i++) {
    publicationList[i] = publicationList[i].substr(3);
  }

  // alphabetically sort genre list
  genreList = genreList.sort();

  // alphabetically sort publisher list
  publisherList.sort();

  return {
    nameList,
    genreList,
    typeList,
    publicationList,
    publisherList,
  };
};
