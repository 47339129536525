import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadPrintApi } from '../services/api';

import { getClientToken } from '../selectors';

import bubbleUtils from 'bubble-utils';

export const LOAD_PRINT = 'prints/loadPrint';

const initialState = {
  prints: {},
  loading: {},
  errors: {},
};

export const loadPrint = createAsyncThunk(LOAD_PRINT, async (params, { getState }) => {
  const objectId = params?.objectId || null;
  const options = params?.options || null;

  const clientToken = getClientToken(getState());
  const queryString = bubbleUtils.string.buildQueryString(options);
  const print = await loadPrintApi(clientToken, objectId, queryString);
  return { print };
});

const printsSlice = createSlice({
  name: 'prints',
  initialState,
  reducers: {
    resetPrintsReducer: {
      reducer: (state, action) => initialState,
    },
    storePrints: {
      reducer: (state, action) => {
        const newPrints = {};
        action.payload.prints.forEach((print) => (newPrints[print.objectId] = print));
        state.prints = Object.assign({}, state.prints, newPrints);
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadPrint
      .addCase(loadPrint.pending, (state, action) => {
        state.loading[LOAD_PRINT] = true;
        state.errors[LOAD_PRINT] = null;
      })
      .addCase(loadPrint.fulfilled, (state, action) => {
        const newPrint = { [action.payload.print.objectId]: action.payload.print };
        state.prints = Object.assign({}, state.prints, newPrint);
        state.loading[LOAD_PRINT] = false;
        state.errors[LOAD_PRINT] = null;
      })
      .addCase(loadPrint.rejected, (state, action) => {
        state.loading[LOAD_PRINT] = false;
        state.errors[LOAD_PRINT] = action.error;
      });
  },
});

export default printsSlice.reducer;

export const { storePrints, resetPrintsReducer } = printsSlice.actions;
