import React from 'react';

import Cover from '@/components/Cover/Cover';

import '../AlbumCard.scss';

export default function AlbumCardPlaceholder() {
  return (
    <div className="album-card-placeholder">
      <div className="rounded bg-white p-3 nart-shadow">
        <div className="mb-3">
          <Cover className="album-card-image" />
        </div>
        <div className="pt-2">
          <div className="text-black no-decoration link-to-orange">
            <div className="fw-bold overflow-hidden mt-n2 album-card-placeholder-top-bars">
              <div className="bg-secondary rounded w-75 album-card-placeholder-bar" />
              <div className="bg-secondary rounded w-25 album-card-placeholder-bar mt-1" />
            </div>
          </div>
          <div className="album-card-placeholder-spacer"></div>
          <div className="d-flex justify-content-between align-items-center pt-1">
            <div className="bg-secondary rounded w-50 album-card-placeholder-bar" />
            <div className="bg-secondary rounded w-25 album-card-placeholder-bar" />
          </div>
          <div className="border rounded p-2 d-flex justify-content-center mt-2">
            <div className="bg-secondary d-flex w-75 align-self-center rounded album-card-placeholder-bar" />
          </div>
        </div>
      </div>
    </div>
  );
}
