import PropTypes from 'prop-types';
import React from 'react';

import { PROJECT } from '@/project-constants';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { createStorePartnershipRequest } from 'bubble-reducers/src/reducers/stores';
import { addStoreToFavorite, removeStoreFromFavorite } from 'bubble-reducers/src/reducers/user';
import { getUserFavoriteStoresIds, getUserOrGuest } from 'bubble-reducers/src/selectors';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import { Icon } from '@/components/Icon/Icon';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

import './OneClickStoreCard.css';

const OneClickStoreCard = (props) => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const user = useSelector((state) => getUserOrGuest(state));
  const isFavorite = useSelector(
    (state) => !!getUserFavoriteStoresIds(state).includes(props.bookseller.objectId),
  );

  const hasStoreInfo = !('noStockInfo' in props);
  const bookseller = props.bookseller || {};
  const stocks = bookseller.stocks || { availableItems: [], orderableItems: [] };
  const availableItemsCount = stocks.availableItems.length;
  const orderableItemsCount = stocks.orderableItems.length;
  const requestedItems = props.requestedItems || [];
  const storeHasNothingInStock = availableItemsCount === 0 && orderableItemsCount === 0;
  let label = '';
  let color = '';
  if (availableItemsCount || orderableItemsCount) {
    if (requestedItems.length === 1) {
      label = availableItemsCount ? 'En stock' : 'Commandable';
    } else {
      label = [
        availableItemsCount ? availableItemsCount + ' en stock' : null,
        ' ',
        orderableItemsCount
          ? orderableItemsCount + ' commandable' + (orderableItemsCount > 1 ? 's' : '')
          : null,
      ]
        .join('')
        .trim();
    }
    color = availableItemsCount && orderableItemsCount === 0 ? 'text-success' : 'text-warning';
  } else {
    label = 'Non disponible';
    color = 'text-danger';
  }

  const isNotPartner = !bookseller.isClickAndCollectSeller;
  const isClosed = !!bookseller.isClosed;

  const onClickMissingStore = () => {
    const storeObjectId = bookseller.objectId;
    dispatch(createStorePartnershipRequest({ storeObjectId, userObjectId: user.objectId }));
    alert(
      `Nous avons bien pris en compte votre demande pour que la librairie ${bookseller.name} à ${bookseller.city} soit partenaire Bubble, merci 🙂`,
    );
  };

  const addToFavorite = (storeObjectId) => {
    dispatch(addStoreToFavorite({ userObjectId: user.objectId, storeObjectId }));
  };

  const removeFromFavorite = (storeObjectId) => {
    dispatch(removeStoreFromFavorite({ userObjectId: user.objectId, storeObjectId }));
  };

  const onClickFavorite = () => {
    if (isUserlogged()) {
      isFavorite ? removeFromFavorite(bookseller.objectId) : addToFavorite(bookseller.objectId);
    }
  };

  const onClickStoreCardButton = () => {
    const requestedItems = props.requestedItems || [];
    const stocks = bookseller.stocks || { availableItems: [], orderableItems: [] };
    const availableItemsCount = stocks.availableItems.length;
    const storeHasEverythingInStock = availableItemsCount === requestedItems.length;

    if (isUserlogged()) {
      if (props.buttonCallback) {
        return props.buttonCallback(
          bookseller,
          requestedItems.map((item) => item.itemObjectId) || null,
          storeHasEverythingInStock,
        );
      }
    }
  };

  return (
    <div className="container">
      <div className="py-3 row align-items-center bg-white rounded position-relative">
        <div className="col-lg-7 d-flex">
          <div className="me-3">
            <img
              alt="img"
              className="rounded store-logo"
              src={bookseller.logo || placeholderProfileJpg}
            />
          </div>
          <div>
            <Link
              to={`/${bookseller.permalink}/store/${bookseller.objectId}`}
              className="fw-bold bb-medium-text-size"
            >
              {bookseller.name}
            </Link>
            <div>{bookseller.schedule}</div>
            <div>
              {bookseller.line1} {bookseller.zipcode} {bookseller.city} (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={bookseller.googleMapsUrl || 'https://www.google.com/maps'}
              >
                Voir sur le plan
              </a>
              )
            </div>
            <button
              className={`${isFavorite && 'text-danger'} btn btn-link no-decoration mx-0 p-0 my-2`}
              onClick={onClickFavorite}
            >
              <Icon className="bb-medium-text-size" name="store" />{' '}
              {isFavorite
                ? 'Supprimer de mes librairies favorites'
                : 'Ajouter à mes librairies favorites'}
            </button>
          </div>
        </div>
        {hasStoreInfo && (
          <div className="col-lg-2 text-center">
            {isNotPartner ? (
              <div className={`fw-bold`}>Librairie non partenaire</div>
            ) : (
              <div className={`fw-bold ${color}`}>{label}</div>
            )}
          </div>
        )}
        {hasStoreInfo && (
          <div className="col-lg-3">
            {isNotPartner ? (
              <button onClick={onClickMissingStore} className="btn btn-lg btn-primary w-100">
                Demande de partenariat
              </button>
            ) : isClosed ? (
              <button disabled={true} className="btn btn-lg btn-primary w-100">
                Librairie fermée
              </button>
            ) : (
              <button
                disabled={storeHasNothingInStock}
                onClick={onClickStoreCardButton}
                className="btn btn-lg btn-primary text-white w-100"
              >
                Retirer gratuitement
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

OneClickStoreCard.propTypes = {
  bookseller: PropTypes.object.isRequired,
  requestedItems: PropTypes.arrayOf(
    PropTypes.shape({
      itemObjectId: PropTypes.string,
      ean: PropTypes.string,
      images: PropTypes.object,
    }),
  ),
  buttonCallback: PropTypes.func,
};

export default OneClickStoreCard;
