export const PROJECT = {
  CATEGORY_NAMES_LONG: {
    bd: 'Bande dessinées',
    comics: 'Comics',
    mangas: 'Mangas',
    jeunesse: 'Jeunesse',
    Jeunesse: 'Jeunesse',
    'Light Novel': 'Light Novel',
  },
  CATEGORY_NAMES_SHORT: {
    bd: 'BD',
    comics: 'Comics',
    mangas: 'Mangas',
    Jeunesse: 'Jeunesse',
    jeunesse: 'Jeunesse',
    'Light Novel': 'Light Novel',
  },
  PAPER_CATEGORIES: ['bd', 'comics', 'mangas', 'jeunesse', 'webtoon'],
  PAPER_SECTIONS_NAMES: ['actualites', 'critiques', 'incontournables', 'edito', 'en-video'],
  PAPER_SECTIONS_ID_MAP: {
    actualites: '608491395',
    edito: '608487237',
    critiques: '608487239',
    incontournables: '1',
    'en-video': '174725',
    default: null,
  },

  CHECKOUT_STAGE: {
    SHIPPING: 1,
    ADDRESS: 2,
    PAYMENT_METHOD: 3,
    RECAP: 4,
  },
};
