import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import authorPlaceholder from '@/assets/placeholders/placeholder-profile.jpg';

const defaultObject = {};

export default function NArtAuthorCard({ authorObjectId }) {
  const author = useSelector((state) => state.authors.authors[authorObjectId]) || defaultObject;

  return (
    <div className="bg-white">
      <Link
        to={`/${author.permalink}/author/${author.objectId}`}
        className="no-decoration d-block nart-shadow p-3 rounded"
      >
        <div className="d-flex align-items-center">
          <div className="pe-3">
            <img
              alt={author.displayName || 'auteur'}
              src={author.images?.front?.medium || authorPlaceholder}
              style={{ width: '70px', height: '70px' }}
              className="rounded-circle nart-shadow bb-background-light-grey"
            />
          </div>
          <div className="d-flex flex-fill flex-column">
            <div className="h6 m-0 fw-bold">{author.displayName}</div>
            <div className="h6 m-0 text-secondary">
              {[author.yearOfBirth, author.yearOfDeath].filter((row) => row).join(' - ')}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
