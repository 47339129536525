import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';

import { getDefaultSellingPrint } from 'bubble-utils/src/album-utils';

import { Icon } from '@/components/Icon/Icon';
import ImageBrowsingModal from '@/components/Modals/ImageBrowsingModal';
import SearchAlbumCard from '@/components/SearchResults/components/SearchAlbumCard';
import SerieVerticalCard from '@/components/SerieVerticalCard/SerieVerticalCard';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import bubbleUtils from 'bubble-utils';

import './PromotionCard.scss';

const PromotionCard = ({ promotion }) => {
  const [isCopied, _setIsCopied] = useState(false);
  const [showImageBrowsingModal, setShowImageBrowsingModal] = useState(false);

  const onClickCopy = useCallback(() => {
    bubbleUtils.string.copyToClipboard(promotion.promoCode?.code);
    _setIsCopied(true);
  }, [_setIsCopied, promotion.promoCode?.code]);

  const handleShowImageBrowsingModal = () => {
    setShowImageBrowsingModal(true);
  };

  return (
    <div
      id={promotion.objectId}
      className="col-12 bg-white rounded text-center text-md-start py-4 my-4 px-sm-4"
    >
      {showImageBrowsingModal && (
        <ImageBrowsingModal
          show={showImageBrowsingModal}
          startIndex={0}
          images={[promotion.imageUrl]}
          callback={() => setShowImageBrowsingModal(false)}
        />
      )}

      <div className="row align-items-md-center g-0">
        <div className="col-12 col-md-2 text-center">
          <WithClickHandler
            className="d-flex justify-content-center"
            onClick={handleShowImageBrowsingModal}
          >
            <div className="bb-shadow-hover">
              <img
                alt={promotion.title}
                className="promotion-product-image rounded"
                src={promotion.imageUrl}
              />
            </div>
          </WithClickHandler>
        </div>
        <div className="col-md-7">
          <div className="col-12 d-flex justify-content-center justify-content-md-start text-bubble-color pt-3 pt-md-0">
            {promotion.condition}
          </div>
          <div className="col-12 d-flex justify-content-center justify-content-md-start pt-3">
            <h2>{promotion.title}</h2>
          </div>
          <div className="col-12 d-flex justify-content-center justify-content-md-start pt-3 pt-md-0">
            <p className="with-carriage-return">{promotion.description}</p>
          </div>
          {!!promotion.promoCode?.code && (
            <div className="col-md-12 pt-md-2">
              <div className="row">
                <div className="col-12 col-md-auto justify-content-center justify-content-md-start fw-bold">
                  <div className="bb-l-text-size">{promotion.promoCode?.code}</div>
                </div>
                <div className="order-md-first col-12 col-md-auto d-flex justify-content-center justify-content-md-start">
                  <button
                    onClick={onClickCopy}
                    className={`btn px-4 ${
                      isCopied ? 'btn-success' : 'btn-bubble-color'
                    } text-white`}
                  >
                    {isCopied ? <Icon className="bb-s-text-size pe-2" name="check" /> : null}
                    {isCopied ? 'Code copié !' : 'Copier le code'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {!!promotion.attachedSeries?.length && (
          <div className="col-md-3 d-flex justify-content-center flex-wrap">
            {promotion.attachedSeries.map((serie) => (
              <div key={serie.objectId} className="p-1">
                <SerieVerticalCard noSubscribe serie={serie} />
              </div>
            ))}
          </div>
        )}
        {!!promotion.attachedAuthors?.length && (
          <div className="col-12 col-md-3 pt-4 pt-md-0 d-flex flex-column align-items-center">
            {promotion.attachedAuthors.map((author) => (
              <Link to={`${author.permalink}/author/${author.objectId}`}>
                {!!author.images?.front?.medium && (
                  <img
                    alt={author.displayName || 'auteur'}
                    src={author.images?.front?.medium}
                    className="rounded promotion-author-image"
                  />
                )}
                <div className="pt-1">{author.displayName}</div>
              </Link>
            ))}
          </div>
        )}
        {!!promotion.attachedSearch && (
          <Link
            to={`/search?q=${promotion.attachedSearch}`}
            className="col-12 col-md-3 pt-4 pt-md-0 d-flex flex-column align-items-center no-decoration"
          >
            <Icon name="search" className="bb-l-text-size pb-1" />
            <div>
              <u>Voir les articles concernés</u>
            </div>
          </Link>
        )}
        {!!promotion.attachedAlbums?.length && (
          <div className="text-start col-12 col-md-3 pt-3 pt-md-0 ps-4 ps-md-0">
            {promotion.attachedAlbums.map((album) => {
              const print = getDefaultSellingPrint(album);
              return (
                <div key={album.objectId} className="py-1">
                  <SearchAlbumCard
                    album={{
                      ...album,
                      imageUrl: print.images?.front?.smallFramed,
                      serieTitle: album.serie?.title,
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PromotionCard;
