import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addAuthorToFollow, removeAuthorFromFollow } from 'bubble-reducers/src/reducers/profiles';
import { getUserId } from 'bubble-reducers/src/selectors';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

export default function AuthorSubscribeBadge({ authorObjectId }) {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const userObjectId = useSelector(getUserId);
  const isSubscribed =
    useSelector((state) => state.profiles.followedAuthorsIdsMap[authorObjectId]) || false;

  const onClickSubscribe = useCallback(() => {
    if (isUserlogged()) {
      if (isSubscribed) dispatch(removeAuthorFromFollow({ userObjectId, authorObjectId }));
      else dispatch(addAuthorToFollow({ userObjectId, authorObjectId }));
    }
  }, [dispatch, isUserlogged, userObjectId, authorObjectId, isSubscribed]);

  return (
    <div
      className={`border px-2 rounded ${
        isSubscribed
          ? 'text-white bordered border-success bg-success'
          : 'text-secondary bordered border-muted bb-background-light-grey'
      }`}
    >
      <WithClickHandler
        consumeEvent
        onClick={onClickSubscribe}
        className="mt-1 d-flex align-items-center"
      >
        <div>{isSubscribed ? <Icon className="pb-1" name="check" size="xs" /> : '+'}</div>
        <div className="d-flex d-md-none d-lg-flex">
          &nbsp;{isSubscribed ? 'Abonné' : "S'abonner"}
        </div>
      </WithClickHandler>
    </div>
  );
}
