import React from 'react';

import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import RevisionMenuCell from './components/RevisionMenuCell';

import './Modals.css';

const AlbumRevisionMenuModal = (props) => {
  const { albumObjectId, serieObjectId, printObjectId } = props;

  const serie = useSelector((state) => state.series.series[serieObjectId]);
  const album = useSelector((state) => state.albums.albums[albumObjectId]);
  const print = useSelector((state) => state.prints.prints[printObjectId]);

  const dismiss = () => {
    props.callback();
  };

  const handleClickSerieInfoRevision = () => {
    dismiss();
    props.openSerieRevisionMenuModal();
  };

  const handleClickAlbumRevision = () => {
    dismiss();
    props.openAlbumRevisionModal();
  };

  const handleClickPrintRevision = () => {
    dismiss();
    props.openPrintRevisionModal();
  };

  const handleClickMisplacedAlbumRevisionMenu = () => {
    dismiss();
    props.openMisplacedAlbumRevisionMenuModal();
  };

  const handleOtherError = () => {
    window &&
      window.Intercom &&
      window.Intercom(
        'showNewMessage',
        `Série: ${serie.title || ''} ${serie.type || ''}\nAlbum: ${album.title || ''} ${
          album.tome || ''
        } ${print.ean || ''}\nErreur:`,
      );
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      {(album && serie && (
        <>
          <div className="modal-header header-no-separator">
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={dismiss}
            ></button>
          </div>
          <div className="modal-body mx-3">
            <div className="row pb-2">
              <div className="col py-1">
                <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
                  <span>Signalement</span>
                  <span className="fw-bold">
                    {serie.title ? serie.title + ' - ' : ''}
                    {album.title ? album.title : album.tome ? `Tome ${album.tome}` : ''}
                  </span>
                </div>
                <div className="text-secondary text-center">
                  Quelles modifications souhaitez-vous suggérer pour cet album ?
                </div>
              </div>
            </div>
            <RevisionMenuCell
              onClick={handleClickAlbumRevision}
              mainText="Informations sur l'album"
              subText="Titre, tome et resumé"
              imageSource="icon_album_memo_grey.svg"
            />
            <RevisionMenuCell
              onClick={handleClickPrintRevision}
              mainText="Informations sur l'édition"
              subText="Editeur, collection, format, auteurs..."
              imageSource="icon_edition_grey.svg"
            />
            <RevisionMenuCell
              onClick={handleClickMisplacedAlbumRevisionMenu}
              mainText="Cet album n'est pas à sa place"
              subText="Mauvaise série, autre édition..."
              imageSource="icon_wrong_place.svg"
            />
            <div className="h-separator mx-5 my-4 d-flex flex-fill" />
            <RevisionMenuCell
              onClick={handleClickSerieInfoRevision}
              mainText="Informations sur la série"
              subText="Nom, catégorie, résumé..."
              imageSource="icon_info_serie.svg"
            />
          </div>
          <div className="h-separator mt-2" />
          <div className="d-flex flex-row justify-content-between p-4">
            <WithClickHandler onClick={handleOtherError}>
              <div className="text-start">
                <u>Signaler une autre erreur</u>
              </div>
            </WithClickHandler>
            <WithClickHandler onClick={dismiss}>
              <div className="text-end">
                <u>Annuler</u>
              </div>
            </WithClickHandler>
          </div>
        </>
      )) ||
        null}
    </Modal>
  );
};

export default AlbumRevisionMenuModal;
