import { getTodayUtcDateWithoutTime } from './date-utils';

export const isUserInfinity = (user, common = {}) => {
  const isActivatedForceInfinity = common.isActivatedForceInfinity;
  const forceInfinityMode = common.forceInfinityMode;
  if (isActivatedForceInfinity) return forceInfinityMode;
  if (!user || !user.infinityExpirationDate) return false;

  return new Date(user.infinityExpirationDate) >= getTodayUtcDateWithoutTime();
};

export const isUserUnlimitedInfinity = (user, common = {}) => {
  const isActivatedForceInfinity = common.isActivatedForceInfinity;
  const forceInfinityMode = common.forceInfinityMode;
  if (isActivatedForceInfinity) return forceInfinityMode;
  if (!user) return false;

  return !!user.hasUnlimitedInfinity;
};

export const isUserInfinityInWarningPeriod = (user, common = {}) => {
  const forceInfinityWarningPeriodMode = common.forceInfinityWarningPeriodMode || false;
  if (forceInfinityWarningPeriodMode) return true;
  if (!user) return false;

  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 * hours * minutes * seconds * milliseconds

  return (
    new Date(user.infinityExpirationDate).getTime() -
      getTodayUtcDateWithoutTime().getTime() -
      sevenDaysInMilliseconds <=
    0
  );
};

export const daysBeforeEndOfPremium = (user) => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  return Math.round(
    Math.abs(
      (new Date(user.infinityExpirationDate).getTime() - getTodayUtcDateWithoutTime().getTime()) /
        oneDayInMilliseconds,
    ),
  );
};

export const hasActivatedTrialPeriod = (user, common = {}) => {
  const isActivatedForceTrialPeriod = common.isActivatedForceTrialPeriod;
  const forceTrialPeriodMode = common.forceTrialPeriodMode;
  if (isActivatedForceTrialPeriod) return forceTrialPeriodMode;
  if (!user || !user.hasActivatedTrialPeriod) return false;
  return true;
};

export const isOverLimit = (user, common = {}, library = {}, amountToAdd = 1) => {
  const forceXAlbumsLimitMode = common.forceXAlbumsLimitMode || false;
  // if the admin panel limit mode is active, we force it if it is true
  const limit = forceXAlbumsLimitMode
    ? { active: true, max: 100 }
    : common.appOptions && common.appOptions.limitOnMaxAlbums
      ? common.appOptions.limitOnMaxAlbums
      : { active: false, max: 100 };

  // limit is inactive
  if (!limit.active) return false;

  // if limit is active we check the number of albums owned
  const numberOfAlbumsOwned = Object.keys(library.ownedAlbumsIdsMap || {}).length || 0;
  return numberOfAlbumsOwned + amountToAdd > limit.max;
};

export const has2MonthsTrialPeriod = (user) => {
  return user && user.has2MonthsTrialPeriod;
};
