import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { loadHighlightedResources } from 'bubble-reducers/src/reducers/highlighted-resources';
import { get4HighlightedResources, getHighlightedArticles } from 'bubble-reducers/src/selectors';

import ArticleHorizontalCard from '@/components/ArticleHorizontalCard/ArticleHorizontalCard';
import SerieCard from '@/components/SerieCard/SerieCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import HighlightAlbumCard from './HighlightAlbumCard';
import HighlightAuthorCard from './HighlightAuthorCard';
import HighlightStoreCard from './HighlightStoreCard';

const HighlightSection = () => {
  const dispatch = useDispatch();

  const highlightedArticles = useSelector((state) => getHighlightedArticles(state));
  const highlightedResources = useSelector((state) => get4HighlightedResources(state));
  const series = useSelector((state) => state.series.series);
  const prints = useSelector((state) => state.prints.prints);

  useEffect(() => {
    dispatch(loadHighlightedResources());
  }, []);

  return (
    <>
      <TitledListContainer
        title={
          <div className="h1 text-center fw-bold pt-5 pb-3">
            BD, Comics, Mangas : les dernières sorties, les dossiers et l'actu décryptée pour vous
          </div>
        }
        list={highlightedArticles.slice(0, 3).map((article) => (
          <div
            className="col-md-6 col-lg-4 mt-3 d-flex flex-fill"
            key={`selection-${article.objectId}`}
          >
            <ArticleHorizontalCard article={article} />
          </div>
        ))}
      />
      <div className="mt-n5 pb-5 w-100">
        <div className="row">
          {highlightedResources?.series?.map((resource) => (
            <div key={'highlight_' + resource.objectId} className="col-md-4 col-lg-3 mt-3">
              <SerieCard serie={resource} />
            </div>
          ))}
          {highlightedResources?.albums?.map((resource) => (
            <div className="col-md-4 col-lg-3 mt-3" key={'highlight_' + resource.objectId}>
              <HighlightAlbumCard
                album={resource}
                serie={series[resource.serie.objectId]}
                prints={(resource.prints || []).map((printObjectId) => prints[printObjectId])}
              />
            </div>
          ))}
          {highlightedResources?.authors?.map((resource) => (
            <div className="col-md-4 col-lg-3 mt-3" key={'highlight_' + resource.objectId}>
              <HighlightAuthorCard author={resource} />
            </div>
          ))}
          {highlightedResources?.stores?.map((resource) => (
            <div className="col-md-4 col-lg-3 mt-3" key={'highlight_' + resource.objectId}>
              <HighlightStoreCard store={resource} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HighlightSection;
