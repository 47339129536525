export default {
  INFINITY_DEFAULT_MAX_ALBUMS: 300,

  BD: 'bd',
  COMICS: 'comics',
  MANGAS: 'mangas',

  // PRIVACY BINARY FLAGS
  FLAG_PRIVACY_MY_BDS: 1,
  FLAG_PRIVACY_MY_MISSING_ALBUMS: 1 << 1,
  FLAG_PRIVACY_MY_WISHLIST: 1 << 2,
  FLAG_PRIVACY_MY_AGENDA: 1 << 3,
  FLAG_PRIVACY_MY_STATS: 1 << 4,
  FLAG_PRIVACY_ALL: 31,

  // ECOMMERCE ISSUES BINARY FLAGS
  STEVEN_NOT_SHIPPED: 1,
  STEVEN_NOT_RECEIVED: 1 << 1,
  STEVEN_CANCELED_RELEASE: 1 << 2,
  STEVEN_UPDATED_RELEASE: 1 << 3,
  STEVEN_OFFICE_NOT_RECEIVED: 1 << 4,

  // ECOMMERCE ISSUES RESOLVED BINARY FLAGS
  ECOMMERCE_ISSUES: {
    RESOLVE: {
      MISMATCH: 1,
      REPORTED: 1 << 1,
      OFFICE_NOT_RECEIVED: 1 << 2,
      NOT_RECEIVED: 1 << 3,
      NOT_RECEIVED_NO_REASON: 1 << 4,
      NOT_RECEIVED_QUANTITY_MISMATCH: 1 << 5,
      NOT_SHIPPED: 1 << 6,
      NOT_SHIPPED_NO_REASON: 1 << 7,
    },

    ISSUE_RESOLVED_FLAG_TO_STRING: {
      [1]: 'Mismatch',
      [1 << 1]: 'Reported',
      [1 << 2]: 'Office not received',
      [1 << 3]: 'Not received',
      [1 << 4]: 'Not Received - No reason',
      [1 << 5]: 'Not Received - Quantity mismatch',
      [1 << 6]: 'Not Shipped',
      [1 << 7]: 'Not Shipped - No reason',
    },
  },

  // BANNER TYPES BINARY FLAGS
  FLAG_PRODUCT_BD: 1,
  FLAG_PRODUCT_COMICS: 1 << 1,
  FLAG_PRODUCT_MANGAS: 1 << 2,
  FLAG_PRODUCT_PROMO: 1 << 3,

  // USER AGENDA REASON BINARY FLAGS
  STEVEN_USER_AGENDA_SERIE_FOLLOWED: 1,
  STEVEN_USER_AGENDA_SERIE_OWNED: 1 << 1,
  STEVEN_USER_AGENDA_ALBUM_WISHLISTED: 1 << 2,
  STEVEN_USER_AGENDA_AUTHOR_FOLLOWED: 1 << 3,

  // USER PRINT OPTIONS BINARY FLAGS
  USER_PRINT: {
    OWNED: 1,
    READ: 1 << 1,
    WISHLIST: 1 << 2,
    LOAN: 1 << 3,
    ORIGINAL_EDITION: 1 << 4,
    SIGNED: 1 << 5,
    MEMO: 1 << 6,
    DELUXE: 1 << 7,
    EXLIBRIS: 1 << 8,
    DIGITAL: 1 << 9,
    FOR_SALE: 1 << 10,
    LIMITED: 1 << 11,
    NUMBERED: 1 << 12,
  },

  PRINT_CONDITION: {
    NEW: 1,
    GOOD: 2,
    AVERAGE: 3,
    BAD: 4,
  },

  PRINT_CONDITION_TO_STRING: {
    [1]: 'Neuf',
    [2]: 'Bon état',
    [3]: 'État moyen',
    [4]: 'Abimé',
  },

  PAYMENT_TYPES: {
    BANK_TRANSFER: 'bank_transfer',
    CHECK: 'check',
    GOCARDLESS: 'gocardless',
  },

  RESERVATION_HELP_TYPE: {
    VALIDATION: 1,
    FOLLOWUP: 2,
    OTHER: 3,
    CANCEL: 4,
  },
  ORDER_HELP_TYPE: {
    EDIT_ADDRESS: 10,
    EDIT_ITEMS: 20,
    CANCEL: 30,
    OTHER: 40,
  },
  PAYMENT_METHOD_ACTIONS: {
    ADD: 'add',
    DEFAULT: 'default',
    DELETE: 'delete',
  },
  REWARD_PROGRAM: {
    MINIMUM_REVIEW_LENGTH: 200,
    POINTS_FOR_NEW_REVIEW: 5,
    POINTS_FOR_NEW_REVIEW_WITH_VERIFIED_PURCHASE: 50,
  },
};
