export const getShippingMethod = (order) => {
  if (!order) {
    return {};
  }
  const shippingMethodSelected = order.shippingMethodSelected || 'home';
  return (
    (order.shippingMethods || []).find(
      (shippingMethod) => shippingMethod.method === shippingMethodSelected,
    ) ||
    (order.shippingMethods || []).find((shippingMethod) => shippingMethod.method) ||
    {}
  );
};

export const getFrenchStatusForOrder = (order) => {
  let status = {};
  const actions = order.storeReservationActions || {};
  status.date =
    ((order.shippingTracking || {}).status || {}).date || actions.shippedDate || order.orderDate;
  status.message = 'En traitement';

  if (order.status === 'canceled') {
    status.message = 'Annulée';
    status.date = actions.orderCanceledDate;
    return status;
  }
  if (order.type === 'reservation') {
    status.date =
      actions.notPaidDate ||
      actions.paidDate ||
      actions.canceledDate ||
      actions.acceptedDate ||
      actions.ongoingDate;

    if (order.status === 'reserved') status.message = 'En attente du libraire';
    if (order.status === 'reservation-ongoing') status.message = 'En commande';
    if (order.status === 'reservation-canceled') status.message = 'Annulée par le libraire';
    if (order.status === 'reserved-and-confirmed') status.message = 'Mise à disposition';
    if (actions && actions.paidDate) status.message = 'Récupérée';
    if (actions && actions.notPaidDate) status.message = 'Non récupérée';
  }
  if (order.type === 'order') {
    status.message = ((order.shippingTracking || {}).status || {}).message || 'En traitement';
  }

  return status;
};
