import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { loadPromotions } from 'bubble-reducers/src/reducers/promotions';

import { getSeoForPromotions } from '@/services/seo-utils';

import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';

import PromotionCard from '../../components/PromotionCard/PromotionCard';
import PromotionsFilter from './components/PromotionsFilter';

import { LINK } from 'bubble-constants';

import './Promotions.scss';

const Promotions = () => {
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const promotionsObject = Object.values(
    useSelector((state) => state.promotions.promotionsMapByObjectId) || {},
  );

  useEffect(() => {
    dispatch(loadPromotions({ paramsString: '?display_order=1' }));
  }, [dispatch]);

  const onSelectFilter = (filter, reset) => {
    let newSeselectedFilters = [...selectedFilters];
    if (reset) {
      newSeselectedFilters = [];
    } else {
      if (selectedFilters.includes(filter)) {
        newSeselectedFilters.splice(selectedFilters.indexOf(filter), 1);
      } else {
        newSeselectedFilters.push(filter);
      }
    }
    setSelectedFilters(newSeselectedFilters);
  };

  return (
    <div className="bb-background-light-grey">
      <BubbleHelmet seo={getSeoForPromotions()} />
      <div className="promotions-image-container">
        <div className="container">
          <div className="row pt-3 pt-md-4">
            <div className="col-12">
              <h1 className="bb-xxl-text-size text-center fw-bold">
                Promotions BD, Comics, Mangas
              </h1>
            </div>
            <div className="col-10 offset-1 text-center py-3">
              <p>Retrouvez ici tous les bons plans et les bonnes affaires du moment.</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-link m-0 p-0 mt-n4 text-primary"
                href={LINK.FAQ_PROMO_CODE}
              >
                Cliquez ici pour savoir comment ajouter un code promo
              </a>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <PromotionsFilter selected={selectedFilters} callback={onSelectFilter} />
            </div>
          </div>
          {promotionsObject
            .filter(
              (promo) =>
                !promo.categories ||
                !selectedFilters.length ||
                selectedFilters.length === 3 ||
                promo.categories.some((category) =>
                  selectedFilters.includes((category || '').toLowerCase()),
                ),
            )
            .map((promo) => (
              <PromotionCard promotion={promo} key={promo.title} />
            ))}
          <div style={{ height: 100 }}></div>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
