import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getTopRatedSeries } from 'bubble-reducers/src/selectors';

import SerieCard from '@/components/SerieCard/SerieCard';

const topSeries = [
  {
    title: 'Les plus commentées du moment',
    link: '/series-les-plus-commentees-du-moment/tops/topMostCommentedSeries30Days',
  },
  {
    title: 'Les plus suivies du moment',
    link: '/series-les-plus-suivies-du-moment/tops/topMostFollowedSeries30Days',
  },
  {
    title: 'Les plus commentées',
    link: '/series-les-plus-commentees/tops/topMostCommentedSeriesAllTime',
  },
  { title: 'Les mieux notées', link: '/series-les-mieux-notees/tops/topBestNotedSeriesAllTime' },
];
const topAlbums = [
  {
    title: 'Nouveautés attendues',
    link: '/nouveautes-les-plus-attendues/tops/topUpcomingAlbums',
  },
  {
    title: 'Albums tendance',
    link: '/albums-tendance/tops/topMostAddedAlbums30Days',
  },
  {
    title: 'Les plus ajoutés 2024',
    link: '/albums-les-plus-ajoutes-en-2024/tops/topMostAddedAlbumsOf2024',
  },
  {
    title: 'Les plus ajoutés 2023',
    link: '/albums-les-plus-ajoutes-en-2023/tops/topMostAddedAlbumsOf2023',
  },
  {
    title: 'Les plus ajoutés 2022',
    link: '/albums-les-plus-ajoutes-en-2022/tops/topMostAddedAlbumsOf2022',
  },
  {
    title: 'Les plus ajoutés 2021',
    link: '/albums-les-plus-ajoutes-en-2021/tops/topMostAddedAlbumsOf2021',
  },
  {
    title: 'Les plus ajoutés 2020',
    link: '/albums-les-plus-ajoutes-en-2020/tops/topMostAddedAlbumsOf2020',
  },
];

const TopMenuDropdown = (props) => {
  const topRatedSeries = useSelector((state) => getTopRatedSeries(state, 4));
  return (
    <>
      <div className="col-md-2">
        <div className="fw-bold">Tops séries</div>
        {topSeries.map((top) => (
          <Link
            key={top.title}
            onClick={props.hideMenu}
            className="d-block no-decoration text-black link-to-orange"
            to={top.link}
          >
            {top.title}
          </Link>
        ))}
      </div>
      <div className="col-md-2">
        <div className="fw-bold">Tops albums</div>
        {topAlbums.map((top) => (
          <Link
            key={'menu' + top.title}
            onClick={props.hideMenu}
            className="d-block no-decoration text-black link-to-orange"
            to={top.link}
          >
            {top.title}
          </Link>
        ))}
      </div>
      <div className="col-md-8">
        <div className="fw-bold">Séries les mieux notées par les membres de Bubble</div>

        <div className="col-md-12">
          <div className="row">
            {topRatedSeries.map((serie) => (
              <div key={serie.objectId} className="col-6 ms-n3 pb-3" onClick={props.hideMenu}>
                <SerieCard grey serieObjectId={serie.objectId} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

TopMenuDropdown.propTypes = {
  hideMenu: PropTypes.func.isRequired,
  clickedHeaderMenu: PropTypes.string,
};

export default TopMenuDropdown;
