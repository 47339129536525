import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {},
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFilterItemValue: {
      reducer: (state, action) => {
        const { filterAction, filterId, filterItem, value } = action.payload;
        if (!state.filters[filterId]) {
          state.filters[filterId] = {};
        }
        if (!state.filters[filterId][filterItem]) {
          state.filters[filterId][filterItem] = {};
        }
        if (filterAction === 'add') {
          state.filters[filterId][filterItem][value] = true;
        }
        if (filterAction === 'del') {
          delete state.filters[filterId][filterItem][value];
        }
      },
    },
    updateFilterItem: {
      reducer: (state, action) => {
        const { filterId, filterItem, values } = action.payload;
        if (!state.filters[filterId]) {
          state.filters[filterId] = {};
        }
        state.filters[filterId][filterItem] = values.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
      },
    },
    reinitFilterId: {
      reducer: (state, action) => {
        const { filterId } = action.payload;
        delete state.filters[filterId];
      },
    },
  },
});

export default filtersSlice.reducer;

export const { updateFilterItemValue, updateFilterItem, reinitFilterId } = filtersSlice.actions;
