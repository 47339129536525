import React, { useEffect, useRef, useState } from 'react';

import config from '@/project-config';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { searchMultiIndex } from 'bubble-reducers/src/reducers/search';
import { getHitsForWebsiteSearchBar } from 'bubble-reducers/src/selectors';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import GlobalOverlay from '../GlobalOverlay/GlobalOverlay';
import SearchResults from '../SearchResults/SearchResults';

import { KEYCODES } from 'bubble-constants';

import './HomeSearchBar.scss';

const HomeSearchBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const hits = useSelector((state) => getHitsForWebsiteSearchBar(state));

  const [searching, setSearching] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [text, setText] = useState('');

  const searchInput = props.inputRef || useRef();
  const barRef = useRef();

  useEffect(() => {
    setSearching(false);
  }, [location.pathname]);

  useEffect(() => {
    return document.removeEventListener('keyup', handleKeyboardEvent);
  }, []);

  const exitAndClear = (refocus = true) => {
    setSearching(false);
    setHasFocus(false);
    setText('');
    if (searchInput.current) searchInput.current.blur();
    if (refocus) {
      setTimeout(() => {
        if (searchInput.current) searchInput.current.focus();
      }, 50);
    }
    if (!!props.exitAndClearCallback) {
      props.exitAndClearCallback();
    }
  };

  const handleKeyboardEvent = (event) => {
    // ESCAPE
    if (event.keyCode === 27) {
      exitAndClear(false);
    }
    // ENTER
    if (text && event.keyCode === KEYCODES.ENTER) {
      navigate(`/search?q=${encodeURI(text.replace(/%/g, ''))}`);
      setSearching(false);
      setHasFocus(false);
      if (searchInput.current) searchInput.current.blur();
    }
  };

  const handleClickOnOverlay = () => {
    setSearching(false);
    setHasFocus(false);
    setText('');
  };

  const search = (event) => {
    const text = event.target.value;
    dispatch(
      searchMultiIndex({
        indexes: [
          { name: config.algolia.seriesIndex, options: { hitsPerPage: 4 } },
          { name: config.algolia.authorsIndex, options: { hitsPerPage: 4 } },
          { name: config.algolia.articlesIndex, options: { hitsPerPage: 3 } },
          { name: config.algolia.albumsIndex, options: { hitsPerPage: 4 } },
          { name: config.algolia.storesIndex, options: { hitsPerPage: 3, filters: 'isPublic=1' } },
        ],
        text,
      }),
    );
    setSearching(!!text);
    setText(text);
  };

  const focus = () => {
    document.addEventListener('keyup', handleKeyboardEvent);
    setHasFocus(true);
    setSearching(!!text);
  };

  const blur = () => {
    document.removeEventListener('keyup', handleKeyboardEvent);
    setHasFocus(false);
  };

  const putFocusOnInput = () => {
    searchInput.current.focus();
  };

  return (
    <div className="d-flex flex-fill justify-content-center" ref={barRef}>
      <GlobalOverlay onClick={handleClickOnOverlay} showGlobalOverlay={!!searching} />
      <div
        className={`home-search-bar rounded-medium input-group bg-grey align-items-center ${
          searching ? 'over-global-overlay' : ''
        }`}
      >
        {!hasFocus && !searching && !text && (
          <div className="placeholder-text">
            <span> Rechercher un album, une série, un auteur...</span>
          </div>
        )}
        <input
          ref={searchInput}
          onFocus={focus}
          onBlur={blur}
          onChange={search}
          value={text}
          className="form-control transparent-container my-2 text-white"
          aria-label="Recherche"
        />
        {(!!props.forceExitButton || text) && (
          <div className="search-clear-icon">
            <WithClickHandler onClick={exitAndClear}>
              <Icon name="times" />
            </WithClickHandler>
          </div>
        )}
        {!!!props.forceExitButton && (
          <div className="input-group-append" onClick={putFocusOnInput}>
            <div className="bg-grey rounded-medium" id="basic-addon1">
              <button className="btn btn-transparent py-3 px-3">
                <Icon name="search" className="text-white" />
              </button>
            </div>
          </div>
        )}
        {searching && <SearchResults hits={hits} text={text.repeat(1)} searchBarRef={barRef} />}
      </div>
    </div>
  );
};

export default HomeSearchBar;
