import React from 'react';

import { Icon } from '@/components/Icon/Icon';

import bubbleUtils from 'bubble-utils';

import '../Author.scss';

const AuthorSocials = ({ author }) => {
  const shouldShow =
    author.websiteUrl ||
    author.twitterUsername ||
    author.instagramUsername ||
    author.twitterUsername ||
    author.facebookUrl;

  if (!shouldShow) {
    return null;
  }

  return (
    <div className="d-flex align-items-center justify-content-between justify-content-md-start flex-wrap author-social-text px-5 px-md-0">
      {!!author.websiteUrl && (
        <SocialRow url={author.websiteUrl} icon="globe" iconPrefix="fa-solid" text="Site Web" />
      )}
      {!!author.facebookUrl && (
        <SocialRow
          url={author.facebookUrl}
          iconPrefix="fa-brands"
          icon="facebook"
          text={author.facebookUrl.split('/')?.at(-1)}
          className="text-facebook"
        />
      )}
      {!!author.twitterUsername && bubbleUtils.validity.isValidUsername(author.twitterUsername) && (
        <SocialRow
          url={'https://www.twitter.com/' + author.twitterUsername.replace('@', '')}
          icon="twitter"
          iconPrefix="fa-brands"
          text={author.twitterUsername.replace('@', '')}
          className="text-twitter"
        />
      )}
      {!!author.instagramUsername &&
        bubbleUtils.validity.isValidUsername(author.instagramUsername) && (
          <SocialRow
            url={'https://www.instagram.com/' + author.instagramUsername.replace('@', '')}
            iconPrefix="fa-brands"
            icon="instagram"
            text={author.instagramUsername.replace('@', '')}
            className="text-instagram"
          />
        )}
    </div>
  );
};

const SocialRow = ({ url, text, iconPrefix, icon, className = '' }) => (
  <div className="d-flex align-items-center no-wrap pb-1 pe-md-3">
    <a
      className={`no-decoration${className && ` ${className}`}`}
      target="_blank"
      rel="noopener noreferrer"
      href={url}
    >
      <Icon size="md" prefix={iconPrefix || null} name={icon} />
      <span className="ps-2 ps-md-1">{text}</span>
    </a>
  </div>
);

export default AuthorSocials;
