import { liteClient } from 'algoliasearch/lite';
import config from 'bubble-config';

import { formatTextForSearch } from 'bubble-utils/src/string-utils';

const algoliaClient = liteClient(config.algolia.appID, config.algolia.apiKey);

export const multiIndexSearch = (indexes, text) => {
  if (!indexes) {
    indexes = [
      { name: config.algolia.albumsIndex, options: { hitsPerPage: 10 } },
      { name: config.algolia.seriesIndex, options: { hitsPerPage: 10 } },
      { name: config.algolia.authorsIndex, options: { hitsPerPage: 10 } },
      { name: config.algolia.publishersIndex, options: { hitsPerPage: 10 } },
      { name: config.algolia.storesIndex, options: { hitsPerPage: 10, filters: 'isPublic=1' } },
      { name: config.algolia.articlesIndex, options: { hitsPerPage: 10 } },
    ];
  }
  const queries = indexes.map((index) => {
    return {
      indexName: index.name,
      query: formatTextForSearch(text),
      attributesToHighlight: [],
      ...index.options,
    };
  });

  return algoliaClient.search(queries);
};

export const search = (indexName, text, options) => {
  return algoliaClient.search({
    requests: [
      { indexName, query: formatTextForSearch(text), attributesToHighlight: [], ...options },
    ],
  });
};

export const searchStores = (text, options) => {
  return algoliaClient.search({
    requests: [
      {
        indexName: config.algolia.storesIndex,
        query: formatTextForSearch(text),
        filters: 'isPublic=1', // only shows public booksellers, basically remove "bdfugue.com" and "Les Yeux dans les Bulles"
        attributesToHighlight: [],
        ...options,
      },
    ],
  });
};
