import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { createAlbumRevision } from 'bubble-reducers/src/reducers/catalogs';
import { searchSeries } from 'bubble-reducers/src/reducers/search';

import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import SerieSelectionCell from './components/SerieSelectionCell';

import './Modals.css';

const MoveAlbumToSerieModal = (props) => {
  const dispatch = useDispatch();
  const { albumObjectId, serieObjectId, allowGoBack } = props;

  const [searchSerieText, setSearchSerieText] = useState('');
  const [selectedSerieObjectId, setSelectedSerieObjectId] = useState(null);

  const album = useSelector((state) => state.albums.albums[albumObjectId]);
  const serie = useSelector((state) => state.series.series[serieObjectId]);
  const userObjectId = useSelector((state) => state.user.user.objectId);
  const hitsSeries = useSelector((state) => state.search.hitsSeries);

  const dismiss = () => {
    setSearchSerieText('');
    setSelectedSerieObjectId(null);
    props.callback();
  };

  const handleGoBack = () => {
    dismiss();
    props.handleGoBack();
  };

  const sendRevision = () => {
    if (userObjectId && selectedSerieObjectId && serieObjectId !== selectedSerieObjectId) {
      const revision = {
        userObjectId,
        oldValue: serieObjectId,
        newValue: selectedSerieObjectId,
        fieldRevised: 'serieObjectId',
      };
      dispatch(createAlbumRevision({ albumObjectId, revisions: [revision] }));
      dismiss();
      props.openThankYourModal();
    } else {
      alert("Aucun champ n'a l'air d'avoir été modifié, êtes vous bien sûr de vos modifications ?");
    }
  };

  const handleSelectSerieObjectId = (objectId) => {
    if (selectedSerieObjectId !== objectId) setSelectedSerieObjectId(objectId);
    else setSelectedSerieObjectId(null);
  };

  const renderSeriesSection = () => {
    const series = hitsSeries.data.slice(0, 20);
    return series.map((serie) => (
      <SerieSelectionCell
        key={serie.objectId}
        serie={serie}
        onClick={() => handleSelectSerieObjectId(serie.objectId)}
        selectedSerieObjectId={selectedSerieObjectId}
      />
    ));
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      {' '}
      {serie && album && (
        <>
          <div className="modal-header header-no-separator">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <Icon name="chevron-left" />
              </WithClickHandler>
            )}
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={dismiss}
            ></button>
          </div>
          <div className="modal-body mx-sm-3">
            <div className="row">
              <div className="col">
                <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
                  <span>Signalement</span>
                  <span className="fw-bold">
                    {serie.title ? serie.title + ' - ' : ''}
                    {album.tome && `Tome ${album.tome}`}
                  </span>
                </div>
                <div className="text-secondary d-flex justify-content-center">
                  Rechercher la série auquel appartient cet album
                </div>
              </div>
            </div>
            <div className="py-2">
              <LabeledTextInput
                white
                noRightLabel
                value={searchSerieText}
                placeholder="Rechercher la série"
                onChange={(e) => {
                  setSearchSerieText(e.target.value);
                  dispatch(searchSeries({ text: e.target.value }));
                }}
              />
            </div>
            {renderSeriesSection()}
            <div className="h-separator mt-2" />
            <div className="d-flex flex-row justify-content-between p-4 align-items-center">
              {allowGoBack && (
                <WithClickHandler onClick={handleGoBack}>
                  <div className="text-start bb-medium-text-size">
                    <u>Retour</u>
                  </div>
                </WithClickHandler>
              )}
              <div className="text-end">
                <button onClick={sendRevision} className="btn btn-success btn-lg text-white">
                  Soumettre
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default MoveAlbumToSerieModal;
