import { configureStore } from '@reduxjs/toolkit';

// import the reducers and listeners
import listeners from './listeners';
import reducers from './reducers';

import bubbleUtils from 'bubble-utils';

const createAppStore = (url = '/') => {
  // Do we have preloaded state available? Great, save it.
  const initialState = !bubbleUtils.env.isServer ? window.__PRELOADED_STATE__ : {};

  // Delete the state once we have it stored in a variable and update the location
  if (!bubbleUtils.env.isServer) {
    delete window.__PRELOADED_STATE__;
  }

  // automatically set up the Redux DevTools extension connection https://redux-toolkit.js.org/api/configureStore#devtools
  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      // https://redux-toolkit.js.org/api/getDefaultMiddleware#development
      getDefaultMiddleware({
        // immutableCheck: { warnAfter: 128 },
        // serializableCheck: { warnAfter: 128 },
        immutableCheck: false,
        serializableCheck: false,
      }).prepend(listeners.middleware),
  });

  return { store };
};

export default createAppStore;
