import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  loadArticle,
  loadArticleResources,
  loadRelatedArticles,
} from 'bubble-reducers/src/reducers/articles';
import { loadArticleComments } from 'bubble-reducers/src/reducers/comments';
import { loadUserLikes } from 'bubble-reducers/src/reducers/user';
import { getRelatedArticles, getResourcesForArticle } from 'bubble-reducers/src/selectors';

import { getSeoForArticle } from '@/services/seo-utils';

import GenericNewsSection from '@/scenes/NArt/components/GenericNewsSection/GenericNewsSection';
import NArtAlbumCard from '@/scenes/NArt/components/NArtAlbumCard/NArtAlbumCard';
import NArtAuthorCard from '@/scenes/NArt/components/NArtAuthorCard/NArtAuthorCard';
import NArtNewsletterOptIn from '@/scenes/NArt/components/NArtNewsletterOptIn/NArtNewsletterOptIn';

import ArticleCard from '@/components/ArticleCard/ArticleCard';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import Comments from '@/components/Comments/Comments';
import SerieCard from '@/components/SerieCard/SerieCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import ArticleContentHeader from './components/ArticleContentHeader';
import ArticleHeaderImage from './components/ArticleHeaderImage';
import ArticleTag from './components/ArticleTag';

import './Article.scss';

const Article = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permalink } = useParams();

  const articleObjectId =
    useSelector((state) => state.articles.articlesObjectIdsMapByPermalink[permalink]) || null;
  const user = useSelector((state) => state.user.user);
  const article = useSelector((state) => state.articles.articlesMapByObjectId[articleObjectId]);
  const articleContent = useSelector((state) => state.articles.articleContent || null);
  const resources = useSelector((state) => getResourcesForArticle(state, permalink));
  const relatedArticles = useSelector((state) => getRelatedArticles(state, permalink));

  useEffect(() => {
    if (permalink) {
      dispatch(loadArticle({ articleObjectId: permalink }));
      dispatch(loadArticleResources({ articleObjectId: permalink }));
      dispatch(loadArticleComments({ articleObjectId: permalink }));
      dispatch(loadRelatedArticles({ articleObjectId: permalink }));
      if (user?.objectId) {
        dispatch(loadUserLikes({ userObjectId: user.objectId }));
      }
    }
  }, [permalink]);

  //TODO:9eart I don't remember why this exists
  const handleClickInArticleContent = (event) => {
    const targetLink = event.target.closest('a');
    if (!targetLink) return;

    if (window.URL) {
      const newLocation = new URL(targetLink.href);
      const currentLocation = window.location;
      // use router only if the host is the same and the pathname are differents
      if (
        currentLocation.host === newLocation.host &&
        currentLocation.pathname !== newLocation.pathname
      ) {
        navigate(newLocation.pathname + newLocation.hash);
        event.preventDefault();
      }
    }
  };

  const albumsResources = resources.albums || [];
  const seriesResources = resources.series || [];
  const authorsResources = resources.authors || [];
  const seoSchema = article ? getSeoForArticle(article) : null;

  if (!!article?.objectId && !article?.wordpressId && !article?.isPublished) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      {!!articleObjectId && <BubbleHelmet seo={seoSchema} />}
      <article>
        <ArticleHeaderImage article={article} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 py-4 py-md-paper px-4 px-md-paper">
              <ArticleContentHeader article={article} />
              <div
                className="article-content"
                onClick={handleClickInArticleContent}
                dangerouslySetInnerHTML={{
                  __html: articleContent || '',
                }}
              />
              <div className="d-flex w-100 flex-wrap">
                {article?.tags &&
                  article.tags.map((tag) => (
                    <div key={`tag_^${tag}`} className="pe-2 pb-2">
                      <ArticleTag tag={tag} />
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-4 px-4 px-md-paper bb-background-light-grey pt-paper">
              {!!albumsResources.length && <div className="h2 fw-bold">Oeuvre(s)</div>}
              {albumsResources.slice(0, 4).map((album) => (
                <div className="pb-4" key={'resource-album-' + album.objectId}>
                  <NArtAlbumCard albumObjectId={album.objectId} />
                </div>
              ))}
              {(!!seriesResources.length || !!authorsResources.length) && (
                <>
                  <div className="h2 fw-bold pt-2">Série(s) et Auteur(s)</div>
                  {seriesResources.slice(0, 3).map((serie) => (
                    <div className="pb-4" key={'resource_serie_' + serie.objectId}>
                      <SerieCard serieObjectId={serie.objectId} />
                    </div>
                  ))}
                  {authorsResources.slice(0, 3).map((author, index) => (
                    <div className="pb-4" key={`resource-author-${author.objectId}-${index}`}>
                      <NArtAuthorCard authorObjectId={author.objectId} />
                    </div>
                  ))}
                </>
              )}
              <GenericNewsSection />
            </div>
          </div>
        </div>
      </article>

      <div className="nart-background-grey px-4 px-md-paper">
        <TitledListContainer
          noWrap
          title={<div className="h2 fw-bold">Publications similaires</div>}
          list={relatedArticles.slice(0, 3).map((article) => (
            <ArticleCard
              className="col-10 col-md-6 col-lg-4 pt-3"
              key={article?.objectId}
              article={article}
            />
          ))}
        />
      </div>

      <div className="p-4 p-md-paper bb-background-light-grey">
        {articleObjectId && <Comments articleObjectId={articleObjectId} />}
      </div>

      <NArtNewsletterOptIn />
    </>
  );
};

export default Article;
