import React from 'react';

import { Link } from 'react-router-dom';

import { getAssetImage } from '@/services/image-utils';

import { Icon } from '@/components/Icon/Icon';

import logo from '@/assets/logo/full_horizontal_black_font.svg';

export default function CheckoutHeader(props) {
  return (
    <div className="px-3 border-bottom">
      <nav className="navbar navbar-expand navbar-light py-4">
        <div className="d-flex flex-fill align-items-center">
          <ul className="navbar-nav d-flex align-items-center ">
            <li>
              <Link to={'/'}>
                <img className="bubble-logo py-1" alt="img" src={logo} />
              </Link>
            </li>
            <li className="ms-4">
              <Icon className="text-secondary bb-s-text-size" name="chevron-left" />{' '}
              <Link to={'/cart'}>Retour au panier</Link>
            </li>
          </ul>
          <div className="flex-fill" />
          <div className="d-none d-md-flex align-items-center">
            <img className="lock-logo" alt="lock" src={getAssetImage('icon_lock_green.svg')} />
            <div className="ms-3">Paiement 100% sécurisé</div>
          </div>
        </div>
      </nav>
    </div>
  );
}
