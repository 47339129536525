import { Link } from 'react-router-dom';

export default function Navbar9eArtNavItem({ text, selected, to }) {
  return (
    <div className="nav-item py-3">
      <Link className="no-decoration" to={to}>
        <div className={`px-4 mb-n1 transition ${selected ? `nart-text-highlight fw-bold` : ''}`}>
          {text}
        </div>
        {!!selected && (
          <div
            className={`paper-section-element-bar transition rounded-medium nart-bg-highlight`}
          />
        )}
      </Link>
    </div>
  );
}
