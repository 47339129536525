import React, { useState } from 'react';

const ArticleHeaderImage = ({ article }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const onImageLoaded = () => setImageLoaded(true);

  return (
    <div className={`${!imageLoaded ? 'article-loading' : ''}`}>
      {!!article?.imageUrl && (
        <img
          className="article-image"
          onLoad={onImageLoaded}
          src={article?.imageUrl}
          alt="Illustration de l'article"
        />
      )}
    </div>
  );
};

export default ArticleHeaderImage;
