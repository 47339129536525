import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from '@/components/Icon/Icon';

import './SearchBar.css';

const SearchBar = (props) => {
  const handleOnChange = (event, clearText = false) => {
    let newEvent = Object.assign({}, event);
    if (clearText) {
      newEvent.target.value = '';
    }
    props.onChange && props.onChange(event);
  };

  const handleOnChangeWithClear = (event) => handleOnChange(event, true);

  return (
    <div className="input-group">
      <span className="input-group-text transparent-icon-container" id="basic-addon1">
        {!Number(props.matches) && props.matches !== 0 && <Icon name="search" />}
        {props.matches && <span>{props.matches}</span>}
      </span>
      <input
        onChange={handleOnChange}
        value={props?.text || ''}
        type="text"
        className="form-control input-no-start-border input-no-end-border input-medium-height"
        placeholder={props.placeholder || 'Rechercher'}
        aria-label="Rechercher"
        aria-describedby="basic-addon1"
      />
      <button
        onClick={handleOnChangeWithClear}
        className="input-group-text transparent-icon-container input-no-start-border"
        id="basic-addon1"
      >
        <Icon name="times" />
      </button>
    </div>
  );
};

SearchBar.propTypes = {
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  matches: PropTypes.number,
  placeholder: PropTypes.string,
};

export default SearchBar;
