export default {
  // App only events
  APP_OPEN: 'App open',

  // general screens
  VIEW_MY_LIBRARY: 'View library',
  VIEW_MY_COMPLETION: 'View completion',
  VIEW_SEARCH: 'View search',
  VIEW_AGENDA: 'View agenda all',
  VIEW_CART: 'View cart',
  VIEW_PROFILE: 'View profile',
  VIEW_DISCOVER: 'View discovery',
  VIEW_SCAN: 'View scan',
  VIEW_STATS: 'View stats',
  VIEW_PROMOTIONS: 'View promotions',
  VIEW_SHIPPING_FEES: 'View shipping fees',
  VIEW_LOGIN: 'View login',
  VIEW_LOGIN_FORM: 'View login form',
  VIEW_SIGNUP_FORM: 'View signup form',
  VIEW_FORGOT_PASSWORD: 'View forgot password',
  VIEW_INFINITY_MODAL: 'View infinity modal',
  VIEW_INFINITY_MODAL_TRIAL: 'View infinity modal trial',
  VIEW_ARTICLES: 'View articles list',
  VIEW_TOP: 'View top',
  VIEW_REVIEWS: 'View community',
  VIEW_GIFT_CARD: 'View gift card',
  VIEW_ADD_ALBUM: 'View add album',
  VIEW_ADD_REVIEW: 'View add note',
  VIEW_EDIT_MEMO: 'View edit memo',
  VIEW_SEARCH_PLACES: 'View search places',
  VIEW_SEARCH_RELAYS: 'View search relay points',
  VIEW_SEARCH_BOOKSELLERS: 'View search booksellers',
  VIEW_SEARCH_SERIES: 'View search series',
  VIEW_SEARCH_AUTHORS: 'View search authors',
  VIEW_SEARCH_PUBLISHERS: 'View search publishers',
  VIEW_SEARCH_COLLECTIONS: 'View search collections',
  VIEW_SEARCH_TYPES: 'View search types',
  VIEW_SEARCH_GENRES: 'View search genres',
  VIEW_SEARCH_CATEGORIES: 'View search categories',
  VIEW_WISHLIST: 'View wishlist',
  VIEW_MY_AGENDA: 'View agenda',
  VIEW_FILTERS_IN_MY_LIBRARY: 'View filters library',
  VIEW_FILTERS_IN_COMPLETION: 'View filters completion',
  VIEW_FILTERS_IN_AGENDA_ALL: 'View filters agenda',
  VIEW_FILTER_GENRES: 'View filter genres',
  VIEW_FILTER_NOTES: 'View filter notes',
  VIEW_FILTER_PUBLISHERS: 'View filter publishers',
  VIEW_FILTER_AVAILABILITIES: 'View filter availabilities',
  VIEW_FILTER_PUBLICATION_DATES: 'View filter publication dates',
  VIEW_FILTER_SERIES: 'View filter series',
  VIEW_INTERSTITIAL_AD: 'View interstitial ad',
  VIEW_EDIT_TAGS: 'View edit tags',
  VIEW_CHAT: 'View chat',
  VIEW_ORDER_FINE_TUNING: 'View order fine tuning',
  VIEW_FIRST_LAUNCH_TUTORIAL: 'View first launch tutorial',
  VIEW_RESERVATION_HELP_OTHER_REQUEST: 'View reservation help other request',

  // revisions screens
  VIEW_SERIE_REVISION_MENU: 'View revision serie menu',
  VIEW_SERIE_REVISION: 'View revision serie form',
  VIEW_DUPLICATE_ALBUM_REVISION: 'View revision duplicate album',
  VIEW_ALBUM_REVISION_MENU: 'View revision album menu',
  VIEW_ALBUM_REVISION: 'View revision album form',
  VIEW_PRINT_REVISION: 'View revision print form',
  VIEW_MISPLACED_ALBUM_REVISION_MENU: 'View revision misplaced album menu',
  VIEW_MOVE_ALBUM_TO_SERIE_REVISION: 'View revision album other serie',
  VIEW_MOVE_PRINT_TO_ALBUM_REVISION: 'View revision print other album',

  // profile screens
  VIEW_ADDRESSES: 'View addresses list',
  VIEW_ADD_ADDRESS: 'View add address',
  VIEW_ADD_ADDRESS_MANUALLY: 'View add address manually',
  VIEW_COUNTRIES: 'View countries',
  VIEW_CARDS: 'View cards list',
  VIEW_ADD_CARD: 'View add card',
  VIEW_EDIT_CARD: 'View edit card',
  VIEW_MY_LOANS: 'View loans',
  VIEW_STATS_IN_PROFILE: 'View stats in profile',
  VIEW_ORDERS: 'View orders',
  VIEW_ORDER_FEEDBACK: 'View order feedback',
  VIEW_DELETE_MY_ACCOUNT: 'Delete my account',
  VIEW_MY_AVAILABILITY_ALERTS: 'View availability alerts',
  VIEW_MY_NEWSLETTER_OPTIONS: 'View my newsletter options',
  VIEW_REFERRAL: 'View referral',
  VIEW_REWARD_PROGRAM: 'View reward program',
  VIEW_MY_REWARD_PROGRAM: 'View my reward program',
  VIEW_EDIT_INFORMATIONS: 'View edit informations',
  VIEW_MY_PROMO_CODES: 'View my promo codes',
  VIEW_MY_FAVORITE_STORES: 'View favorite stores',
  VIEW_MY_REVIEWS: 'View my reviews',
  VIEW_LAST_ADDED_ALBUMS: 'View last added',
  VIEW_MY_REVISIONS: 'View my revisions',
  VIEW_MY_ADDITIONS: 'View my added albums',
  VIEW_MY_PRIVACY_SETTINGS: 'View my privacy settings',
  VIEW_FAQ: 'View faq',
  VIEW_PRIVATE_POLICY: 'View private policy',

  // checkout screens
  VIEW_CHECKOUT_USER_INFORMATION: 'View checkout user informations',
  VIEW_CHECKOUT_SHIPPING_METHOD: 'View checkout shipping method',
  VIEW_CHECKOUT_PAYMENT_METHOD: 'View checkout payment method',
  VIEW_CHECKOUT_VALIDATION: 'View checkout validation',
  VIEW_ADD_EMAIL: 'View add email',

  // individual screens
  VIEW_SERIE: 'View serie',
  VIEW_ALBUM: 'View album',
  VIEW_ARTICLE: 'View article',
  VIEW_AUTHOR: 'View author',
  VIEW_ORDER: 'View order',

  // Web only events
  SIGNUP_GUEST: 'Signup guest',

  // App + Web events
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  SIGNUP: 'Signup',

  ADD_TO_WISHLIST: 'Add album to wishlist',
  REMOVE_FROM_WISHLIST: 'Remove album from wishlist',
  ALBUMS_IN_WISHLIST: '_Albums in wishlist',

  ADD_TO_CART: 'Cart: add article',
  REMOVE_FROM_CART: 'Cart: remove article',
  ITEMS_IN_CART: '_Articles in cart',

  ORDER_PAID: 'Order: paid',
  TRANSACTIONS_TOTAL_AMOUNT: '_Transactions total amount',
  ORDER_RESERVED: 'Order: reserved',
  RESERVATIONS_TOTAL_AMOUNT: '_Reservations total amount',
};
