import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { compressNumber } from 'bubble-utils/src/number-utils';

import { Icon } from '@/components/Icon/Icon';

import AuthorSubscribeBadge from './AuthorSubscribeBadge';

import '../Author.scss';

export default function AuthorSubscriberSection({ authorObjectId }) {
  const authorSubscribersCount = useSelector(
    (state) => state.authors.authors[authorObjectId]?.subscriberCount || 0,
  );

  const subscriberCount = useMemo(
    () => compressNumber(authorSubscribersCount),
    [authorSubscribersCount],
  );

  return (
    <div className="bg-white rounded d-flex flex-fill align-items-center justify-content-between author-subscribe-button px-3">
      <div className="text-success d-flex align-items-center">
        <Icon className="me-2 mt-n1" name="user-check" />
        {subscriberCount}
        <span className="d-flex d-md-none d-xl-flex">
          &nbsp;abonné{authorSubscribersCount > 1 && 's'}
        </span>
      </div>
      <AuthorSubscribeBadge authorObjectId={authorObjectId} />
    </div>
  );
}
