import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';

import { loadHighlightedResourcesApi } from '../services/api';

import { getClientToken } from '../selectors';
import { storeAlbums } from './albums';
import { storeArticles } from './articles';
import { storeAuthors } from './authors';
import { storePrints } from './prints';
import { storeSeries } from './series';
import { storeStores } from './stores';

export const LOAD_HIGHLIGHTED_RESOURCES = 'highlightedResources/loadHighlightedResources';

const initialState = {
  highlightedResourcesIdMap: { articles: [], series: [], albums: [], authors: [], stores: [] },
  loading: {},
  errors: {},
};

export const loadHighlightedResources = createAsyncThunk(
  LOAD_HIGHLIGHTED_RESOURCES,
  async (params, { getState, dispatch }) => {
    const clientToken = getClientToken(getState());
    const highlightedResources = await loadHighlightedResourcesApi(clientToken);

    const { albums, prints } = extractAlbumAndPrints(highlightedResources.albums);

    dispatch(storeSeries({ series: highlightedResources.series }));
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));
    dispatch(storeArticles({ articles: highlightedResources.articles }));
    dispatch(storeStores({ stores: highlightedResources.stores }));
    dispatch(storeAuthors({ authors: highlightedResources.authors }));

    const highlightedResourcesIdMap = {};
    Object.keys(highlightedResources).forEach((key) => {
      highlightedResourcesIdMap[key] = highlightedResources[key].map((object) => object.objectId);
    });

    return { highlightedResourcesIdMap };
  },
);

const highlightedResourcesSlice = createSlice({
  name: 'highlightedResources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadHighlightedResources
      .addCase(loadHighlightedResources.pending, (state, action) => {
        state.loading[LOAD_HIGHLIGHTED_RESOURCES] = true;
        state.errors[LOAD_HIGHLIGHTED_RESOURCES] = null;
      })
      .addCase(loadHighlightedResources.fulfilled, (state, action) => {
        state.highlightedResourcesIdMap = action.payload.highlightedResourcesIdMap;
        state.loading[LOAD_HIGHLIGHTED_RESOURCES] = false;
        state.errors[LOAD_HIGHLIGHTED_RESOURCES] = null;
      })
      .addCase(loadHighlightedResources.rejected, (state, action) => {
        state.loading[LOAD_HIGHLIGHTED_RESOURCES] = false;
        state.errors[LOAD_HIGHLIGHTED_RESOURCES] = action.error;
      });
  },
});

export default highlightedResourcesSlice.reducer;
