import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { isUserInfinity } from 'bubble-utils/src/infinity-utils';

export const useIsUserlogged = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user.user);

  return useCallback(
    (canLogAsGuest = false, redirectTo = null, noRedirect = false) => {
      if (!!user?.objectId) {
        return true;
      } else {
        if (!noRedirect) {
          navigate('/login', { state: { canLogAsGuest, to: redirectTo || location?.pathname } });
        }
        return false;
      }
    },
    [user, navigate],
  );
};

export const useIsUserInfinity = () => {
  return useCallback((user) => {
    if (!!user?.objectId && isUserInfinity(user)) {
      return true;
    } else {
      return false;
    }
  });
};
