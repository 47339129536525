import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { CSSTransition } from 'react-transition-group';

import './GlobalOverlay.css';

const GlobalOverlay = (props) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={props.showGlobalOverlay || false}
      unmountOnExit
      timeout={100}
      classNames="global-overlay"
    >
      <div ref={nodeRef} onClick={props.onClick ? props.onClick : null} className="global-overlay">
        {props.children}
      </div>
    </CSSTransition>
  );
};

GlobalOverlay.propTypes = {
  showGlobalOverlay: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default GlobalOverlay;
