import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setClickedHeaderMenu, setShowHeaderMenu } from '@/engine/reducers/website-interface';

import { Icon } from '@/components/Icon/Icon';

import './HeaderDropdownButton.css';

const typeMap = {
  bd: { title: 'BD' },
  comics: { title: 'Comics' },
  mangas: { title: 'Mangas' },
  jeunesse: { title: 'Jeunesse' },
  tops: {
    title: (
      <>
        Tops<span className="d-none d-xl-inline-block ps-1">& sélections</span>
      </>
    ),
  },
};

const HeaderDropdownButton = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasLeft = useRef(false);
  const hasHovered = useRef(false);

  const type = props.type;

  const toggleClickedMenu = () => {
    hasLeft.current = false;
    hasHovered.current = true;
    dispatch(setClickedHeaderMenu({ clickedHeaderMenu: type }));
    setTimeout(() => {
      if (hasHovered.current && !hasLeft.current) {
        dispatch(setShowHeaderMenu({ showHeaderMenu: true }));
      }
      hasLeft.current = false;
      hasHovered.current = false;
    }, 150);
  };

  const handleOnMouseLeave = () => {
    hasLeft.current = true;
    dispatch(setShowHeaderMenu({ showHeaderMenu: false }));
  };

  const handleClick = () => {
    dispatch(setShowHeaderMenu({ showHeaderMenu: false }));
    if (type) {
      navigate(type === 'tops' ? '/tops' : `/list?category=${type}`);
    }
  };

  const typeObject = typeMap[type || 'bd'];
  return (
    <div className="dropdown d-flex align-items-center">
      <button
        onMouseEnter={toggleClickedMenu}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleClick}
        className="d-flex button-no-style px-3"
        type="button"
      >
        {typeObject.title}
        <span className="bb-xs-text-size position-relative ms-2 navbar-dropdown-chevron">
          <Icon name="chevron-down" />
        </span>
      </button>
    </div>
  );
};

HeaderDropdownButton.propTypes = {
  type: PropTypes.string.isRequired,
};

export default HeaderDropdownButton;
