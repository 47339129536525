import React from 'react';

const PrivateProfileNotice = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="text-center my-6">L'utilisateur a rendu cette page privée.</div>
    </div>
  );
};

export default PrivateProfileNotice;
