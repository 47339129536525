import React, { useRef, useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { randomIntFromRange } from 'bubble-utils/src/number-utils';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import blankBannerJpg from '@/assets/placeholders/blank-banner.jpg';
import blankBannerWebp from '@/assets/placeholders/blank-banner.webp';

import HighlightBanner from './HighlightBanner';

import './HighlightBanner.css';

const HighlightBanners = () => {
  const bannersMap = useSelector((state) => state.banners.banners || {});
  const [loaded, setLoaded] = useState(false);
  const nodeRef = useRef(null);

  const banners = Object.values(bannersMap);
  const startingBanner = randomIntFromRange(0, banners?.length);

  const display = () => setLoaded(true);

  const renderSlider = () => (
    <CSSTransition
      nodeRef={nodeRef}
      in={banners.length > 0 && loaded}
      unmountOnExit
      timeout={10000}
      classNames="banner-anim"
    >
      <Carousel defaultActiveIndex={startingBanner || null}>
        {banners.map((banner, index) => (
          <Carousel.Item key={banner.objectId + index}>
            <HighlightBanner
              imageUrl={banner.imageUrl}
              imageUrlMobile={banner.imageUrlMobile}
              alt={banner.imageAlt}
              link={banner.link}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </CSSTransition>
  );

  return (
    <>
      {/* Loading display */}
      <div className="d-flex justify-content-center align-items-center">
        {!loaded && (
          <WebpWrapper
            webp={blankBannerWebp}
            default={blankBannerJpg}
            className="w-100 placeholder-container"
            alt="placeholder"
          />
        )}

        <div className="d-flex flex-fill h-100 position-absolute align-items-center justify-content-center">
          <div className="spinner-border position-relative text-secondary" role="status" />
        </div>
        {renderSlider()}
      </div>

      {/* Preload image so slider doesnt flicker */}
      {!loaded && !!banners.length && (
        <img
          alt="preloader"
          className="d-none"
          src={banners[startingBanner].imageUrl}
          onLoad={display}
        />
      )}
    </>
  );
};

export default HighlightBanners;
