import React from 'react';

import { Link } from 'react-router-dom';

const Header = ({ selected = 'series' }) => {
  return (
    <div className="bb-background-dark-grey">
      <div className="container">
        <h1 className="text-white py-5 bb-xl-text-size">Explorer notre catalogue</h1>
      </div>
      <div className="container border-secondary d-flex flex-row justify-content-start p-0">
        <Link
          to="/series/nouveautes"
          className={`text-${
            selected === 'series' ? 'black' : 'white'
          } no-decoration hover-decoration`}
        >
          <div
            className={`py-2 px-5 ms-n1 ${
              selected === 'series' ? 'bb-background-light-grey rounded-top' : ''
            }`}
          >
            Séries
          </div>
        </Link>
        <Link
          to="/auteurs/top"
          className={`text-${
            selected === 'authors' ? 'black' : 'white'
          } no-decoration hover-decoration`}
        >
          <div
            className={`py-2 px-5 ${
              selected === 'authors' ? 'bb-background-light-grey rounded-top' : ''
            }`}
          >
            Auteurs
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Header;
