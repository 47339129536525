import React from 'react';

import './LinkButton.css';

function Spinner() {
  return <span className="spinner-border spinner-border-sm" role="status"></span>;
}

function LinkButton(props) {
  const { busy, action, children } = props;
  const displayTextClass = !busy ? 'visible' : 'invisible';
  return (
    <div className="linkButton Comment-LinkButton" onClick={action}>
      <span className={`text-primary ${displayTextClass}`}>{children}</span>

      {busy ? (
        <div className="Comment-LinkButton-busy">
          <Spinner />
        </div>
      ) : null}
    </div>
  );
}

export default LinkButton;
