import React from 'react';

export const Icon = (props) => {
  const classNames = [
    props.className,
    props.prefix ? props.prefix : 'fas',
    'fa-' + props.name,
    props.size ? 'fa-' + props.size : null,
  ]
    .filter((row) => row)
    .join(' ');
  return <i style={props.style} className={classNames}></i>;
};
