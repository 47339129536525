import React from 'react';

import { useSelector } from 'react-redux';

import { LOAD_ARTICLE_COMMENTS } from 'bubble-reducers/src/reducers/comments';

import CommentItemizer from './components/CommentItemizer';
import CommentsLoading from './components/CommentsLoading';
import WriteComment from './components/WriteComment/WriteComment';

const Comments = (props) => {
  const comments = useSelector((state) => state.comments.comments);
  const loading = useSelector((state) => state.comments.loading[LOAD_ARTICLE_COMMENTS]);
  const user = useSelector((state) => state.user.user);

  const numComments = Object.keys(comments).length;
  const noComments = !numComments;
  const isAdmin = user && user.roles && user.roles.includes('admin');

  return (
    <div className="col-12 px-0 mx-0">
      <div id="comments" />
      <div className="h3 fw-bold">Commentaires {numComments ? `(${numComments})` : ''}</div>
      {loading ? (
        <CommentsLoading />
      ) : (
        <>
          <WriteComment noComments={noComments} articleObjectId={props.articleObjectId} />
          <CommentItemizer isAdmin={isAdmin} comments={comments} />
        </>
      )}
    </div>
  );
};

export default Comments;
