import React from 'react';

import { Link } from 'react-router-dom';

import '../Article.scss';

export default function ArticleTag({ tag }) {
  return (
    <div className="d-flex flex-fill align-items-center">
      <Link
        to={`/9emeart/tag/${tag}`}
        className="d-flex align-items-center no-decoration article-tag rounded text-center px-2 fw-bold"
      >
        {tag}
      </Link>
    </div>
  );
}
