import React, { useCallback, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { setShowHeaderMenu } from '@/engine/reducers/website-interface';

import MenuDropdown from './components/MenuDropdown';
import TopMenuDropdown from './components/TopMenuDropdown';

import './DropdownContainer.css';

const DropdownContainer = () => {
  const dispatch = useDispatch();
  const clickedHeaderMenu = useSelector((state) => state.websiteInterface.clickedHeaderMenu);
  const showHeaderMenu = useSelector((state) => state.websiteInterface.showHeaderMenu);

  const nodeRef = useRef(null);

  const showMenu = useCallback(
    () => dispatch(setShowHeaderMenu({ showHeaderMenu: true })),
    [dispatch],
  );
  const hideMenu = useCallback(
    () => dispatch(setShowHeaderMenu({ showHeaderMenu: false })),
    [dispatch],
  );

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={showHeaderMenu || false}
      unmountOnExit
      timeout={150}
      classNames="fade"
    >
      <div
        ref={nodeRef}
        className="container px-0 me-3 bg-white bb-shadow-hover dropdown-container"
        onMouseEnter={showMenu}
        onMouseLeave={hideMenu}
      >
        <hr className="my-0 mx-0" />
        <div className="row p-4">
          {clickedHeaderMenu === 'tops' ? (
            <TopMenuDropdown clickedHeaderMenu={clickedHeaderMenu} hideMenu={hideMenu} />
          ) : (
            <MenuDropdown clickedHeaderMenu={clickedHeaderMenu} hideMenu={hideMenu} />
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default DropdownContainer;
