import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import Cover from '@/components/Cover/Cover';

/**
 * Renders a highlight album card
 */
const HighlightAlbumCard = (props) => {
  const album = props.album || {};
  const serie = props.serie || {};
  const prints = props.prints || [];

  let images = album.images;
  const defaultPrint = prints.find((print) => print.objectId === album.defaultSellingPrintObjectId);
  if (defaultPrint) {
    images = defaultPrint.images;
  }

  return (
    <Link className="no-decoration" to={`/${album.permalink}/album/${album.objectId}`}>
      <div className="rounded bb-shadow-hover d-flex align-items-center bb-background-light-grey">
        <div className="me-3">
          <Cover height={110} imageUrl={images ? images.front.smallFramed : album.imageUrl} />
        </div>
        <div className="overflow-hidden py-1">
          <div className="fw-bold mb-1">{serie.title || album.serie.title}</div>
          <div className="bb-medium-text-size bb-text-gray-dark text-truncate">{album.title}</div>
          <div className="font-secondary text-truncate">
            {album.tome ? `Tome ${album.tome}` : ''}
          </div>
          <div className="text-primary text-truncate">Découvrir le tome</div>
        </div>
      </div>
    </Link>
  );
};

HighlightAlbumCard.propTypes = {
  serie: PropTypes.shape({
    objectId: PropTypes.string,
    title: PropTypes.string,
    isTerminated: PropTypes.bool,
    note: PropTypes.number,
    imageUrl: PropTypes.string,
  }),
  album: PropTypes.object,
  prints: PropTypes.array,
};

export default HighlightAlbumCard;
