import PropTypes from 'prop-types';
import React from 'react';

import { getAssetImage } from '@/services/image-utils';

import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './SerieModeSwitcher.css';

export const MODES = {
  GRID: 0,
  LIST: 1,
};

const SerieModeSwitcher = (props) => {
  const handleClickGrid = () => props.callback(MODES.GRID);
  const handleClickList = () => props.callback(MODES.LIST);

  return (
    <div className="d-flex border rounded serie-mode-switcher">
      <WithClickHandler onClick={handleClickGrid}>
        <img
          className="px-1"
          src={getAssetImage(`icon_grid_${props.mode === MODES.GRID ? 'on' : 'off'}.svg`)}
          alt="list"
        />
      </WithClickHandler>
      <div className="v-separator mx-2 serie-mode-switcher-separator" />
      <WithClickHandler onClick={handleClickList}>
        <img
          src={getAssetImage(`icon_list_${props.mode === MODES.LIST ? 'on' : 'off'}.svg`)}
          alt="list"
        />
      </WithClickHandler>
    </div>
  );
};

SerieModeSwitcher.propTypes = {
  mode: PropTypes.number,
  callback: PropTypes.func,
};
export default SerieModeSwitcher;
