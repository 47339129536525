import React from 'react';

import './ButtonWithIcon.css';

const ButtonWithIcon = (props) => {
  let newProps = Object.assign({}, props);
  newProps.className = `${
    props.className || ''
  } d-flex flex-column align-items-center btn justify-content-center button-with-icon`;
  return (
    <button {...newProps}>
      <img className="mb-2" src={props.icon} alt="icon" />
      <div className="mt-1">{props.children}</div>
    </button>
  );
};

export default ButtonWithIcon;
