import { searchStores } from '../reducers/search';
import {
  checkStocksForEans,
  setAvailabilitySearchFlag,
  setFavoriteSearchFlag,
} from '../reducers/stores';
import { setReservationsStoresToShow } from '../reducers/stores';
import { getHitsStores, getStoresReducer, getUserFavoriteStoresIds } from '../selectors';

export const addStoresListeners = (startListening) => {
  startListening({
    actionCreator: searchStores.fulfilled,
    effect: async (action, { getState, dispatch }) => {
      await processStoresToShowFlow(getState, dispatch);
    },
  });
  startListening({
    actionCreator: checkStocksForEans.fulfilled,
    effect: async (action, { getState, dispatch }) => {
      await processStoresToShowFlow(getState, dispatch);
    },
  });
  startListening({
    actionCreator: setAvailabilitySearchFlag,
    effect: async (action, { getState, dispatch }) => {
      await processStoresToShowFlow(getState, dispatch);
    },
  });
  startListening({
    actionCreator: setFavoriteSearchFlag,
    effect: async (action, { getState, dispatch }) => {
      await processStoresToShowFlow(getState, dispatch);
    },
  });
};

const processStoresToShowFlow = async (getState, dispatch) => {
  //TODO: add a top level boolean that dictates if we should execute this function or not
  const favoriteStoresIds = getUserFavoriteStoresIds(getState());
  const storesReducer = getStoresReducer(getState());

  const allStoresAsMap = storesReducer.stores;
  const allStores = Object.values(storesReducer.stores);
  const hitsStores = getHitsStores(getState());
  const favoriteStores = (favoriteStoresIds || []).map((id) => allStoresAsMap[id]).filter(Boolean);
  const isFavoriteEnabled = storesReducer.favoriteSearchFlag;
  const isAvailableOnlyEnabled = storesReducer.availabilitySearchFlag;
  const stocks = storesReducer.itemsAvailabilitiesPerStores;

  // build list of stores to display
  let selectedStoresToDisplay = [];
  // case 1: favorite filter is active
  if (isFavoriteEnabled) {
    selectedStoresToDisplay = favoriteStores;
    if (hitsStores.length) {
      selectedStoresToDisplay = selectedStoresToDisplay.filter(
        (storeToDisplay) => !!hitsStores.find((hit) => hit.objectId === storeToDisplay.objectId),
      );
    }
  }
  // case 2: availabilites filter is active
  //    => get all stores, and filter based on availabilities after ( => case 2 following)
  if (isAvailableOnlyEnabled) {
    selectedStoresToDisplay = allStores;
    if (hitsStores.length) {
      selectedStoresToDisplay = selectedStoresToDisplay.filter(
        (storeToDisplay) => !!hitsStores.find((hit) => hit.objectId === storeToDisplay.objectId),
      );
    }
  }
  // case 3: no filter is active but with an ongoing search
  if (hitsStores.length && !isFavoriteEnabled && !isAvailableOnlyEnabled) {
    selectedStoresToDisplay = hitsStores.slice();
  }

  // add isFavorite info
  if (selectedStoresToDisplay.length) {
    selectedStoresToDisplay.map((selectedStore) => {
      return Object.assign({}, selectedStore, {
        isFavorite: !!favoriteStores.find(
          (favoriteStore) => favoriteStore.objectId === selectedStore.objectId,
        ),
      });
    });
  }

  let storesWithStocks = [];
  if (selectedStoresToDisplay.length) {
    storesWithStocks = selectedStoresToDisplay.map((selectedStore) => {
      const itemsAvailabilitiesForStore =
        stocks.find(
          (storeItemsAvailabilities) =>
            storeItemsAvailabilities.objectId === selectedStore.objectId,
        ) || {};
      return Object.assign({}, selectedStore, {
        isClosed: itemsAvailabilitiesForStore.isClosed,
        stocks: {
          availableItems: itemsAvailabilitiesForStore.availableItems || [],
          orderableItems: itemsAvailabilitiesForStore.orderableItems || [],
        },
      });
    });
  }

  // case 2 following, remove stores with no availabilities
  if (isAvailableOnlyEnabled) {
    storesWithStocks = storesWithStocks.filter(
      (store) => store.stocks.availableItems.length || store.stocks.orderableItems.length,
    );
  }

  // sort stores with the biggest available items first
  storesWithStocks = storesWithStocks.sort((a, b) => {
    const aAvailableItems = a.stocks.availableItems.length;
    const aOrderableItems = a.stocks.orderableItems.length;
    const bAvailableItems = b.stocks.availableItems.length;
    const bOrderableItems = b.stocks.orderableItems.length;

    const n = bAvailableItems - aAvailableItems;
    if (n !== 0) {
      return n;
    }
    return bAvailableItems + bOrderableItems - (aAvailableItems + aOrderableItems);
  });

  dispatch(setReservationsStoresToShow({ stores: storesWithStocks }));
};
