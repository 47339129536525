import React from 'react';

import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import RevisionMenuCell from './components/RevisionMenuCell';

import './Modals.css';

const MisplacedAlbumRevisionMenuModal = (props) => {
  const { albumObjectId, serieObjectId, printObjectId, allowGoBack } = props;

  const serie = useSelector((state) => state.series.series[serieObjectId]);
  const album = useSelector((state) => state.albums.albums[albumObjectId]);
  const print = useSelector((state) => state.prints.prints[printObjectId]);

  const dismiss = () => {
    props.callback();
  };

  const handleMoveAlbumToSerie = () => {
    dismiss();
    props.openMoveAlbumToSerieModal();
  };

  const handleMovePrintToAlbum = () => {
    dismiss();
    props.openMovePrintToAlbumModal();
  };

  const handleGoBack = () => {
    dismiss();
    props.handleGoBack();
  };

  const handleDeleteDuplicateAlbum = () => {
    dismiss();
    window &&
      window.Intercom &&
      window.Intercom(
        'showNewMessage',
        `Série: ${serie.title || ''} ${serie.type || ''}\nAlbum: ${album.title || ''} ${
          album.tome || ''
        } ${print.ean || ''}\nErreur: Cet album est en double, il faudrait le supprimer.`,
      );
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      {(serie && album && (
        <>
          <div className="modal-header header-no-separator">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <Icon name="chevron-left" />
              </WithClickHandler>
            )}
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={dismiss}
            ></button>
          </div>
          <div className="modal-body mx-sm-3">
            <div className="row pb-2">
              <div className="col py-1">
                <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
                  <span>Signalement</span>
                  <span className="fw-bold">
                    {serie.title ? serie.title + ' - ' : ''}
                    {album.title}
                  </span>
                </div>
                <div className="text-secondary text-center">
                  Quelles modifications souhaitez-vous suggérer pour cet album ?
                </div>
              </div>
            </div>
            <RevisionMenuCell
              onClick={handleMoveAlbumToSerie}
              mainText="Cet album appartient à une autre série"
              subText=""
              imageSource="icon_info_serie.svg"
            />
            <RevisionMenuCell
              onClick={handleMovePrintToAlbum}
              mainText="Cet album est une édition d'un autre album"
              subText=""
              imageSource="icon_edition_grey.svg"
            />
            <RevisionMenuCell
              onClick={handleDeleteDuplicateAlbum}
              mainText="Cet album est en double, il faut le supprimer"
              subText=""
              imageSource="icon_double_album.svg"
            />
            <div className="h-separator mt-2" />
            <div className="d-flex flex-row justify-content-between p-4">
              <WithClickHandler>
                <div className="text-start">
                  <u>Signaler une autre erreur</u>
                </div>
              </WithClickHandler>
              <WithClickHandler onClick={dismiss}>
                <div className="text-end">
                  <u>Annuler</u>
                </div>
              </WithClickHandler>
            </div>
          </div>
        </>
      )) ||
        null}
    </Modal>
  );
};

export default MisplacedAlbumRevisionMenuModal;
