import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { RESET_PASSWORD, resetPassword } from 'bubble-reducers/src/reducers/auth';

import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';

import bubbleUtils from 'bubble-utils';

import './Modals.css';

const ForgotPasswordModal = (props) => {
  const dispatch = useDispatch();

  const resetPasswordError = useSelector((state) => state.auth.errors[RESET_PASSWORD]);
  const loading = useSelector((state) => state.auth.loading[RESET_PASSWORD]);

  const [value, setValue] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const dismiss = () => {
    props.callback();
  };

  const checkEmail = (email = '') => bubbleUtils.validity.isValidEmail(email);

  const submitReinitialisation = () => {
    if (!checkEmail(value)) {
      return alert('Votre email semble invalide');
    }
    dispatch(resetPassword({ email: value }));
    setHasSubmitted(true);
  };

  const showError = () => {
    if (resetPasswordError && resetPasswordError.message) {
      return <div className="text-danger mt-2">{resetPasswordError.message}</div>;
    }
  };

  const renderLoader = () => (
    <div className="d-flex flex-column align-items-center justify-content-center py-5">
      <div className="spinner-grow text-bubble-color" role="status" />
      <div className="text-bubble-color mt-2">Nous vous envoyons un email de réinitialisation</div>
      <div className="my-5" />
    </div>
  );

  const renderForm = () => (
    <>
      <div className="bb-l-text-size mb-3">
        Vous avez oublié votre mot de passe ? Pas de panique, nous allons vous envoyer un email de
        réinitialisation
      </div>
      <LabeledTextInput
        onEnterPressed={submitReinitialisation}
        white
        mandatory
        title="Adresse e-mail"
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        type="email"
        aria-describedby="emailHelp"
      />

      <button className="btn btn-primary" onClick={submitReinitialisation}>
        Valider
      </button>
      {!!resetPasswordError && showError()}
      {hasSubmitted && !loading && !resetPasswordError && renderDone()}
    </>
  );

  const renderDone = () => (
    <div className="text-success bb-medium-large-text-size fw-bold mt-2">
      Un email de réinitialisation vous a été envoyé a l'adresse {value}
    </div>
  );

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="md"
      centered
      contentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        {loading && renderLoader()}
        {!loading && renderForm()}
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
