import { activateInfinityCode } from 'bubble-reducers/src/reducers/user';

export const addWebsiteInterfaceListeners = (startListening) => {
  startListening({
    actionCreator: activateInfinityCode.fulfilled,
    effect: (action) => {
      const codeResult = action.payload.codeResult;
      alert(`${codeResult.title} - ${codeResult.message}`);
    },
  });
};
