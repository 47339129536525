import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadGenresApi } from '../services/api';
import initialGenres from '../services/genres.json';

import { getClientToken } from '../selectors';

const LOAD_GENRES = 'genres/loadGenres';

const initialState = {
  genres: initialGenres.slice(),
  loading: {},
  errors: {},
};

export const loadGenres = createAsyncThunk(LOAD_GENRES, async (params, { getState }) => {
  const clientToken = getClientToken(getState());
  const genres = await loadGenresApi(clientToken);
  return { genres };
});

const genresSlice = createSlice({
  name: 'genres',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadGenres
      .addCase(loadGenres.pending, (state, action) => {
        state.genres = initialState.genres;
        state.loading[LOAD_GENRES] = true;
        state.errors[LOAD_GENRES] = null;
      })
      .addCase(loadGenres.fulfilled, (state, action) => {
        state.genres = action.payload.genres;
        state.loading[LOAD_GENRES] = false;
        state.errors[LOAD_GENRES] = null;
      })
      .addCase(loadGenres.rejected, (state, action) => {
        state.loading[LOAD_GENRES] = false;
        state.errors[LOAD_GENRES] = action.error;
      });
  },
});

export default genresSlice.reducer;
