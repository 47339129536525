import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { uploadUserImage } from 'bubble-reducers/src/reducers/user';
import { getUserProfile } from 'bubble-reducers/src/selectors';

import { isValidImageExtension } from 'bubble-utils/src/validity-utils';

import { getAssetImage } from '@/services/image-utils';

import { Icon } from '@/components/Icon/Icon';
import ShareZone from '@/components/ShareZone/ShareZone';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

import UserHeaderStats from './components/UserHeaderStats';
import UserProfileNavbar from './components/UserProfileNavbar';

import { GENERAL } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

const UserProfileHeader = (props) => {
  const dispatch = useDispatch();

  const userFromStore = useSelector((state) => state.user.user);
  const stats = useSelector((state) => getUserProfile(state, props.userObjectId).stats) || {};
  const otherUsers = useSelector((state) => state.user.otherUsers);

  const [imageHovered, setImageHovered] = useState(false);
  const uploader = useRef();

  const user = props.user || {};
  const isInfinity = bubbleUtils.infinity.isUserInfinity(user);

  const setUploaderRef = useCallback((ref) => (uploader.current = ref), [uploader]);

  const getUploaderImage = ({ target }) => {
    const formData = new FormData();
    formData.append('img', target.files[0]);
    const img = formData.get('img');
    if (!isValidImageExtension(img.name, ['jpg', 'jpeg', 'png'])) {
      return alert('Votre image doit etre au format JPG / JPEG / PNG');
    }

    dispatch(uploadUserImage({ userObjectId: userFromStore.objectId, formData }));
  };
  const isPublicProfile =
    userFromStore?.objectId === props.userObjectId ||
    (otherUsers?.[props.userObjectId]?.profilePrivacyFlags & GENERAL.FLAG_PRIVACY_MY_BDS) ===
      GENERAL.FLAG_PRIVACY_MY_BDS;

  const handleEnter = useCallback(() => setImageHovered(true), [setImageHovered]);
  const handleLeave = useCallback(() => setImageHovered(false), [setImageHovered]);

  const onUploaderClick = useCallback(() => uploader.current?.click(), []);

  return (
    <div className="bb-background-dark-grey">
      <div className="container">
        <div className="d-flex justify-content-end align-items-center py-2">
          {!props.isGuest && (
            <Link
              className="bb-s-text-size text-white no-decoration pe-3"
              to="/my-account/my-settings#visibility"
            >
              Paramètres de confidentialité
            </Link>
          )}
          <ShareZone white title={`Partager ${props.isGuest ? 'son' : 'mon'} profil`} />
        </div>
        <div className="px-sm-3">
          <div className="row pb-4 mt-n3 text-white">
            <div className="col-md-7">
              <div className="d-lg-flex align-items-center">
                <div className="me-3">
                  <WithClickHandler
                    className="d-flex align-items-center justify-content-center"
                    onMouseEnter={handleEnter}
                    onMouseLeave={handleLeave}
                    disabled={props.isGuest}
                    noDisabledStyle
                    onClick={onUploaderClick}
                  >
                    <img
                      className={`${user.imageUrl ? '' : 'bg-white'} user-image rounded-circle `}
                      alt="user"
                      src={user.imageUrl ? user.imageUrl : placeholderProfileJpg}
                    />
                    {!props.isGuest && imageHovered && (
                      <Icon name="camera" className="position-absolute bb-xxl-text-size" />
                    )}
                  </WithClickHandler>
                  <input
                    className="d-none"
                    ref={setUploaderRef}
                    type="file"
                    id="file"
                    onChange={getUploaderImage}
                  />
                </div>
                <div className="user-profile-name-container">
                  <div className="d-flex align-items-center user-profile-name">
                    <h1 className={`bb-xl-text-size ${isInfinity ? 'text-infinity' : ''}`}>
                      {user.username ||
                        (user.email || 'Utilisateur Bubble').split('@')[0].split('.')[0]}
                    </h1>
                    {isInfinity && (
                      <img
                        className="ms-3 mt-n1"
                        alt="infinity"
                        src={getAssetImage('icon_badge_infinity.svg')}
                      />
                    )}
                  </div>
                  {!isPublicProfile && (
                    <div className="bb-medium-text-size mt-n2">
                      Membre depuis le {bubbleUtils.date.formatDateWithFormat(user.creationDate)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isPublicProfile && (
              <div className="col-md-5 d-flex align-items-center justify-content-end">
                <UserHeaderStats stats={stats} isInfinity={isInfinity} />
              </div>
            )}
          </div>
          <hr className="m-0 mx-n3" />
          <div className="row">
            <UserProfileNavbar isGuest={props.isGuest} userObjectId={user.objectId} />
          </div>
        </div>
      </div>
    </div>
  );
};

UserProfileHeader.propTypes = {
  isGuest: PropTypes.bool,
};

export default UserProfileHeader;
