import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import cartIcon from '@/assets/header/icon_cart.svg';

import '../../Header.scss';

const CartSection = () => {
  const numberOfItems = useSelector((state) => state.cart.cart?.numberOfItems);

  return (
    <Link className="nav-link d-flex no-decoration text-white" to={'/cart'}>
      <img alt="shopping-bag" src={cartIcon} className="header-icon" />
      <div className="cart-item-amount-container">
        <div className="cart-item-amount">{numberOfItems || 0}</div>
      </div>
    </Link>
  );
};

export default CartSection;
