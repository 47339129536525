import React from 'react';

import { Link } from 'react-router-dom';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import concept1 from '@/assets/home/achats.png';
import concept1Webp from '@/assets/home/achats.webp';
import concept2 from '@/assets/home/collection.png';
import concept2Webp from '@/assets/home/collection.webp';
import concept3 from '@/assets/home/communaute.png';
import concept3Webp from '@/assets/home/communaute.webp';
import concept4 from '@/assets/home/equipe.png';
import concept4Webp from '@/assets/home/equipe.webp';

import './BubbleConcept.scss';

const conceptMap = {
  1: { default: concept1, webp: concept1Webp },
  2: { default: concept2, webp: concept2Webp },
  3: { default: concept3, webp: concept3Webp },
  4: { default: concept4, webp: concept4Webp },
};

const BubbleConcept = (props) => {
  return (
    <div className="row p-xl-6 p-5 bubble-concept-background text-white">
      <div className="col-lg-5">
        <div className="d-flex h-100 flex-column justify-content-center align-items-center align-items-xl-start text-center text-lg-start">
          <h1 className="fw-bold display-4">TOUT, pour les fans de BD Comics et Mangas</h1>
          <div>
            <Link
              to="/qui-sommes-nous"
              className="mt-3 btn btn-outline-white px-5 bb-medium-text-size"
            >
              Découvrir nos engagements
            </Link>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block col-7 ps-lg-4 ps-xxl-6">
        <div className="d-flex">
          <div className="w-50">
            <div className="pb-3">
              <Concept number={1} />
            </div>
            <Concept number={3} />
          </div>
          <div className="w-50 pt-5">
            <div className="pb-3">
              <Concept number={2} />
            </div>
            <Concept number={4} />
          </div>
        </div>
      </div>
      <div className="d-block d-lg-none col-12">
        <div className="d-flex overflow-auto pt-5 mx-n5">
          <div className="pe-4 ps-5">
            <Concept number={1} />
          </div>
          <div className="pe-4">
            <Concept number={3} />
          </div>
          <div className="pe-4">
            <Concept number={2} />
          </div>
          <div className="pe-5">
            <Concept number={4} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Concept = ({ number }) => {
  return (
    <WebpWrapper
      className="bubble-concept-box rounded"
      alt="concept"
      default={conceptMap[number].default}
      webp={conceptMap[number].webp}
    />
  );
};

const areEqual = (prevProps, nextProps) => true;

export default React.memo(BubbleConcept, areEqual);
