import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  CREATE_AVAILABILITY_ALERT,
  createAvailabilityAlert,
  deleteAvailabilityAlert,
} from 'bubble-reducers/src/reducers/availability-alerts';
import { addToCart } from 'bubble-reducers/src/reducers/cart';
import { getGuestId, getUserId } from 'bubble-reducers/src/selectors';

import usePrevious from '@/services/hooks/usePrevious';
import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import Toaster from '@/components/DUPLICATE/Toaster/Toaster';
import AddedToCartModal from '@/components/Modals/AddedToCartModal';
import GuestAvailabilityAlertModal from '@/components/Modals/GuestAvailabilityAlertModal';

import bubbleUtils from 'bubble-utils';

import './AddToCartZone.scss';

const AddToCartZone = (props) => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const [hovered, _setHovered] = useState(false);
  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);

  const userObjectId = useSelector(getUserId);
  const guestUserId = useSelector(getGuestId);
  const print = props.print;
  const availabilityAlert = useSelector(
    (state) => state.availabilityAlerts.availabilityAlertsMapByPrintObjectId[print?.objectId],
  );
  const availabilities = props.availabilities || bubbleUtils.album.getAvailabilities(print);
  const sellingInfo = print.sellingInfo || {};
  const hasOnlineAvailability = !!sellingInfo?.online?.numberOfSellers;
  const isLoadingCreation = useSelector(
    (state) =>
      state.availabilityAlerts.loading[CREATE_AVAILABILITY_ALERT] &&
      state.availabilityAlerts.loading[CREATE_AVAILABILITY_ALERT] === print?.objectId,
  );
  const wasLoadingCreation = usePrevious(isLoadingCreation);

  const [showGuestAvailabilityAlertModal, setShowGuestAvailabilityAlertModal] = useState(false);

  if (!isLoadingCreation && wasLoadingCreation) {
    // Toaster.addToast(
    //   'Bravo ! Alerte activée 🔔',
    //   'On vous préviendra par email quand cet article sera commandable.\nAssurez vous que votre email soit bien renseigné dans mon profil>mes informations',
    // );
  }

  availabilities.message =
    sellingInfo?.price !== null
      ? availabilities.message === 'Non disponible (via Bubble)'
        ? 'Non disponible'
        : availabilities.message
      : 'Non disponible';

  const handleAvailabilityAlert = useCallback(() => {
    if (!!availabilityAlert && !userObjectId && !!guestUserId) {
      return dispatch(
        deleteAvailabilityAlert({
          userObjectId: guestUserId,
          availabilityAlertObjectId: availabilityAlert.objectId,
        }),
      );
    }
    if (isUserlogged(false, null, true)) {
      if (!!availabilityAlert) {
        dispatch(
          deleteAvailabilityAlert({
            userObjectId,
            availabilityAlertObjectId: availabilityAlert.objectId,
          }),
        );
      } else {
        dispatch(createAvailabilityAlert({ userObjectId, printObjectId: print?.objectId }));
      }
    } else {
      handleShowGuestAvailabilityAlertModal();
    }
  }, [isUserlogged, dispatch, guestUserId, userObjectId, availabilityAlert, print?.objectId]);

  const addAlbumToCart = (e) => {
    e.stopPropagation();
    if (props.isOwned) {
      if (
        window.confirm(
          "Cet album fait déjà parti de votre collection, Êtes vous sûr de vouloir l'ajouter à votre panier ?",
        )
      ) {
        dispatch(addToCart({ prints: print }));
        setShowAddedToCartModal(true);
      }
    } else {
      dispatch(addToCart({ prints: print }));
      setShowAddedToCartModal(true);
    }
  };

  const handleShowGuestAvailabilityAlertModal = () => {
    setShowGuestAvailabilityAlertModal(true);
  };

  const handleMouseEnter = useCallback(() => _setHovered(true), [_setHovered]);
  const handleMouseLeave = useCallback(() => _setHovered(false), [_setHovered]);

  return props.customCartAvailabilities ? (
    <button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={addAlbumToCart}
      disabled={!props.customCartAvailabilities.active}
      className={`btn btn-add-to-cart${props.customCartAvailabilities.colorAddon} my-2`}
    >
      <span className="d-none d-md-block">
        {props.isInCart
          ? 'Déjà au panier'
          : !(!props.customCartAvailabilities.active || props.isInCart) && hovered
            ? 'Ajouter au panier'
            : props.customCartAvailabilities.text}
      </span>
      <span className="d-block d-md-none">
        {props.isInCart ? 'Déjà au panier' : 'Ajouter au panier'}
      </span>
    </button>
  ) : (
    <>
      {showAddedToCartModal && (
        <AddedToCartModal
          show={showAddedToCartModal}
          itemIds={[print].map((print) => print.objectId)}
          callback={() => setShowAddedToCartModal(false)}
        />
      )}
      {(hasOnlineAvailability || props.isInCart) && (
        <button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={addAlbumToCart}
          disabled={!availabilities.atLeastOneChannel || sellingInfo.price === null}
          className={`btn btn-add-to-cart mt-2 ${props.className || ''}`}
        >
          <span className="d-none d-md-block">
            {props.isInCart
              ? 'Déjà au panier'
              : !(!availabilities.atLeastOneChannel || props.isInCart) && hovered
                ? 'Ajouter au panier'
                : availabilities.message}
          </span>
          <span className="d-block d-md-none">
            {props.isInCart ? 'Déjà au panier' : 'Ajouter au panier'}
          </span>
        </button>
      )}
      {!hasOnlineAvailability && !props.isInCart && (
        <>
          {showGuestAvailabilityAlertModal && (
            <GuestAvailabilityAlertModal
              show={showGuestAvailabilityAlertModal}
              printObjectId={print?.objectId}
              callback={() => setShowGuestAvailabilityAlertModal(false)}
            />
          )}
          <button
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleAvailabilityAlert}
            className={`btn btn-add-to-cart mt-2 ${props.className || ''}`}
          >
            <span className="d-none d-md-block">
              {!!availabilityAlert ? 'Alerte activée' : 'Alertez-moi par email'}
            </span>
            <span className="d-block d-md-none">
              {props.isInCart ? 'availabilityAlert' : 'Alerte email'}
            </span>
          </button>
        </>
      )}
    </>
  );
};

AddToCartZone.propTypes = {
  print: PropTypes.object,
  availabilities: PropTypes.any, // object
};

export default AddToCartZone;
