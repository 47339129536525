import React from 'react';

import LinkUtils from '@/services/link-utils';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import iconAppStore from '@/assets/app-store-button.png';
import iconAppStoreWebp from '@/assets/app-store-button.webp';
import iconPlayStore from '@/assets/google-play-button.png';
import iconPlayStoreWebp from '@/assets/google-play-button.webp';

import './BubbleHomeMobileAppAd.scss';

export default function BubbleHomeMobileAppAd() {
  return (
    <div className="row home-mobile-app-background py-6 px-3 px-lg-5 d-flex d-lg-block align-items-center align-items-lg-block">
      <div className="row">
        <div className="col-lg-4 offset-lg-4">
          <div className="h2 fw-bold text-center">
            Retrouvez votre collection Bubble sur votre smartphone grâce à notre app mobile !
          </div>
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center pt-4">
            <div className="pe-lg-3 pb-4 pb-lg-0">
              <a href={LinkUtils.getLinkDownloadFromFooterIos()}>
                <WebpWrapper default={iconAppStore} webp={iconAppStoreWebp} />
              </a>
            </div>
            <div className="ps-lg-3">
              <a href={LinkUtils.getLinkDownloadFromFooterAndroid()}>
                <WebpWrapper default={iconPlayStore} webp={iconPlayStoreWebp} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
