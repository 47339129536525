import React from 'react';
import { useMemo } from 'react';

import { randomIntFromRange } from 'bubble-utils/src/number-utils';

import './Tag.scss';

export default function TagPlaceholder() {
  const randomWidth = useMemo(() => ({ width: randomIntFromRange(50, 150) }), []);

  return (
    <div
      className={`tag-placeholder text-nowrap tag-container p-1 px-2 rounded d-flex align-items-center`}
      style={randomWidth}
    >
      <div className="bg-primary w-100 h-50 rounded tag-placeholder-text" />
    </div>
  );
}
