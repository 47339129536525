import { createListenerMiddleware } from '@reduxjs/toolkit';

import { addProfilesListeners } from 'bubble-reducers/src/listeners/profiles';
import { addStoresListeners } from 'bubble-reducers/src/listeners/search';

import { addWebsiteAuthListeners } from '../engine/listeners/website-auth';
import { addWebsiteEventsListeners } from '../engine/listeners/website-events';
import { addWebsiteInterfaceListeners } from '../engine/listeners/website-interface';
import { addWebsiteWishlistListeners } from '../engine/listeners/website-wishlist';

const listeners = createListenerMiddleware();

// from bubble-reducers
addProfilesListeners(listeners.startListening);
addStoresListeners(listeners.startListening);
// from bubble-website
addWebsiteWishlistListeners(listeners.startListening);
addWebsiteAuthListeners(listeners.startListening);
addWebsiteEventsListeners(listeners.startListening);
addWebsiteInterfaceListeners(listeners.startListening);

export default listeners;
