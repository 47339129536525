import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from 'react-redux';

import {
  isAlbumOwned,
  makeGetPreviousAlbumsFromSerie,
  makeIsNoPreviousAlbumsOwned,
} from 'bubble-reducers/src/selectors';

import { isBinaryFlagEnabled } from 'bubble-utils/src/code-utils';

import {
  useAddPrintsToLibrary,
  useRemovePrintsFromLibrary,
} from '@/services/hooks/useLibraryUtils';

import { Icon } from '@/components/Icon/Icon';
import Tooltip from '@/components/Tooltip/Tooltip';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import iconCollectionGreen from '@/assets/icon_library_green.svg';

import { GENERAL } from 'bubble-constants';

import './AddToCollectionZone.scss';

const AddToCollectionZone = (props) => {
  const addPrintsToLibrary = useAddPrintsToLibrary();
  const removePrintsFromLibrary = useRemovePrintsFromLibrary();

  const isNoPreviousAlbumsOwned = makeIsNoPreviousAlbumsOwned();
  const getPreviousAlbumsFromSerie = makeGetPreviousAlbumsFromSerie();

  const album = props.album || {};
  const serie = props.serie || {};

  const albumObjectId = album.objectId;
  const serieObjectId = serie.objectId;

  const user = useSelector((state) => state.user.user);
  const albumInLibrary = useSelector((state) => state.profiles.myAlbums[albumObjectId]);
  const isOwned = useSelector((state) => isAlbumOwned(state, albumObjectId));
  const previousAlbumsFromSerie =
    useSelector((state) => getPreviousAlbumsFromSerie(state, { albumObjectId, serieObjectId })) ||
    [];
  const noPreviousAlbumsOwned =
    useSelector((state) => isNoPreviousAlbumsOwned(state, { albumObjectId, serieObjectId })) || [];

  const handleCollectionClick = () => {
    const albumDefaultPrint = {
      album: { objectId: albumObjectId },
      objectId: album.defaultPrintObjectId,
    };
    const previousAlbumDefaultPrints = [album, ...previousAlbumsFromSerie].map((album) => ({
      album: { objectId: album?.objectId },
      objectId: album?.defaultPrintObjectId,
    }));
    const ownedAlbumPrints = albumInLibrary
      ? Object.entries(albumInLibrary?.userPrints || {})
          .map(([printObjectId, flag]) => {
            if (isBinaryFlagEnabled(flag, GENERAL.USER_PRINT.OWNED)) {
              return {
                album: { objectId: albumObjectId },
                objectId: printObjectId,
              };
            } else return null;
          })
          .filter((row) => row)
      : [];
    if (!isOwned) {
      if (
        noPreviousAlbumsOwned &&
        window.confirm('Voulez-vous ajouter tous les tomes précédents ?')
      ) {
        addPrintsToLibrary(user, previousAlbumDefaultPrints);
      } else {
        addPrintsToLibrary(user, [albumDefaultPrint]);
      }
    } else {
      removePrintsFromLibrary(
        user,
        ownedAlbumPrints.length ? ownedAlbumPrints : [albumDefaultPrint],
        true,
      );
    }
  };

  return (
    <div
      className={`add-to-collection-zone rounded display-on-parent-hover${
        isOwned ? ' force-show' : ''
      }`}
    >
      <Tooltip tip={isOwned ? 'Retirer de ma collection' : 'Ajouter à ma collection'}>
        <WithClickHandler consumeEvent onClick={handleCollectionClick}>
          {!isOwned ? (
            <img className="p-1" alt="add" src={iconCollectionGreen} />
          ) : (
            <Icon name="check" className="text-success bb-l-text-size pt-1" />
          )}
        </WithClickHandler>
      </Tooltip>
    </div>
  );
};

AddToCollectionZone.propTypes = {
  album: PropTypes.object,
  serie: PropTypes.object,
};

export default AddToCollectionZone;
