import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import StarLine from '@/components/StarLine/StarLine';

import '../SearchResults.css';

const SearchProductCard = (props) => {
  const product = props.product || {};
  return (
    <Link
      to={`/${product.permalink}/product/gift-card/${product.objectId}`}
      className="d-flex no-decoration align-items-center"
    >
      <img
        className="rounded border small-rectange-size me-2"
        alt="cheque cadeau"
        src={((product.images || {}).front || {}).smallFramed}
      />
      <div>
        <div>{'Les chèques cadeaux'}</div>
        <div>
          <StarLine note={5} />
        </div>
      </div>
    </Link>
  );
};

SearchProductCard.propTypes = {
  product: PropTypes.shape({
    objectId: PropTypes.string,
    permalink: PropTypes.string,
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    price: PropTypes.string,
  }),
};

export default SearchProductCard;
