import PropTypes from 'prop-types';
import React from 'react';

import { getAssetImage } from '@/services/image-utils';

import Tooltip from '@/components/Tooltip/Tooltip';

const UserHeaderStats = (props) => {
  const color = props.isInfinity ? 'yellow' : 'white';
  const stats = props.stats;
  return (
    <div className="d-flex flex-fill">
      <div className="flex-fill w-25 header-stats-box border border-secondary border-end-0 rounded-start d-flex align-items-center justify-content-center">
        <Tooltip
          className="d-flex flex-column align-items-center justify-content-center"
          tip="Séries avec au moins 1 album possédé/lu"
        >
          <img
            className="d-none d-lg-block"
            alt="series"
            src={getAssetImage(`icon_series_${color}.svg`)}
          />
          <div className="pt-lg-3 bb-l-text-size">{stats.serieCount || 0}</div>
          <div>séries</div>
        </Tooltip>
      </div>
      <div className="flex-fill w-25 header-stats-box border border-secondary d-flex align-items-center justify-content-center">
        <Tooltip
          tip="Albums possédés ou lus"
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <img
            className="d-none d-lg-block"
            alt="albums"
            src={getAssetImage(`icon_album_${color}.svg`)}
          />
          <div className="pt-lg-3 bb-l-text-size">{stats.albumsOwnedOrRead || 0}</div>
          <div>albums</div>
        </Tooltip>
      </div>
      <div className="flex-fill w-25 header-stats-box border border-secondary border-start-0 d-flex flex-column align-items-center justify-content-center">
        <img
          className="d-none d-lg-block"
          alt="editions originales"
          src={getAssetImage(`icon_edition_originale_${color}.svg`)}
        />
        <div className="pt-lg-3 bb-l-text-size">{stats.albumsOe || 0}</div>
        <div>éd. orig.</div>
      </div>
      <div className="flex-fill w-25 header-stats-box border border-secondary border-start-0 rounded-end d-flex flex-column align-items-center justify-content-center">
        <img
          className="d-none d-lg-block"
          alt="dedicaces"
          src={getAssetImage(`icon_dedicace_${color}.svg`)}
        />
        <div className="pt-lg-3 bb-l-text-size">{stats.albumsSigned || 0}</div>
        <div>dédicaces</div>
      </div>
    </div>
  );
};

UserHeaderStats.propTypes = {
  isInfinity: PropTypes.bool,
  stats: PropTypes.object,
};
export default UserHeaderStats;
