import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeIsAlbumInCart } from 'bubble-reducers/src/selectors';

import { formatCurrency } from 'bubble-utils/src/currency-utils';

import AddToCartZone from '@/components/AlbumCard/components/AddToCartZone';
import Cover from '@/components/Cover/Cover';
import StarLine from '@/components/StarLine/StarLine';

import albumPlaceholder from '@/assets/placeholders/placeholder-item.jpg';

export default function NArtAlbumCard({ albumObjectId, className }) {
  const album = useSelector((state) => state.albums.albums[albumObjectId]) || {};
  const print =
    useSelector((state) => state.prints.prints[album?.defaultSellingPrintObjectId]) || {};
  const isAlbumInCart = makeIsAlbumInCart();
  const isInCart = !!useSelector((state) => isAlbumInCart(state, print?.objectId)) || false;
  const price = (print.sellingInfo || { online: {} } || {}).price || album.price;

  return (
    <Link
      to={`/${album.permalink}/album/${album.objectId}`}
      className={`bg-white no-decoration d-block nart-shadow p-3 rounded w-100 ${className}`}
    >
      <div className="d-flex align-items-center h-100 w-100">
        <div className="pe-3">
          <Cover
            height={120}
            rounded
            shadow
            imageUrl={print?.images?.front?.smallFramed || albumPlaceholder}
          />
        </div>
        <div className="d-flex flex-fill h-100 flex-column justify-content-between py-2">
          <div>
            <div className="h6 m-0 fw-bold">{album.title || album.serie?.title}</div>
            <div className="text-black bb-s-text-size">{album.serie?.title}</div>
            <div className="text-secondary py-1">{album.authors?.[0]?.displayName}</div>
          </div>
          <div>
            <div className="d-flex w-100 justify-content-between">
              <div className="text-secondary">
                <StarLine short note={album.note} />
              </div>
              {!!price && <div className="fw-bold">{formatCurrency(price)}</div>}
            </div>
            <div>
              <AddToCartZone className="h-50" print={print} album={album} isInCart={isInCart} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
