import { addEventApi } from 'bubble-reducers/src/services/api';

import { trackers } from '../init/events';

import { EVENT } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

let eventList = [];
let isRequestIdleCallbackScheduled = false;

export const addEvent = (name, value, properties) => {
  eventList.push({ name, value, properties, date: new Date().toISOString() });

  if (isRequestIdleCallbackScheduled) {
    return;
  }
  isRequestIdleCallbackScheduled = true;
  if (window.requestIdleCallback) {
    window.requestIdleCallback(processPendingEvents);
  } else {
    processPendingEvents();
  }
};

const processPendingEvents = () => {
  isRequestIdleCallbackScheduled = false;

  sendEventsToBubble(eventList);
  while (eventList.length > 0) {
    processEvent(eventList.shift());
  }
};

const processEvent = (event) => {
  trackers.forEach((tracker) => logEventWithTracker(tracker, event));
};

const logEventWithTracker = (tracker, event) => {
  const eventStructure = EVENTS_MAPPING[event.name]?.[tracker.type];
  if (eventStructure) {
    const properties = bubbleUtils.object.createObjectFromPaths(
      eventStructure.paths,
      event.properties,
    );
    tracker.fct(eventStructure.name, event.value, properties, eventStructure.type);
  }
};

const sendEventsToBubble = (events) => {
  try {
    addEventApi(
      null,
      events.map((event) => {
        const eventStructure = EVENTS_MAPPING[event.name]?.bubble;
        const bubbleProperties = bubbleUtils.object.createObjectFromPaths(
          eventStructure.paths,
          event.properties,
        );
        return Object.assign({}, event, event.properties, {
          name: eventStructure.name,
          properties: bubbleProperties,
        });
      }),
    );
  } catch (error) {
    console.log('sendEventsToBubble:', error);
  }
};

const EVENTS_MAPPING = {
  [EVENT.LOGIN]: {
    bubble: {
      name: EVENT.LOGIN,
      paths: [{ key: 'type', value: 'method' }],
    },
    facebookPixel: {
      name: 'Login',
      paths: [{ key: 'method', value: 'method' }],
    },
    googleAnalytics: {
      name: 'login',
      type: 'standard',
      paths: [
        { key: 'userId', value: 'userObjectId' },
        { key: 'method', value: 'method' },
      ],
    },
  },
  [EVENT.LOGOUT]: {
    bubble: {
      name: EVENT.LOGOUT,
    },
    facebookPixel: {
      name: 'Logout',
    },
    googleAnalytics: {
      name: 'logout',
      paths: [{ key: 'userId', value: null, type: 'static' }],
    },
  },
  [EVENT.SIGNUP_GUEST]: {
    bubble: {
      name: EVENT.SIGNUP_GUEST,
      paths: [{ key: 'type', value: 'method' }],
    },
    facebookPixel: {
      name: 'Signup guest',
      paths: [{ key: 'method', value: 'method' }],
    },
    googleAnalytics: {
      name: 'sign_up_guest',
      paths: [
        { key: 'userId', value: 'userObjectId' },
        { key: 'method', value: 'method' },
      ],
    },
  },
  [EVENT.SIGNUP]: {
    bubble: {
      name: EVENT.SIGNUP,
      paths: [{ key: 'type', value: 'method' }],
    },
    facebookPixel: {
      name: 'CompleteRegistration',
      type: 'standard',
      paths: [
        { key: 'currency', value: 'EUR', type: 'static' },
        { key: 'value', value: 1, type: 'static' },
        { key: 'status', value: true, type: 'static' },
      ],
    },
    googleAnalytics: {
      name: 'sign_up',
      type: 'standard',
      paths: [
        { key: 'userId', value: 'userObjectId' },
        { key: 'method', value: 'method' },
      ],
    },
  },
  [EVENT.ORDER_PAID]: {
    bubble: {
      name: EVENT.ORDER_PAID,
      paths: [
        { key: 'albumsNumber', value: 'albumsNumber' },
        { key: 'cartAmount', value: 'totalAmount' },
      ],
    },
    facebookPixel: {
      name: 'Purchase',
      type: 'standard',
      paths: [
        { key: 'currency', value: 'currencyCode' },
        { key: 'value', value: 'totalAmount' },
        { key: 'num_items', value: 'numberOfItems' },
        { key: 'contents', value: 'facebookItems' },
        { key: 'content_ids', value: 'productSkus' },
        { key: 'content_type', value: 'product', type: 'static' },
      ],
    },
    googleAnalytics: {
      name: 'purchase',
      type: 'standard',
      paths: [
        { key: 'userId', value: 'userObjectId' },
        { key: 'ecommerce.currencyCode', value: 'currencyCode' },
        { key: 'ecommerce.purchase.actionField.id', value: 'orderObjectId' },
        { key: 'ecommerce.purchase.actionField.revenue', value: 'totalAmount' },
        { key: 'ecommerce.purchase.actionField.tax', value: 'taxAmount' },
        { key: 'ecommerce.purchase.actionField.shipping', value: 'shippingAmount' },
        { key: 'ecommerce.purchase.products', value: 'googleAnalyticsItems' },
      ],
    },
  },
  [EVENT.TRANSACTIONS_TOTAL_AMOUNT]: {
    bubble: {
      name: EVENT.TRANSACTIONS_TOTAL_AMOUNT,
      paths: [
        { key: 'albumsNumber', value: 'albumsNumber' },
        { key: 'cartAmount', value: 'totalAmount' },
      ],
    },
  },
  [EVENT.ORDER_RESERVED]: {
    bubble: {
      name: EVENT.ORDER_RESERVED,
      paths: [
        { key: 'albumsNumber', value: 'albumsNumber' },
        { key: 'cartAmount', value: 'totalAmount' },
      ],
    },
  },
  [EVENT.RESERVATIONS_TOTAL_AMOUNT]: {
    bubble: {
      name: EVENT.RESERVATIONS_TOTAL_AMOUNT,
      paths: [
        { key: 'albumsNumber', value: 'albumsNumber' },
        { key: 'cartAmount', value: 'totalAmount' },
      ],
    },
  },

  [EVENT.ADD_TO_WISHLIST]: {
    bubble: {
      name: EVENT.ADD_TO_WISHLIST,
      paths: [
        { key: 'isBatch', value: 'isBatch' },
        { key: 'albumObjectIds', value: 'albumObjectIds' },
      ],
    },
    facebookPixel: {
      name: 'AddToWishlist',
      type: 'standard',
      paths: [
        { key: 'currency', value: 'currencyCode' },
        { key: 'value', value: 'totalAmount' },
        { key: 'contents', value: 'facebookItems' },
        { key: 'content_ids', value: 'productSkus' },
        { key: 'content_type', value: 'product', type: 'static' },
      ],
    },
    googleAnalytics: {
      name: 'add_to_wishlist',
      paths: [
        { key: 'userId', value: 'userObjectId' },
        { key: 'items', value: 'googleAnalyticsItems' },
      ],
    },
  },
  [EVENT.REMOVE_FROM_WISHLIST]: {
    bubble: {
      name: EVENT.REMOVE_FROM_WISHLIST,
      paths: [
        { key: 'isBatch', value: 'isBatch' },
        { key: 'albumObjectIds', value: 'albumObjectIds' },
      ],
    },
    facebookPixel: {
      name: 'remove_from_wishlist',
      paths: [
        { key: 'currency', value: 'currencyCode' },
        { key: 'value', value: 'totalAmount' },
        { key: 'contents', value: 'facebookItems' },
        { key: 'content_ids', value: 'productSkus' },
        { key: 'content_type', value: 'product', type: 'static' },
      ],
    },
    googleAnalytics: {
      name: 'remove_from_wishlist',
      paths: [
        { key: 'userId', value: 'userObjectId' },
        { key: 'items', value: 'googleAnalyticsItems' },
      ],
    },
  },
  [EVENT.ALBUMS_IN_WISHLIST]: {
    bubble: {
      name: EVENT.ALBUMS_IN_WISHLIST,
      paths: [
        { key: 'albumObjectId', value: 'albumObjectId' },
        { key: 'isBatch', value: 'isBatch' },
        { key: 'albumObjectIds', value: 'albumObjectIds' },
      ],
    },
  },

  [EVENT.ADD_TO_CART]: {
    bubble: {
      name: EVENT.ADD_TO_CART,
      paths: [
        { key: 'type', value: 'type' },
        { key: 'skus', value: 'productSkus' },
        { key: 'isBatch', value: 'isBatch' },
        { key: 'albumObjectIds', value: 'albumObjectIds' },
        { key: 'printObjectIds', value: 'printObjectIds' },
      ],
    },
    facebookPixel: {
      name: 'AddToCart',
      type: 'standard',
      paths: [
        { key: 'currency', value: 'currencyCode' },
        { key: 'value', value: 'totalAmount' },
        { key: 'contents', value: 'facebookItems' },
        { key: 'content_ids', value: 'productSkus' },
        { key: 'content_type', value: 'product', type: 'static' },
      ],
    },
    googleAnalytics: {
      name: 'add_to_cart',
      type: 'standard',
      paths: [
        { key: 'userId', value: 'userObjectId' },
        { key: 'items', value: 'googleAnalyticsItems' },
      ],
    },
  },
  [EVENT.REMOVE_FROM_CART]: {
    bubble: {
      name: EVENT.REMOVE_FROM_CART,
      paths: [
        { key: 'type', value: 'type' },
        { key: 'skus', value: 'productSkus' },
        { key: 'isBatch', value: 'isBatch' },
        { key: 'albumObjectIds', value: 'albumObjectIds' },
        { key: 'printObjectIds', value: 'printObjectIds' },
      ],
    },
    facebookPixel: {
      name: 'remove_from_cart',
      paths: [
        { key: 'currency', value: 'currencyCode' },
        { key: 'value', value: 'totalAmount' },
        { key: 'contents', value: 'facebookItems' },
        { key: 'content_ids', value: 'productSkus' },
        { key: 'content_type', value: 'product', type: 'static' },
      ],
    },
    googleAnalytics: {
      name: 'remove_from_cart',
      type: 'standard',
      paths: [
        { key: 'userId', value: 'userObjectId' },
        { key: 'items', value: 'googleAnalyticsItems' },
      ],
    },
  },
  [EVENT.ITEMS_IN_CART]: {
    bubble: {
      name: EVENT.ITEMS_IN_CART,
      paths: [
        { key: 'type', value: 'type' },
        { key: 'skus', value: 'productSkus' },
        { key: 'isBatch', value: 'isBatch' },
        { key: 'albumObjectIds', value: 'albumObjectIds' },
        { key: 'printObjectIds', value: 'printObjectIds' },
      ],
    },
  },
};
