import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';

import { loadSerieApi, loadSeriesApi, loadSeriesAtAlphanumericIndexApi } from '../services/api';
import menuSeries from '../services/menu-series.json';

import { getClientToken } from '../selectors';
import { storeAlbums } from './albums';
import { storePrints } from './prints';

export const LOAD_SERIE = 'series/loadSerie';
export const LOAD_SERIES = 'series/loadSeries';
const LOAD_SERIES_ALPHANUMERIC_INDEX = 'series/loadSeriesAtAlphanumericIndex';

const initialState = {
  mapOfSerieIdsClassic: {},
  mapOfSerieIdsTopRated: {},
  mapOfSerieIdsCategoriesAndGenres: {},
  mapOfSeriesIdsTeamFavorite: {},
  menuSeriesObjectIds: {
    bd: ['4eKFLiHZY6', 'aVEuh2T41m'],
    comics: ['YexpqI1CMZ', 'i2GNIZI1CV'],
    mangas: ['qyoCxB0di6', 'JxOzbofIbs'],
    jeunesse: ['Tg3wI2JpTz', 'kXTrOHff6C', 'DNEPUjJkGQ'],
  },
  series: menuSeries,
  seriesAlphanumericIndexMap: {},
  loading: {},
  errors: {},
};

export const loadSerie = createAsyncThunk(LOAD_SERIE, async (params, { getState, dispatch }) => {
  const serieObjectId = params?.serieObjectId || null;
  const options = params?.options || null;

  const clientToken = getClientToken(getState());
  const serie = await loadSerieApi(clientToken, serieObjectId, options);

  // extract albums first because it will be mutated by storeSeries
  const { albums, prints } = extractAlbumAndPrints(
    [serie].reduce((tab, cur) => tab.concat(cur.albums), []),
  );

  dispatch(storeSeries({ series: [serie] }));
  if (albums.every((album) => typeof album !== 'string')) {
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));
  }
});

export const loadSeries = createAsyncThunk(LOAD_SERIES, async (params, { getState, dispatch }) => {
  const options = params?.options || null;

  const clientToken = getClientToken(getState());
  const series = await loadSeriesApi(clientToken, options);

  // extract albums first because it will be mutated by storeSeries
  // const albums = series.reduce((tab, cur) => tab.concat(cur.albums), []);
  const { albums, prints } = extractAlbumAndPrints(
    series.reduce((tab, cur) => tab.concat(cur.albums), []),
  );

  dispatch(storeSeries({ series }));
  if (albums.every((album) => typeof album !== 'string')) {
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));
  }

  return { series, options };
});

export const loadSeriesAtAlphanumericIndex = createAsyncThunk(
  LOAD_SERIES_ALPHANUMERIC_INDEX,
  async (params, { getState }) => {
    const index = params?.index || null;

    const clientToken = getClientToken(getState());
    const series = await loadSeriesAtAlphanumericIndexApi(clientToken, index);

    return { index, series };
  },
);

const seriesSlice = createSlice({
  name: 'series',
  initialState,
  reducers: {
    resetSerieReducer: {
      reducer: (state, action) => initialState,
    },
    storeSeries: {
      reducer: (state, action) => {
        const series = action.payload.series.reduce(
          (map, serie) => {
            serie.albums = serie.albums.map((album) =>
              typeof album === 'string' ? album : album.objectId,
            );
            map[serie.objectId] = serie;
            return map;
          },
          { ...state.series },
        );

        state.series = series;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadSerie
      .addCase(loadSerie.pending, (state, action) => {
        state.loading[LOAD_SERIE] = true;
        state.errors[LOAD_SERIE] = null;
      })
      .addCase(loadSerie.fulfilled, (state, action) => {
        state.loading[LOAD_SERIE] = false;
        state.errors[LOAD_SERIE] = null;
      })
      .addCase(loadSerie.rejected, (state, action) => {
        state.loading[LOAD_SERIE] = false;
        state.errors[LOAD_SERIE] = action.error;
      })
      // loadSeries
      .addCase(loadSeries.pending, (state, action) => {
        state.loading[LOAD_SERIES] = true;
        state.errors[LOAD_SERIES] = null;
      })
      .addCase(loadSeries.fulfilled, (state, action) => {
        const options = action.payload.options;
        const serieObjectIds = action.payload.series.map((serie) => serie.objectId);

        let mapOfSerieIdsClassic = state.mapOfSerieIdsClassic;
        let mapOfSerieIdsTopRated = state.mapOfSerieIdsTopRated;
        let mapOfSerieIdsCategoriesAndGenres = state.mapOfSerieIdsCategoriesAndGenres;
        let mapOfSeriesIdsTeamFavorite = state.mapOfSeriesIdsTeamFavorite;

        const key =
          (options.category || '') + (options.genre || '') + (options.tags || '') || 'all';

        if (options && options.is_classic) {
          mapOfSerieIdsClassic = Object.assign({}, mapOfSerieIdsClassic);
          mapOfSerieIdsClassic[key] = serieObjectIds;
        }
        if (options && options.is_top_rated) {
          mapOfSerieIdsTopRated = Object.assign({}, mapOfSerieIdsTopRated);
          mapOfSerieIdsTopRated[key] = serieObjectIds;
        }
        if (options && options.is_team_favorite) {
          mapOfSeriesIdsTeamFavorite = Object.assign({}, mapOfSeriesIdsTeamFavorite);
          mapOfSeriesIdsTeamFavorite[key] = serieObjectIds;
        }

        if (options && !options.is_classic && !options.is_top_rated && !options.is_team_favorite) {
          mapOfSerieIdsCategoriesAndGenres = Object.assign({}, mapOfSerieIdsCategoriesAndGenres);
          mapOfSerieIdsCategoriesAndGenres[key] = serieObjectIds;
        }

        state.mapOfSerieIdsClassic = mapOfSerieIdsClassic;
        state.mapOfSerieIdsTopRated = mapOfSerieIdsTopRated;
        state.mapOfSerieIdsCategoriesAndGenres = mapOfSerieIdsCategoriesAndGenres;
        state.mapOfSeriesIdsTeamFavorite = mapOfSeriesIdsTeamFavorite;

        state.loading[LOAD_SERIES] = false;
        state.errors[LOAD_SERIES] = null;
      })
      .addCase(loadSeries.rejected, (state, action) => {
        state.loading[LOAD_SERIES] = false;
        state.errors[LOAD_SERIES] = action.error;
      })
      // loadSeriesAtAlphanumericIndex
      .addCase(loadSeriesAtAlphanumericIndex.pending, (state, action) => {
        state.loading[LOAD_SERIES_ALPHANUMERIC_INDEX] = true;
        state.errors[LOAD_SERIES_ALPHANUMERIC_INDEX] = null;
      })
      .addCase(loadSeriesAtAlphanumericIndex.fulfilled, (state, action) => {
        state.seriesAlphanumericIndexMap[action.payload.index] = action.payload.series;
        state.loading[LOAD_SERIES_ALPHANUMERIC_INDEX] = false;
        state.errors[LOAD_SERIES_ALPHANUMERIC_INDEX] = null;
      })
      .addCase(loadSeriesAtAlphanumericIndex.rejected, (state, action) => {
        state.loading[LOAD_SERIES_ALPHANUMERIC_INDEX] = false;
        state.errors[LOAD_SERIES_ALPHANUMERIC_INDEX] = action.error;
      });
  },
});

export default seriesSlice.reducer;

export const { storeSeries, resetSerieReducer } = seriesSlice.actions;
