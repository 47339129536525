export const convertAsString = (authors) => {
  if (Array.isArray(authors)) {
    return authors
      .map((author) => {
        if (!author.firstName && !author.lastName && author.pseudo) return author.pseudo;
        return [author.firstName, author.lastName].filter((value) => value).join(' ');
      })
      .join('; ');
  }
  return authors;
};

/**
 * Zep => Zep (pseudo)
 * Bastien Vivès => B. Vivès (firstname and lastname)
 * Hergé => Hergé (lastname only)
 * Tibet - Gilbert Gascard => Tibet (pseudo, firstname and lastname)
 */
export const compressAuthorName = (author) => {
  if (author.pseudo) return author.pseudo;

  if (author.firstName && author.lastName)
    return `${author.firstName.charAt(0)}. ${author.lastName}`;

  if ((author.firstName && !author.lastName) || (author.lastName && !author.firstName))
    return author.fullName;

  return null;
};

/**
 * Zep => Zep (pseudo)
 * Bastien Vivès => Bastien Vivès (firstname, lastname)
 * Hergé => Hergé (lastname only)
 * Tibet - Gilbert Gascard => Tibet - Gilbert Gascard (pseudo, firstname and lastname)
 */
export const getAuthorAllNames = (author) => {
  const string = [];
  if (author.pseudo) {
    string.push(author.pseudo);
  }

  if (author.fullName && string.length) {
    string.push(' - ');
  }
  string.push(author.fullName);

  return string.length ? string.join('') : null;
};

/**
 * 1980 (yearOfBirth only)
 * 1980 - 2022 (yearOfBirth and yearOfDeath)
 * - 2020 (yearOfDeath only)
 */
export const compressDates = (author) => {
  const string = [];
  if (author.yearOfBirth) {
    string.push(author.yearOfBirth);
  }
  if (author.yearOfDeath) {
    string.push(' - ' + author.yearOfDeath);
  }

  return string.length ? string.join('') : null;
};
