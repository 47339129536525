import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { loadArticles } from 'bubble-reducers/src/reducers/articles';

import ArticleHorizontalBigCard from '@/components/ArticleHorizontalBigCard/ArticleHorizontalBigCard';
import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';

const NArtHomeHeader = () => {
  const dispatch = useDispatch();

  const highlightedArticleObjectIds = useSelector(
    (state) => state.articles.highlightedArticleObjectIds,
  );
  const articles = useSelector((state) => state.articles.articlesMapByObjectId);

  useEffect(() => {
    dispatch(loadArticles({ options: { highlighted: 1 } }));
  }, []);

  const highlightedArticleLeft = articles[highlightedArticleObjectIds[0]];

  return (
    <>
      <div className="d-none d-lg-block">
        <div className="px-paper bb-background-light-grey">
          <div className="row py-paper">
            <div className="col-8">
              <ArticleHorizontalBigCard article={highlightedArticleLeft} />
            </div>
            <div className="col-4">
              <div className="d-flex h-100 flex-column justify-content-between">
                {highlightedArticleObjectIds.slice(1).map((articleObjectId) => (
                  <ArticleHorizontalCardWhite
                    key={`article-${articleObjectId}`}
                    article={articles[articleObjectId]}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-block d-lg-none">
        <div className="bb-background-light-grey">
          <ArticleHorizontalBigCard article={highlightedArticleLeft} />
          <div className="d-flex h-100 px-4 py-4 flex-column justify-content-between">
            {highlightedArticleObjectIds.slice(1).map((articleObjectId) => (
              <div key={`article-${articleObjectId}`} className="py-2">
                <ArticleHorizontalCardWhite article={articles[articleObjectId]} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NArtHomeHeader;
