import PropTypes from 'prop-types';
import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';

import { tabs } from '../Store';

const UserProfileNavbar = (props) => {
  const store = props.store || {};
  const location = useLocation();
  const currentTab = location.pathname.split('/')[4] || '';

  return (
    <ul className="nav nav-tabs paper-navbar pt-4">
      <StoreHeaderNavItem
        active={!currentTab}
        text={<Icon className="bb-l-text-size" name="home" />}
        path={tabs.HOME}
        store={store}
        onChange={props.onChange}
      />
      <StoreHeaderNavItem
        active={currentTab === tabs.STORE}
        text={'Notre librairie'}
        path={`/${tabs.STORE}`}
        store={store}
        onChange={props.onChange}
      />
    </ul>
  );
};

const StoreHeaderNavItem = ({ store, text, active, path, onChange }) => {
  return (
    <li className="nav-item d-flex">
      <Link
        onClick={onChange}
        className={`no-decoration nav-link px-lg-5 px-3 py-3 transition bb-medium-text-size ${
          active ? ' bg-white text-black' : 'text-white'
        }`}
        to={`/${store.permalink}/store/${store.objectId}${path}`}
      >
        <span>{text}</span>
      </Link>
    </li>
  );
};

UserProfileNavbar.propTypes = {
  isGuest: PropTypes.bool,
};

export default UserProfileNavbar;
