import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { createAddressApi, deleteAddressApi, editAddressApi } from '../services/api';

import { getClientToken } from '../selectors';
import { loadUserAddresses } from './user';

const CREATE_ADDRESS = 'addresses/createAddress';
const EDIT_ADDRESS = 'addresses/editAddress';
const DELETE_ADDRESS = 'addresses/deleteAddress';

const initialState = {
  loading: {},
  errors: {},
};

export const createAddress = createAsyncThunk(
  CREATE_ADDRESS,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const address = params?.address || null;

    const clientToken = getClientToken(getState());
    const createdAddress = await createAddressApi(clientToken, address);
    dispatch(loadUserAddresses({ userObjectId }));

    return { address: createdAddress };
  },
);

export const editAddress = createAsyncThunk(
  EDIT_ADDRESS,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const addressObjectId = params?.addressObjectId || null;
    const address = params?.address || null;

    const clientToken = getClientToken(getState());
    const updatedAddress = await editAddressApi(clientToken, addressObjectId, address);
    dispatch(loadUserAddresses({ userObjectId }));

    return { address: updatedAddress };
  },
);

export const deleteAddress = createAsyncThunk(
  DELETE_ADDRESS,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const addressObjectId = params?.addressObjectId || null;

    const clientToken = getClientToken(getState());
    const updatedAddress = await deleteAddressApi(clientToken, addressObjectId);
    dispatch(loadUserAddresses({ userObjectId }));

    return { address: updatedAddress };
  },
);

const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // createAddress
      .addCase(createAddress.pending, (state, action) => {
        state.loading[CREATE_ADDRESS] = true;
        state.errors[CREATE_ADDRESS] = null;
      })
      .addCase(createAddress.fulfilled, (state, action) => {
        state.loading[CREATE_ADDRESS] = false;
        state.errors[CREATE_ADDRESS] = null;
      })
      .addCase(createAddress.rejected, (state, action) => {
        state.loading[CREATE_ADDRESS] = false;
        state.errors[CREATE_ADDRESS] = action.error;
      })
      // editAddress
      .addCase(editAddress.pending, (state, action) => {
        state.loading[EDIT_ADDRESS] = true;
        state.errors[EDIT_ADDRESS] = null;
      })
      .addCase(editAddress.fulfilled, (state, action) => {
        state.loading[EDIT_ADDRESS] = false;
        state.errors[EDIT_ADDRESS] = null;
      })
      .addCase(editAddress.rejected, (state, action) => {
        state.loading[EDIT_ADDRESS] = false;
        state.errors[EDIT_ADDRESS] = action.error;
      })
      // deleteAddress
      .addCase(deleteAddress.pending, (state, action) => {
        state.loading[DELETE_ADDRESS] = true;
        state.errors[DELETE_ADDRESS] = null;
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.loading[DELETE_ADDRESS] = false;
        state.errors[DELETE_ADDRESS] = null;
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        state.loading[DELETE_ADDRESS] = false;
        state.errors[DELETE_ADDRESS] = action.error;
      });
  },
});

export default addressesSlice.reducer;
