import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

const ListSelection = (props) => {
  return (
    <div>
      <div className="fw-bold mb-1">Sélection</div>
      <ul className="ul-no-indent">
        {/* <li className="li-no-indent">Les grands classiques (todo)</li> */}
        <li className="li-no-indent">
          <Link className="text-black no-decoration" to="/agenda">
            Nouveautés et prochaines sorties
          </Link>
        </li>
        <li className="li-no-indent">
          <Link className="text-black no-decoration" to="/tops/topBestNotedSeriesAllTime">
            Les mieux notées
          </Link>
        </li>
        {/* <li className="li-no-indent">Coups de coeur de Bubble (todo)</li> */}
        {/* <li className="li-no-indent">Meilleures ventes du moment (todo)</li> */}
      </ul>
      {!!props.authors.length && <div className="mt-3 fw-bold mb-1">Auteurs / dessinateurs</div>}
      <ul className="ul-no-indent">
        {props.authors
          .filter((row) => row)
          .map((author) => (
            <li key={`topauthor_${author.objectId}`}>
              <Link
                className="text-black no-decoration"
                to={`/${author.permalink}/author/${author.objectId}`}
              >
                {author.displayName}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

ListSelection.propTypes = {
  category: PropTypes.object,
  genre: PropTypes.object,
  tags: PropTypes.array,
  authors: PropTypes.array,
};

export default ListSelection;
