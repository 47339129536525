import React from 'react';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadArticles } from 'bubble-reducers/src/reducers/articles';

import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';

export default function GenericNewsSection({ maxArticlesCount, title = 'Actualités' }) {
  const dispatch = useDispatch();
  const articlesPage = useSelector((state) => state.articles.paginatedArticleMapByQuery['']) || {};
  const articleObjectIds = articlesPage.articles || [];

  useEffect(() => {
    dispatch(loadArticles());
  }, [dispatch]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="h2 fw-bold">{title}</div>
        <Link to="/9emeart/articles">Voir tout</Link>
      </div>

      {articleObjectIds.slice(0, maxArticlesCount || 4).map((articleObjectId) => (
        <div key={`generic_brief_${articleObjectId}`} className="pb-4">
          <ArticleHorizontalCardWhite articleObjectId={articleObjectId} />
        </div>
      ))}
    </>
  );
}
