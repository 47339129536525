import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

import '../SearchResults.css';

const SearchStoreCard = (props) => {
  const store = props.store || {};

  return (
    <Link
      to={`/${store.permalink}/store/${store.objectId}`}
      className="d-flex no-decoration align-items-center"
    >
      <img
        className="rounded border small-square-size me-2"
        alt="img"
        src={store.logo || placeholderProfileJpg}
      />
      <div>
        <div>{store.name}</div>
        <div>
          {store.zipcode}&nbsp;{store.city}
        </div>
      </div>
    </Link>
  );
};

SearchStoreCard.propTypes = {
  store: PropTypes.shape({
    objectId: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    zipcode: PropTypes.string,
  }),
};

export default SearchStoreCard;
