import PropTypes from 'prop-types';
import React from 'react';

import bubbleConfig from 'bubble-config';
import { Helmet } from 'react-helmet-async';

import { TEXT } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

const BubbleHelmet = (props) => {
  const seo = props.seo || {};
  const html = seo.html || {};

  return (
    <Helmet>
      <title>
        {[
          bubbleUtils.env.isProductionEnvironment ? '' : '[DEV] ',
          html.title ? html.title + ' - ' : '',
          TEXT.WEBSITE_TITLE,
        ].join('')}
      </title>
      {html.description ? <meta name="description" content={html.description} /> : ''}
      {html.keywords ? <meta name="keywords" content={html.keywords} /> : ''}
      {html.canonical ? <link rel="alternate" hreflang="fr" href={html.canonical} /> : ''}
      {html.canonical ? <link rel="canonical" href={html.canonical} /> : ''}
      {html.noIndex ? <meta name="robots" content="noindex" /> : ''}

      <meta property="fb:app_id" content={bubbleConfig.facebook.appId} />

      {/* https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/abouts-cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@BubbleBD_" />
      <meta name="twitter:creator" content="@BubbleBD_" />
      {Object.entries(seo.twitterCard || {})
        .filter(([key, value]) => !!value)
        .map(([key, value]) => (
          <meta key={key} name={key} content={value} />
        ))}

      {/* https://ogp.me/ */}
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:site_name" content={TEXT.WEBSITE_TITLE} />
      {Object.entries(seo.openGraph || {})
        .filter(([key, value]) => !!value)
        .map(([key, value]) =>
          (Array.isArray(value) ? value : [value]).map((value) => (
            <meta key={key} property={key} content={value} />
          )),
        )}

      <script type="application/ld+json">{JSON.stringify(seo.structuredData)}</script>
    </Helmet>
  );
};

BubbleHelmet.propTypes = {
  object: PropTypes.object,
};

const areEqual = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

export default React.memo(BubbleHelmet, areEqual);
