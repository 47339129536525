import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadPaymentMethodsApi, updatePaymentMethodApi } from '../services/api';

import { getClientToken } from '../selectors';

export const LOAD_PAYMENT_METHODS = 'paymentMethods/loadPaymentMethods';
export const UPDATE_PAYMENT_METHOD = 'paymentMethods/updatePaymentMethod';

const initialState = {
  paymentMethods: [],
  loading: {},
  updating: {},
  errors: {},
};

export const loadPaymentMethods = createAsyncThunk(
  LOAD_PAYMENT_METHODS,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());

    const paymentMethods = await loadPaymentMethodsApi(clientToken, userObjectId);
    return { paymentMethods };
  },
);

export const updatePaymentMethod = createAsyncThunk(
  UPDATE_PAYMENT_METHOD,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;
    const paymentMethod = params?.paymentMethod || null;

    const clientToken = getClientToken(getState());

    const paymentMethods = await updatePaymentMethodApi(clientToken, userObjectId, paymentMethod);
    return { paymentMethods };
  },
);

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    resetPaymentMethodsReducer: {
      reducer: (state, action) => initialState,
    },
    resetPaymentMethodsErrors: {
      reducer: (state, action) => {
        state.errors = { ...initialState.errors };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadPaymentMethods
      .addCase(loadPaymentMethods.pending, (state, action) => {
        state.paymentMethods = [];
        state.loading[LOAD_PAYMENT_METHODS] = true;
        state.errors[LOAD_PAYMENT_METHODS] = null;
      })
      .addCase(loadPaymentMethods.fulfilled, (state, action) => {
        const paymentMethods = action.payload.paymentMethods;
        // paymentMethods can be null if the user has no stripe customer
        state.paymentMethods = paymentMethods?.length ? paymentMethods : [];

        state.loading[LOAD_PAYMENT_METHODS] = false;
        state.errors[LOAD_PAYMENT_METHODS] = null;
      })
      .addCase(loadPaymentMethods.rejected, (state, action) => {
        state.paymentMethods = [];
        state.loading[LOAD_PAYMENT_METHODS] = false;
        state.errors[LOAD_PAYMENT_METHODS] = action.error;
      })
      // updatePaymentMethod
      .addCase(updatePaymentMethod.pending, (state, action) => {
        state.paymentMethods = [];
        state.loading[UPDATE_PAYMENT_METHOD] = true;
        state.errors[UPDATE_PAYMENT_METHOD] = null;
      })
      .addCase(updatePaymentMethod.fulfilled, (state, action) => {
        const paymentMethods = action.payload.paymentMethods;
        // paymentMethods can be null if the user has no stripe customer
        state.paymentMethods = paymentMethods?.length ? paymentMethods : [];

        state.loading[UPDATE_PAYMENT_METHOD] = false;
        state.errors[UPDATE_PAYMENT_METHOD] = null;
      })
      .addCase(updatePaymentMethod.rejected, (state, action) => {
        state.paymentMethods = [];
        state.loading[UPDATE_PAYMENT_METHOD] = false;
        state.errors[UPDATE_PAYMENT_METHOD] = action.error;
      });
  },
});

export default paymentMethodsSlice.reducer;

export const { resetPaymentMethodsReducer, resetPaymentMethodsErrors } =
  paymentMethodsSlice.actions;
