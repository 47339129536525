import PropTypes from 'prop-types';
import React from 'react';

import { useLocation } from 'react-router-dom';

import GeneralLink from '@/components/GeneralLink/GeneralLink';

import './HighlightBanner.css';

const HighlightBanner = (props) => {
  const location = useLocation();
  return (
    <GeneralLink
      className={props.className}
      to={props.link || `${location.pathname}${location.search}`}
    >
      <picture>
        <source srcSet={props.imageUrl} media="(min-width: 830px)" />
        <img
          className={`w-100 image-container ${props.slim ? 'container-slim' : ''}`}
          alt={props.alt || 'img'}
          src={props.imageUrlMobile || props.imageUrl}
        />
      </picture>
    </GeneralLink>
  );
};

HighlightBanner.propTypes = {
  imageUrl: PropTypes.string,
  imageUrlMobile: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
};

export const areEqual = (prevProps, nextProps) =>
  prevProps.imageUrl === nextProps.imageUrl &&
  prevProps.imageUrlMobile === nextProps.imageUrlMobile &&
  prevProps.alt === nextProps.alt &&
  prevProps.link === nextProps.link;

export default React.memo(HighlightBanner, areEqual);
