import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { LOAD_ALBUMS } from 'bubble-reducers/src/reducers/albums';
import { getAlbumIdsForAuthorId, getAuthor } from 'bubble-reducers/src/selectors';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import AlbumCardPlaceholder from '@/components/AlbumCard/variants/AlbumCardPlaceholder';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import AuthorSerieCard from '../components/AuthorSerieCard/AuthorSerieCard';

const defaultArray = [];
const defautObject = {};

export default function AuthorAllSection({ authorObjectId }) {
  const lastPublishedAlbumsObjectIds = useSelector((state) =>
    getAlbumIdsForAuthorId(state, authorObjectId),
  );
  const isLoadingAlbums = useSelector((state) => state.albums.loading[LOAD_ALBUMS]);
  const author = useSelector((state) => getAuthor(state, authorObjectId)) || defautObject;
  const { permalink } = useParams();
  const authorSeriesObjectIdsMap = useSelector(
    (state) => state.authors.authorSeriesObjectIdsMap[authorObjectId],
  );
  const authorSeriesObjectIdsMapLimitedSet = useMemo(
    () => authorSeriesObjectIdsMap?.slice(0, 6),
    [authorSeriesObjectIdsMap],
  );

  const memoizedAlbums = useMemo(
    () =>
      lastPublishedAlbumsObjectIds?.slice(0, 4).map((albumObjectId, index) => (
        <div
          key={`author_lastalbum_${albumObjectId}`}
          className={`col col-7${index >= 3 ? ' d-block d-md-none d-lg-block' : ''}`}
        >
          <AlbumCard albumObjectId={albumObjectId} />
        </div>
      )),
    [lastPublishedAlbumsObjectIds],
  );

  const albumList = useMemo(
    () =>
      isLoadingAlbums && !lastPublishedAlbumsObjectIds?.length
        ? [0, 1, 2, 3, 4].map((_, index) => [
            <div key={`author_lastalbum_ph_${index}`} className={`col col-7`}>
              <AlbumCardPlaceholder />
            </div>,
          ])
        : memoizedAlbums,
    [isLoadingAlbums, lastPublishedAlbumsObjectIds, memoizedAlbums],
  );

  const serieList = useMemo(
    () =>
      authorSeriesObjectIdsMapLimitedSet?.map((serieObjectId) => (
        <div className="col-12 col-md-6 col-lg-4 pb-3" key={`author_${serieObjectId}`}>
          <AuthorSerieCard serieObjectId={serieObjectId} />
        </div>
      )),
    [authorSeriesObjectIdsMapLimitedSet],
  );

  const rootPath = '/' + [permalink, 'author', authorObjectId].join('/');

  return (
    <>
      <TitledListContainer
        noWrap
        rowClassName="row-cols-lg-5 row-cols-7 row-cols-md-3"
        topRightLink={<Link to={rootPath + '/albums#ct1'}>Voir tous les albums</Link>}
        rightLinkWrapToBottomThreshold="md"
        title={`Les albums de ${author.displayName || "l'auteur"}`}
        list={albumList || defaultArray}
        className="py-2"
      />
      <hr className="my-4" />
      <TitledListContainer
        topRightLink={<Link to={rootPath + '/series#ct1'}>Voir toutes les séries</Link>}
        rightLinkWrapToBottomThreshold="md"
        title={`Les séries de ${author.displayName || "l'auteur"}`}
        list={serieList || defaultArray}
        className="py-2"
      />
    </>
  );
}
